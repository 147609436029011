import { getHistory } from './manager';
import redoItem from './redoItem';
import redoSlide from './redoSlide';

const redo = (params) => {
  let latestAction;

  const futureHistoriesOfSlide = getHistory({ ...params, key: 'Future' });
  const futureHistoriesOfPresentation = getHistory({ ...params, key: 'Future', slideId: '' });

  // Find out which one is latest. Item or slide?
  if (futureHistoriesOfSlide.length > 0 && futureHistoriesOfPresentation.length > 0) {
    const lastItemAction = futureHistoriesOfSlide[futureHistoriesOfSlide.length - 1];
    const lastSlideAction = futureHistoriesOfPresentation[futureHistoriesOfPresentation.length - 1];

    if (lastItemAction.time >= lastSlideAction.time) {
      latestAction = 'Item';
    } else {
      latestAction = 'Slide';
    }
  } else if (futureHistoriesOfSlide.length > 0) {
    latestAction = 'Item';
  } else if (futureHistoriesOfPresentation.length > 0) {
    latestAction = 'Slide';
  }

  if (latestAction === 'Item') {
    redoItem(params);
  } else if (latestAction === 'Slide') {
    redoSlide(params);
  }
};

export default redo;
