import { Controller } from 'stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {
  addCollaborator(event) {
    const formData = new FormData();
    formData.append('user_id', document.getElementById('new_user_id').value);

    Rails.ajax({
      url: `/posts/${event.target.getAttribute('data-post-id')}/add_collaborator`,
      type: 'POST',
      data: formData,
    });
  }

  selectCollaborator(event) {
    this.addCollaborator(event);
    event.target.querySelector('input').value = '';
  }

  cancel(event) {
    event.target.closest('.post-collaborators-container').classList.add('hidden');
  }
}
