import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['header']

  initialize() {
    this.fixed = false;
  }

  onScroll(event) {
    const bgColor = this.headerTarget.getAttribute('data-bg-color');

    if (this.fixed === false && window.scrollY > 25) {
      this.headerTarget.classList.remove('bg-transparent');
      this.headerTarget.style.background = bgColor;

      this.fixed = true;
    } else if (this.fixed === true && window.scrollY < 25) {
      this.headerTarget.classList.add('bg-transparent');
      this.headerTarget.style.background = 'transparent';

      this.fixed = false;
    }
  }
}