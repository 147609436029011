const fixSlideIndexes = () => {
  const slides = document.querySelectorAll('#pageThumbsContainer .slideContainer[data-is-deleted="false"]');
  let index = 1;
  slides.forEach((slide) => {
    slide.querySelector('.slideIndex').innerHTML = index;
    index += 1;
  });

  const allSlides = document.querySelectorAll('#pageThumbsContainer .slideContainer');
  index = 1;
  allSlides.forEach((slide) => {
    slide.dataset.position = index;
    index += 1;
  });
};

export default fixSlideIndexes;
