/* jshint esversion: 6 */

import { Controller } from 'stimulus';
import { createRequest } from '../helpers/createRequest';
import { htmlToElement } from '../helpers/dom';

export default class extends Controller {
  unenrollUser(event) {
    if (!window.confirm('Do you really want to remove the user from the studio?')) {
      return;
    }
    const { userId } = event.target.parentNode.dataset;
    const formData = new FormData();
    formData.append('user_id', userId);

    fetch(createRequest(`/studios/${this.data.get('studio-id')}/remove_user_from_studio`, 'post', formData))
      .then((response) => response.text())
      .then((text) => {
        if (text === '') {
          event.target.parentNode.parentNode.remove();
        } else {
          event.target.parentNode.parentNode.replaceWith(htmlToElement(text));
        }
      });
  }
}
