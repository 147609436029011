import 'whatwg-fetch'

const getHeaders = () => {
  let headers = new window.Headers({
    'X-Requested-With': 'XMLHttpRequest'
  })

  const csrfToken = document.head.querySelector("[name='csrf-token']").content
  if (csrfToken) {
    headers.append('X-CSRF-Token', csrfToken)
  }

  return headers
}

export const createRequest = (url, method = 'get', body) => {
  const request = new Request(url, {
    headers: getHeaders(),
    method: method,
    credentials: 'same-origin',
    body: body
  })
  return request
}
