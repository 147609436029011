const setDataAttributes = (itemId, x1, x2, y1, y2) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return false;

  const actualLine = target.closest('.item-container').querySelector('.actual-line');
  const lineHandler = target.closest('.item-container').querySelector('.line-handler');
  const lineSnapper = target.closest('.item-container').querySelector('.line-snapper');
  const handlerFrame = window.mapItems.get(lineHandler);
  const snapperFrame = window.mapItems.get(lineSnapper);

  const diffX = parseFloat(snapperFrame.get('transform', 'translateX')) - parseFloat(handlerFrame.get('transform', 'translateX'));
  const diffY = parseFloat(snapperFrame.get('transform', 'translateY')) - parseFloat(handlerFrame.get('transform', 'translateY'));

  let angle = parseInt(snapperFrame.get('transform', 'rotate'), 0);
  let diffRightHandleX = -1;
  let diffRightHandleY = -0.5;
  if (angle === -90) diffRightHandleY = 0.5;
  if (angle === -180) diffRightHandleX = 0;

  if (angle === -0) angle = 0;
  if (angle === 90) angle = -90;
  if (angle === -180) angle = -0;

  const lineStart = target.closest('.item-container').querySelector('.handle-left');
  const lineEnd = target.closest('.item-container').querySelector('.handle-right');
  const snapHandleLeft = target.closest('.item-container').querySelector('.snap-handle-left');
  const snapHandleRight = target.closest('.item-container').querySelector('.snap-handle-right');

  const frameLineStart = window.mapItems.get(lineStart);
  const frameLineEnd = window.mapItems.get(lineEnd);
  const frameSnapHandleLeft = window.mapItems.get(snapHandleLeft);
  const frameSnapHandleRight = window.mapItems.get(snapHandleRight);

  snapperFrame.set('transform', 'translateX', `${x1}px`);
  snapperFrame.set('transform', 'translateY', `${y1}px`);
  handlerFrame.set('transform', 'translateX', `${x1 - diffX}px`);
  handlerFrame.set('transform', 'translateY', `${y1 - diffY}px`);
  frameLineStart.set('transform', 'translateX', `${x1 - 10}px`);
  frameLineStart.set('transform', 'translateY', `${y1 - 10}px`);
  frameLineEnd.set('transform', 'translateX', `${x2 - 10}px`);
  frameLineEnd.set('transform', 'translateY', `${y2 - 10}px`);
  frameSnapHandleLeft.set('transform', 'translateX', `${x1}px`);
  frameSnapHandleLeft.set('transform', 'translateY', `${y1 - 0.5}px`);
  frameSnapHandleRight.set('transform', 'translateX', `${x2 + diffRightHandleX}px`);
  frameSnapHandleRight.set('transform', 'translateY', `${y2 + diffRightHandleY}px`);

  lineStart.style.cssText += frameLineStart.toCSS();
  lineEnd.style.cssText += frameLineEnd.toCSS();
  snapHandleLeft.style.cssText += frameSnapHandleLeft.toCSS();
  snapHandleRight.style.cssText += frameSnapHandleRight.toCSS();
  lineHandler.style.cssText += handlerFrame.toCSS();
  lineSnapper.style.cssText += snapperFrame.toCSS();

  target.setAttribute('data-x1', x1);
  target.setAttribute('data-y1', y1);
  target.setAttribute('data-x2', x2);
  target.setAttribute('data-y2', y2);
  actualLine.setAttribute('x1', x1);
  actualLine.setAttribute('x2', x2);
  actualLine.setAttribute('y1', y1);
  actualLine.setAttribute('y2', y2);
  lineSnapper.setAttribute('data-x1', x1);
  lineSnapper.setAttribute('data-y1', y1);
  lineSnapper.setAttribute('data-x2', x2);
  lineSnapper.setAttribute('data-y2', y2);
  lineHandler.setAttribute('data-x1', x1);
  lineHandler.setAttribute('data-y1', y1);
  lineHandler.setAttribute('data-x2', x2);
  lineHandler.setAttribute('data-y2', y2);
};

export default setDataAttributes;
