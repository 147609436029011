const showNewCommentBox = (positionX, positionY) => {
  let container = document.querySelector('#newCommentContainer #newComment');
  positionX = parseInt(positionX) + 8;
  positionY = parseInt(positionY) + 35;

  container.style.transform = `translate(${positionX}px,${positionY}px)`;
  container.style.display = 'flex';
  container.dataset.x = positionX;
  container.dataset.y = positionY;

  container.querySelector('#comment_body').focus();
};

export default showNewCommentBox;
