import itemType from '../itemType';
import updateRect from '../updateRect';
import setDataAttributes from '../setDataAttributes';

const fillItem = (target) => {
  const frame = window.mapItems.get(target);

  frame.set('width', window.slideWidth);
  frame.set('height', window.slideHeight);
  frame.set('transform', 'translateX', '0px');
  frame.set('transform', 'translateY', '0px');

  const moveableContainer = target.querySelector('.moveableContainer');
  if (moveableContainer) {
    moveableContainer.style.width = `${window.slideWidth}px`;
    moveableContainer.style.height = `${window.slideHeight}px`;
  }

  target.style.width = `${window.slideWidth}px`;
  target.style.height = `${window.slideHeight}px`;
  target.style.cssText += frame.toCSS();
  setDataAttributes(target.dataset.itemId);
};

const fill = (itemId) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;

  switch (itemType(itemId)) {
    case 'image':
    case 'camera_asset':
      fillItem(target);
      break;
    default:
      break;
  }

  updateRect(itemId);
  window.hideContextMenus();
};

export default fill;
