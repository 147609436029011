// This method updates moveable Frame object via data attributes in DOM
import itemType from './itemType';

const updateFrameForItems = (target, frame) => {
  frame.set('width', parseFloat(target.dataset.width));
  frame.set('height', parseFloat(target.dataset.height));
  frame.set('transform', 'translateX', `${parseFloat(target.dataset.translateX)}px`);
  frame.set('transform', 'translateY', `${parseFloat(target.dataset.translateY)}px`);
  frame.set('transform', 'rotate', `${parseInt(target.dataset.rotate, 0)}deg`);

  window.mapItems.set(target, frame);
};

const updateFrameForLines = (target, frame) => {
  frame.set('width', parseFloat(target.dataset.width));
  frame.set('height', parseFloat(target.dataset.height));
  frame.set('transform', 'translateX', `${parseFloat(target.dataset.translateX)}px`);
  frame.set('transform', 'translateY', `${parseFloat(target.dataset.translateY)}px`);
  frame.set('transform', 'rotate', `${parseInt(target.dataset.rotate, 0)}deg`);

  window.mapItems.set(target, frame);
};

const updateFrame = (itemId) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return false;

  const frame = window.mapItems.get(target);

  if (frame) {
    if (itemType(itemId) === 'line') {
      updateFrameForLines(target, frame);
    } else {
      updateFrameForItems(target, frame);
    }
  }

  return frame;
};

export default updateFrame;
