const updatePanel = (itemId, target) => {
  const panel = document.querySelector(`#item_panel_${itemId}`);
  if (!panel) return;

  // Top
  if (panel.querySelector('#item_translate_y')) {
    panel.querySelector('#item_translate_y').value = parseInt(target.dataset.translateY || 0, 0);
  }

  // Left
  if (panel.querySelector('#item_translate_x')) {
    panel.querySelector('#item_translate_x').value = parseInt(target.dataset.translateX || 0, 0);
  }

  // Width
  if (panel.querySelector('#item_width')) {
    panel.querySelector('#item_width').value = parseInt(target.dataset.width || 0, 0);
  }

  // Height
  if (panel.querySelector('#item_height')) {
    panel.querySelector('#item_height').value = parseInt(target.dataset.height || 0, 0);
  }
};

const setDataAttributes = (itemId) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return false;

  const frame = window.mapItems.get(target);
  const attributes = frame.get('transform');

  target.dataset.translateX = parseFloat(attributes.translateX);
  target.dataset.translateY = parseFloat(attributes.translateY);
  target.dataset.rotate = `${parseInt(attributes.rotate, 0) || 0}deg`;
  if (frame.get('opacity')) target.dataset.opacity = parseFloat(frame.get('opacity')) * 100;
  if (frame.get('width')) target.dataset.width = frame.get('width');
  if (frame.get('height')) target.dataset.height = frame.get('height');

  updatePanel(itemId, target);
};

export default setDataAttributes;
