import { getStroke } from 'perfect-freehand';
import ApplicationController from './application_controller';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';
import { isTouchDevice } from '../helpers/utils';

export default class extends ApplicationController {
  connect() {
    super.connect();
    document.querySelector(`div.canvas.user-${window.currentUserId}`)
      .addEventListener('storeSnapshot', this.storeSnapshot);
  }

  storeSnapshot(event) {
    console.log(event);
    storeItemSnapshotForUndo(event.detail.itemId, 'ADD_SLIDE_ITEM');
  }

  initialize() {
    super.initialize();
    this.isDrawing = false;
    this.points = [];
    this.pathOptions = {
      size: 6,
      smoothing: 0.5,
      thinning: 0.5,
      streamline: 0.5,
      easing: (t) => t,
      start: {
        taper: 0,
        cap: true,
      },
      end: {
        taper: 0,
        cap: true,
      },
    };
  }


  touchEventCache = [];

  startDrawing(e) {
    e.preventDefault();
    e.stopPropagation();

    this.touchEventCache.push(e);
    // Don't draw if this is a pinch/zoom
    if (this.touchEventCache.length > 1) {
      this.isDrawing = false;
      return;
    }

    window.hideContextMenus();
    window.hideColorPicker();

    this.isDrawing = true;
    this.points = [[e.offsetX, e.offsetY, e.pressure]];

    const canvasContainer = document.querySelector('.canvasContainer');
    const canvasRect = canvasContainer.getBoundingClientRect();

    this.relativePoints = [[e.clientX - canvasRect.x, e.clientY - canvasRect.y, e.pressure]];
    this.pathOptions.size = document.querySelector('#pencil_thickness').value;
    this.pathOptions.color = document.querySelector('#pencil_color').value;

    const svg = document.querySelector('#vectors');
    this.currentPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    svg.appendChild(this.currentPath);
  }

  drawing(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isDrawing) {
      this.points.push([e.offsetX, e.offsetY, e.pressure]);
      const canvasContainer = document.querySelector('.canvasContainer');
      const canvasRect = canvasContainer.getBoundingClientRect();

      this.relativePoints.push([e.clientX - canvasRect.x, e.clientY - canvasRect.y, e.pressure]);

      this.stroke = getStroke(this.points, {
        ...this.pathOptions,
        size: this.pathOptions.size * zoomLevel
      });
      const pathData = this.getSvgPathFromStroke(this.stroke);

      this.currentPath.setAttribute('fill', this.pathOptions.color);
      this.currentPath.setAttribute('d', pathData);
    }
  }

  endDrawing(e) {
    e.preventDefault();
    e.stopPropagation();
    const index = this.touchEventCache.findIndex((cachedEv) => cachedEv.pointerId === e.pointerId);
    this.touchEventCache.splice(index, 1);

    if (this.isDrawing) {
      this.isDrawing = false;

      const canvasContainer = document.querySelector('.canvasContainer');
      const path = document.querySelector('#vectors path:last-child');

      if (this.relativePoints.length < 3) {
        path.remove();
        return;
      }

      const pathRect = path.getBoundingClientRect();
      const canvasRect = canvasContainer.getBoundingClientRect();

      const top = pathRect.y - canvasRect.y;
      const left = pathRect.x - canvasRect.x;

      const stroke = getStroke(this.relativePoints, {
        ...this.pathOptions,
        size: this.pathOptions.size * zoomLevel,
      });
      const pathData = this.getSvgPathFromStroke(stroke);

       this.stimulate('Item#create_vector',
         `<path d="${pathData}" style="transform: translate(${-1 * (left / zoomLevel)}px, ${-1 * (top / zoomLevel)}px) scale(${1 / zoomLevel})")>`,
         {
           top: top / zoomLevel,
           left: left / zoomLevel,
           width: pathRect.width / zoomLevel,
           height: pathRect.height / zoomLevel,
           color: this.pathOptions.color,
         })
         .then(() => {
           setTimeout(() => path.remove(), 300);
         });
    }
  }

  preventZoom(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  toggle() {
    if (document.querySelector('#pen')
      .classList
      .contains('hidden')) {
      this.activate();
    } else {
      this.deactivate();
    }
  }

  activate() {
    document.querySelector('.button.pen svg path')
      .setAttribute('fill', '#f35d91');
    document.querySelector('#pen')
      .classList
      .remove('hidden');
    if (window.selectoForItems) {
      window.selectoForItems.destroy();
      window.selectoForItems = null;
    }

    // Deactivate pan/zoom
    document.querySelector('#eventDisabler')
      .classList
      .add('hidden');
    document.querySelector('.button.pan-zoom')
      .classList
      .remove('active');


    const button = document.querySelector('#topbar .button.pen ');
    const thickness = button.dataset.penThickness;
    const color = button.dataset.penColor;
    this.stimulate('Item#create_vector_panel', thickness, color);
  }

  deactivate() {
    document.querySelector('#pen')
      .classList
      .add('hidden');

    document.querySelector('.canvas')
      .moveable
      .activateSelecto();
  }

  keyDown(event) {
    if (event.code == 'Escape') {
      this.deactivate();
    }
  }

  getSvgPathFromStroke(stroke) {
    if (!stroke.length) return '';

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q'],
    );

    d.push('Z');
    return d.join(' ');
  }
}
