import ApplicationController from './application_controller.js'
import { Frame } from "scenejs";
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';

export default class extends ApplicationController {
  static targets = [ "container" ]

  connect() {
    super.connect();

    if (this.containerTarget.className == "button") return;

    this.initializeLineStart();
    this.initializeLineEnd();
    this.initializeLineBody();
  }

  create(event) {
    this.stimulate('Item#create_line', event.currentTarget).then(() => {
      event.target.closest('.button').querySelector('.toolbar-tooltip').removeAttribute('style');
      document.querySelector('#canvas').canvas.clearHighlightPanelTrigger();

      const item = document.querySelector('.draggable-item-container.selected');
      storeItemSnapshotForUndo(item.dataset.itemId, 'ADD_SLIDE_ITEM');
      const controller = item.item;
      if (controller) {
        controller.selectItem(item);
      }
    });
  }

  select(event) {
    event.preventDefault();
    event.stopPropagation();

    if (window.draggingElement) return;

    if (!event.shiftKey) {
      this.unSelectOtherItems(this.containerTarget);
    }

    this.selectItem(this.containerTarget.closest('.item-container').querySelector('.item-type-line'));

    if (event.shiftKey && this.selectedItems().length > 1) {
      document.querySelector('.canvas').canvas.selectMultiItems();
    }

    this.stimulate("Item#select", this.selectedItemIds());
  }

  unSelectOtherItems(target) {
    let selectedItems = document.querySelectorAll('.item-container .selected');
    console.log(selectedItems);
    for (const selectedItem of selectedItems) {
      if (target != selectedItem) {
        selectedItem.classList.remove('selected');
        selectedItem.classList.remove('multi-select');
        selectedItem.closest('.item-container').querySelector('.handle-left')?.classList.add('hidden');
        selectedItem.closest('.item-container').querySelector('.handle-right')?.classList.add('hidden');
      }
    }

    window.moveableForItems.target = null;
    window.moveableForGroups.target = null;
    window.moveableForText.target = null;
    window.moveableForLineHandle.target = null;
    window.moveableForLineBody.target = null;
    window.Diagrams.clearCurrentDiagramSelection();
  }

  selectItem(target, source = 'itemClick') {
    if (document.body.classList.contains('handMode')) {
      return;
    } else {
      if (!target.classList.contains('selected')) {
        if(target.dataset.groupId !== '' && source === 'itemClick' && window.currentActiveGroupId !== target.dataset.groupId) { // We have a item in a group
          const items = document.querySelectorAll(`
            .item-container .draggable-item-container[data-group-id="${target.dataset.groupId}"]:not(.item-type-line),
            .item-container .line-snapper[data-group-id="${target.dataset.groupId}"]
          `);

          window.selectoForItems.trigger('select', {selected: Array.from(items)});
          window.selectoForItems.trigger('selectEnd', {afterAdded: Array.from(items)});
        } else {
          if(target.dataset.groupId !== window.currentActiveGroupId) { // We clicked on an item that's not in the group
            window.currentActiveGroupId = null;
          }
          target.closest('.item-container').querySelector('.handle-left').classList.remove('hidden');
          target.closest('.item-container').querySelector('.handle-right').classList.remove('hidden');
          target.classList.add('selected');
          target.closest('.item-container').querySelector('.line-snapper').classList.add('selected');
        }
      }
    }
  }

  getZoomScale() {
    let zoomLevel = parseFloat(window.zoomLevel);
    return zoomLevel;
  }

  selectedItems() {
    return document.querySelectorAll('.item-container .selected');
  }

  selectedItemIds() {
    return Array.from(this.selectedItems()).map(div => div.dataset.itemId).join();
  }

  initializeLineStart() {
    let target = this.containerTarget;
    if (!target.classList.contains('handle-left')) return;

    const frame = window.mapItems.get(target);

    if (!frame) {
      const frame = new Frame({
        "z-index": target.style.zIndex,
        "width": "10px",
        "height": "10px",
        "top": "5px",
        "left": "5px",
        "border-radius": "5px",
        transform: {
          translateX: `${parseFloat(target.dataset.cx) - 10}px`,
          translateY: `${parseFloat(target.dataset.cy) - 10}px`,
          rotate: "0deg",
          scaleX: 1,
          scaleY: 1
        }
      });
      target.style.cssText = frame.toCSS();
      window.mapItems.set(target, frame);
    }

    let snapTarget = target.closest('.item-container').querySelector('.snap-handle-left');
    const snapFrame = window.mapItems.get(snapTarget);

    const lineSnapper = target.closest('.item-container').querySelector('.line-snapper');
    const lineSnapperFrame = window.mapItems.get(lineSnapper);

    if (!snapFrame) {
      const snapFrame = new Frame({
        "z-index": snapTarget.style.zIndex,
        "width": "1px",
        "height": "1px",
        "transform-origin": "left center",
        "background": "transparent",
        transform: {
          translateX: `${parseFloat(snapTarget.dataset.cx)}px`,
          translateY: `${parseFloat(snapTarget.dataset.cy) - 0.5}px`,
          rotate: lineSnapperFrame.get('transform', 'rotate'),
          scaleX: 1,
          scaleY: 1
        }
      });
      snapTarget.style.cssText = snapFrame.toCSS();
      window.mapItems.set(snapTarget, snapFrame);
    }
  }

  initializeLineEnd() {
    let target = this.containerTarget;
    if (!target.classList.contains('handle-right')) return;

    const frame = window.mapItems.get(target);

    if (!frame) {
      const frame = new Frame({
        "z-index": target.style.zIndex,
        "width": "10px",
        "height": "10px",
        "top": "5px",
        "left": "5px",
        "border-radius": "5px",
        transform: {
          translateX: `${parseFloat(target.dataset.cx) - 10}px`,
          translateY: `${parseFloat(target.dataset.cy) - 10}px`,
          rotate: "0deg",
          scaleX: 1,
          scaleY: 1
        }
      });
      target.style.cssText = frame.toCSS();
      window.mapItems.set(target, frame);
    }

    let snapTarget = target.closest('.item-container').querySelector('.snap-handle-right');
    const snapFrame = window.mapItems.get(snapTarget);

    const lineSnapper = target.closest('.item-container').querySelector('.line-snapper');
    const lineSnapperFrame = window.mapItems.get(lineSnapper);
    let angle = parseInt(lineSnapperFrame.get('transform', 'rotate'));

    let diffX = -1;
    let diffY = -0.5;
    if (angle === -90) diffY = 0.5;
    if (angle === -180) diffX = 0;

    if (angle === -0) angle = 0;
    if (angle === 90) angle = -90;
    if (angle === -180) angle = -0;

    if (!snapFrame) {
      const snapFrame = new Frame({
        "z-index": snapTarget.style.zIndex,
        "width": "1px",
        "height": "1px",
        "transform-origin": "center top",
        "background": "transparent",
        transform: {
          translateX: `${parseFloat(snapTarget.dataset.cx) + diffX}px`,
          translateY: `${parseFloat(snapTarget.dataset.cy) + diffY}px`,
          rotate: `${angle}deg`,
          scaleX: 1,
          scaleY: 1
        }
      });
      snapTarget.style.cssText = snapFrame.toCSS();
      window.mapItems.set(snapTarget, snapFrame);
    }
  }

  initializeLineBody() {
    let target = this.containerTarget;
    const snapperTarget = target.closest('.item-container').querySelector('.line-snapper');
    const frame = window.mapItems.get(snapperTarget);

    if (!frame) {
      const frame = new Frame({
        "z-index": snapperTarget.style.zIndex,
        background: 'transparent',
        width: `${snapperTarget.dataset.width}px`,
        height: `${snapperTarget.dataset.height}px`,
        'transform-origin': 'top left',
        transform: {
          translateX: `${snapperTarget.dataset.x1}px`,
          translateY: `${snapperTarget.dataset.y1}px`,
          rotate: `${snapperTarget.dataset.angle}deg`,
          scaleX: 1,
          scaleY: 1
        }
      });
      snapperTarget.style.cssText = frame.toCSS();
      window.mapItems.set(snapperTarget, frame);

      const handlerTarget = target.closest('.item-container').querySelector('.line-handler');
      const handlerFrame = window.mapItems.get(handlerTarget);

      if (!handlerFrame) {
        const handlerFrame = new Frame({
          position: 'absolute',
          'z-index': handlerTarget.dataset.zIndex,
          background: 'transparent',
          cursor: 'move',
          width: `${handlerTarget.dataset.length}px`,
          height: `${handlerTarget.dataset.height}px`,
          'transform-origin': 'center left',
          transform: {
            translateX: `${handlerTarget.dataset.x1}px`,
            translateY: `${handlerTarget.dataset.y1}px`,
            rotate: `${handlerTarget.dataset.angle}deg`,
            scaleX: 1,
            scaleY: 1
          }
        });
        handlerTarget.style.cssText = handlerFrame.toCSS();
        window.mapItems.set(handlerTarget, handlerFrame);
      }
    }
  }

  bodyDragStart(event) {
    event.preventDefault();
    event.stopPropagation();

    let target = event.target.closest('.item-container').querySelector('.line-snapper');
    if (!target) return;

    if((target.dataset.groupId)) {
      this.selectItem(target);
      window.moveableForGroups.dragStart(event);
      return;
    }

    let itemId = event.target.dataset.itemId;

    window.moveableForLineBody.target = target;
    window.moveableForLineBody.elementGuidelines = [
      ...document.querySelectorAll('.draggable-item-container:not(.line-container)'),
      document.querySelector('.canvasContainer'),
      ...document.querySelectorAll(`.item-container-line:not(#item-container-${itemId}) .snap-handle-left`),
      ...document.querySelectorAll(`.item-container-line:not(#item-container-${itemId}) .snap-handle-right`)
    ];
    window.moveableForLineBody.dragStart(event);
    this.disallowDeselect();
  }

  handleDragStart(event) {
    event.preventDefault();
    event.stopPropagation();
    let itemId = event.target.dataset.itemId;

    let target;
    let otherEdge;

    if (event.target.classList.contains('handle-left')) {
      target = event.target.closest('.item-container').querySelector('.snap-handle-left');
      otherEdge = event.target.closest('.item-container').querySelector('.snap-handle-right');
    } else if (event.target.classList.contains('handle-right')) {
      target = event.target.closest('.item-container').querySelector('.snap-handle-right');
      otherEdge = event.target.closest('.item-container').querySelector('.snap-handle-left');
    }

    window.moveableForLineHandle.target = target;
    window.moveableForLineHandle.elementGuidelines = [
      ...document.querySelectorAll('.draggable-item-container:not(.line-container)'),
      document.querySelector('.canvasContainer'),
      otherEdge,
      ...document.querySelectorAll(`.item-container-line:not(#item-container-${itemId}) .snap-handle-left`),
      ...document.querySelectorAll(`.item-container-line:not(#item-container-${itemId}) .snap-handle-right`),
    ];
    window.moveableForLineHandle.dragStart(event);
    this.disallowDeselect();
  }

  disallowDeselect(ms = 10) {
    window.draggingElement = true;
    setTimeout(() => {
      window.draggingElement = false;
    }, ms);
  }
}
