import { Controller } from 'stimulus';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import Rails from 'rails-ujs';

export default class extends Controller {

  connect() {
    this.renderCalendar();
  }

  disconnect() {
    window.calendar.destroy();
  }

  renderCalendar() {
    const calendarEl = document.getElementById('calendar');
    const eventSourceUrl = this.data.get("event-source-url");
    const initialView = this.data.get("initial-view");
    const startDate = this.data.get("start-date");
    const userType = this.data.get("user-type");
    // const endDate = this.data.get("end-date");
    const canAddEvent = this.data.get("can-add-event") === 'true';
    let weekendsEnabled = false;
    if (this.data.get("weekends-enabled") === 'true') {
      weekendsEnabled = true;
    }
    const weekday = new Array(7);
    weekday[1] = "MON";
    weekday[2] = "TUE";
    weekday[3] = "WED";
    weekday[4] = "THU";
    weekday[5] = "FRI";
    weekday[6] = "SAT";
    weekday[0] = "SUN";

    const calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin ],
      height: 1450,
      events: eventSourceUrl,
      timeZone: 'America/New_York',
      initialView: initialView,
      slotMinTime: '07:00:00',
      slotMaxTime: '20:00:00',
      weekNumberCalculation: "ISO",
      weekends: weekendsEnabled,
      locale: 'en',
      allDayContent: 'All Day',
      firstDay: 1,
      showNonCurrentDates: true,
      visibleRange: {
        start: startDate,
        // end: endDate
      },
      customButtons: {
          addEventButton: {
            text: '',
            click: function() {
              return;
            }
          }
        },
      headerToolbar: {
        left: 'prev,next title today',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek addEventButton'
      },
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: true
      },
      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: true,
        meridiem: true
      },
      // views: {
      //   timeGridWeek: { 
      //     dayHeaderFormat: function(info) {
      //       return {html: '<div class="fc-date-title">'+ weekday[info.date.marker.getDay()] + '</div><div class="fc-date-day">'+info.date.day+'</div>'};
      //     }
      //   },
      //   timeGridDay: { 
      //     dayHeaderFormat: function(info) {
      //       return {html: '<div class="fc-date-title">'+ weekday[info.date.marker.getDay()] + '</div><div class="fc-date-day">'+info.date.day+'</div>'};
      //     },
      //   }
      // },
      eventClick: function(info) {
        if (userType === 'student' && info.event.extendedProps.event_type === 'assignment') {
          window.location = info.event.extendedProps.assignment_link;
        } else {
          Rails.ajax({ url: info.event.extendedProps.event_edit_url, type: 'GET' });
        }
      },
      dateClick: function(info) {
        if (canAddEvent) {
          Rails.ajax({ 
            url: document.getElementById('calendarAddButton').dataset.modalSourceUrl,
            type: 'GET',
            data: new URLSearchParams({'start_date': info.dateStr}).toString()
          });
        }
      },
      eventClassNames: function(arg) {
        if (arg.event.extendedProps.event_type === 'assignment') {
          return [ 'type-assignment' ]
        } else {
          return [ 'type-event' ]
        }
      }
    });
    if (startDate) {
      calendar.gotoDate( startDate );
    }
    calendar.render();
    if (canAddEvent) {
      document.querySelector(".fc-addEventButton-button").innerHTML = document.getElementById('calendarAddButton').innerHTML;
      document.querySelector(".fc-addEventButton-button").dataset.action = document.getElementById('calendarAddButton').dataset.action;
      document.querySelector(".fc-addEventButton-button").dataset.controller = document.getElementById('calendarAddButton').dataset.controller;
      document.querySelector(".fc-addEventButton-button").dataset.modalSourceUrl = document.getElementById('calendarAddButton').dataset.modalSourceUrl;
    }
    window.calendar = calendar;
  }

}
