import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    window.expandableTextArea();
  }

  expandInput(event) {
    event.target.closest('.new-comment-container').querySelector('.new-comment-is-private').classList.remove('hidden');
  }

  hidePrivateOption(event) {
    if (
      (event.relatedTarget !== null)
        &&
      (event.relatedTarget.classList.contains('new-comment-is-private') || event.relatedTarget.type === 'checkbox')
    ) {
      // Do nothing
    } else {
      event.target.closest('.new-comment-container')
        .querySelector('.new-comment-is-private')
        .classList
        .add('hidden');
    }
  }
}
