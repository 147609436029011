import { Sortable, MultiDrag } from 'sortablejs';
import ApplicationController from './application_controller';
import switchMode from '../helpers/editor/modes/switch';
import { setButtonStates, storeSlideSnapshotForUndo } from '../helpers/editor/history/manager';
import fixSlideIndexes from '../helpers/editor/slides/fixSlideIndexes';
import { confirmDeleteSlide } from '../helpers/editor/slides/deleteSlide';
import { isTouchDevice } from '../helpers/utils';

export default class extends ApplicationController {
  initialize() {
    Sortable.mount(new MultiDrag());
  }

  connect() {
    super.connect();

    document.addEventListener('snapshotHistoryForDuplicatedSlides', this.snapshotHistoryForDuplicatedSlides);
    document.addEventListener('snapshotHistoryForPastedSlides', this.snapshotHistoryForPastedSlides);

    // Make slide thumbs draggable
    const sortableInstance = document.querySelector('#pageThumbsContainer');
    if (sortableInstance) {
      let positions = [];
      const el = Sortable.create(sortableInstance, {
        multiDrag: !isTouchDevice(),
        multiDragKey: 'meta',
        selectedClass: 'multiSelected',
        draggable: '.slideContainer',
        avoidImplicitDeselect: true,
        animation: 150,
        delay: isTouchDevice() ? 100 : 30,
        filter: '.contextMenu',
        group: {
          name: 'child-group',
        },
        forceFallback: true,
        onStart: (event) => {
          const thumbs = el.toArray();
          thumbs.pop(); // Remove last item
          thumbs.forEach((item, index) => {
            positions.push({ slide_id: item, position: index + 1 });
          });
          storeSlideSnapshotForUndo('', 'UPDATE_SLIDE_POSITIONS', this.generateRandomString(), positions);
        },
        onEnd: (event) => {
          let displayedIndex = 0;
          el.toArray().forEach((item, index) => {
            // acts_as_list for slide is setup to have 1 as the top of the list
            positions.push({ slide_id: item, position: index + 1 });
            document.querySelector(`#slideIndexContainer-${item}`).innerHTML = displayedIndex + 1;
            const containerItem = document.querySelector(`#page-thumb-container-with-index-${item}`);
            if (containerItem.dataset.isDeleted == 'false') {
              displayedIndex += 1;
            }
          });
          this.stimulate('Slide#update_position', event.item, JSON.stringify(positions));
          positions = [];
        },
      });

      const deselectMultiDrag = el.multiDrag._deselectMultiDrag;
      document.removeEventListener('pointerup', deselectMultiDrag, false);
      document.removeEventListener('mouseup', deselectMultiDrag, false);
      document.removeEventListener('touchend', deselectMultiDrag, false);

      window.GhostThumbnail = {};
      window.GhostThumbnail.draggingItem = null;
      window.GhostThumbnail.draggingImageClone = document.createElement('div');
      window.GhostThumbnail.draggingImageClone.dataset.items = JSON.stringify([]);
      window.GhostThumbnail.draggingImageClone.style.position = 'fixed';
      document.body.appendChild(window.GhostThumbnail.draggingImageClone);
    }
  }

  mouseOver(event) {
    window.GhostThumbnail.draggingItem = document.querySelectorAll('.draggable-item-container.selected');

    if (window.GhostThumbnail.draggingItem !== null && window.GhostThumbnail.draggingItem.length > 0) {
      if (window.GhostThumbnail.draggingItem[0].dataset.moved === '0') {
        return;
      }

      const multipleItems = window.GhostThumbnail.draggingItem.length > 1;

      document.querySelectorAll('.slide-hovered').forEach((item) => {
        item.classList.remove('slide-hovered');
      });
      event.target.closest('.slideContainer').querySelector('.slide').classList.add('slide-hovered');
      window.GhostThumbnail.draggingImageClone.classList.add('scale-down');

      let groupContainerBox;
      if (multipleItems) {
        const groupContainer = document.querySelector('.moveable-area');
        if (groupContainer) {
          groupContainerBox = groupContainer.getBoundingClientRect();
        }
      }

      window.GhostThumbnail.draggingItem.forEach((draggingItem) => {
        const currentItems = JSON.parse(window.GhostThumbnail.draggingImageClone.dataset.items);
        if (currentItems.includes(draggingItem.id)) return;
        currentItems.push(draggingItem.id);
        window.GhostThumbnail.draggingImageClone.dataset.items = JSON.stringify(currentItems);

        let relativeX;
        let relativeY;
        let width;
        if (multipleItems) {
          const itemBox = draggingItem.getBoundingClientRect();
          relativeX = itemBox.x - groupContainerBox.x;
          relativeY = itemBox.y - groupContainerBox.y;
          width = itemBox.width;
        }

        draggingItem.style.visibility = 'hidden';
        if (document.querySelector('.moveable-dragging')) document.querySelector('.moveable-dragging').style.visibility = 'hidden';

        let clone;
        if (draggingItem.dataset.itemType === 'text') {
          clone = draggingItem.querySelector('.text-content').cloneNode(true);
        } else if (draggingItem.dataset.itemType === 'line') {
          document.querySelectorAll('.handle').forEach((item) => {
            item.style.visibility = 'hidden';
          });
          clone = draggingItem.cloneNode(true);
          clone.removeAttribute('data-controller');
          clone.classList.value = '';
          clone.id = '';
          clone.querySelector('line').classList.value = '';
          clone.style.visibility = 'visible';
        } else {
          clone = draggingItem.querySelector('.moveableContainer').cloneNode(true);
        }

        if (multipleItems) {
          clone.style.position = 'absolute';
          clone.style.transform = `translate(${relativeX}px, ${relativeY}px)`;
          clone.style.width = `${width}px`;
        }

        window.GhostThumbnail.draggingImageClone.innerHTML += clone.outerHTML;
        window.GhostThumbnail.draggingImageClone.style.visibility = 'visible';
      });
    }
  }

  mouseMove(event) {
    const { clientX, clientY } = event;
    const CURSOR_OFFSET = 10;
    if (window.GhostThumbnail.draggingItem !== null && document.querySelector('.moveable-dragging')) {
      window.GhostThumbnail.draggingImageClone.style.top = `${clientY + CURSOR_OFFSET}px`;
      window.GhostThumbnail.draggingImageClone.style.left = `${clientX + CURSOR_OFFSET}px`;

      // No need to handle multiple lines.
      if (window.GhostThumbnail.draggingItem.length > 1) {
        return;
      }
      window.GhostThumbnail.draggingItem.forEach((draggingItem) => {
        if (draggingItem.dataset.itemType === 'line') {
          const y1 = draggingItem.querySelector('line').getAttribute('y1');
          const y2 = draggingItem.querySelector('line').getAttribute('y2');
          const x1 = draggingItem.querySelector('line').getAttribute('x1');
          const x2 = draggingItem.querySelector('line').getAttribute('x2');
          if (y1 < y2) {
            window.GhostThumbnail.draggingImageClone.querySelector('line').setAttribute('y1', CURSOR_OFFSET);
            window.GhostThumbnail.draggingImageClone.querySelector('line').setAttribute('y2', y2 - y1 + CURSOR_OFFSET);
          } else {
            window.GhostThumbnail.draggingImageClone.querySelector('line').setAttribute('y1', y1 - y2 + CURSOR_OFFSET);
            window.GhostThumbnail.draggingImageClone.querySelector('line').setAttribute('y2', CURSOR_OFFSET);
          }
          window.GhostThumbnail.draggingImageClone.querySelector('line').setAttribute('x1', CURSOR_OFFSET);
          window.GhostThumbnail.draggingImageClone.querySelector('line').setAttribute('x2', x2 - x1 + CURSOR_OFFSET);
        }
      });
    }
  }

  mouseLeave() {
    if (window.GhostThumbnail.draggingItem !== null && window.GhostThumbnail.draggingItem.length > 0) {
      document.querySelectorAll('.slide-hovered').forEach((item) => {
        item.classList.remove('slide-hovered');
      });
      document.querySelectorAll('.handle').forEach((item) => {
        item.style.visibility = 'visible';
      });
      window.GhostThumbnail.draggingImageClone.classList.remove('scale-down');
      window.GhostThumbnail.draggingImageClone.innerHTML = '';
      window.GhostThumbnail.draggingImageClone.dataset.items = JSON.stringify([]);
      if (document.querySelector('.moveable-dragging')) document.querySelector('.moveable-dragging').style.visibility = 'visible';
      window.GhostThumbnail.draggingItem.forEach((draggingItem) => {
        draggingItem.style.visibility = 'visible';
      });
    }
    window.GhostThumbnail.draggingItem = null;
  }

  mouseUp() {
    if (window.GhostThumbnail.draggingItem !== null && window.GhostThumbnail.draggingItem.length > 0) {
      window.GhostThumbnail.draggingItem.forEach((draggingItem) => {
        draggingItem.style.visibility = 'visible';
      });
      document.querySelectorAll('.slide-hovered').forEach((item) => {
        item.classList.remove('slide-hovered');
      });
      if (document.querySelector('.moveable-dragging')) document.querySelector('.moveable-dragging').style.visibility = 'visible';
      window.GhostThumbnail.draggingImageClone.style.visibility = 'hidden';
      window.GhostThumbnail.draggingImageClone.innerHTML = '';
      window.GhostThumbnail.draggingImageClone.dataset.items = JSON.stringify([]);
      window.GhostThumbnail.draggingImageClone.classList.remove('scale-down');
      document.querySelectorAll('.draggable-item-container.selected').forEach((item) => {
        item.parentElement.remove();
      });
    }
    window.GhostThumbnail.draggingItem = null;
  }

  openExpandedView() {
    document.querySelector('#pageThumbs').classList.add('expandedView');
    document.querySelector('#pageThumbActions').classList.add('expandedButtonView');
    document.querySelector('#pageThumbsContainer').style.width = '';
    document.querySelector('.openExpandedViewButton').style.display = 'none';
    document.querySelector('.closeExpandedViewButton').style.display = 'flex';
  }

  closeExpandedView() {
    document.querySelector('#pageThumbs').classList.remove('expandedView');
    document.querySelector('#pageThumbActions').classList.remove('expandedButtonView');
    document.querySelector('#pageThumbsContainer').style.width = '100%';
    document.querySelector('.openExpandedViewButton').style.display = 'flex';
    document.querySelector('.closeExpandedViewButton').style.display = 'none';
  }

  deleteSlide(event) {
    event.preventDefault();
    event.stopPropagation();
    confirmDeleteSlide();
  }

  duplicateSlide(event) {
    event.preventDefault();
    event.stopPropagation();
    let slideIds = [event.target.dataset.slideId];

    const selectedThumbs = document.querySelectorAll('#pageThumbsContainer .slideContainer.selected, #pageThumbsContainer .slideContainer.multiSelected');
    if (selectedThumbs.length > 1) {
      slideIds = Array.from(selectedThumbs)
        .map((thumb) => thumb.dataset.id)
        .filter((id) => id !== undefined);
    }

    slideIds = slideIds.map((x) => +x);
    this.stimulate('Slide#duplicate', JSON.stringify(slideIds));
  }

  toggleVisibility(event) {
    event.preventDefault();
    event.stopPropagation();

    const selectedThumbs = document.querySelectorAll('#pageThumbsContainer .slideContainer.selected, #pageThumbsContainer .slideContainer.multiSelected');
    const slideIds = Array.from(selectedThumbs).map((thumb) => thumb.dataset.slideId);

    this.stimulate('Slide#toggle_visibility', { resolveLate: true }, JSON.stringify(slideIds)).then(() => {
      switchMode(this, event, 'Pointer');
      setButtonStates({
        presentationId: document.querySelector('.canvas').dataset.presentationId,
        slideId: '',
        userId: document.querySelector('.canvas').dataset.userId,
      });
    });
  }

  snapshotHistoryForDuplicatedSlides(event) {
    event.detail.newSlideIds.forEach((slideId) => {
      storeSlideSnapshotForUndo(slideId, 'ADD_SLIDE');
    });
  }

  copySlide(event) {
    event.preventDefault();
    event.stopPropagation();
    const slideIds = _.uniq([...document.querySelectorAll('.slideContainer.multiSelected .slide, .slideContainer.selected .slide')].map((slide) => parseInt(slide.dataset.slideId)));
    this.stimulate('Slide#copy', slideIds);
    window.notice('Copied slide');
    window.hideContextMenus();
  }

  pasteSlide(event) {
    event.preventDefault();
    event.stopPropagation();
    const { slideId } = event.target.dataset;
    this.stimulate('Slide#paste', { resolveLate: true }, slideId).then(() => {
      fixSlideIndexes();
      window.hideContextMenus();
    });
  }

  snapshotHistoryForPastedSlides(event) {
    const selectionId = Math.round((Math.random() * 36 ** 12)).toString(36);
    event.detail.newSlideIds.forEach((slideId) => {
      storeSlideSnapshotForUndo(slideId, 'ADD_SLIDE', selectionId);
    });
  }

  generateRandomString() {
    return Math.round((Math.random() * 36 ** 12)).toString(36);
  }
}
