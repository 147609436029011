import { Controller } from 'stimulus';
import Rails from 'rails-ujs';

export default class extends Controller {

  showCalendarEvent(event) {
    event.preventDefault();
    const sourceUrl = this.data.get("source-url");
    Rails.ajax({ url: sourceUrl, type: 'GET'});
  }

  renderDescriptionText() {
  	const eventTypes = document.getElementsByName("calendar_event[event_type]");
  	eventTypes.forEach(eventType => {
      if (eventType.checked) {
        if (eventType.value === 'assignment') {
        	document.getElementById('calendar_event_description').placeholder = 'Link'
        } else {
        	document.getElementById('calendar_event_description').placeholder = 'Description'
        }
      }
    });
  }

  close(event) {
    event.preventDefault();
    document.getElementById('modal-content').innerHTML = '';
  }

  toggleDescription(event) {
  	this.renderDescriptionText();
  }
}
