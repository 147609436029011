import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  select(event) {
    event.preventDefault();
    event.stopPropagation();

    event.target.closest('.selectable').classList.toggle('selected');
    event.target.closest('.selectable').classList.toggle('border-pink');
  }

  filter(event) {
    document.querySelector(".filters a.btn-pink").classList.remove('btn-pink')
    event.target.classList.add('btn-pink')
  }

  selectItemForTemplate(event) {
    event.preventDefault();
    event.stopPropagation();

    if (document.querySelectorAll('.asset.selected').length === 0) {
      alert('Please select something.');
      return;
    } else if (document.querySelectorAll('.asset.selected').length > 1) {
      alert('Please select only one item.');
      return;
    }

    if (event.target.classList.contains('in-progress')) {
      return;
    }

    var target = event.target.closest('a');

    target.querySelector('.loader').classList.remove('hidden');
    target.classList.add('in-progress');

    let cameraAssetPath = document.querySelector('.asset.selected').getAttribute('data-camera-asset-path');
    let itemId = target.getAttribute('data-item-id');

    let formData = new FormData();
    formData.append("content_type", 'CameraAsset');
    formData.append("camera_asset_path", cameraAssetPath);
    formData.append("post_id", target.getAttribute('data-post-id'));
    formData.append("slide_id", target.getAttribute('data-slide-id'));
    formData.append('studio_id', target.getAttribute('data-studio-id'));

    Rails.ajax({
      url: `/items/${itemId}/update_template`,
      type: "POST",
      data: formData
    });
  }

  createItems(event) {
    event.preventDefault();
    event.stopPropagation();

    if (document.querySelectorAll('.asset.selected').length === 0) {
      alert('Please select something.');
      return;
    }

    if (event.target.classList.contains('in-progress')) {
      return;
    }

    var target = event.target.closest('a');
    target.querySelector('.loader').classList.remove('hidden');
    target.classList.add('in-progress');

    var camera_asset_ids = [];
    let studioId = target.getAttribute('data-studio-id');

    document.querySelectorAll('.asset.selected').forEach(function(el) {
      camera_asset_ids.push(el.getAttribute('data-camera-asset-id'));
    });

    let formData = new FormData();
    formData.append('slide_type', target.getAttribute('data-slide-type'));
    formData.append("camera_asset_ids", camera_asset_ids);
    formData.append('post_id', target.getAttribute('data-post-id'));
    formData.append('slide_id', target.getAttribute('data-slide-id'));
    formData.append('studio_id', studioId);

    Rails.ajax({
      url: `/items/create_via_camera_assets`,
      type: 'POST',
      data: formData
    });
  }
}
