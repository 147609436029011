import { Controller } from "stimulus";

export default class extends Controller {

  triggerDurationChange(event) {
    console.log(event);
    const input = event.currentTarget;
    if (input.classList.contains('per-term-checkbox')) {
      document.querySelector('#view_type_per_term').checked = true;
      document.querySelector('#view_type_per_year').checked = false;
      document.querySelectorAll('.per-year-view input[type="checkbox"]').forEach((checkbox) => {
        checkbox.checked = false;
      });
    } else if (input.classList.contains('per-year-checkbox')) {
      document.querySelector('#view_type_per_term').checked = false;
      document.querySelector('#view_type_per_year').checked = true;
      document.querySelectorAll('.per-term-view input[type="checkbox"]').forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  // Collect user transcript page form fields and send to PDF export
  export(event) {
    event.preventDefault();
    const exportButton = event.currentTarget;
    const exportForm = exportButton.closest(".export-form");
    const transcriptUrl = exportButton.dataset.transcriptUrl
    const options = exportForm.querySelectorAll('.export-options');
    const parameters = Array.apply(null, options)
      .filter(option => option.checked)
    // .map(option => `${option.name}=${option.value}`)
    exportButton.textContent = "Exporting...";
    var form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'post';
    form.action = transcriptUrl;
    for (const parameter of parameters) {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = parameter.name;
      input.value = parameter.value;
      form.appendChild(input);
    }
    if (document.getElementById('edit_message_on_top').checked) {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'transcript_options[top_message]';
      input.value = document.getElementById('top_message').value;
      form.appendChild(input);
    }
    form.submit();
    // window.location = `${transcriptUrl}?${parameters.join('&')}`;
  }
}
