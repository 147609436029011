import { Controller } from 'stimulus';
import { createRequest } from './helpers/createRequest';
import { htmlToElement } from './helpers/dom';

export default class extends Controller {
  static targets = ['dueDate']

  openCalendar(event) {
    let defaultDate = null;
    if (this.dueDateTarget.dataset.dueDate !== '') {
      defaultDate = new Date(this.dueDateTarget.dataset.dueDate);
    }
    flatpickr(event.target,
      {
        enableTime: true,
        defaultDate: defaultDate,
        dateFormat: 'F j, Y h:iK ', //November 18, 2021 3:00pm
        onChange: (selectedDates, dateStr, instance) => {
          if (dateStr === undefined) {
            window.notice("Due date not selected.");
            return;
          }
          this.dueDateTarget.dataset.dueDate = selectedDates[0].toUTCString();
          event.target.innerText = dateStr;
          const formData = new FormData();
          formData.append('project[due_date]', selectedDates[0].toUTCString());
          fetch(createRequest(`/projects/${this.dueDateTarget.dataset.projectId}/update_due_date`, 'PATCH', formData));
        }
      })
      .open();
  }

  statuses(event) {
    document.querySelectorAll('.status-dropdown').forEach(
      el => {
        el.classList.add("hidden")
      }
    );
    event.currentTarget.querySelector(".status-dropdown")
      .classList
      .toggle("hidden");
  }

  updateAssignmentStatus(event) {
    const dropDown =  event.target.closest('.status-dropdown');
    const projectId = dropDown.dataset.projectId;
    const userId = dropDown.dataset.userId;
    const responseId = event.target.closest('li').dataset.responseId;
    const status = event.target.closest('li').dataset.status;
    const row = event.target.closest('tr');

    const formData = new FormData();
    formData.set("project_id", projectId);
    formData.set("response_id", responseId);
    formData.set("status", status);
    formData.set("user_id", userId);

    fetch(createRequest(`/projects/${projectId}/update_assignment_response`, 'POST', formData))
      .then(response => response.text())
      .then(html => row.replaceWith(htmlToElement(html)) )
      .catch((error) => {
        console.log(error)
      });

  }

  showStudent(event) {
    document.querySelectorAll('.student-label').forEach(node => node.classList.remove('text-pink'));
    event.target.closest('.student-label').classList.add('text-pink');
    const userId = event.target.dataset.studentId;
    const studioId = event.target.dataset.studioId;

    const table = document.querySelector('#assignment-list');

    fetch(createRequest(`/studios/${studioId}/assignment_tracker_for_student/${userId}`))
      .then(response => response.text())
      .then(html => table.replaceWith(htmlToElement(html)) )
      .catch((error) => {
        console.log(error)
      });

  }

}
