const setLineAttributes = (itemId, markerStart, markerEnd, strokeDasharray, color, thickness) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;

  const line = target.querySelector('line');
  if (!line) return;

  line.setAttribute('marker-start', `url(#${markerStart}-${itemId})`);
  line.setAttribute('marker-end', `url(#${markerEnd}-${itemId})`);
  line.setAttribute('stroke-width', thickness);
  line.setAttribute('stroke-dasharray', `${strokeDasharray.split(',')[0] * thickness} ${strokeDasharray.split(',')[1] * thickness}`);

  // Set color
  line.setAttribute('stroke', color);
  line.setAttribute('fill', color);
  const markers = target.querySelectorAll('marker path, marker circle, marker rect');
  markers.forEach((marker) => {
    marker.setAttribute('fill', color);
  });

  target.setAttribute('data-marker-start', markerStart);
  target.setAttribute('data-marker-end', markerEnd);
  target.setAttribute('data-stroke-dasharray', strokeDasharray);
  target.setAttribute('data-color', color);
  target.setAttribute('data-thickness', thickness);
};

export default setLineAttributes;
