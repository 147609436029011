/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import Sortable from 'sortablejs';
import { htmlToElement } from '.././helpers/dom'

export default class extends Controller {
  static targets = ["tabForm", "tabName", "tabTemplate", "tabFormContainer"]

  connect() {
    if (this.hasTabFormTarget) {
      // Show the first tab while all others are hiding
      this.tabFormTargets[0].classList.toggle('hidden');

      // Show all dynamic fields
      this.tabFormTargets.forEach((el) => {
        const fields = JSON.parse(el.dataset.fieldsToShow);
        const fieldsToClassNames = fields.map(field => "." + field);
        el.querySelectorAll(fieldsToClassNames.join(",")).forEach((field) => {
          field.classList.toggle('hidden', false);
        });
      });
    }

    // Make the tab selectors sortable
    const sortable = document.querySelector('.sortable');
    var el = Sortable.create(sortable, {
      animation: 150,
      filter: ".ignore-sort",
      draggable: ".draggable",
      delay: 50,
      forceFallback: true
    });

    // Highlight initial tab
    document.querySelector('#edit-tabs ul.sortable li').classList.add('btn-pink');
  }

  switchTo(event) {
    this.tabFormTargets.forEach((el, i) => {
      el.classList.toggle("hidden", el.dataset.tabId != event.target.dataset.tabId)
    });

    // Highlight selected tab
    document.querySelector('#edit-tabs ul.sortable li.btn-pink').classList.remove('btn-pink');
    event.target.classList.add('btn-pink');
  }

  addNewTab(event) {
    const id = new Date().getTime();
    const template = `<li class='inline-block mr-10 mb-14 btn draggable' data-target='studios--edit-tabs.tabName' data-action='click->studios--edit-tabs#switchTo' data-tab-id='${id}'>New Tab</li>`
    const newTabLink = htmlToElement(template);
    this._insertBefore(newTabLink, event.target);
    newTabLink.addEventListener("click", (ev) => {
      this.switchTo(ev);
    });
    const newTabForm = htmlToElement(this.tabTemplateTarget.innerHTML.replace(/PARENT_TEMPLATE_RECORD/g, id));
    newTabForm.dataset.tabId = id;
    this.tabFormContainerTarget.insertAdjacentElement('beforeend', newTabForm);

    newTabLink.click();
    window.activateSlimSelect();

    // Select the custom option if we are on new studio form
    const option = document.querySelector('#studio_tab_template_custom');
    if (option != null && option.checked === false) {
      option.checked = true;
    }

    // Initialize flatpickr for calendar tab
    var date_inputs = document.querySelectorAll('input.date-picker:not(.flatpickr-input)');

    for (let date_input of date_inputs) {
      flatpickr(date_input, {});
    }
  }

  updateShowableFields(event) {
    const selectedTabType = event.target.selectedOptions[0];
    const container = event.target.parentNode.parentNode.parentNode;
    // Hide all dynamic fields first
    container.querySelectorAll('.type-dynamic-field').forEach(el => el.classList.toggle('hidden', true));

    // Show only the releavant ones
    if (selectedTabType.value != '') {
      container.querySelectorAll('.type-dynamic-field').forEach(el => el.classList.toggle('hidden', true));
      const fields = JSON.parse(selectedTabType.dataset.fieldsToShow);
      if (fields.length > 0) {
        const fieldsToClassNames = fields.map(field => "." + field);
        container.querySelectorAll(fieldsToClassNames.join(",")).forEach((field) => {
          field.classList.toggle('hidden', false);
        });
      }
    }
  }

  updateTitle(event) {
    const tabId = event.target.parentElement.parentElement.parentElement.dataset.tabId;
    this.tabNameTargets.find(el => el.dataset.tabId  == tabId).innerText = event.target.value;
  }

  removeTab(event) {
    if(window.confirm("Are you sure?")) {
      const tabForm = event.target.parentElement;
      const tabId = tabForm.dataset.tabId;
      this.tabNameTargets.find(el => el.dataset.tabId  == tabId).style.display = 'none';
      tabForm.querySelector("input[name*='_destroy']").value = 1;
      tabForm.style.display = 'none';
    }
  }

  _insertBefore(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode);
  }

  _insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }

}
