import {
  ITEM_ACTIONS, getHistory, addPast, setButtonStates,
} from './manager';
import batchSave from '../items/batchSave';
import updateFrame from '../items/updateFrame';
import deleteItems from '../items/actions/delete';
import undeleteItems from '../items/actions/undelete';

const redoItem = (params) => {
  const futureHistories = getHistory({ ...params, key: 'Future' });
  let selectionId;
  if (futureHistories.length > 0) {
    let popSize = 1;
    if (futureHistories[0].selectionId) {
      selectionId = futureHistories[0].selectionId;
      popSize = futureHistories.filter((ph) => ph.selectionId === selectionId).length;
    }
    const groupedHistories = futureHistories.splice(0, popSize);
    let newPastItem;
    const updatedItemIds = [];
    groupedHistories.forEach((futureHistory) => {
      // Item Actions
      if (ITEM_ACTIONS.includes(futureHistory.actionType)) {
        if (futureHistory.actionType === 'DELETE_SLIDE_ITEM') {
          deleteItems(futureHistory.contentId);
          params.controller.stimulate(
            'Item#delete',
            document.querySelectorAll(`#draggable-item-container-${futureHistory.contentId}`),
            [{ itemId: futureHistory.contentId }],
          );
        } else if (futureHistory.actionType === 'ADD_SLIDE_ITEM') {
          undeleteItems(params, futureHistory.contentId);
        } else {
          const historyItem = futureHistory.content;
          const updatedItem = document.querySelector(`.canvasContainer #item-container-${futureHistory.contentId}`);
          newPastItem = updatedItem.cloneNode(true);
          // Update item
          if (futureHistory.actionType === 'UPDATE_SLIDE_ITEM') {
            updateFrame(updatedItem.querySelector('.draggable-item-container').dataset.itemId);
            updatedItem.innerHTML = historyItem;
          }
          updatedItemIds.push(futureHistory.contentId);
        }
      }

      addPast({
        presentationId: params.presentationId,
        slideId: params.slideId,
        userId: params.userId,
        actionType: futureHistory.actionType,
        contentType: 'Item',
        content: newPastItem ? newPastItem.innerHTML : '',
        contentId: futureHistory.contentId,
        selectionId: futureHistory.selectionId,
      });
    });

    batchSave(updatedItemIds, params.controller);

    // Remove from future
    localStorage.setItem(`Presentations:${params.presentationId}:Slide:${params.slideId}:User:${params.userId}:History:Future`, JSON.stringify(futureHistories));
  }
  setButtonStates(params);
};

export default redoItem;
