import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu"];

  toggle(event) {
    this.menuTarget.classList.toggle("hidden");

    if (!this.menuTarget.classList.contains("hidden")) {
      // Position menu at click coordinates
      const x = event.clientX;
      const y = event.clientY;

      // Check viewport boundaries
      const menuRect = this.menuTarget.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      // Calculate position, keeping menu within viewport
      let posX = x;
      let posY = y;

      if (x + menuRect.width > viewportWidth) {
        posX = viewportWidth - menuRect.width;
      }

      if (y + menuRect.height > viewportHeight) {
        posY = y - menuRect.height;
      }

      // Position the menu
      this.menuTarget.style.position = "fixed";
      this.menuTarget.style.top = `${posY}px`;
      this.menuTarget.style.left = `${posX}px`;
    }
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.add("hidden");
    }
  }
}
