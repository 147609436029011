/* eslint-disable max-len */
export const isTouchDevice = () => {
  // eslint-disable-next-line no-undef
  if (!(('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch))) {
    return false;
  }
  return true;
};

export const updateMoveableHorizontalGuidelines = (guides, zoom = 1) => {
  if (!window.viewer) return;
  const normalizedGuides = guides.map((guide) => guide * zoom);
  const groupGuides = guides.map((guide) => (guide - window.viewer.getScrollTop() - 303) * zoom);
  if (window.moveableForGroups) window.moveableForGroups.horizontalGuidelines = groupGuides;
  if (window.moveableForItems) window.moveableForItems.horizontalGuidelines = normalizedGuides;
  if (window.moveableForText) window.moveableForText.horizontalGuidelines = normalizedGuides;
  if (window.moveableForLineHandle) window.moveableForLineHandle.horizontalGuidelines = normalizedGuides;
  if (window.moveableForLineBody) window.moveableForLineBody.horizontalGuidelines = normalizedGuides;
  if (window.moveableForCrop) window.moveableForCrop.horizontalGuidelines = normalizedGuides;
};

export const updateMoveableVerticalGuidelines = (guides, zoom = 1) => {
  if (!window.viewer) return;
  const normalizedGuides = guides.map((guide) => guide * zoom);
  const groupGuides = guides.map((guide) => (guide - window.viewer.getScrollLeft() + 128) * zoom);
  if (window.moveableForGroups) window.moveableForGroups.verticalGuidelines = groupGuides;
  if (window.moveableForItems) window.moveableForItems.verticalGuidelines = normalizedGuides;
  if (window.moveableForText) window.moveableForText.verticalGuidelines = normalizedGuides;
  if (window.moveableForLineHandle) window.moveableForLineHandle.verticalGuidelines = normalizedGuides;
  if (window.moveableForLineBody) window.moveableForLineBody.verticalGuidelines = normalizedGuides;
  if (window.moveableForCrop) window.moveableForCrop.verticalGuidelines = normalizedGuides;
};

export const updateRulerStateAtContextMenu = (state) => {
  const { isRulersVisible } = state;
  if (isRulersVisible) {
    document.querySelector('.slideContextMenu .btn-context-show-rulers').classList.add('hidden');
    document.querySelector('.slideContextMenu .btn-context-hide-rulers').classList.remove('hidden');
    document.querySelector('.btn-zoom-menu-show-rulers').classList.add('hidden');
    document.querySelector('.btn-zoom-menu-hide-rulers').classList.remove('hidden');
  } else {
    document.querySelector('.slideContextMenu .btn-context-show-rulers').classList.remove('hidden');
    document.querySelector('.slideContextMenu .btn-context-hide-rulers').classList.add('hidden');
    document.querySelector('.btn-zoom-menu-show-rulers').classList.remove('hidden');
    document.querySelector('.btn-zoom-menu-hide-rulers').classList.add('hidden');
  }
};
