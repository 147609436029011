import { Controller } from "stimulus";
import Rails from 'rails-ujs';
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    this.initSortForm('form_team', 'team-sortable');
    this.initSortForm('form_advisor', 'advisor-sortable');
  }

  initSortForm(formId, sortableId) {
    document.querySelectorAll(`#${sortableId} .box`).forEach((element) => {
      element.insertAdjacentHTML('beforeend', `<input type="hidden" name="user[][id]" value="${element.dataset.id}" /><input type="hidden" class="user-team-ordinal" name="user[][team_ordinal]" value="${element.dataset.teamOrdinal}" />`);
    });
    const sortable = document.getElementById(sortableId);
    var el = Sortable.create(sortable, {
      animation: 150,
      group: {
          name: 'child-group'
      },
      draggable: '.box',
      forceFallback: true,
      delay: 100000, // Disable on mobile 
      delayOnTouchOnly: true, // Disable on mobile
      onEnd(event) {
        el.toArray().forEach((item, index) => {
          document.querySelector(`[data-id="${item}"] .user-team-ordinal`).value = index + 1;
        });
        Rails.fire(document.getElementById(formId), 'submit');
      },
    });
  }
}


