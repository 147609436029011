import ApplicationController from './application_controller';
import startCrop from '../helpers/editor/crop/startCrop';
import center from '../helpers/editor/items/actions/center';
import maximize from '../helpers/editor/items/actions/maximize';
import fill from '../helpers/editor/items/actions/fill';
import save from '../helpers/editor/items/save';
import {
  setLeft, setBottom, setTop, setRight, verticalCenters,
} from '../helpers/editor/items/actions/multiAlign';

import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';
import updateRect from '../helpers/editor/items/updateRect';

export default class extends ApplicationController {
  center(event) {
    event.preventDefault();
    event.stopPropagation();

    const { itemId } = event.target.dataset;
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    center(itemId);
    save(itemId, this);
  }

  reset(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemId } = event.target.dataset;
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');

    const { target } = event;
    this.stimulate('Item#reset', target)
      .then(() => {
        setTimeout(() => {
          window.moveableForItems.target = document.querySelector(`#draggable-item-container-${itemId}`);
          window.moveableForItems.updateRect();
          window.hideContextMenus();
        }, 10);
      });
  }

  maximize(event) {
    event.preventDefault();
    event.stopPropagation();

    const { itemId } = event.target.dataset;
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    maximize(itemId);
    save(itemId, this);
  }

  fill(event) {
    event.preventDefault();
    event.stopPropagation();

    const { itemId } = event.target.dataset;
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    fill(itemId);
    save(itemId, this);
  }

  delete(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemId } = event.target.dataset;

    window.moveableForItems.target = null;
    window.moveableForText.target = null;
    storeItemSnapshotForUndo(itemId, 'DELETE_SLIDE_ITEM');
    document.querySelector(`#draggable-item-container-${itemId}`).dataset.isDeleted = true;
    document.querySelector(`#draggable-item-container-${itemId}`)
      .classList
      .remove('selected');

    this.stimulate('Item#delete', [{ itemId }]);
  }

  duplicate(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemId } = event.target.dataset;

    const { target } = event;
    this.stimulate('Item#duplicate', target)
      .then(() => {
        setTimeout(() => {
          if (event.target.closest('.contextMenu').dataset.itemType === 'line') {
            // Do nothing.
          } else {
            window.moveableForItems.target = document.querySelector(`#draggable-item-container-${itemId}`);
            window.moveableForItems.updateRect();
          }
          window.hideContextMenus();
        }, 10);
      });
  }

  replace_image(event) {
    event.preventDefault();
    event.stopPropagation();

    const { target } = event;
    this.stimulate('MediaHub#replace_image_panel', target)
      .then(() => {
        setTimeout(() => {
          window.moveableForItems.target = null;
          window.hideContextMenus();
        }, 10);
      });
  }

  replace_photo(event) {
    event.preventDefault();
    event.stopPropagation();

    const { target } = event;
    this.stimulate('MediaHub#replace_photo_panel', target)
      .then(() => {
        setTimeout(() => {
          window.moveableForItems.target = null;
          window.hideContextMenus();
        }, 10);
      });
  }

  bring_forward(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemId } = event.target.dataset;
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');

    const { target } = event;
    this.stimulate('Item#bring_forward', target)
      .then(() => {
        setTimeout(() => {
          if (event.target.closest('.contextMenu').dataset.itemType === 'line') {
            // Do nothing.
          } else {
            window.moveableForItems.target = document.querySelector(`#draggable-item-container-${itemId}`);
          }
          window.hideContextMenus();
        }, 10);
      });
  }

  bring_to_front(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemId } = event.target.dataset;
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');

    const { target } = event;
    this.stimulate('Item#bring_to_front', target)
      .then(() => {
        setTimeout(() => {
          if (event.target.closest('.contextMenu').dataset.itemType === 'line') {
            // Do nothing.
          } else {
            window.moveableForItems.target = document.querySelector(`#draggable-item-container-${itemId}`);
          }
          window.hideContextMenus();
        }, 10);
      });
  }

  send_backward(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemId } = event.target.dataset;
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');

    const { target } = event;
    this.stimulate('Item#send_backward', target)
      .then(() => {
        setTimeout(() => {
          if (event.target.closest('.contextMenu').dataset.itemType === 'line') {
            // Do nothing.
          } else {
            window.moveableForItems.target = document.querySelector(`#draggable-item-container-${itemId}`);
          }
          window.hideContextMenus();
        }, 10);
      });
  }

  send_to_back(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemId } = event.target.dataset;
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');

    const { target } = event;
    this.stimulate('Item#send_to_back', target)
      .then(() => {
        setTimeout(() => {
          if (event.target.closest('.contextMenu').dataset.itemType === 'line') {
            // Do nothing.
          } else {
            window.moveableForItems.target = document.querySelector(`#draggable-item-container-${itemId}`);
          }
          window.hideContextMenus();
        }, 10);
      });
  }

  crop(event) {
    event.preventDefault();
    event.stopPropagation();
    const {
      itemId,
      imageType,
    } = event.target.dataset;
    // Don't try to crop svg
    if (imageType === 'image/svg+xml') {
      return;
    }
    startCrop(itemId);
    window.hideContextMenus();
  }

  align(event) {
    event.preventDefault();
    event.stopPropagation();

    const { currentTarget: target } = event;
    const {
      itemId,
      alignType,
    } = target.dataset;
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');

    const item = document.querySelector(`#draggable-item-container-${itemId}`);

    switch (alignType) {
      case 'align-left':
        setLeft(item, 0);
        break;
      case 'align-right':
        setRight(item, window.slideWidth);
        break;
      case 'align-top':
        setTop(item, 0);
        break;
      case 'align-bottom':
        setBottom(item, window.slideHeight);
        break;
      case 'center-vertical':
        center(itemId, 'vertical');
        break;
      case 'center-horizontal':
        center(itemId, 'horizontal');
        break;
      default:
        break;
    }
    updateRect(itemId);
    save(itemId, this);
  }

  copy(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemId } = event.target.dataset;
    this.stimulate('Item#copy', itemId, document.querySelector('.slide-container').dataset.slideId);

    // Clear clipboard for custom paste
    navigator.clipboard.writeText(JSON.stringify({
      context: 'copy',
      items: itemId,
    }));
  }

  paste(event) {
    event.preventDefault();
    event.stopPropagation();

    this.stimulate('Item#paste', document.querySelector('.slide-container').dataset.slideId);
  }
}
