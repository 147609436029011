import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  select(event) {
    if (!event.target.classList.contains('disable-select')) {
      event.preventDefault();
      event.stopPropagation();

      event.target.closest('.selectable').classList.toggle('selected');
      event.target.closest('.selectable').classList.toggle('border-pink');
    }
  }

  filter(event) {
    document.querySelector(".filters a.btn-pink").classList.remove('btn-pink')
    event.target.classList.add('btn-pink')
  }

  deleteAsset(event) {
    event.preventDefault();
    event.stopPropagation();

    if (confirm('Are you sure?') == true) {
      let assetId = event.target.getAttribute('data-asset-id');
      Rails.ajax({
        url: `/assets/${assetId}`,
        type: 'DELETE'
      });
    }
  }

  selectItemForContent(event) {
    event.preventDefault();
    event.stopPropagation();
    let target = event.target.closest('a');
    let contentType = target.getAttribute('data-content-type');
    let contentAttribute = target.getAttribute('data-content-attribute');

    if (document.querySelectorAll('.asset.selected').length === 0) {
      alert('Please select something.');
      return;
    } else if (document.querySelectorAll('.asset.selected').length > 1) {
      alert('Please select only one item.');
      return;
    }

    if (event.target.classList.contains('in-progress')) {
      return;
    }

    target.querySelector('.loader').classList.remove('hidden');
    target.classList.add('in-progress');

    let assetId = document.querySelector('.asset.selected').getAttribute('data-asset-id');

    document.querySelectorAll('.rep_image').forEach((field) => field.value = '');
    document.querySelector(`input#${contentType}_${contentAttribute}`).value = assetId;
    let imagePlaceholder = document.querySelector(`input#${contentType}_${contentAttribute}`).closest('.image-uploader-container');

    imagePlaceholder.style.backgroundImage = document.querySelector('.asset.selected div').style.backgroundImage;
    imagePlaceholder.style.backgroundSize = document.querySelector('.asset.selected div').style.backgroundSize;
    imagePlaceholder.style.backgroundPosition = document.querySelector('.asset.selected div').style.backgroundPosition;

    window.closeMediaBrowser();
  }

  createItems(event) {
    event.preventDefault();
    event.stopPropagation();

    if (document.querySelectorAll('.asset.selected').length === 0) {
      alert('Please select something.');
      return;
    }

    if (event.target.classList.contains('in-progress')) {
      return;
    }

    var target = event.target.closest('a');

    target.querySelector('.loader').classList.remove('hidden');
    target.classList.add('in-progress');

    var asset_ids = [];
    let studioId = target.getAttribute('data-studio-id');

    document.querySelectorAll('.asset.selected').forEach(function(el) {
      asset_ids.push(el.getAttribute('data-asset-id'));
    });

    let formData = new FormData();
    formData.append("slide_type", target.getAttribute('data-slide-type'));
    formData.append("asset_ids", asset_ids);
    formData.append("post_id", target.getAttribute('data-post-id'));
    formData.append("slide_id", target.getAttribute('data-slide-id'));
    formData.append('studio_id', studioId);

    Rails.ajax({
      url: `/items/create_via_assets`,
      type: "POST",
      data: formData
    });
  }

  selectItemForTemplate(event) {
    event.preventDefault();
    event.stopPropagation();

    if (document.querySelectorAll('.asset.selected').length === 0) {
      alert('Please select something.');
      return;
    } else if (document.querySelectorAll('.asset.selected').length > 1) {
      alert('Please select only one item.');
      return;
    }

    if (event.target.classList.contains('in-progress')) {
      return;
    }

    var target = event.target.closest('a');

    target.querySelector('.loader').classList.remove('hidden');
    target.classList.add('in-progress');

    let assetId = document.querySelector('.asset.selected').getAttribute('data-asset-id');
    let itemId = target.getAttribute('data-item-id');

    let formData = new FormData();
    formData.append("content_type", 'Asset');
    formData.append("asset_id", assetId);
    formData.append("post_id", target.getAttribute('data-post-id'));
    formData.append("slide_id", target.getAttribute('data-slide-id'));
    formData.append('studio_id', target.getAttribute('data-studio-id'));

    Rails.ajax({
      url: `/items/${itemId}/update_template`,
      type: "POST",
      data: formData
    });
  }

  fetchAndPreviewAsset(event) {
    let fetch_url_field = document.getElementById('fetch-url-input');
    let fetch_url_data = fetch_url_field.value;
    let studioId = event.target.getAttribute('data-studio-id');

    let formData = new FormData();
    formData.append("fetch_url", fetch_url_data);
    formData.append('studio_id', studioId);

    Rails.ajax({
      url: `/assets/create_with_fetch`,
      type: "POST",
      data: formData
    });

    fetch_url_field.value = '';
  }
}
