import updateRect from '../updateRect';
import setDataAttributes from '../setDataAttributes';

const setOpacity = (itemId, opacity) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;

  const newOpacity = parseFloat((opacity || 0), 0);

  if (newOpacity < 0 || newOpacity > 100 || isNaN(newOpacity)) {
    return;
  }

  const frame = window.mapItems.get(target);
  frame.set('opacity', newOpacity / 100);
  target.style.cssText += frame.toCSS();

  setDataAttributes(target.dataset.itemId);

  updateRect(itemId);
  window.hideContextMenus();
};

export default setOpacity;
