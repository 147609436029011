import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {

  }

  connect() {
    if (document.getElementById('tab-content-select-studio')) {
      let checkboxes = document.querySelectorAll("#tab-content-select-studio input[type=checkbox]");

      for (const checkbox of checkboxes) {
        if (checkbox.checked) {
          checkbox.closest('.studio-container').querySelector('.active-image').classList.remove('hidden');
          checkbox.closest('.studio-container').querySelector('.inactive-image').classList.add('hidden');
        }
      }
      this.calculateTotalCost();
    }
  }

  selectTerm(event) {
    let termId = event.target.value;
    if (termId) {
      let studioId = event.target.getAttribute('data-studio_id');
      let url = '/signup/terms/' + termId;
      if (studioId) {
        url += '/studios/' + studioId;
      }
      window.location = url;
    }
  }

  reasonChanged(event) {
    if (event.target.value === 'other') {
      document.getElementById('referral-source-other-container').classList.toggle('hidden');
    }
  }

  studioSelected(event) {
    if (event.target.checked) {
      // Uncheck current row
      let currentSlot = event.target.getAttribute('data-slot');
      let checkboxes = document.querySelectorAll(`[data-slot='${currentSlot}']`);

      for (const checkbox of checkboxes) {
        if (checkbox !== event.target) {
          checkbox.checked = false;
          checkbox.closest('.studio-container').querySelector('.active-image').classList.add('hidden');
          checkbox.closest('.studio-container').querySelector('.inactive-image').classList.remove('hidden');
        }
      }

      event.target.closest('.studio-container').querySelector('.active-image').classList.remove('hidden');
      event.target.closest('.studio-container').querySelector('.inactive-image').classList.add('hidden');
    } else {
      event.target.closest('.studio-container').querySelector('.active-image').classList.add('hidden');
      event.target.closest('.studio-container').querySelector('.inactive-image').classList.remove('hidden');
    }

    this.calculateTotalCost();
  }

  calculateTotalCost() {
    let checkboxes = document.querySelectorAll("input[name='studios[]']:checked");
    let sum = 0;
    for (const checkbox of checkboxes) {
      sum += parseFloat(checkbox.getAttribute('data-cost'));
    }
    document.getElementById('signup_deposit').innerText = `$${sum}`;
    document.getElementById('deposit').value = sum;
  }
}
