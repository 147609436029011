import ApplicationController from './application_controller';
import { storeSlideSnapshotForUndo } from '../helpers/editor/history/manager';

export default class extends ApplicationController {
  beforeUndo(element) {
    this.storeMoveableTargets();
  }

  beforeRedo(element) {
    this.storeMoveableTargets();
  }

  afterUndo(element) {
    this.updateMoveableTargets();
  }

  afterRedo(element) {
    this.updateMoveableTargets();
  }

  storeMoveableTargets() {
    if (window.moveableForGroups.target) {
      window.storedMoveableForGroupsTarget = window.moveableForGroups.target;
      window.moveableForGroups.target = null;
    }

    if (window.moveableForItems.target) {
      window.storedMoveableForItemsTarget = window.moveableForItems.target;
      window.moveableForItems.target = null;
    }

    if (window.moveableForText.target) {
      window.storedMoveableForTextTarget = window.moveableForText.target;
      window.moveableForText.target = null;
    }
  }

  updateMoveableTargets() {
    if (window.storedMoveableForGroupsTarget) {
      window.moveableForGroups.target = Array.from(window.storedMoveableForGroupsTarget).map((item) => document.getElementById(item.id));
      window.storedMoveableForGroupsTarget = null;
    }

    if (window.storedMoveableForItemsTarget) {
      window.moveableForItems.target = document.getElementById(window.storedMoveableForItemsTarget.id);
      window.storedMoveableForItemsTarget = null;
    }

    if (window.storedMoveableForTextTarget) {
      window.moveableForText.target = document.getElementById(window.storedMoveableForTextTarget.id);
      window.storedMoveableForTextTarget = null;
    }
  }

  updateBackgroundColor(event) {
    const color = event.target.getAttribute('fill') || event.target.value;
    if (color) {
      const currentSlide = document.querySelector('.canvasContainer .slide-container');
      const { slideId } = currentSlide.dataset;
      const currentColor = currentSlide.style.backgroundColor;
      storeSlideSnapshotForUndo(slideId, 'UPDATE_SLIDE_BACKGROUND', '', currentColor);
      this.stimulate('Slide#set_background_color', color);
    }
  }

  showPicker(event) {
    document.querySelector('.colorPicker').classList.remove('hidden');
  }
}
