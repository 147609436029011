import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  addCollaborator(event) {
    const presentationId = event.target.getAttribute("data-presentation-id");
    const collaboratorId = document.getElementById('new_user_id').value;
    this.stimulate('Share#add_collaborator', presentationId, collaboratorId);
    // const formData = new FormData();
    // formData.append('user_id', document.getElementById('new_user_id').value);

    // Rails.ajax({
    //   url: `/posts/${event.target.getAttribute('data-post-id')}/add_collaborator`,
    //   type: 'POST',
    //   data: formData,
    // });
  }

  selectCollaborator(event) {
    this.addCollaborator(event);
    event.target.querySelector('input').value = '';
  }
}
