import ApplicationController from './application_controller.js';
import interact from 'interactjs';
import showCommentBox from '../helpers/editor/comments/showCommentBox';
import hideCommentBox from '../helpers/editor/comments/hideCommentBox';
import { Frame } from 'scenejs';
import hideCommentPanel from "../helpers/editor/panels/hideCommentPanel";
import hideItemPanel from "../helpers/editor/panels/hideItemPanel";
import hideLinkPanel from "../helpers/editor/panels/hideLinkPanel";

export default class extends ApplicationController {
  static targets = ['container'];

  connect() {
    super.connect();

    if (!this.hasContainerTarget) {
      return;
    }

    this.initializeMoveableForSingleItem();

    if (this.containerTarget.dataset.itemType !== 'comment') return; // Switching to moveable

    if (
      document.body.classList.contains('commentMode') &&
      !this.containerTarget.classList.contains('item-type-comment')
    ) {
      return;
    }

    let position = {
      x: parseFloat(this.containerTarget.dataset.translateX),
      y: parseFloat(this.containerTarget.dataset.translateY),
    };

    var dragAxis = '';

    interact(this.containerTarget)
      .draggable({
        ignoreFrom: '.ql-editor[contenteditable="true"]',
      })
      .on('dragstart', (event) => {
        position = {
          x: parseFloat(this.containerTarget.dataset.translateX),
          y: parseFloat(this.containerTarget.dataset.translateY),
        };
      })
      .on('dragmove', (event) => {
        if (document.body.classList.contains('handMode')) return;

        let zoomLevel = this.getZoomScale();

        position.x += event.dx / zoomLevel;
        position.y += event.dy / zoomLevel;

        event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
        event.target.dataset.translateX = position.x;
        event.target.dataset.translateY = position.y;

        window.Diagrams.updateDiagramPositions();
        if (window.leaderLine) {
          window.leaderLine.position();
          window.leaderLine2.position();
        }
      })
      .on('dragend', (event) => {
        if (document.body.classList.contains('handMode')) return;

        dragAxis = '';

        if (!document.body.classList.contains('handMode')) {
          this.stimulate('Item#update', event.target);
          this.disallowDeselect(10);
        }
      });
  }

  getZoomScale() {
    let zoomLevel = parseFloat(window.zoomLevel);
    return zoomLevel;
  }

  disallowDeselect(ms = 10) {
    // To prevent clickking whitespace
    window.draggingElement = true;
    setTimeout(function () {
      window.draggingElement = false;
    }, 10);
  }

  select(event) {
    if(event.touches && event.touches.length > 1) {
      return;
    }

    if (
      window.draggingElement ||
      (document.body.classList.contains('commentMode') &&
        !event.target.closest('.item-type-comment'))
    ) {
      return;
    }

    if (event.target.closest('.item-type-comment')) {
      let target = event.target.closest('.item-type-comment');
      if (target.classList.contains('selected')) {
        this.unSelectOtherItems(null);
        hideCommentBox();
      } else {
        showCommentBox(this, target);
        this.unSelectOtherItems(target);
        this.selectItem(target);
      }
      return;
    }

    if (this.containerTarget.classList.contains('selected')) {
      if (event.target.closest('.item-type-text') || event.target.closest('.item-type-grid') ) {
        return;
      }
    }

    if (document.body.classList.contains('commentMode')) {
      return;
    }

    if (!event.shiftKey) {
      this.unSelectOtherItems(this.containerTarget);
    }

    this.selectItem(this.containerTarget);

    if (event.shiftKey && this.selectedItems().length > 1) {
      document.querySelector('.canvas').canvas.selectMultiItems();
    }

    this.stimulate('Item#select', this.selectedItemIds());
  }

  unSelectOtherItems(target) {
    let selectedItems = document.querySelectorAll('.item-container .selected');
    for (const selectedItem of selectedItems) {
      if (target != selectedItem) {
        selectedItem.classList.remove('selected');
        selectedItem.classList.remove('multi-select');
        selectedItem.classList.remove('has-link')
        if (selectedItem.closest('.item-container').querySelector('.handle')) {
          selectedItem.closest('.item-container').querySelector('.handle-left').classList.add('hidden');
          selectedItem.closest('.item-container').querySelector('.handle-right').classList.add('hidden');
        }
        hideLinkPanel()
      }
    }

    window.moveableForItems.target = null;
    window.moveableForText.target = null;
    window.moveableForGroups.target = null;
    window.moveableForLineHandle.target = null;

    document.querySelectorAll('.leader-line').forEach((el) => el.classList.remove('active'));
  }

  unSelectItem(target) {
    let selectedItems = document.querySelectorAll('.item-container .selected');
    for (const selectedItem of selectedItems) {
      if (target === selectedItem) {
        selectedItem.classList.remove('selected');
        selectedItem.classList.remove('multi-select');
        if (selectedItem.closest('.item-container').querySelector('.handle')) {
          selectedItem.closest('.item-container').querySelector('.handle-left').classList.add('hidden');
          selectedItem.closest('.item-container').querySelector('.handle-right').classList.add('hidden');
        }
        hideLinkPanel()
      }
    }

    let newSelectedItems = []
    window.moveableForGroups.target.forEach(item => {
      if (item != target) {
        newSelectedItems.push(item)
      }
    });
    window.moveableForItems.target = null;
    window.moveableForText.target = null;
    window.moveableForLineHandle.target = null;

    window.selectoForItems.trigger('select', {selected: newSelectedItems});
    window.selectoForItems.trigger('selectEnd', {selected:newSelectedItems, afterAdded: newSelectedItems});

  }

  selectItem(target, source = 'itemClick') {
    if (document.body.classList.contains('handMode') || target.dataset.itemType === 'comment') {
      return;
    } else {
      window.moveableForText.target = null;
      document.querySelector('.canvas').canvas.disableQuill();
      window.moveableForItems.target = null;
      window.moveableForGroups.target = null;

      if(target.dataset.groupId !== '' && source === 'itemClick' && window.currentActiveGroupId !== target.dataset.groupId) { // We have a item in a group
        const items = document.querySelectorAll(`
          .item-container .draggable-item-container[data-group-id="${target.dataset.groupId}"]:not(.item-type-line),
          .item-container .line-snapper[data-group-id="${target.dataset.groupId}"]
        `);

        window.selectoForItems.trigger('select', {selected: Array.from(items)});
        window.selectoForItems.trigger('selectEnd', {selected: Array.from(items), afterAdded: Array.from(items)});
      } else {
        if(target.dataset.groupId !== window.currentActiveGroupId) { // We clicked on an item that's not in the group
          window.currentActiveGroupId = null;
        }
        target.classList.add('selected');
        if(target.dataset.itemType === 'text') {
          window.moveableForText.target = target;
        } else {
          window.moveableForItems.target = target
        }
      }

      // Show link icon for items that has link
      let itemLink = target.dataset.link
      if (itemLink){
        target.classList.add('has-link');
        let linkContextParent = target.closest('.item-container').querySelector('.linkContextMenu')
        let linkText = linkContextParent.querySelector('.linkText')

        if (itemLink.includes('http')) {
          linkText.href = itemLink
        }
        linkContextParent.classList.remove('hidden')
      }
    }

      //Hide all other types of panels
      hideCommentPanel();
      hideCommentBox();
      hideItemPanel();

      if (document.getElementById('left-template-button')) {
        Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('fill', 'black'));
        Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('stroke', ''));
      }
      Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('stroke', 'none'));
      Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('fill', 'black'));

      if (document.getElementsByClassName("share-button")[0].classList.length > 9){
        document.getElementById("shareButton").click()
      }
      if (document.getElementsByClassName("comments-button")[0].classList.length > 9){
        document.getElementById("commentButton").click()
      }
  }

  copyButton(event){
    let textContent = event.target.parentElement.parentElement.querySelector('.linkText').href
    console.log(textContent)
    navigator.clipboard.writeText(textContent).then(function() {
      window.notice("Copied Link to Clipboard")
    }, function() {
      window.notice('Something went wrong, please try again')
    });
  }

  linkClicked(event){
    let textContent = event.target.closest('.item-container').querySelector('.draggable-item-container').dataset.link
    if (!(this.context.scope.element.innerHTML.includes('www.'))) {
      const canvasController = document.querySelector('.canvas').canvas;
      canvasController.changeSlide(event, textContent)
    }
  }

  selectedItems() {
    return document.querySelectorAll('.item-container .selected');
  }

  selectedItemIds() {
    return Array.from(this.selectedItems())
      .map((div) => div.dataset.itemId)
      .join();
  }

  dragStart(event) {
    event.preventDefault();
    event.stopPropagation();

    // Selecting a table cell
    if(event.target.querySelector('td.selected')) {
      return;
    }

    this.unSelectOtherItems(event.target);

    if (typeof event.target.closest === "undefined") return;
    if (event.target.querySelector('.ql-editor[contenteditable="true"]')) {
      return;
    }


    window.moveableForItems.target = null;
    window.moveableForGroups.target = null;
    window.moveableForText.target = null;
    window.moveableForLineHandle.target = null;
    window.moveableForLineBody.target = null;

    let target = event.target.closest('.draggable-item-container');
    if(target.dataset.groupId !== '') {
      const items = document.querySelectorAll(`
            .item-container .draggable-item-container[data-group-id="${target.dataset.groupId}"]:not(.item-type-line),
            .item-container .line-snapper[data-group-id="${target.dataset.groupId}"]
          `);
      window.moveableForGroups.target = items;
      window.moveableForGroups.dragStart(event);
    }
    else if (target.classList.contains('item-type-text')) {
      window.moveableForText.target = target;
      window.moveableForText.dragStart(event);
    } else {
      window.moveableForItems.target = target;
      window.moveableForItems.dragStart(event);
    }
  }

  initializeMoveableForSingleItem() {
    let target = this.containerTarget;
    if (target.id === 'croppable-item-container') return; // Skip if crop background

    const frame = window.mapItems.get(target);

    if (!frame) {
      const frame = new Frame({
        "z-index": target.style.zIndex,
        position: 'absolute',
        width: (target.dataset.itemType === 'text' ? target.style.width : target.dataset.width),
        height: (target.dataset.itemType === 'text' ? target.style.height : target.dataset.height),
        opacity: parseInt(target.dataset.opacity, 0) / 100,
        transform: {
          translateX: `${ target.dataset.translateX || 0 }px`,
          translateY: `${ target.dataset.translateY || 0 }px`,
          rotate: `${ target.dataset.rotate || "0deg" }`,
          scaleX: `${ target.dataset.scaleX || 1 }`,
          scaleY: `${ target.dataset.scaleY || 1 }`
        }
      });
      target.style.cssText = frame.toCSS();
      window.mapItems.set(target, frame);
    }
  }
}
