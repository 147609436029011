/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ["childTabTemplate", "addNewChildTabLink"]

  connect() {
  }

  addNewChildTab(event) {
    event.preventDefault();
    const id = new Date().getTime();
    var content = this.childTabTemplateTarget.innerHTML.replace(/CHILD_TEMPLATE_RECORD/g, id);
    this.addNewChildTabLinkTarget.insertAdjacentHTML('beforebegin', content)
    window.activateSlimSelect();
  }

  removeChildTab(event) {
    if(window.confirm("Are you sure?")) {
      const childTabForm = event.target.parentElement;
      console.log(childTabForm);
      childTabForm.querySelector("input[name*='_destroy']").value = 1;
      childTabForm.style.display = 'none';
    }
  }
}
