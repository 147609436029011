import { Controller } from "stimulus";
import Rails from 'rails-ujs';

export default class extends Controller {
  connect() {
    var isStudent = this.data.get("is-student")
    if (isStudent === "true") {
      var studioId = this.data.get("studio-id");
      var studentId = this.data.get("user-id");
      var studentName = this.data.get("student-name");
      this.callStudentGrading(studioId, studentId, studentName);
    } else {
      if (document.querySelector('.student-label')) {
        var studioId = this.data.get("studio-id");
        var studentId = document.querySelector('.student-label').dataset.tagId;
        var studentName = this.data.get("student-name");
        this.callStudentGrading(studioId, studentId, studentName);
      }
    }
  }

  onStudentChanged(event) {
    event.preventDefault();
    event.stopPropagation();

    document.getElementById('studio-grading-container').innerHTML = '';

    var studioId = event.target.getAttribute("data-studio-id");
    var studentId = event.target.getAttribute("data-student-id");
    var studentName = event.target.getAttribute("data-student-name");

    this.callStudentGrading(studioId, studentId, studentName);
    document.getElementsByTagName('html')[0].scrollIntoView({ behavior: "smooth" });
  }

  callStudentGrading(studioId, studentId, studentName) {
    Rails.ajax({
      url: `/studios/${studioId}/student_grading/${studentId}`,
      type: 'GET',
      success: (resp, status, xhr) => {
        var menus = document.querySelectorAll('.action-menu');
        menus.forEach((menu) => {
          menu.classList.add('hidden');
        });
        var labels = document.querySelectorAll('.student-label');
        labels.forEach((label) => {
          label.classList.remove('text-pink');
          label.classList.remove('font-bold');
        });

        document.getElementById(`label-student-${studentId}`).classList.add('text-pink');
        document.getElementById(`label-student-${studentId}`).classList.add('font-bold');

        document.getElementById('studio-grading-container').innerHTML = xhr.responseText;
        this.initSlider()
      }
    });
  }

  initSlider() {
    window.customSlider();
    let gradingSliderInputs = document.querySelectorAll('.grading-custom-slider');

    gradingSliderInputs.forEach((input) => {
      let maximumGradeScore = parseInt(input.getAttribute('data-maximum-grade') ?? 100);
      let step = parseFloat(input.getAttribute('data-step') ?? 1);
      let density = parseFloat(input.getAttribute('data-density') ?? 6.25);
      // Bostonia density: 6.25, cambridge: 12,
      noUiSlider.create(input, {
        start: [input.getAttribute('data-value')],
        connect: 'lower',
        step: step,
        range: {
          'min': 0,
          'max': maximumGradeScore
        },
        pips: {
          mode: 'range',
          density: density
        },
      }).on('update', function (values, handle) {
          var value = values[handle];
          var targetElement = input.closest('.slider-holder').querySelector('.slider-value');
          targetElement.value = value
      });
    });
  }

  changeWillNotGraded(event) {
    let parentHolder = this.getClosest(event.currentTarget, '.collapsable-panel')
    let skillNameLabel = parentHolder.querySelector('.studio-skill-skill-name');
    let skillSlider = parentHolder.querySelector('.grading-custom-slider');

    if (event.currentTarget.checked) {
      skillNameLabel.classList.add('line-through');
      skillSlider.setAttribute('disabled', true);
    } else {
      skillNameLabel.classList.remove('line-through');
      skillSlider.removeAttribute('disabled');
    }
  }

  getClosest(elem, selector) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Get the closest matching element
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
      if ( elem.matches( selector ) ) return elem;
    }
    return null;

  };

  togglePanel(event) {
    event.target.closest('.collapsable-panel').querySelector('.descriptions').classList.toggle('hidden');
    const arrowDown = event.target.closest('.collapsable-panel').querySelector('.fa-chevron-down');
    const arrowUp = event.target.closest('.collapsable-panel').querySelector('.fa-chevron-up');

    if (arrowDown) {
      arrowDown.classList.remove('fa-chevron-down');
      arrowDown.classList.add('fa-chevron-up');
    }

    if (arrowUp) {
      arrowUp.classList.remove('fa-chevron-up');
      arrowUp.classList.add('fa-chevron-down');
    }
  }
}
