import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  search(searchEvent) {
    const outsideClickListener = (event) => {
      if (!event.target.closest('#searchbar-results')) {
        this.hideResults();
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
    };

    document.addEventListener('click', outsideClickListener);

    if (searchEvent.target.value.length < 2) {
      return;
    }

    let debounce;
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      this.stimulate('Searchbar#search', searchEvent.target.value);
    }, 350);
  }

  hideResults() {
    document.getElementById('searchbar-results').innerHTML = '';
    document.getElementById('searchbar-input').value = '';
  }
}
