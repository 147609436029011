/* global SlimSelect */
import { Controller } from "stimulus";
import currentSidebarState from "./helpers/currentSidebarState";
import changeLastToggledElement from "./helpers/changeLastToggledElement";

export default class extends Controller {
  static targets = [
    "login",
    "menu",
  ]

  connect() {
    // For fading effect on homepage. It can be used everywhere.
    var onEnterViewPort = function(entries, observer) {
      entries.forEach(function(entry) {
        // Fade in when we enter the viewport
        if (entry.intersectionRatio !== 0) {
          entry.target.classList.add('in');
        }
        // Fade back out when we leave the viewport
        else {
          entry.target.classList.remove('in');
        }
      })
    }

    var observer =  new IntersectionObserver(onEnterViewPort , {
    });

    var hidemes= document.querySelectorAll('.hideme');
    for(var i = 0; i < hidemes.length; ++i) {
      observer.observe(hidemes[i]);
    }
  }

  async cloneNodes(activeElement){
    let activeClonedNodes = [];
    const activeNodes = activeElement.children;
    if(Object.values(activeNodes).length > 0){
      for(let activeNode of activeNodes){
        const clonedNode = activeNode.cloneNode(true)
        activeClonedNodes.push(clonedNode)
      }
      return activeClonedNodes;
    }
  }

  async putInSidebar(clonedNodes){
    const sidebarInner = document.getElementById("empty-sidebar");
    const clonedMenu = sidebarInner.children[0].cloneNode(true);
    sidebarInner.innerHTML = "";
    sidebarInner.appendChild(clonedMenu);

    if(Object.values(clonedNodes).length > 0){
      for(let clonedNode of clonedNodes){
        sidebarInner.appendChild(clonedNode);
      }
    }
  }

  async changeSidebarInner(activeElement){
    const clonedNodes = await this.cloneNodes(activeElement);
    await this.putInSidebar(clonedNodes)
  }

  controlSidebar(activeElement){
    const currentStatus = currentSidebarState();
    const emptySidebar = document.getElementById('empty-sidebar');
    const lastToggledElement = document.getElementById('empty-sidebar').getAttribute('data-last-open');

    if(currentStatus === false){
      document.getElementById("sidebar-state").classList.remove('block');
      document.getElementById("sidebar-state").classList.add('hidden');
      document.querySelector('.overlay').classList.remove('hidden');

      const sidebar = document.getElementById('empty-sidebar');
      sidebar.setAttribute('data-sidebar-open', 'true');
      sidebar.classList.remove('hidden');
      sidebar.classList.remove('now');

      if(lastToggledElement !== activeElement.id){
        this.changeSidebarInner(activeElement);
        changeLastToggledElement(activeElement.id);
      }
      emptySidebar.classList.remove('sidemenuEffect');
    }else if(currentStatus === true && lastToggledElement !== activeElement.id){
      this.changeSidebarInner(activeElement);
      changeLastToggledElement(activeElement.id)
    }else if(currentStatus === true && lastToggledElement === activeElement.id){
      return;
    }
  }

  toggleSidebar() {//menu
    const activeElement = document.getElementById('sidebar');
    this.controlSidebar(activeElement);
  }

  toggleProfile() {//login
    const activeElement = document.getElementById('profile');
    this.controlSidebar(activeElement)
  }

  controlElement(event){
    event.preventDefault();
    event.stopPropagation();

    // debugger
    switch (event.target) {
      case this.loginTarget:
        this.toggleProfile();
        break;

      case this.menuTarget:
        this.toggleSidebar();
        break;

      default:
        break;
    }
  }

  //When user click the "LOGIN" in empty sidebar this action will execute
  clickedLogin(event){
    event.preventDefault();
    event.stopPropagation();

    document.getElementById("login-target").click();
  }

  //When user click the "MENU" in empty sidebar this action will execute
  clickedMenu(event){
    event.preventDefault();
    event.stopPropagation();

    document.getElementById("menu-target").click();
  }

  togglePasswordReset(event) {
    // TODO: Refactor

    event.preventDefault();
    event.stopPropagation();
    let elem = document.getElementById('password-reset-form');
    if (elem.classList.contains("hidden")) {
      elem.classList.remove("fadeOutUp");
      elem.classList.add("fadeInDown");
      elem.classList.remove("hidden");
    } else {
      elem.classList.remove("fadeInDown");
      elem.classList.add("fadeOutUp");

      elem.addEventListener('animationend', function() {
        if (elem.classList.contains("fadeOutUp")) {
          elem.classList.add("hidden");
        }
      });
    }
  }

  closeSidebar(e) {
    const menu = document.querySelector(".sidemenu");
    menu.classList.remove('now');
    menu.classList.add('sidemenuEffect');
    document.getElementById('sidebar-state').classList.remove('hidden');
    document.getElementById('sidebar-state').classList.add('block');
    document.querySelector('.overlay').classList.add('hidden');
    document.getElementById('empty-sidebar').setAttribute('data-sidebar-open', 'false');
  }

  // TODO: Move somewhere else
  hideImages(event) {
    event.preventDefault();
    event.stopPropagation();
    window.closeMediaBrowser();
  }

  pegagogyMouseOver(event) {
    event.preventDefault();
    event.stopPropagation();
    let videoContainer = event.target.parentNode.querySelector('.pedagogy-video')
    let videoElement = videoContainer.querySelector('video')
    let contentSectionClass = `.${event.target.getAttribute('data-item')}`
    document.querySelector('.pedagogy-main').classList.remove('block')
    document.querySelector('.pedagogy-main').classList.add('hidden')
    document.querySelector(contentSectionClass).classList.add('block')
    document.querySelector(contentSectionClass).classList.remove('hidden')
    videoElement.play()
    videoContainer.style.visibility = 'visible'
  }

  pegagogyMouseOut(event) {
    event.preventDefault();
    event.stopPropagation();
    let videoContainer = event.target.parentNode
    let videoElement = event.target
    let contentSectionClass = `.${event.target.getAttribute('data-item')}`
    document.querySelector('.pedagogy-main').classList.remove('hidden')
    document.querySelector('.pedagogy-main').classList.add('block')
    document.querySelector(contentSectionClass).classList.add('hidden')
    document.querySelector(contentSectionClass).classList.remove('block')
    videoElement.pause()
    videoContainer.style.visibility = 'hidden'
  }
}
