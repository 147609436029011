import { Controller } from 'stimulus';
import Rails from 'rails-ujs';
import { htmlToElement } from '../helpers/dom';
import { createRequest } from '../helpers/createRequest';

export default class extends Controller {
  connect() {
    if (this.element.dataset.target === 'skillInput') {
      const textarea = this.element.closest('form').querySelector('textarea');
      textarea.placeholder = document.querySelector(`#skill-desc-${this.element.value}`).innerHTML.trim();
    }

    if (this.element.id === 'g4g-selected-skills') {
      // eslint-disable-next-line no-underscore-dangle
      this.showSelectedSkills(this.element);
    }
  }

  // eslint-disable-next-line no-underscore-dangle
  showSelectedSkills(element) {
    const selected = [...document.querySelectorAll('.custom-checkbox:checked')];
    const html = selected.map((input) => {
      const select = input.closest('.flex').querySelector('select');
      const option = select.options[select.selectedIndex];
      const optionText = option.innerText;
      return `<span class="p-2 rounded bg-dcdbdb w-full appearance-none mr-2">${optionText}</span>`;
    }).join('');
    element.innerHTML = html;
  }


  changeWeek(event) {
    const url = new URL(window.location);
    url.searchParams.set('week', event.target.value);
    Turbolinks.visit(url.toString());
  }

  updateGoal(event) {
    Rails.fire(event.target.closest('form'), 'submit');
    this.showSelectedSkills(document.querySelector('#g4g-selected-skills'));
  }

  addGoal(event) {
    const container = event.target.closest('.coach-goals-container');
    const goals = container.querySelector('.coach-goals');

    const formData = new FormData();
    formData.set('skill_category_id', event.target.dataset.skillCategoryId);
    formData.set('studio_id', event.target.dataset.studioId);
    formData.set('week', event.target.dataset.week);

    fetch(createRequest('/g4g_goals', 'POST', formData))
      .then((response) => response.text())
      .then((html) => {
        goals.insertAdjacentElement('afterbegin', htmlToElement(html));
        window.activateSlimSelect();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  destroy(event) {
    const el = event.currentTarget;
    if (confirm('Are you sure?')) {
      const id = el.dataset.goalId;

      fetch(createRequest(`/g4g_goals/${id}`, 'DELETE'))
        .then(() => el.parentElement.remove())
        .catch((error) => {
          console.log(error);
        });
    }
  }

  setSkillPlaceholder(event) {
    const textarea = event.target.closest('form').querySelector('textarea');
    textarea.placeholder = document.querySelector(`#skill-desc-${event.target.value}`).innerHTML.trim();
  }

  updateProjectDescription(event) {
    Rails.fire(event.target.closest('form'), 'submit');
  }

  updateCoachComment(event) {
    Rails.fire(event.target.closest('form'), 'submit');
  }
}
