import { Controller } from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  connect() {
    this.initOverviewMultiSelects();
    this.initBackendSearchChoice();
  }

  initOverviewMultiSelects() {
    document.querySelectorAll('.choices-local-data')
      .forEach((element) => {
        element.choices = new Choices(
          element,
          {
            removeItemButton: true,
            placeholder: true,
            placeholderValue: 'Pick an Strokes record'
          }
        );
    });
  }

  initBackendSearchChoice() {
    const config = {
      removeItemButton: true,
      searchResultLimit: 15,
      placeholderValue: 'Start typing',
      shouldSort: false,
      searchFloor: 3, // or whatever value makes sense for your data / API
      searchChoices: false // see bullet point 2 above
    };
    document.querySelectorAll('.choices-remote-data')
      .forEach((element) => {
        // Construct the Choices object.
        var choices = new Choices(element, config);
        element.choices = choices;

        // Some config and bookkeeping for API calls.
        var apiUrl = element.dataset.remoteUrl;
        var lookupDelay = 100;
        var lookupTimeout = null;
        var lookupCache = {};

        // Function to perform the API lookup.
        var serverLookup = function () {
          // we can't use choices.currentValue because this is blank if we set searchChoices
          // to false. Instead we use the actual input field's value.
          var query = choices.input.value;
          if (query in lookupCache) {
            populateOptions(lookupCache[query]);
          } else {
            // Ajax query and results parsing depends on your API structure and how
            // you make AJAX calls in your codebase.
            fetch(
              // query doesnt work because the external api doesnt support it.. now its random..
              apiUrl + '?query=' + query
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                lookupCache[query] = data;
                populateOptions(data);
              });
          }
        };

        // Function to actually populate the results from the API lookup.
        var populateOptions = function (options) {
          // We have to cull duplicates ourselves here, because Choices doesn't do it for
          // us. There's probably a better way of doing this, but here's the easy way:
          var toRemove = [];
          for (var i = 0; i < choices._currentState.items.length; i++) {
            var item = choices._currentState.items[i];
            if (item.active) {
              toRemove.push(item.value);
            }
          }
          var toKeep = [];
          for (var i = 0; i < options.length; i++) {
            // However you check if an array contains something in your codebase.
            if (!toRemove.includes(options[i].id)) {
              toKeep.push(options[i]);
            }
          }
          // Now actually set the (de-duped) results.
          choices.setChoices(toKeep, 'id', 'text', true);
        };

        // Trigger an API lookup when the user pauses after typing.
        element.addEventListener('search', function (event) {
          clearTimeout(lookupTimeout);
          lookupTimeout = setTimeout(serverLookup, lookupDelay);
        });

        // We want to clear the API-provided options when a choice is selected.
        element.addEventListener('choice', function (event) {
          choices.setChoices([], 'id', 'text', true);
        });
      });
  }

  selectQuerySet(event) {
    event.preventDefault();
    event.stopPropagation();
    let btn = event.currentTarget;
    let querySetId = btn.dataset.querySetId;
    let querySetData = btn.dataset.querySetData;
    let parsedQuerySetData = JSON.parse(querySetData);
    // Set title
    document.getElementById('query_title').value = parsedQuerySetData['title'];
    // Populuate critera selections
    for (let i = 0; i < Object.keys(parsedQuerySetData['criteria']).length; i++) {
      let element = Object.keys(parsedQuerySetData['criteria'])[i];
      let elementValues = Object.values(parsedQuerySetData['criteria'])[i];
      let htmlElement = document.getElementById('query_criteria_' + element);
      if (htmlElement && Array.isArray(elementValues) && elementValues.length > 0) {
        // Remove previously populated options
        htmlElement.choices.removeActiveItems();
        const formElements = elementValues.forEach(function(el){
          const splittedText = el.split("|||");
          const label = splittedText[1];
          // Set values to choices.js element
          htmlElement.choices.setValue([{ value: el, label: label }]);
        })
      }
    }

    // Populate fields to show
    for (let i = 0; i < Object.keys(parsedQuerySetData['fields']).length; i++) {
      let element = Object.keys(parsedQuerySetData['fields'])[i];
      let elementValues = Object.values(parsedQuerySetData['fields'])[i];
      if (elementValues === "1") {
        document.getElementById('query_fields_' + element).checked = true;
      }
    }
  }
}
