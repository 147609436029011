import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    const tabs = document.querySelectorAll('div.action-item button');
    if (tabs.length > 0) tabs[0].classList.add('bold-by-shadow');
    const activeTab = document.querySelectorAll('div.action-item.active')[0];
    if (activeTab) {
      this.switch_tab_by_name(activeTab.dataset.tabName);
    }
    if (location.hash) {
      this.switch_tab_by_name(location.hash.replace('#', ''));
    }
  }

  switch_tab(event) {
    event.preventDefault();
    this.hide_all_tabs();

    const panel = document.getElementById(event.target.dataset.value);
    if (panel.classList.contains('hide-form-action-buttons') && document.querySelector('.form-action-buttons')) {
      document.querySelector('.form-action-buttons')
        .classList
        .add('hidden');
    } else if (document.querySelector('.form-action-buttons')) {
      document.querySelector('.form-action-buttons')
        .classList
        .remove('hidden');
    }

    if (panel !== null) panel.classList.remove('hidden');

    // event.target.classList.remove('font-normal');
    event.target.classList.add('bold-by-shadow');

    const button = document.querySelector(`div.action-item button[data-value='${event.target.dataset.value}']`);
    if (button !== null) {
      // button.classList.remove('font-normal');
      button.classList.add('bold-with-shadow');
    }

    window.expandableTextArea();
  }

  switch_tab_by_name(name) {
    this.hide_all_tabs();
    let tabContentName = `tab-content-${name}`;
    let panel = document.getElementById(tabContentName);
    if (panel !== null) panel.classList.remove('hidden');

    let button = document.querySelector(`div.action-item button[data-value='${tabContentName}']`);
    if (button !== null) {
      // button.classList.remove('font-normal');
      button.classList.add('bold-with-shadow');
      button.classList.add('bold-by-shadow');
    }

    window.expandableTextArea();
  }

  hide_all_tabs() {
    var tabs = document.querySelectorAll('div.tab');
    tabs.forEach((tab) => {
      tab.classList.add('hidden');
    });

    tabs = document.querySelectorAll('div.action-item button');
    tabs.forEach((tab) => {
      tab.classList.remove('bold-by-shadow');
      // tab.classList.add('font-normal');
    });
  }

  add_new(event) {
    console.log('adding new tab...');
  }
}
