const calculateCropAttributes = (target) => {
  if (!target) return;

  if (target.dataset.itemType === 'image' || target.dataset.itemType === 'camera_asset') {
    if (!target.dataset.clip || target.dataset.clip === 'undefined') return;

    const frame = window.mapItems.get(target);
    const attributes = frame.get('transform');

    const deltaX = parseFloat(attributes.translateX) - parseFloat(target.dataset.translateX);
    const deltaY = parseFloat(attributes.translateY) - parseFloat(target.dataset.translateY);

    target.dataset.translateXBeforeCrop = parseFloat(target.dataset.translateXBeforeCrop) + deltaX;
    target.dataset.translateYBeforeCrop = parseFloat(target.dataset.translateYBeforeCrop) + deltaY;

    const prevWidth = parseFloat(target.dataset.width);
    const widthRatio = (parseFloat(frame.get('width')) / prevWidth);

    target.dataset.widthWas = parseFloat(target.dataset.widthWas) * widthRatio;

    const prevHeight = parseFloat(target.dataset.height);
    const heightRatio = (parseFloat(frame.get('height')) / prevHeight);

    target.dataset.heightWas = parseFloat(target.dataset.heightWas) * heightRatio;

    const clipValues = target.dataset.clip.match(/([0-9]\d*(\.\d+)?)/g);

    let top = parseFloat(clipValues[0]);
    let right = parseFloat(clipValues[1]);
    let bottom = parseFloat(clipValues[2]);
    let left = parseFloat(clipValues[3]);

    if (widthRatio !== 1) {
      const oldLeft = left;
      left *= widthRatio;
      right *= widthRatio;

      // Keep cropped image in place
      target.dataset.translateXBeforeCrop = parseFloat(target.dataset.translateXBeforeCrop) - (left - oldLeft);
    }

    if (heightRatio !== 1) {
      const oldTop = top;
      top *= heightRatio;
      bottom *= heightRatio;

      // Keep cropped image in place
      target.dataset.translateYBeforeCrop = parseFloat(target.dataset.translateYBeforeCrop) - (top - oldTop);
    }

    target.dataset.clip = `rect(${top}px,${right}px,${bottom}px,${left}px)`;
  }
};

export default calculateCropAttributes;
