import ApplicationController from './application_controller.js';
import Quill from 'quill';
import 'quill-paste-smart';
import SlimSelect from 'slim-select';
import { debounce } from 'lodash';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = null;
Quill.register(SizeStyle, true);

const Parchment = Quill.import('parchment');
const lineHeightConfig = {
  scope: Parchment.Scope.BLOCK,
  whitelist: null,
};

const lineHeightStyle = new Parchment.Attributor.Style(
  'lineheight',
  'line-height',
  lineHeightConfig,
);

Parchment.register(lineHeightStyle);

const letterSpacingConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: ['0px', '1px', '2px', '3px', '4px', '5px'],
};

const letterSpacingStyle = new Parchment.Attributor.Style(
  'letterspace',
  'letter-spacing',
  letterSpacingConfig,
);

Parchment.register(letterSpacingStyle);

const fontWeightConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: ['100', '300', '400', '500', '700', '900']
};

const fontWeightStyle = new Parchment.Attributor.Style(
  'fontweight',
  'font-weight',
  fontWeightConfig,
);

Parchment.register(fontWeightStyle);

const Font = Quill.import('attributors/style/font');
Font.whitelist = ['Cairo', 'Poppins', 'Readex Pro', 'IBM Plex Sans Arabic', 'Inter', 'Montserrat', 'Merriweather', 'Raleway', 'Cabin', 'Inconsolata', 'Open Sans', 'Cardo', 'PT Sans', 'Lato', 'Crimson Text', 'Spectral', 'Lora', 'Playfair Display', 'Libre Baskerville', 'Archivo Narrow', 'Roboto', 'Source Serif Pro', 'Fira Sans', 'Rubik', 'Space Grotesk', 'Space Mono', 'Inter', 'Work Sans'].sort();
Quill.register(Font, true);

let List = Quill.import('formats/list');
let ListItem = Quill.import('formats/list/item');

class CustomColor extends Parchment.Attributor.Style {
  value(node) {
    let value = super.value(node);
    return value;
  }
}

class CustomFontSize extends Parchment.Attributor.Style {
  value(node) {
    let value = super.value(node);
    return value;
  }
}

const customColorAttributor = new CustomColor('custom-color', 'color', {
  scope: Parchment.Scope.BLOCK,
});

const customFontSizeAttributor = new CustomFontSize('custom-font-size', 'font-size', {
  scope: Parchment.Scope.BLOCK,
});

Quill.register(customColorAttributor);
Quill.register(customFontSizeAttributor);

class PlainListItem extends ListItem {
  optimize(context) {
    super.optimize(context);

    if (this.children.length === 1) {
      const child = this.children.head;
      const attributes = child.attributes;

      if (attributes && attributes.attributes.color) {
        const color = attributes.attributes.color.value(child.domNode);
        super.format('custom-color', color);
      }

      if (attributes && attributes.attributes.size) {
        const fontSize = attributes.attributes.size.value(child.domNode);
        super.format('custom-font-size', fontSize);
      }
    } else {
      if (this.attributes.attributes.hasOwnProperty('custom-color')) {
        super.format('custom-color', null);
      }
      if (this.attributes.attributes.hasOwnProperty('custom-font-size')) {
        super.format('custom-font-size', null);
      }
    }
  }
}

Quill.register(PlainListItem, true);

export default class extends ApplicationController {
  beforeUpdateText(element) {
    console.log('text updated...');
    window.moveableForText.updateRect();
  }

  connect() {
    super.connect();
    if (this.element.id == 'toolbar') {
      this.bindInitialValues();
    } else {
      document.querySelectorAll('.item-type-text')
        .forEach((el) => {
          let quill = Quill.find(el.querySelector('.text-content'));
          if (!quill) {
            quill = new Quill(el.querySelector('.text-content'), {
              modules: {
                clipboard: {
                  matchVisual: false,
                },
              },
            });
            quill.disable();
          }
        });
    }
  }

  bindInitialValues() {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    if (selectedTextTarget) {
      const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
      const format = quill.getFormat(0, quill.getText().length);
      console.log(format);

      // Quill doesn't report the size properly when there are mixed font-sizes,
      // so we calculate it manually.
      // Get all paragraphs
      const paragraphs = [...selectedTextTarget.querySelector("[contenteditable]").querySelectorAll("p, li")];

      // Get all children, which might have different sizes
      const paragraphChildren = [...paragraphs.map(paragraph => paragraph.children).filter(node => node.length > 0).map(item => Array.from(item))].flat()

      const allNodesToCalculate = paragraphs.concat(paragraphChildren);

      // Get all fontsizes
      const fontSizes = allNodesToCalculate.map(node => getComputedStyle(node).fontSize)
      const fontSizeSet = [... new Set(fontSizes)];

      if (fontSizeSet.length === 1) {
        document.querySelector('input.ql-size').value = fontSizes[0].replace('px', '');
      } else if(fontSizeSet.length > 1) {
        document.querySelector('input.ql-size').value = "Mixed"
      }

      if (format.color) {
        document.querySelector('.text-color').style.fill = format.color;
      }

      if (format.fontweight) {
        document.querySelector('select.ql-fontweight').value = format.fontweight;
      }

      const fontSelect = document.querySelector('select.ql-font');

      if (format.font) {
        fontSelect.value = format.font;
        fontSelect.style.fontFamily = format.font;
      }

      const fontWeightSelect = document.querySelector('select.ql-fontweight');
      fontWeightSelect.style.fontFamily = fontSelect.style.fontFamily;

      if (format.align) {
        document.querySelector(`.ql-align[data-alignment-value=${format.align}]`)
          .classList
          .add('ql-active');
      } else {
        document.querySelector('.ql-align')
          .classList
          .add('ql-active');
      }

      if (format.italic) {
        document.querySelector('.ql-italic')
          .classList
          .add('ql-active');
      }

      if (format.underline) {
        document.querySelector('.ql-underline')
          .classList
          .add('ql-active');
      }

      if (format.strike) {
        document.querySelector('.ql-strike')
          .classList
          .add('ql-active');
      }

      if (format.list) {
        document.querySelector(`.ql-list[data-list-type=${format.list}]`)
          .classList
          .add('ql-active');
      }

      // if(format.lineheight) {
      //   document.querySelector('select.ql-lineheight').value = format.lineheight;
      // }

      if (format.letterspace) {
        document.querySelector('input.ql-letterspace').value = format.letterspace.replace('px', '');
      }

      new SlimSelect({
        select: '.ql-font',
        showSearch: false,
        onChange: info => {
          document.querySelector('select.ql-font').style.fontFamily = info.value;
          document.querySelectorAll('.ql-fontweight')
            .forEach(el => el.style.fontFamily = info.value);
        }
      });

      new SlimSelect({
        select: '.ql-fontweight',
        showSearch: false
      });

    }
  }

  _checkElement = async (selector) => {
    while (document.querySelector(selector) === null) {
      await new Promise((resolve) => requestAnimationFrame(resolve));
    }
    return document.querySelector(selector);
  };

  editTextMode(event) {
    event.preventDefault();
    const textItem = event.target.closest('.item-type-text');
    this._editText(textItem);
  }

  /* saveSelection(event) {
    event.preventDefault();
    event.stopPropagation();
    event.target.focus();

    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
    window.selectedText = quill.getSelection();
    event.target.select();
  } */

  clearSelection() {
    window.selectedText = null;
  }

  changeSize(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');
    const selectedValue = parseInt(event.target.value);
    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    if (selectedTextTarget.classList.contains('inline-edit')) {
      const range = window.selectedText;
      if(range) {
        quill.formatText(range.index, range.length, 'size', `${selectedValue}px`)
      }
      return;
    } else {
      quill.formatText(0, quill.getText().length, 'size', false);


      selectedTextTarget.querySelector('.ql-editor').style.fontSize = selectedValue + 'px';
      window.moveableForText.updateRect();

      this.stimulate('Item#update_font_size', selectedTextTarget, selectedValue);
      const content = quill.container.querySelector('.ql-editor').innerHTML;
      this.stimulate('Item#update_text', content);
    }
    quill = null;
  }

  toggleBold(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    quill.formatText(
      0,
      quill.getText().length,
      'bold',
      !quill.getFormat(0, quill.getText().length).bold,
    );

    document.querySelector('.ql-bold')
      .classList
      .toggle('ql-active');

    const content = quill.container.querySelector('.ql-editor').innerHTML;
    this.stimulate('Item#update_text', content);
    quill = null;
  }

  toggleItalic(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    quill.formatText(
      0,
      quill.getText().length,
      'italic',
      !quill.getFormat(0, quill.getText().length).italic,
    );

    document.querySelector('.ql-italic')
      .classList
      .toggle('ql-active');

    const content = quill.container.querySelector('.ql-editor').innerHTML;
    this.stimulate('Item#update_text', content);
    quill = null;
  }

  toggleUnderline(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    quill.formatText(
      0,
      quill.getText().length,
      'underline',
      !quill.getFormat(0, quill.getText().length).underline,
    );

    document.querySelector('.ql-underline')
      .classList
      .toggle('ql-active');

    const content = quill.container.querySelector('.ql-editor').innerHTML;
    this.stimulate('Item#update_text', content);
    quill = null;
  }

  toggleStrike(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    quill.formatText(
      0,
      quill.getText().length,
      'strike',
      !quill.getFormat(0, quill.getText().length).strike,
    );

    document.querySelector('.ql-strike')
      .classList
      .toggle('ql-active');

    const content = quill.container.querySelector('.ql-editor').innerHTML;
    this.stimulate('Item#update_text', content);
    quill = null;
  }

  selectColor(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      const range = window.selectedText;
      if (range) {
        quill.formatText(range.index, range.length, 'color', event.target.value)
      }
      return;
    } else {
      quill.formatText(0, quill.getText().length, 'color', event.target.value);

      const content = quill.container.querySelector('.ql-editor').innerHTML;
      this.stimulate('Item#update_text', content);
    }

    quill = null;
  }

  toggleAlignment(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    quill.formatText(
      0,
      quill.getText().length,
      'align',
      event.currentTarget.dataset.alignmentValue,
    );

    document.querySelector('.ql-align.ql-active')
      .classList
      .remove('ql-active');
    if (event.currentTarget.dataset.alignmentValue !== '') {
      document.querySelector(`.ql-align[data-alignment-value=${event.currentTarget.dataset.alignmentValue}]`)
        .classList
        .add('ql-active');
    } else {
      document.querySelector(`.ql-align`)
        .classList
        .add('ql-active');
    }

    const content = quill.container.querySelector('.ql-editor').innerHTML;
    this.stimulate('Item#update_text', content);
    quill = null;
  }

  toggleList(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    const currentListFormat = quill.getFormat(0, quill.getText().length).list;
    const selectedFormat = event.currentTarget.dataset.listType;
    let format;
    if (typeof currentListFormat === 'undefined' || currentListFormat !== selectedFormat) {
      format = selectedFormat;
    } else {
      format = false;
    }
    quill.formatText(0, quill.getText().length, 'list', format);

    document.querySelectorAll('.ql-list')
      .forEach(button => button.classList.remove('ql-active'));
    if (format) {
      document.querySelector(`.ql-list[data-list-type=${format}]`)
        .classList
        .add('ql-active');
    }

    const content = quill.container.querySelector('.ql-editor').innerHTML;
    this.stimulate('Item#update_text', content);
    quill.disable();
    quill = null;
  }

  toggleLetterSpacing(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    const selectedValue = parseInt(event.target.value);
    let quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    if (selectedTextTarget.classList.contains('inline-edit')) {
      const range = window.selectedText;
      if(range) {
        quill.formatText(range.index, range.length, 'letterspace', `${selectedValue}px`)
      }
      return;
    } else {
      quill.formatText(0, quill.getText().length, 'letterspace', `${event.target.value}px`);

      const content = quill.container.querySelector('.ql-editor').innerHTML;
      this.stimulate('Item#update_text', content);
    }
    quill = null;
  }

  toggleLineHeight(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    const selectedValue = parseFloat(event.target.value);
    selectedTextTarget.querySelector('.ql-editor').style.lineHeight = selectedValue;
    window.moveableForText.updateRect();

    this.stimulate('Item#update_line_height', selectedTextTarget, selectedValue);
  }

  toggleFontWeight(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    quill.formatText(0, quill.getText().length, 'fontweight', event.target.value);

    const content = quill.container.querySelector('.ql-editor').innerHTML;
    this.stimulate('Item#update_text', content);
  }

  toggleFont(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));

    quill.formatText(0, quill.getText().length, 'font', event.target.value);

    const content = quill.container.querySelector('.ql-editor').innerHTML;
    this.stimulate('Item#update_text', content);
  }

  toggleParagraphSpacing(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    const itemId = selectedTextTarget.dataset.itemId

    event.preventDefault();
    event.stopImmediatePropagation();

    const selectedValue = parseFloat(event.target.value);
    this.stimulate('Item#update_paragraph_spacing',
      selectedTextTarget,
      {resolveLate: true},
      selectedValue).then(() => {
        setTimeout(() => {
          window.moveableForText.target = document.querySelector('.item-type-text.selected');
          window.moveableForText.updateRect();
        }, 200);
      }
    )
  }

  toggleDirection(event) {
    const selectedTextTarget = document.querySelector('.item-type-text.selected');
    storeItemSnapshotForUndo(this.selectedItemId(), 'UPDATE_SLIDE_ITEM');

    if (selectedTextTarget.classList.contains('inline-edit')) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
    const direction = event.currentTarget.value === 'rtl' ? 'rtl' : false;
    const align = event.currentTarget.dataset.alignmentValue === 'right' ? 'right' : false;

    quill.formatLine(0, quill.getText().length, {'direction': direction, 'align': align});

    const content = quill.container.querySelector('.ql-editor').innerHTML;
    this.stimulate('Item#update_text', content);
  }

  create(event) {
    document.querySelector('#pen').pen.deactivate();
    window.selectoForItems.trigger('select', {selected: []});
    window.selectoForItems.trigger('selectEnd', {selected: [], afterAdded: []});


    this.stimulate('Item#create_text', { resolveLate: true })
      .then((payload) => {
        const textItem = document.querySelector('.item-type-text.selected');
        storeItemSnapshotForUndo(textItem.dataset.itemId, 'ADD_SLIDE_ITEM');

        this._editText(textItem);
      });
  }

  _editText(textItem) {
    if (!textItem) {
      return;
    }

    if (textItem.classList.contains('inline-edit')) {
      return;
    }

    textItem.classList.add('inline-edit');
    textItem.draggable = false;

    this._checkElement(`#toolbar[data-item-id="${textItem.dataset.itemId}"]`)
      .then((selector) => {
        const bindings = {
          bold: {
            key: 'b',
            shortKey: true,
            handler(range, context) {
              console.log(range, context);
              let fontWeigth = '400';
              if (context.format.fontweight === undefined || context.format.fontweight === '400') {
                fontWeigth = '700';
              }
              this.quill.format('fontweight', fontWeigth, Quill.sources.USER);
            }
          },
          escape: {
            key: 'escape',
            handler(_range, _context) {
              this.quill.disable();
              const currentEditingElement = document.querySelector('.item-type-text.selected.inline-edit');
              currentEditingElement.classList.remove('inline-edit');
              currentEditingElement.setAttribute('draggable', "true");
            }
          },
          copy: {
            key: 'c',
            shortKey: true,
            collapsed: false,
            handler(range, _context) {
              window.copiedText = this.quill.getText(range);
              return true;
            }
          },
          backspace: {
            key: 'backspace',
            handler(range, context) {
              if ((range.index === 1 && range.length === 0) || (range.index === 0 && range.length > 0)) {
                if (typeof context.format.list !== 'undefined') {
                  return true;
                }
                this.quill.deleteText(0, range.length === 0 ? 1 : range.length);
                this.quill.insertText(0, '\uFEFF');
                this.quill.formatText(0, 1, context.format);
              } else {
                return true;
              }
            }
          }
        };

        const quill = new Quill(textItem.querySelector('.text-content'), {
          modules: {
            toolbar: '#toolbar',
            keyboard: {
              bindings: bindings
            },
            clipboard: {
              matchVisual: false
            }
          },
          formats: [
            'color',
            'font',
            'italic',
            'size',
            'strike',
            'underline',
            'list',
            'align',
            'fontweight',
            'lineheight',
            'letterspace',
            'indent',
            'direction'
          ]
        });

        quill.enable();
        quill.focus();
        quill.setSelection(quill.getText().length);

        quill.on(
          'text-change',
          debounce(() => {
            window.moveableForText.updateRect();

            const textContainer = quill.container;
            const item = textContainer.parentNode;
            const content = textContainer.querySelector('.ql-editor').innerHTML;

            const style = getComputedStyle(item);
            item.dataset.height = parseFloat(style.height);
            item.dataset.width = parseFloat(style.width);

            this.stimulate('Item#update_text', item, content);
          }, 200)
        );

        quill.on('selection-change', function (range, oldRange, source) {
          if (range && oldRange) {
            window.selectedText = range;
            const format = quill.getFormat();
            console.log(format);

            let color = '#000000';
            if (format.color) {
              color = format.color;
            }
            document.querySelector('.text-color').style.fill = color;

            // Get the font size from parent if it is set at block level
            let size;
            if (format.size === undefined) {
              size = quill.container.style.fontSize;
            } else {
              size = format.size;
            }

            document.querySelector('input.ql-size').value = size.replace('px', '');

            let fontWeight = '400';
            if (format.fontweight) {
              fontWeight = format.fontweight;
            }

            let font = 'Roboto';
            if (format.font) {
              font = format.font;
            }

            /*
              Setting slim value with .set() causes quill to jump cursor on text,
              so we are recreating it.
             */
            const fontSelect = document.querySelector('select.ql-font');
            fontSelect.slim.destroy();
            fontSelect.value = font;
            fontSelect.style.fontFamily = fontSelect.value;

            const fontWeightSelect = document.querySelector('select.ql-fontweight');
            fontWeightSelect.slim.destroy();
            fontWeightSelect.value = fontWeight;
            fontWeightSelect.style.fontFamily = fontSelect.style.fontFamily;

            new SlimSelect({
              select: '.ql-font',
              showSearch: false,
              onChange: info => {
                document.querySelector('select.ql-font').style.fontFamily = info.value;
                document.querySelectorAll('.ql-fontweight')
                  .forEach(el => el.style.fontFamily = info.value);
              }
            });

            new SlimSelect({
              select: '.ql-fontweight',
              showSearch: false
            });

          } else {
            console.log('Cursor not in the editor');
          }
        });

        window.moveableForText.target = null;
      });
  }

  stopEmptySpaceClickOnPanel(event) {
    if (event.target.classList.contains('itemPanel') || event.target.classList.contains('panelRow')) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  selectedItemId() {
    return document.querySelector('.item-type-text.selected').dataset.itemId;
  }
}
