import { Controller } from 'stimulus';
import Rails from 'rails-ujs';
import morphdom from 'morphdom';

export default class extends Controller {
  static targets = ["query"];

  selectProject(event) {
    document.querySelectorAll('#toolbox-content .record').forEach((el) => el.classList.remove('selected'));
    event.target.closest('.record').classList.toggle('selected');

    const { id } = event.target.closest('.record').dataset;
    Rails.ajax({
      url: `/projects/${id}/details_for_toolbox`,
      type: 'get',
    });
  }

  selectProjectForPull(event) {
    event.target.closest('.record').classList.toggle('selected');
  }

  addFromToolbox(event) {
    const selectedIds = [...document.querySelectorAll('#toolbox-content .record.selected')].map((el) => el.dataset.id);
    const formData = new FormData();
    selectedIds.forEach((selectedId) => formData.append('project_ids[]', selectedId));

    Rails.ajax({
      url: event.target.dataset.postPath,
      type: 'post',
      data: formData,
      success() {
        document.getElementById("modal-content").innerHTML = '';
        window.notice(`Adding project${selectedIds.length > 1 ? 's' : ''} to studio. It might take some time.`); },
    });
  }

  toggleTag(event) {
    this.search(event);
  }

  toggleSorting(event) {
    this.search(event);
  }

  // eslint-disable-next-line class-methods-use-this
  search(event) {
    const formData = new FormData();
    formData.append('query', this.queryTarget.value);

    const checkedTags = [...document.querySelectorAll('.tag-field input[type="checkbox"]:checked')]
    checkedTags.forEach((tag) => formData.append(tag.name, tag.value));

    Rails.ajax({
      url: '/toolbox/search',
      type: 'post',
      data: formData,
      success(resp, data, xhr) {
        document.getElementById('toolbox-content').innerHTML = xhr.responseText;
      },
    });
  }
}
