import { Controller } from "stimulus";

export default class extends Controller {

  selectColor(event) {
    event.preventDefault();
    event.stopPropagation();

    const color = event.target.getAttribute('data-color');
    const colorPicker = document.getElementById('color-picker');
    const sourceElementId = colorPicker.getAttribute('data-source-element');
    const sourceElement = document.getElementById(sourceElementId);
    sourceElement.value = color;

    var event = new Event('change', { bubbles: true, cancelable: true });
    sourceElement.dispatchEvent(event);

    window.hideColorPicker();
  }

  showColorPicker(event) {
    window.hideGuides();

    const colorPicker = document.getElementById('color-picker');
    const hiddenInput = event.target.closest('.color-input-container').querySelector('input[type=text]')

    var bodyRect = document.body.getBoundingClientRect(),
        elemRect = event.target.getBoundingClientRect(),
        offset   = elemRect.top - bodyRect.top;

    const left = event.target.getBoundingClientRect().x;
    const top = offset;

    colorPicker.classList.remove('hidden');
    colorPicker.style.left = left + 'px';
    colorPicker.style.top = top + event.target.getBoundingClientRect().height + 'px';
    colorPicker.style.zIndex = 2000;
    colorPicker.setAttribute('data-source-element', hiddenInput.id);

    document.getElementById('selectedColorInput').value = hiddenInput.value;
  }

  inputColor(event) {
    event.preventDefault();
    event.stopPropagation();

    const colorPicker = document.getElementById('color-picker');
    const colorInput = document.getElementById('selectedColorInput');
    const inputValue = colorInput.value;

    if (/^#[0-9A-F]{6}$/i.test(inputValue)) {
      colorInput.style.borderColor = '#aeaeae';
      const sourceElementId = colorPicker.getAttribute('data-source-element');
      const sourceElement = document.getElementById(sourceElementId);
      sourceElement.value = inputValue;

      var event = new Event('change', { bubbles: true, cancelable: true });
      sourceElement.dispatchEvent(event);

      window.hideColorPicker();
    }
    else
      colorInput.style.borderColor = 'red';
  }
}
