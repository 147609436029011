import ApplicationController from './application_controller';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';

export default class extends ApplicationController {
  create(event) {
    document.querySelectorAll('.draggable-item-container.selected').forEach((item) => item.classList.remove('selected'));
    window.moveableForText.target = null;
    window.moveableForGroups.target = null;
    window.moveableForItems.target = null;

    this.stimulate('Item#create_shape', event.currentTarget, { resolveLate: true }).then(() => {
      event.target.closest('.button').querySelector('.toolbar-tooltip').removeAttribute('style');
      document.querySelector('#canvas').canvas.clearHighlightPanelTrigger();

      const item = document.querySelector('.draggable-item-container.selected');
      storeItemSnapshotForUndo(item.dataset.itemId, 'ADD_SLIDE_ITEM');

      window.moveableForItems.target = item;
    });
  }

  disableTooltip(event) {
    event.preventDefault();
    event.target.closest('.button').querySelector('.toolbar-tooltip').style.display = 'none';
  }

  enableTooltip(event) {
    event.preventDefault();
    event.target.closest('.button').querySelector('.toolbar-tooltip').removeAttribute('style');
  }
}
