import itemType from '../itemType';

const deleteItem = (target) => {
  target.closest('.item-container').remove();

  // Set data attribute
  // target.dataset.isDeleted = true;

  // Unselect item
  // target.classList.remove('selected');
};

const deleteLineItem = (target) => {
  // Set data attribute
  target.dataset.isDeleted = true;
  target.closest('.item-container').querySelector('.draggable-item-container').dataset.isDeleted = true;
  target.closest('.item-container').querySelector('.handle-left').dataset.isDeleted = true;
  target.closest('.item-container').querySelector('.handle-right').dataset.isDeleted = true;
  target.closest('.item-container').querySelector('.line-snapper').dataset.isDeleted = true;

  // Unselect item
  target.classList.remove('selected');
  target.closest('.item-container').querySelector('.handle-left').classList.add('hidden');
  target.closest('.item-container').querySelector('.handle-right').classList.add('hidden');
};

const deleteItems = (itemId) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;

  deleteItem(target);

  window.hideContextMenus();
};

export default deleteItems;
