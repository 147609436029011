import ApplicationController from '../editor/application_controller';
import { createRequest } from '../helpers/createRequest';
import { htmlToElement, insertAfter, insertBefore } from '../helpers/dom';

export default class extends ApplicationController {
  addSection(event) {
    const { studioId, tabId } = event.target.dataset;
    const input = document.querySelector('#new-section')
      .content
      .cloneNode(true);
    const sections = document.querySelector('.tabs');
    const lastSection = document.querySelector(`#section-${tabId}`);
    if (lastSection) {
      insertAfter(input, lastSection);
    } else {
      sections.appendChild(input);
    }
    const newInput = sections.querySelector('input');
    newInput.dataset.studioId = studioId;
    newInput.dataset.afterTabId = tabId;
    newInput.focus();
    this.hideDropdowns();
  }

  saveSection(event) {
    const { studioId, afterTabId } = event.target.dataset;
    const formData = new FormData();
    formData.append('name', event.target.value);
    formData.append('afterTabId', afterTabId);
    fetch(createRequest(`/x1/studios/${studioId}/sections`, 'POST', formData))
      .then((response) => response.text())
      .then((html) => event.target.parentElement.replaceWith(htmlToElement(html)));
  }

  cancelAddSection(event) {
    event.target.closest('div').remove();
  }

  renameSection(event) {
    const { studioId, tabId } = event.target.dataset;
    const input = document.querySelector('#rename-section').content.cloneNode(true);
    const section = document.querySelector(`#section-${tabId}`);
    const title = section.querySelector('a').innerText;
    section.firstElementChild.classList.add('hidden');
    insertBefore(input, section.firstElementChild);
    const newInput = section.querySelector('input');
    newInput.dataset.tabId = tabId;
    newInput.dataset.studioId = studioId;
    newInput.value = title;
    newInput.focus();
    this.hideDropdowns();
  }

  updateSection(event) {
    const { studioId, tabId } = event.target.dataset;
    const formData = new FormData();
    formData.append('name', event.target.value);
    fetch(createRequest(`/x1/studios/${studioId}/sections/${tabId}`, 'POST', formData))
      .then((response) => response.text())
      .then((html) => {
        const link = event.target.closest('li').querySelector('a');
        link.innerText = event.target.value;
        link.classList.remove('hidden');
        event.target.parentElement.remove();
        this.hideDropdowns();
      });
  }

  cancelEditSection(event) {
    const cancelButton = event.currentTarget;
    cancelButton.closest('li').querySelector('a').classList.remove('hidden');
    cancelButton.parentElement.remove();
  }

  hideDropdowns() {
    document.querySelectorAll('[data-target="x1--dropdown.menu"]').forEach((el) => el.classList.add('hidden'));
  }
}
