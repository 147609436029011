/*jshint esversion: 6 */
/* global SlimSelect */
import { Controller } from "stimulus";
import Rails from 'rails-ujs';

export default class extends Controller {
  initialize() {
    let height = window.innerHeight;
    let width = window.innerWidth;
    let slideId = document.getElementById('slide-editor-container').getAttribute('data-slide-id');

    Rails.ajax({url: `/slides/${slideId}/editor?width=${width}&height=${height}`, type: 'GET'});
  }
}
