/* global uppy */
/* global fetch */
import ApplicationController from './application_controller.js';
import Rails from 'rails-ujs';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import { BlobRecord } from '@rails/activestorage/src/blob_record';
import { htmlToElement } from '../../controllers/helpers/dom';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';
import fixSlideIndexes from '../helpers/editor/slides/fixSlideIndexes';

const Uppy = require('uppy');

const {Plugin} = require('@uppy/core');

export default class extends ApplicationController {
  static targets = [ "asset", "embed", "cameraAsset", "template" ]
  static uppy;

  connect() {
    super.connect();
    this.activateUppy();
  }

  selectAsset(event) {
    const selectionMode = event.target.closest('div.asset').dataset.selectionMode;
    if (selectionMode && selectionMode === 'replace') {
      const assetList = document.querySelector("div.asset").closest(".assetList").querySelectorAll('div.asset.selected');
      assetList.forEach(item => {
        item.classList.remove('selected')
      })
    }
    event.target.closest('div.asset').classList.toggle('selected');
  }

  selectTemplate(event) {
    event.target.closest('div.template-slide').classList.toggle('selected');
  }

  selectPresentationTemplate(event) {
    document.querySelectorAll('div.template-presentation-item.selected').forEach((template) => {
      template.classList.remove('selected');
    });
    event.target.closest('div.template-presentation-item').classList.toggle('selected');
  }

  selectAllTemplates(event) {
    const templateList = document.querySelectorAll("div.template-slide");
    templateList.forEach(item => {
      item.classList.add('selected')
    })
  }

  addAssetsToSingleSlide(event) {
    event.preventDefault();

    if (event.target.closest('.action-btn').dataset.templateItemId) {
      this.selectItemForTemplate(event);
      return;
    }

    let selectedAssets = this.assetTargets.filter(div => div.classList.contains('selected'));
    let assetIds = selectedAssets.map(div => div.dataset.assetId).join();
    this.stimulate("MediaHub#add_assets_to_single_slide", event.target, {resolveLate: true}, assetIds).then(
      (payload) => {
        window.moveableForItems.target = null;
        window.moveableForGroups.target = null;
        window.moveableForText.target = null;

        const items = document.querySelectorAll('.item-container .selected');
        items.forEach((item) => {
          storeItemSnapshotForUndo(item.dataset.itemId, 'ADD_SLIDE_ITEM');
        });

        if (items.length > 1) {
          window.moveableForGroups.target = items;
        } else {
          window.moveableForItems.target = items;
        }
      }
    )
  }

  replaceAsset(event) {
    event.preventDefault();

    let selectedAssets = this.assetTargets.filter(div => div.classList.contains('selected'));
    let assetIds = selectedAssets.map(div => div.dataset.assetId).join();
    let itemId = event.target.closest('.action-btn').getAttribute('data-item-id');
    this.stimulate("MediaHub#replace_asset", event.target, assetIds, itemId);
  }

  replaceCameraAsset(event) {
    event.preventDefault();

    let selectedCameraAssets = this.cameraAssetTargets.filter(div => div.classList.contains('selected'));
    let assetIds = selectedCameraAssets.map(div => div.dataset.cameraAssetId).join();
    let itemId = event.target.closest('.action-btn').getAttribute('data-item-id');
    this.stimulate("MediaHub#replace_camera_asset", event.target, assetIds, itemId);
  }

  selectItemForTemplate(event) {
    event.preventDefault();
    event.stopPropagation();

    let selectedAssets = this.assetTargets.filter(div => div.classList.contains('selected'));

    if (selectedAssets.length === 0) {
      alert('Please select something.');
      return;
    } else if (selectedAssets.length > 1) {
      alert('Please select only one item.');
      return;
    }

    if (event.target.classList.contains('in-progress')) {
      return;
    }

    let assetId = selectedAssets.map(div => div.dataset.assetId)[0];
    let itemId = event.target.closest('.action-btn').getAttribute('data-template-item-id');

    this.stimulate("Item#update_template", assetId, itemId);
  }

  addAssetsToMultipleSlides(event) {
    event.preventDefault();

    let selectedAssets = this.assetTargets.filter(div => div.classList.contains('selected'));
    let assetIds = selectedAssets.map(div => div.dataset.assetId).join();
    this.stimulate("MediaHub#add_assets_to_multiple_slides", event.target, assetIds);
  }

  addEmbedsToSingleSlide(event) {
    event.preventDefault();

    let selectedEmbeds = this.embedTargets.filter(div => div.classList.contains('selected'));
    let embedIds = selectedEmbeds.map(div => div.dataset.embedId).join();
    this.stimulate("MediaHub#add_embeds_to_single_slide", event.target.closest('div'), embedIds).then(
      (payload) => {
        window.moveableForItems.target = null;
        window.moveableForGroups.target = null;
        window.moveableForText.target = null;

        const items = document.querySelectorAll('.item-container .selected');
        items.forEach((item) => {
          storeItemSnapshotForUndo(item.dataset.itemId, 'ADD_SLIDE_ITEM');
        });

        if (items.length > 1) {
          window.moveableForGroups.target = items;
        } else {
          window.moveableForItems.target = items;
        }
      }
    );
  }

  addEmbedsToMultipleSlides() {
    event.preventDefault();

    let selectedEmbeds = this.embedTargets.filter(div => div.classList.contains('selected'));
    let embedIds = selectedEmbeds.map(div => div.dataset.embedId).join();
    this.stimulate("MediaHub#add_embeds_to_multiple_slides", event.target, embedIds);
  }

  addCameraAssetsToSingleSlide(event) {
    event.preventDefault();

    let selectedCameraAssets = this.cameraAssetTargets.filter(div => div.classList.contains('selected'));
    let cameraAssetIds = selectedCameraAssets.map(div => div.dataset.cameraAssetId).join();
    this.stimulate("MediaHub#add_camera_assets_to_single_slide", event.target, cameraAssetIds).then(
      (payload) => {
        window.moveableForItems.target = null;
        window.moveableForGroups.target = null;
        window.moveableForText.target = null;

        const items = document.querySelectorAll('.item-container .selected');
        items.forEach((item) => {
          storeItemSnapshotForUndo(item.dataset.itemId, 'ADD_SLIDE_ITEM');
        });

        if (items.length > 1) {
          window.moveableForGroups.target = items;
        } else {
          window.moveableForItems.target = items;
        }
      }
    );
  }

  addCameraAssetsToMultipleSlides(event) {
    event.preventDefault();

    let selectedCameraAssets = this.cameraAssetTargets.filter(div => div.classList.contains('selected'));
    let cameraAssetIds = selectedCameraAssets.map(div => div.dataset.cameraAssetId).join();
    this.stimulate("MediaHub#add_camera_assets_to_multiple_slides", event.target, cameraAssetIds);
  }

  addTemplatesToPresentation(event) {
    event.preventDefault();
    let addButton = event.currentTarget;
    addButton.classList.add('pointer-events-none');
    let textElement = addButton.querySelector('div');
    textElement.innerText = 'Adding...';

    let selectedTemplates = this.templateTargets.filter(div => div.classList.contains('selected'));
    // If there is no selected template, add all templates.
    if (selectedTemplates.length === 0) {
      this.templateTargets.forEach(div => div.classList.add('selected'));
      selectedTemplates = this.templateTargets.filter(div => div.classList.contains('selected'));
    }
    let templateIds = selectedTemplates.map(div => div.dataset.templateId).join();

    this.stimulate("Template#add_templates_to_presentation", event.target, templateIds).then((payload) => {
      fixSlideIndexes();
      const canvasController = document.querySelector('.canvas').canvas;
      canvasController.switchToNextSlide(event);
      textElement.innerText = 'Insert Template';
    });
  }

  addPresentationTemplatesToPresentation(event) {
    event.preventDefault();
    const addButton = event.currentTarget;
    addButton.classList.add('pointer-events-none');
    const textElement = addButton.querySelector('div');
    textElement.innerText = 'Adding...';

    const selectedTemplate = document.querySelector(".template-presentation-item.selected");
    const presentationTemplateId = selectedTemplate.dataset.templatePresentationId;
    const studioId = selectedTemplate.dataset.templateStudioId;
    console.log(selectedTemplate, presentationTemplateId, studioId);

    this.stimulate("Template#add_template_to_studio", event.target, {presentation_template_id: presentationTemplateId, studio_id: studioId}).then((payload) => {
      textElement.innerText = 'Add To Document';
    });
  }

  activateUppy() {
    if (!document.querySelector('.fileUploadContainer')) return;

    this.uppy = Uppy.Core({
      autoProceed: true,
      restrictions: { allowedFileTypes: ['image/*', 'video/*', '.heic',  '.heif' ] },
    });

    this.uppy.use(Uppy.FileInput, {
      target: '.fileUploadContainer',
      replaceTargetContent: false,
      pretty: true
    });

    this.uppy.use(AwsS3Multipart, {
      limit: 4,
      companionUrl: '/'
    })

    class UppyS3DirectUpload extends Plugin {
      constructor (uppy, opts) {
        super(uppy, opts)
        this.id = opts.id || 'UppyS3DirectUpload'
        this.type = 'example'
        this.prepareUpload = this.prepareUpload.bind(this) // ← this!
      }

      prepareUpload (fileIDs) {
        const promises = fileIDs.map((fileID) => {
          const file = this.uppy.getFile(fileID)
          this.uppy.emit('preprocess-progress', file, {
            mode: 'indeterminate',
            message: 'Creating signed blobs'
          })

          return new Promise((resolve, reject) => {
            FileChecksum.create(file.data, (error, checksum) => {
              if(error) {
                console.log(error);
                return reject(error)
              } else {
                const blob = new BlobRecord(file.data, checksum, document.querySelector('meta[name=\'direct-upload-url\']').getAttribute('content'));
                blob.create(error => {
                  if(error) {
                    console.log(error);
                    return reject(error)
                  } else {
                    this.uppy.setFileMeta(file.id, {
                      blob: blob.toJSON()
                    });
                    return resolve();
                  }
                });
              }
            });
          });
        })

        const emitPreprocessCompleteForAll = () => {
          fileIDs.forEach((fileID) => {
            const file = this.uppy.getFile(fileID)
            this.uppy.emit('preprocess-complete', file)
          })
        }

        // Why emit `preprocess-complete` for all files at once, instead of
        // above when each is processed?
        // Because it leads to StatusBar showing a weird “upload 6 files” button,
        // while waiting for all the files to complete pre-processing.
        return Promise.all(promises)
          .then(emitPreprocessCompleteForAll)
      }

      install () {
        this.uppy.addPreProcessor(this.prepareUpload)
      }

      uninstall () {
        this.uppy.removePreProcessor(this.prepareUpload)
      }
    }

    this.uppy.use(UppyS3DirectUpload, {});

    this.uppy.on('file-added', (file) => {
      let randomId = Math.floor(Math.random() * 100000) + 1;

      // Create div
      const template = `<div class="temp-asset-container-${randomId}">
        <div class="asset relative bg-c4c4c4 border-4 border-white rounded selectable cursor-pointer hover:border-pink">
          <div class="w-full hd-ratio"></div>
          <div class="asset-progress absolute top-0 left-0 h-4 bg-pink"></div>
          <div class='statusText'>Starting upload...</div>
        </div>
      </div>`

      const el = document.querySelector('.assetList');
      el.prepend(htmlToElement(template));

      // Set meta data
      this.uppy.setFileMeta(file.id, {
        studio_id: el.dataset.studioId,
        presentation_id: el.dataset.presentationId,
        temp_id: randomId,
      });
    });

    this.uppy.on('upload-progress', (file, progress) => {
      let tempId = parseInt(file.meta.temp_id);
      let percentage = progress.bytesUploaded / progress.bytesTotal * 100;
      let container = document.querySelector(`.temp-asset-container-${tempId}`);
      container.querySelector('.statusText').innerHTML = 'Uploading...'
      container.querySelector(`.asset-progress`).style.width = `${percentage}%`;
    });

    this.uppy.on('upload-success', (file, response) => {
      let formData = new FormData();
      formData.append('studio_id', file.meta.studio_id);
      formData.append('presentation_id', file.meta.presentation_id);
      formData.append('blob_signed_id', file.meta.blob.signed_id);
      formData.append('key', new URL(response.uploadURL).pathname.split("/")[1]);
      if(!(file.meta.width === undefined))
        formData.append('width', file.meta.width);
      if(!(file.meta.height === undefined))
        formData.append('height', file.meta.height);
      fetch('/presentations/add_asset_to_media_hub', {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          let el = document.querySelector(`.temp-asset-container-${file.meta.temp_id}`);
          if (data.html) {
            el.id = `asset-container-${data.asset_id}`;
            el.innerHTML = data.html;
          } else {
            alert('Error in file');
            el.remove();
          }
        });
    });
  }

  loadMoreAssets(event) {
    const presentationId = event.target.closest('.canvas').getAttribute('data-presentation-id');
    const page = event.target.dataset.page;

    Rails.ajax({
      url: `/slide-editor/assets?presentation_id=${presentationId}&page=${page}`,
      type: 'GET'
    });
  }

  loadMoreTemplates(event) {
    const page = event.target.dataset.page;

    Rails.ajax({
      url: `/slide-editor/templates?page=${page}`,
      type: 'GET'
    });
  }

  loadMoreCameraAssets(event) {
    const presentationId = event.target.closest('.canvas').getAttribute('data-presentation-id');
    const page = event.target.dataset.page;
    const pathElement = event.target.closest('.mediaHubPanel').querySelector('.folders a.text-pink');
    let path = '';
    if (pathElement) path = pathElement.innerText;

    Rails.ajax({
      url: `/slide-editor/camera-assets?presentation_id=${presentationId}&page=${page}&path=${path}`,
      type: 'GET'
    });
  }

  deleteAsset(event) {
    event.preventDefault();
    event.stopPropagation();

    if (confirm('Are you sure?') == true) {
      let assetId = event.target.getAttribute('data-asset-id');
      Rails.ajax({
        url: `/assets/${assetId}`,
        type: 'DELETE'
      });
    }
    window.hideContextMenus();
  }

  deleteCameraAsset(event) {
    event.preventDefault();
    event.stopPropagation();

    if (confirm('Are you sure?') == true) {
      let cameraAssetId = event.target.getAttribute('data-camera-asset-id');
      Rails.ajax({
        url: `/camera_assets/${cameraAssetId}`,
        type: 'DELETE'
      });
    }
    window.hideContextMenus();
  }

  deleteEmbed(event) {
    event.preventDefault();
    event.stopPropagation();

    if (confirm('Are you sure?') == true) {
      let embedId = event.target.getAttribute('data-embed-id');
      Rails.ajax({
        url: `/embeds/${embedId}`,
        type: 'DELETE'
      });
    }
    window.hideContextMenus();
  }

  toggleCameraAssetFilters(event) {
    event.preventDefault();
    event.stopPropagation();
    let target = document.querySelector("#cameraFolderPicker");

    if (target.style.display == 'flex') {
      target.style.display = 'none';
    } else {
      target.style.display = 'flex';
    }
  }

  openSubMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    const menu = event.target.closest('.action-menu').querySelector('.menu');
    menu.classList.toggle('hidden');
  }

  askToDeleteTemplate(event) {
    event.preventDefault();
    const ok = confirm("Are you sure?")
    if(!ok){
      return false;
    }else{
      const el = event.currentTarget
      const presentationId = el.getAttribute("data-template-presentation-id");
      const studioId = el.getAttribute("data-template-studio-id");
      this.stimulate("Template#delete", presentationId, studioId).then((payload) => {
        const templateItem = document.getElementById('template-container-'+presentationId);
        templateItem.remove();
      });
    }
  }

  handleDragStart(event) {
    event.target.classList.add('selected');
    const assets = document.querySelectorAll('.asset.selected');
    const assetIds = [...assets].map(asset => asset.dataset.assetId || asset.dataset.cameraAssetId)
    const type = event.target.dataset.cameraAssetId ? 'camera' : 'image';
    event.dataTransfer.setData("application/json", JSON.stringify({assetIds: assetIds.join(','), type: type}));

  /*   const ghost = event.target.cloneNode();
    ghost.classList = 'asset';
    if(assets.length > 1) {
      ghost.classList.add('stacked');
    }
    ghost.style.zIndex = '-1';
    ghost.style.position = "absolute";
    ghost.style.opacity = 0.5;
    console.log(ghost);
    event.target.appendChild(ghost);
    event.dataTransfer.setDragImage(ghost, 0, 0); */
  }
}
