import ApplicationController from '../editor/application_controller';

export default class extends ApplicationController {
  toggleCoaches(event) {
    event.preventDefault();
    const sidebar = document.querySelector('#x1-sidebar-content');
    sidebar.innerHTML = document.querySelector('.coaches').innerHTML;
    document.querySelector('.menu-title').innerText = 'Instructors';
    this.hideDropdowns();
  }

  toggleStudents(event) {
    event.preventDefault();
    const sidebar = document.querySelector('#x1-sidebar-content');
    sidebar.innerHTML = document.querySelector('.students').innerHTML;
    document.querySelector('.menu-title').innerText = 'Students';
    this.hideDropdowns();
  }

  hideDropdowns() {
    document.querySelectorAll('[data-target="x1--dropdown.menu"]').forEach(el => el.classList.add('hidden'));
  }
}
