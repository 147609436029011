import updateRect from '../updateRect';
import setDataAttributes from '../setDataAttributes';

const setRotation = (itemId, rotate) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;

  const newRotate = parseFloat(rotate || 0, 0);

  if (newRotate < 0 || isNaN(newRotate)) {
    return;
  }

  const frame = window.mapItems.get(target);

  frame.set('transform', 'rotate', `${newRotate}deg`);
  target.style.cssText += frame.toCSS();

  setDataAttributes(target.dataset.itemId);

  updateRect(itemId);
  window.hideContextMenus();
};

export default setRotation;
