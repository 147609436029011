/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";

export default class extends Controller {
  // static targets = ["tabForm", "tabName"]

  connect() {
    // Highlight initial tab
    // document.querySelector('#year_tabs ul.sortable li').classList.add('btn-pink');
    // this.tabFormTargets[0].classList.remove("hidden");
    this.initSliders()
    this.initPanels()
    window.expandableTextArea();
  }

  initPanels() {
    document.querySelectorAll('.compressable-area').forEach((element) => {
      if (!element.dataset.compressed) {
        this.animateSlideDown(element);
      } else {
        this.animateSlideUp(element);
      }
    });
  }

  initSliders() {
    window.customSlider();
    let transcriptSliderInputs = document.querySelectorAll('.grading-custom-slider');

    transcriptSliderInputs.forEach((input) => {
      let gradingData = JSON.parse(input.getAttribute('data-values'));
      let maximumGradeScore = parseInt(input.getAttribute('data-maximum-grade') ?? 100);

      let sliderValues = []
      let colorsData = {};
      gradingData.forEach((termData) => {
        sliderValues.push(termData.term_average)
        colorsData[parseFloat(termData.term_average).toFixed(1)] = termData.color
      })
      let sliderObj = noUiSlider.create(input, {
        start: sliderValues.sort((function (a, b) { return a - b })),
        connect: true,
        step: 0.1,
        range: {
          'min': 0,
          'max': maximumGradeScore
        },
        pips: {
          mode: 'positions',
          values: [0, 25, 50, 75, 100],
          density: 16
        },
      })
      // console.log(colorsData);
      sliderObj.target.querySelectorAll('.noUi-handle').forEach((handler) => {
        // console.log(handler.getAttribute('aria-valuenow'), colorsData[handler.getAttribute('aria-valuenow')])
        handler.classList.add(colorsData[handler.getAttribute('aria-valuenow')]);
      });
    });
  }

  switchTo(event) {
    let element = event.currentTarget;
    let tabName = element.dataset.tabName;
    document.querySelectorAll(`div[data-tab-name="${tabName}"]`).forEach((el, i) => {
      el.classList.toggle("hidden", el.dataset.tabId != event.target.dataset.tabId)
    });
    // Highlight selected tab
    document.querySelectorAll(`li[data-tab-name="${tabName}"]`).forEach((el, i) => {
      el.classList.remove("btn-pink");
    });
    // document.querySelector('#year_tabs ul.sortable li.btn-pink').classList.remove('btn-pink');
    event.target.classList.add('btn-pink');
    this.hideDetailChart();
  }

  switchFromSelectBoxTo(event) {
    let element = event.currentTarget;
    let tabName = element.options[element.selectedIndex].dataset.tabName;

    document.querySelectorAll(`div[data-tab-name="${tabName}"]`).forEach((el, i) => {
      console.log(tabName);
      console.log(el.dataset.tabId, element.options[element.selectedIndex].dataset.tabId);
      if (el.dataset.tabId != element.options[element.selectedIndex].dataset.tabId) {
        el.classList.add("hidden");
      } else {
        el.classList.remove("hidden");
      }
    });
    this.hideDetailChart();
  }

  togglePanel(event) {
    if (event) event.preventDefault();
    // Get button to change its name
    let element = event.target.closest('.skill-category-group').querySelector(".compressable-area");
    if (element.dataset.compressed.toString() === 'true') {
      this.animateSlideDown(element);
      element.dataset.compressed = false;
    } else {
      this.animateSlideUp(element);
      element.dataset.compressed = true;
    }
  }

  animateSlideUp(target) {
    const duration = 500;
    target.style.height = `${target.offsetHeight}px`;
    if (target.dataset.normalHeight === undefined) {
      target.dataset.normalHeight = target.offsetHeight;
    }
    target.style.opacity = 1;
    window.setTimeout(() => {
      target.style.transitionProperty = 'height, opacity';
      target.style.transitionDuration = `${duration}ms`;
      target.style.height = 0;
      target.style.opacity = 0;
      // target.style.overflow = 'hidden';
      window.setTimeout(() => {
        target.style.display = 'none';
      }, duration);
    }, 10);
  }

  animateSlideDown(target) {
    const duration = 500;
    target.style.removeProperty('display');
    let { display } = window.getComputedStyle(target);
    if (display === 'none') {
      display = 'block';
    }
    window.setTimeout(() => {
      target.style.display = display;
      const height = target.dataset.normalHeight;
      target.style.height = 0;
      target.style.opacity = 0;
      // target.style.overflow = 'hidden';
      // Sliding
      target.style.transitionProperty = 'height, opacity';
      target.style.transitionDuration = `${duration}ms`;
      if (height > 0) {
        target.style.height = `${height}px`;
      } else {
        target.style.height = 'inherit';
      }
      target.style.opacity = 1;
    }, 10);
  }

  showDetailChart(event) {
    let element = event.currentTarget;
    document.querySelectorAll(".term-chart").forEach((el, i) => {
      el.classList.add("opacity-50");
      el.classList.add("hover:opacity-100");
      if (el.dataset.skillCategoryId === element.dataset.skillCategoryId) {
        el.classList.remove("opacity-50");
        el.classList.remove("hover:opacity-100");
      }
    }
    );

    document.querySelectorAll("#per-term-charts .skill-category-group").forEach((el, i) => {
      el.classList.add("hidden");
      if (el.dataset.skillCategoryId === element.dataset.skillCategoryId) {
        el.classList.remove("hidden");
      }
    }
    );
  }

  hideDetailChart() {
    document.querySelectorAll(".term-chart").forEach((el, i) => {
      el.classList.remove("opacity-50");
      el.classList.remove("hover:opacity-100");
      el.classList.remove("opacity-50");
      el.classList.remove("hover:opacity-100");
    }
    );

    document.querySelectorAll("#per-term-charts .skill-category-group").forEach((el, i) => {
      el.classList.add("hidden");
    }
    );
  }

}
