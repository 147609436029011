const currentSidebarState = () =>{
  const currentState = document.getElementById('empty-sidebar').getAttribute('data-sidebar-open');
  switch (currentState) {
    case 'false':
      return false;
      break;
    case 'true':
      return true;
    default:
      return undefined;
  }
}

export default currentSidebarState;