import ApplicationController from './editor/application_controller';

export default class extends ApplicationController {
  removeExternalCourse(event) {
    event.preventDefault();
    if (confirm('Are you sure you want to remove this course?') === true) {
      this.stimulate('TranscriptAcademicsReflex#remove_external_course', event.target.closest('.remove-course'));
    }
  }
}
