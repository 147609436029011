import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let elTimeline = document.querySelector('.timeline')
    if (elTimeline) {
      window.addEventListener("scroll", function(event) {
        // let elTimeline = document.querySelector('.timeline')
        // if (elTimeline === null) return;
        // let backgroundWidth = 960;
        // let backgroundHeight = 540;
        // let scrollThreshold = 1350;
        // const styleElement = getComputedStyle(elTimeline);
        // let timelineWidth = parseInt(styleElement.width);
        // let proportion = timelineWidth / backgroundWidth
        // let timelineHeight = Math.round(proportion * backgroundHeight * 4)
        // let timelineContainerHeight = Math.round(proportion * backgroundHeight)
        
        // elTimeline.style.height = `${timelineContainerHeight * 4}px`
        // document.querySelector('.timeline-holder').style.height = `${timelineHeight}px`
        // document.querySelector('.timeline-container').style.height = `${timelineContainerHeight}px`

        // let firstContent = document.querySelector('.first-content')
        // let secondContent = document.querySelector('.second-content')
        // firstContent.style.width = `${timelineWidth}px`
        // secondContent.style.width = `${timelineWidth}px`
        // var elementTop = parseInt(document.querySelector('.timeline-holder').getBoundingClientRect().top);
        // let backgroundImageProportion = 0
        // // Not zero - 200 for better effect
        // if (elementTop > 0) {
        //   backgroundImageProportion = 0
        //   firstContent.style.opacity = (600 - elementTop) / 600
        // } else {
        //   backgroundImageProportion = Math.round(((Math.abs(elementTop)) / (timelineHeight - backgroundHeight*2)) * 100)
        //   backgroundImageProportion = Math.min(Math.max(backgroundImageProportion, 0), 100)
        //   firstContent.style.transform = `translateX(-${backgroundImageProportion/100*timelineWidth}px)`
        //   if (backgroundImageProportion > 15) {
        //     let firstOpacity = (55 - backgroundImageProportion) / (55 - 15)
        //     firstContent.style.opacity = firstOpacity
        //     let secondOpacity = 1 - (100 - backgroundImageProportion) / 100
        //     secondContent.style.opacity = secondOpacity
        //     secondContent.style.transform = `translateX(-${backgroundImageProportion/100*timelineWidth}px)`
        //   }
        // }
        // // Text Animations
        // document.querySelector('.timeline-container').style.backgroundPositionX = `${backgroundImageProportion}%`
        // if (elementTop < 0 && Math.abs(elementTop) < timelineHeight - timelineContainerHeight) {
        //   elTimeline.style.position = 'fixed'
        //   elTimeline.style.top = 0
        //   document.querySelector('.timeline-container').style.position = 'inherit'
        //   document.querySelector('.timeline-container').style.bottom = 'inherit'
        // } else if (Math.abs(elementTop) > timelineHeight - timelineContainerHeight) {
        //   elTimeline.style.position = 'inherit'
        //   document.querySelector('.timeline-container').style.position = 'absolute'
        //   document.querySelector('.timeline-container').style.bottom = 0
        // }
        // else {
        //   elTimeline.style.position = 'inherit'
        //   document.querySelector('.timeline-container').style.position = 'inherit'
        //   document.querySelector('.timeline-container').style.bottom = 'inherit'
        // }
      });
    }
  }
}