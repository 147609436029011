/* global Hammer */
import { Controller } from "stimulus"

export default class extends Controller {
  initialize(event) {
    let slides = document.querySelectorAll('.slide');
    slides.forEach((slide) => {
      if (!slide.classList.contains('swiped')) {
        var hammertime = new Hammer(slide, {direction: Hammer.DIRECTION_HORIZONTAL});
        hammertime.on('swipe', this.swipe);
        slide.classList.add('swiped');
      }
    });
  }

  swipe(event) {
    if (event.direction == 2) {
      event.target.closest('.presentation-container').querySelector('.right-arrow').click();
    } else if (event.direction == 4) {
      event.target.closest('.presentation-container').querySelector('.left-arrow').click();
    }
  }
}
