/* global Hammer */
import { Controller } from "stimulus"
import Rails from 'rails-ujs';
import Choices from 'choices.js';

export default class extends Controller {
  initialize(event) {
    this.initBackendSearchChoice()
  }

  togglePresentationTemplateForm(event) {
    event.preventDefault();
    const templateContainer = event.currentTarget.closest(".template-item");
    const iconContainer = templateContainer.querySelector(".icon-container");
    const templateOptionsContainer = templateContainer.closest(".template-item").querySelector(".template-options");
    if (templateContainer.classList.contains("collapsed")) {
        templateOptionsContainer.classList.remove('hidden');
        iconContainer.querySelector(".plus-icon").classList.add('hidden');
        iconContainer.querySelector(".minus-icon").classList.remove('hidden');
        templateContainer.classList.remove("collapsed")
    } else {
        templateOptionsContainer.classList.add('hidden');
        iconContainer.querySelector(".plus-icon").classList.remove('hidden');
        iconContainer.querySelector(".minus-icon").classList.add('hidden');
        templateContainer.classList.add("collapsed");
    }
  }

  saveNewTemplateModal(event) {
    event.preventDefault()
    const formData = new FormData(event.target);
    // const formProps = Object.fromEntries(formData);
    Rails.ajax({
      url: '/presentation_templates/',
      type: 'POST',
      data: formData,
      success: (resp, status, xhr) => {
        if (resp && !resp.success) {
          if (resp.editorUrl) {
            window.location = resp.editorUrl
          } else {
            alert('You cannot create presentation templates')
          }
        }
      }
    });
    // this.stimulate('Counter#increment', 1)
  }

  initBackendSearchChoice() {
    const config = {
      removeItemButton: true,
      searchResultLimit: 15,
      placeholderValue: 'Start Typing to Search',
      noChoicesText: 'Start Typing to Search',
      shouldSort: false,
      searchFloor: 3, // or whatever value makes sense for your data / API
      searchChoices: false // see bullet point 2 above
    };
    document.querySelectorAll('.choices-remote-data')
      .forEach((element) => {
        // Construct the Choices object.
        var choices = new Choices(element, config);
        element.choices = choices;

        // Some config and bookkeeping for API calls.
        var apiUrl = element.dataset.remoteUrl;
        var lookupDelay = 100;
        var lookupTimeout = null;
        var lookupCache = {};

        // Function to perform the API lookup.
        var serverLookup = function () {
          // we can't use choices.currentValue because this is blank if we set searchChoices
          // to false. Instead we use the actual input field's value.
          var query = choices.input.value;
          if (query in lookupCache) {
            populateOptions(lookupCache[query]);
          } else {
            // Ajax query and results parsing depends on your API structure and how
            // you make AJAX calls in your codebase.
            fetch(
              // query doesnt work because the external api doesnt support it.. now its random..
              apiUrl + '?query=' + query
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                lookupCache[query] = data;
                populateOptions(data);
              });
          }
        };

        // Function to actually populate the results from the API lookup.
        var populateOptions = function (options) {
          // We have to cull duplicates ourselves here, because Choices doesn't do it for
          // us. There's probably a better way of doing this, but here's the easy way:
          var toRemove = [];
          for (var i = 0; i < choices._currentState.items.length; i++) {
            var item = choices._currentState.items[i];
            if (item.active) {
              toRemove.push(item.value);
            }
          }
          var toKeep = [];
          for (var i = 0; i < options.length; i++) {
            // However you check if an array contains something in your codebase.
            if (!toRemove.includes(options[i].id)) {
              toKeep.push(options[i]);
            }
          }
          // Now actually set the (de-duped) results.
          choices.setChoices(toKeep, 'id', 'text', true);
        };

        // Trigger an API lookup when the user pauses after typing.
        element.addEventListener('search', function (event) {
          clearTimeout(lookupTimeout);
          lookupTimeout = setTimeout(serverLookup, lookupDelay);
        });

        // We want to clear the API-provided options when a choice is selected.
        element.addEventListener('choice', function (event) {
          choices.setChoices([], 'id', 'text', true);
        });
      });
  }
}
