import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    setTimeout(() => {
      this.element.classList.add('flash-notice--fading');
      this.element.addEventListener('animationend', function () {
        this.parentNode.removeChild(this);
      });
    }, this.duration);
  }

  get duration() {
    return parseInt(this.data.get('duration')) * 1000;
  }
}
