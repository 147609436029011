import { getHistory } from './manager';
import undoItem from './undoItem';
import undoSlide from './undoSlide';

const undo = (params) => {
  let latestAction;

  const pastHistoriesOfSlide = getHistory({ ...params, key: 'Past' });
  const pastHistoriesOfPresentation = getHistory({ ...params, key: 'Past', slideId: '' });

  // Find out which one is latest. Item or slide?
  if (pastHistoriesOfSlide.length > 0 && pastHistoriesOfPresentation.length > 0) {
    const lastItemAction = pastHistoriesOfSlide[pastHistoriesOfSlide.length - 1];
    const lastSlideAction = pastHistoriesOfPresentation[pastHistoriesOfPresentation.length - 1];

    if (lastItemAction.time >= lastSlideAction.time) {
      latestAction = 'Item';
    } else {
      latestAction = 'Slide';
    }
  } else if (pastHistoriesOfSlide.length > 0) {
    latestAction = 'Item';
  } else if (pastHistoriesOfPresentation.length > 0) {
    latestAction = 'Slide';
  }

  if (latestAction === 'Item') {
    undoItem(params);
  } else if (latestAction === 'Slide') {
    undoSlide(params);
  }
};

export default undo;
