import {
  SLIDE_ACTIONS,
  getHistory,
  addPast,
  setButtonStates,
} from './manager';
import save from '../slides/save';
import deleteSlide from '../slides/delete';
import undeleteSlide from '../slides/undelete';
import sortSlides from '../slides/sortSlides';
import fixSlideIndexes from '../slides/fixSlideIndexes';

const redoSlide = (params) => {
  const futureHistories = getHistory({ ...params, key: 'Future', slideId: '' });
  if (futureHistories.length > 0) {
    let popSize = 1;
    if (futureHistories[futureHistories.length - 1].selectionId) {
      const { selectionId } = futureHistories[futureHistories.length - 1];
      popSize = futureHistories.filter((ph) => ph.selectionId === selectionId).length;
    }
    const groupedHistories = futureHistories.splice(-popSize, popSize);
    groupedHistories.forEach((futureHistory) => {
      // Slide Actions
      if (SLIDE_ACTIONS.includes(futureHistory.actionType)) {
        // Update slide
        if (futureHistory.actionType === 'ADD_SLIDE') {
          undeleteSlide(futureHistory.contentId);
        } else if (futureHistory.actionType === 'DELETE_SLIDE') {
          deleteSlide(futureHistory.contentId);
        } else if (futureHistory.actionType === 'UPDATE_SLIDE') {
          // updatedItem.innerHTML = historyItem;
        } else if (futureHistory.actionType === 'UPDATE_SLIDE_POSITIONS') {
          sortSlides(futureHistory.content, params.controller);
        }

        if (params.contentId) {
          save(
            futureHistory.contentId,
            params.controller,
          );
        }
        fixSlideIndexes();
      }

      addPast({
        presentationId: params.presentationId,
        slideId: '',
        userId: params.userId,
        actionType: futureHistory.actionType,
        contentType: 'Slide',
        content: futureHistory.content,
        contentId: futureHistory.contentId,
        selectionId: futureHistory.selectionId,
      });
    });

    // Remove from future
    localStorage.setItem(
      `Presentations:${params.presentationId}:Slide:${''}:User:${params.userId}:History:Future`,
      JSON.stringify(futureHistories),
    );

    setButtonStates(params);
  }
};

export default redoSlide;
