import ApplicationController from './application_controller.js'
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import { BlobRecord} from '@rails/activestorage/src/blob_record';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';

const Uppy = require('uppy');
const DropTarget = require('@uppy/drop-target')
const { Plugin } = require('@uppy/core');

export default class extends ApplicationController {
  static uppy;

  connect() {
    super.connect();
    this.dropAssetFromDesktop();

    document.addEventListener('snapshotHistoryForDroppedItems', this.snapshotHistoryForDroppedItems);
    document.addEventListener('paste', this.dropAssetFromClipboard.bind(this));
  }

  trackMousePosition(event) {
    // Store mouse position on window object
    let rect = document.querySelector('.canvasContainer').getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;
    window.mouseX = x;
    window.mouseY = y;
  }

  dropAssetFromClipboard(event) {
    const clipboardData = event.clipboardData || window.clipboardData;
    if(clipboardData && clipboardData.files.length > 0) {
      const hasImage = Array.from(clipboardData.files).some(file => file.type.startsWith('image/'));
      if (hasImage) {
        event.preventDefault();
        window.pastedImage = true;
        const imageFile = Array.from(clipboardData.files).find(file => file.type.startsWith('image/'));
        console.log(imageFile)
        console.log('dropAssetFromClipboard');

        // Add the pasted image to the Uppy instance
        this.uppy.addFile({
          source: 'clipboard',
          name: imageFile.name,
          type: imageFile.type,
          data: imageFile
        });
      }
      return;
    }

    // Text copy paste, allow browser handle it if it pasted on a quill item.
    if(clipboardData && clipboardData.getData('text/plain') && (document.activeElement.classList.contains('ql-editor') || document.activeElement.tagName === 'INPUT')) {
      return;
    }

    if(clipboardData && clipboardData.getData('text/plain')) {
      const clipboardText = event.clipboardData.getData('Text');
      if (!clipboardText.startsWith('{"context"')) {
        this.stimulate('Item#create_text', { resolveLate: true }, clipboardText).then(() => {
          const textItem = document.querySelector('.item-type-text.selected');
          window.moveableForText.target = textItem;
          storeItemSnapshotForUndo(textItem.dataset.itemId, 'ADD_SLIDE_ITEM');
        })
        return;
      }
    }


    // Item copy/paste
    if (window.copiedText) {
        this.stimulate('Item#create_text', { resolveLate: true }, window.copiedText).then(() => {
          const textItem = document.querySelector('.item-type-text.selected');
          window.moveableForText.target = textItem;
          storeItemSnapshotForUndo(textItem.dataset.itemId, 'ADD_SLIDE_ITEM');
        })
        return;
      }

      this.stimulate('Item#paste', document.querySelector('.slide-container').dataset.slideId);
  }

  dropAssetFromMediaHub(event) {
    const data = JSON.parse(event.dataTransfer.getData("application/json"));
    const assetIds = data.assetIds;
    const reflexAction = data.type === 'camera' ? 'add_camera_assets_to_single_slide' : 'add_assets_to_single_slide';

    this.stimulate(`MediaHub#${reflexAction}`, event.target, {resolveLate: true}, assetIds, {'left': mouseX, 'top': mouseY}).then(
      (payload) => {
        window.moveableForItems.target = null;
        window.moveableForGroups.target = null;
        window.moveableForText.target = null;

        const items = document.querySelectorAll('.item-container .selected');
        items.forEach((item) => {
          storeItemSnapshotForUndo(item.dataset.itemId, 'ADD_SLIDE_ITEM');
        });

        if (items.length > 1) {
          window.moveableForGroups.target = items;
        } else {
          window.moveableForItems.target = items;
        }
      }
    )
  }

  dropAssetFromBrowser(event) {
    this.trackMousePosition(event);

    if (event.dataTransfer.getData("application/json")) {
      this.dropAssetFromMediaHub(event);
    }

    // Check if dropped element has a url
    let html = event.dataTransfer.getData('text/html'),
        match = html && /\bsrc="?([^"\s]+)"?\s*/.exec(html),
        url = match && match[1];

    if (url) {
      // Skip if url already exists. Prevents drag/drop of uploading items.
      if (Array.from(document.querySelectorAll('img')).map((img) => img.src).includes(url)) return;

      let randomId = this.generateRandomString();

      // container
      var itemContainer = document.createElement('div');
      itemContainer.style.position = 'absolute';
      itemContainer.style.left = `${window.mouseX}px`;
      itemContainer.style.top = `${window.mouseY}px`;
      itemContainer.style.width = '300px';
      itemContainer.style.zIndex = 500;

      itemContainer.id = `temp-image-container-${randomId}`;
      document.querySelector('.slide-container').appendChild(itemContainer);

      // previewing image
      var imageElement = document.createElement('img');
      imageElement.style.opacity = 0.9;
      imageElement.style.width = '300px';
      itemContainer.style.zIndex = 500;
      imageElement.style.backgroundColor = 'lightgray';
      imageElement.src = url;
      itemContainer.appendChild(imageElement);

      imageElement.addEventListener('load', (event) => {
        var loaderContainer = document.createElement('div');
        loaderContainer.classList.add('loading-item');
        itemContainer.appendChild(loaderContainer);
      })

      let container = document.querySelector(`#temp-image-container-${randomId}`);
      let top = parseFloat(container.style.top);
      let left = parseFloat(container.style.left);
      let width = parseFloat(container.style.width);

      this.stimulate('Item#create_with_remote_url', randomId, top, left, width, url).then(() => {
        setTimeout(() => {
          document.getElementById(`temp-image-container-${randomId}`).remove();
        }, 1000);
      });
    }
  }

  dropAssetFromDesktop() {
    let assetIds = [];

    // Core Uppy definition
    this.uppy = Uppy.Core({ autoProceed: true, restrictions: { allowedFileTypes: ['image/*'] } });

    // Activate drag & drop feature
    this.uppy.use(DropTarget, { target: document.body });

    // Activate S3 Multipart support
    this.uppy.use(AwsS3Multipart, { limit: 4, companionUrl: '/' })

    class UppyS3DirectUpload extends Plugin {
      constructor (uppy, opts) {
        super(uppy, opts)
        this.id = opts.id || 'UppyS3DirectUpload'
        this.type = 'example'
        this.prepareUpload = this.prepareUpload.bind(this) // ← this!
      }

      prepareUpload (fileIDs) {
        const promises = fileIDs.map((fileID) => {
          const file = this.uppy.getFile(fileID)
          this.uppy.emit('preprocess-progress', file, {
            mode: 'indeterminate',
            message: 'Preparing files'
          })

          return new Promise((resolve, reject) => {
            FileChecksum.create(file.data, (error, checksum) => {
              if(error) {
                console.log(error);
                return reject(error)
              } else {
                const blob = new BlobRecord(file.data, checksum, document.querySelector('meta[name=\'direct-upload-url\']').getAttribute('content'));
                blob.create(error => {
                  if(error) {
                    console.log(error);
                    return reject(error)
                  } else {
                    this.uppy.setFileMeta(file.id, {
                      blob: blob.toJSON()
                    });
                    return resolve();
                  }
                });
              }
            });
          });
        })

        const emitPreprocessCompleteForAll = () => {
          fileIDs.forEach((fileID) => {
            const file = this.uppy.getFile(fileID)
            this.uppy.emit('preprocess-complete', file)
          })
        }

        // Why emit `preprocess-complete` for all files at once, instead of
        // above when each is processed?
        // Because it leads to StatusBar showing a weird “upload 6 files” button,
        // while waiting for all the files to complete pre-processing.
        return Promise.all(promises)
          .then(emitPreprocessCompleteForAll)
      }

      install () {
        this.uppy.addPreProcessor(this.prepareUpload)
      }

      uninstall () {
        this.uppy.removePreProcessor(this.prepareUpload)
      }
    }

    // Activate direct S3 upload support
    this.uppy.use(UppyS3DirectUpload, {});

    this.uppy.on('file-added', (file) => {
      let randomId = this.generateRandomString();

      // Set meta data
      this.uppy.setFileMeta(file.id, {
        studio_id: document.getElementById("studio-id").value,
        temp_id: randomId,
      });

      // Check if other files exists, then shift position
      const otherCanvasDropContainers = document.querySelectorAll('.canvas-drop-container');
      const canvasDropContainerCount = otherCanvasDropContainers.length;

      // container
      var itemContainer = document.createElement('div');
      itemContainer.style.position = 'absolute';
      itemContainer.style.left = `${window.mouseX + (canvasDropContainerCount * 310)}px`;
      itemContainer.style.top = `${window.mouseY + (canvasDropContainerCount)}px`;
      itemContainer.style.width = '300px';
      itemContainer.style.zIndex = 500;

      itemContainer.id = `temp-image-container-${randomId}`;
      itemContainer.classList.add('canvas-drop-container');
      document.querySelector('.slide-container').appendChild(itemContainer);

      // previewing image
      var imageElement = document.createElement('img');
      imageElement.style.opacity = 0.9;
      imageElement.style.width = '300px';
      itemContainer.style.zIndex = 500;
      imageElement.style.backgroundColor = 'lightgray';
      itemContainer.appendChild(imageElement);

      var reader = new FileReader();
      reader.onload = function(e) {
        imageElement.src = e.target.result;
      }

      reader.readAsDataURL(file.data);

      imageElement.addEventListener('load', (event) => {
        var loaderContainer = document.createElement('div');
        loaderContainer.classList.add('loading-item');
        itemContainer.appendChild(loaderContainer);
      })
    });

    this.uppy.on('upload-success', (file, response) => {
      let formData = new FormData();
      formData.append('studio_id', file.meta.studio_id);
      formData.append('temp_id', file.meta.temp_id);
      formData.append('blob_signed_id', file.meta.blob.signed_id);
      formData.append('key', new URL(response.uploadURL).pathname.split("/")[1]);
      if(!(file.meta.width === undefined))
        formData.append('width', file.meta.width);
      if(!(file.meta.height === undefined))
        formData.append('height', file.meta.height);
      fetch('/assets', {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          if (data.asset_id) {
            this.uppy.removeFile(file.id);

            let container = document.querySelector(`#temp-image-container-${data.temp_id}`);
            let top = parseFloat(container.style.top);
            let left = parseFloat(container.style.left);
            let width = parseFloat(container.style.width);
            this.stimulate('Item#create_with_asset', data.asset_id, data.temp_id, top, left, width).then(() => {
              setTimeout(() => {
                document.getElementById(`temp-image-container-${data.temp_id}`).remove();
              }, 1000);
            });
          } else {
            alert('Error in file');
          }
        });
    });
  }

  generateRandomString() {
    return Math.round((Math.random() * 36 ** 12)).toString(36);
  }

  snapshotHistoryForDroppedItems(event) {
    storeItemSnapshotForUndo(event.detail.newItemId, 'ADD_SLIDE_ITEM');
  }
}
