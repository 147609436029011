import { Controller } from 'stimulus';
import InfiniteScroll from "infinite-scroll";
import Tagify from '@yaireo/tagify'
import { htmlToElement } from '../helpers/dom';

export default class extends Controller {
  static targets = ["form"]

  initialize() {
    if (document.getElementById('infinite-scroll')) {
      new InfiniteScroll('#infinite-scroll', {
        path: '.pagination__next',
        append: '.school',
        history: false,
      });
    }

    window.tagifyInputs();
    window.expandableTextArea();
  }

  onSubmit(event) {
    // hijacking the form submit just to set ordinals
    event.preventDefault();
    event.stopPropagation();

    const tabTemplates = document.querySelectorAll("#edit-tab-template");
    tabTemplates.forEach(tabTemplate => {

      const tabsController = this.application.getControllerForElementAndIdentifier(tabTemplate, 'schools--edit-tab-templates');

      if (tabsController !== null) {
        const tabForms = tabsController.tabTemplateFormTargets;

        tabsController.tabTemplateNameTargets.forEach((el, i) => {
          const { tabTemplateId } = el.dataset;
          tabForms.find((el) => el.dataset.tabTemplateId == tabTemplateId).querySelector('.ordinal').value = i;
        });
      }

    })

    this.formTarget.submit();
  }

  addTagGroup(event) {
    event.preventDefault();
    event.stopPropagation();

    const context = event.target.getAttribute('data-context');
    const template = document.querySelector(`#new-school-tag-container-for-${context}`)
    const newTagGroup = htmlToElement(template.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime()));
    new Tagify(newTagGroup.querySelector('.taggify'));

    const firstTagGroup = document.querySelector(`.tag-group-parent-container-for-${context}`);
    firstTagGroup.prepend(newTagGroup);
    newTagGroup.querySelector("input:not([type=hidden])").focus();
  }

  removeTagGroup() {
    event.preventDefault();
    event.stopPropagation();

    const existingGroup = event.target.closest('.tag-group-container').querySelector("input[name*='_destroy']")
    if(existingGroup) {
      existingGroup.value = 1;
    }
    event.target.closest('.tag-group-container').classList.add('hidden');
  }
}
