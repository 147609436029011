/*jshint esversion: 6 */
import { Controller } from "stimulus";
import Rails from 'rails-ujs';

export default class extends Controller {

  displayContextMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    window.hideGuides();
    window.hideContextMenus();

    if (event.target.classList.contains('item-container') || event.target.classList.contains('slide-editor')) {
      // Context menu for slide
      document.querySelector('.slide-editor-context-menu').classList.remove('hidden');
      document.querySelector('.slide-editor-context-menu').style.left = event.layerX + 'px';
      document.querySelector('.slide-editor-context-menu').style.top = event.layerY + 'px';
    } else {
      // Context menu for item
      var target = event.target.closest('.context-menu-container');
      var context_menu = target.querySelector('.context-menu');
      if (context_menu) {
        target.classList.add('open-context-menu');
        context_menu.classList.remove('hidden');
        context_menu.style.left = event.offsetX + 'px';
        context_menu.style.top = event.offsetY + 'px';

        if (parseInt(target.getAttribute('data-crop-x')) !== 0) {
          context_menu.style.left = (event.offsetX - parseInt(target.getAttribute('data-crop-x'))) + 'px';
        }
        if (parseInt(target.getAttribute('data-crop-y')) !== 0) {
          context_menu.style.top = (event.offsetY - parseInt(target.getAttribute('data-crop-y'))) + 'px';
        }

        window.unselectAllItems();

        // Select item
        target.closest('.draggable-item-container').classList.add('selected');
        Rails.ajax({ url: `/items/${target.getAttribute('data-item-id')}/select`, type: 'GET' });
      }
    }
  }
}
