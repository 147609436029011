import { Controller } from 'stimulus';
import intlTelInput from 'intl-tel-input';
import InfiniteScroll from 'infinite-scroll';

export default class extends Controller {
  initialize() {
    if (document.getElementById('infinite-scroll')) {
      new InfiniteScroll('#infinite-scroll', {
        path: '.pagination__next',
        append: '.user',
        history: false,
      });
    }

    const input = document.querySelector('#user-phone-number');
    if (input) {
      intlTelInput(input, {
      	formatOnInit: true,
      	separateDialCode: true,
      	hiddenInput: 'user[phone_number]',
      });
    }

    // User extra fields for karam
    const inputExtraPhone = document.querySelector('#user-extras-guardian_phone_number');
    if (inputExtraPhone) {
      window.iti = intlTelInput(inputExtraPhone, {
        initialCountry: "tr",
        formatOnInit: true,
        separateDialCode: true,
        hiddenInput: 'user[extras][guardian_phone_number]',
      });
    }

    const elementDateJoined = document.getElementById('user_extras_date_joined');
    if (elementDateJoined) {
      const maskOptions = {
        mask: Date,
        pattern: 'm/`d/`Y',
        format(date) {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          const year = date.getFullYear();

          if (day < 10) day = `0${day}`;
          if (month < 10) month = `0${month}`;

          return [month, day, year].join('/');
        },
        parse(str) {
          const yearMonthDay = str.split('/');
          return new Date(yearMonthDay[2], yearMonthDay[0] - 1, yearMonthDay[1]);
        },
        min: new Date(2010, 0, 1),
        autofix: true,
        overwrite: true,
      };
      const mask = IMask(elementDateJoined, maskOptions);
    }


    const element = document.getElementById('user_date_of_birth');
    if (element) {
      const maskOptions = {
        mask: Date,
        pattern: 'm/`d/`Y',
        format(date) {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          const year = date.getFullYear();

          if (day < 10) day = `0${day}`;
          if (month < 10) month = `0${month}`;

          return [month, day, year].join('/');
        },
        parse(str) {
          const yearMonthDay = str.split('/');
          return new Date(yearMonthDay[2], yearMonthDay[0] - 1, yearMonthDay[1]);
        },
        min: new Date(1950, 0, 1),
        max: new Date(2020, 0, 1),
        autofix: true,
        overwrite: true,
      };
      const mask = IMask(element, maskOptions);
    }

    const elementKaramIdNumber = document.getElementById('user_extras_id_number');
    if (elementKaramIdNumber) {
      const numberMask = IMask(elementKaramIdNumber,
        {
          mask: Number
        });
    }


    window.activateFroalaEditor();

    // Karam Programme
  }

  toggleKaramPrograms(event) {
    switch (event.target.value) {
      case 'karam_scholars':
        if (event.target.checked) {
          document.getElementById('program_karam_scholars').classList.remove('hidden')
        } else {
          document.getElementById('program_karam_scholars').classList.add('hidden')
        }
        break;
      case 'karam_families':
        if (event.target.checked) {
          document.getElementById('program_karam_families').classList.remove('hidden')
        } else {
          document.getElementById('program_karam_families').classList.add('hidden')
        }
        break;
      default:
        console.warn("Unexpected value", event.target.value)
    }
  }

  addRole(event) {
    event.preventDefault();
    event.stopPropagation();

    const template = document.querySelector('.new-role-template');
    const index = document.querySelectorAll('.role-container').length + 1;
    const innerHTML = template.innerHTML.replace(/TEMPLATE_RECORD/g, index);
    document.querySelector('.new-role-container').insertAdjacentHTML('beforeend', innerHTML);
    window.activateSlimSelect();
  }

  removeRole(event) {
    event.preventDefault();
    event.stopPropagation();

    event.target.closest('.role-container').querySelector("input[name*='_destroy']").value = 1;
    event.target.closest('.role-container').classList.add('hidden');
    event.target.closest('.role-container').classList.remove('sm:flex');
  }
}
