import { Controller } from "stimulus"
import Rails from 'rails-ujs'
import interact from "interactjs"

export default class extends Controller {

  dragMoveListener(event) {
    var target = event.target
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

    // translate the element
    target.style.webkitTransform =
      target.style.transform =
        'translate(' + x + 'px, ' + y + 'px)'

    // update the position attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
  }

  connect() {
    interact('.croppable-item').unset();

    interact('.croppable-item')
      .draggable({
        onmove: this.dragMoveListener,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ]
      })
      .resizable({
        // resize from all edges and corners
        edges: { left: true, right: true, bottom: true, top: true },

        modifiers: [
          interact.modifiers.restrictSize({
            min: { width: 10, height: 10 },
            // max: { width: <%= @item.width.to_i %>, height: <%= @item.height.to_i %> },
          }),
          interact.modifiers.restrict({
            restriction: 'parent',
            endOnly: true
          })
        ],

        inertia: true
      })
      .on('resizemove', function (event) {
        var target = event.target
        var x = (parseFloat(target.getAttribute('data-x')) || 0)
        var y = (parseFloat(target.getAttribute('data-y')) || 0)

        // update the element's style
        target.style.width = event.rect.width + 'px'
        target.style.height = event.rect.height + 'px'

        // translate when resizing from top or left edges
        x += event.deltaRect.left
        y += event.deltaRect.top

        target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

        target.setAttribute('data-x', x)
        target.setAttribute('data-y', y)
      })
  }

  updateItem(event) {
    var item = event.target.closest('.item-container').querySelector('.croppable-item');
    var itemId = item.getAttribute('data-item-id');

    let formData = new FormData()
    formData.append("item[crop_left]", parseInt(item.getAttribute('data-x')));
    formData.append("item[crop_top]", parseInt(item.getAttribute('data-y')));
    formData.append("item[crop_w]", parseInt(item.style.width));
    formData.append("item[crop_h]", parseInt(item.style.height));

    Rails.ajax({
      url: `/items/${itemId}/update_crop`,
      type: "PATCH",
      data: formData
    });
  }
}
