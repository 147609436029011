/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import Rails from 'rails-ujs';
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    // Make slide thumbs draggable
    var el = document.getElementById('sortable-slides');
    if (el) {
      var sortable = Sortable.create(el, {
        draggable: ".draggable-slide-thumb",
        animation: 150,
        delay: 5,
        filter: ".ignore-sort",
        forceFallback: false,
        onEnd: function(event) {
          let slideId = event.item.closest('.draggable-slide-thumb').getAttribute('data-slide-id');
          let newPosition = event.newIndex;

          Rails.ajax({
            url: `/slides/${slideId}/update_position?position=${newPosition}`,
            type: "GET"
          });
        }
      });
    }
  }

  showThumbs(event) {
    event.preventDefault();
    event.stopPropagation();

    var presentationId = event.target.closest('a').getAttribute('data-presentation-id');
    var slideId = event.target.closest('a').getAttribute('data-slide-id');

    Rails.ajax({
      url: `/presentations/${presentationId}/edit_thumbs?selected_slide_id=${slideId}`,
      type: "GET"
    });
  }

  editSlide() {
    event.preventDefault();
    event.stopPropagation();

    var slideId = event.target.closest('.slide-thumb-link').getAttribute('data-slide-id');


    Rails.ajax({
      url: `/slides/${slideId}/edit`,
      type: "GET"
    });
  }

  deleteSlide(event) {
    event.preventDefault();
    event.stopPropagation();

    if (confirm('Are you sure?')) {
      var slideId = event.target.getAttribute('data-slide-id');
      if (!slideId) {
        slideId = event.target.closest('.draggable-slide-thumb').getAttribute('data-slide-id');
      }

      Rails.ajax({
        url: `/slides/${slideId}`,
        type: "DELETE"
      });

      let thumb = document.getElementById(`draggable-slide-thumb-${slideId}`);
      thumb.parentNode.removeChild(thumb);
    }
  }

  duplicateSlide(event) {
    event.preventDefault();
    event.stopPropagation();

    var slideId = event.target.getAttribute('data-slide-id');
    if (!slideId) {
      slideId = event.target.closest('.draggable-slide-thumb').getAttribute('data-slide-id');
    }

    Rails.ajax({
      url: `/slides/${slideId}/duplicate`,
      type: 'GET'
    });
  }

  convertToTemplate(event) {
    event.preventDefault();
    event.stopPropagation();

    var slideId = event.target.getAttribute('data-slide-id');
    if (!slideId) {
      slideId = event.target.closest('.draggable-slide-thumb').getAttribute('data-slide-id');
    }

    Rails.ajax({
      url: `/slides/${slideId}/convert_to_template`,
      type: 'GET'
    });
  }

  removeTemplate(event) {
    event.preventDefault();
    event.stopPropagation();

    var slideId = event.target.getAttribute('data-slide-id');
    if (!slideId) {
      slideId = event.target.closest('.draggable-slide-thumb').getAttribute('data-slide-id');
    }

    Rails.ajax({
      url: `/slides/${slideId}/remove_template`,
      type: 'GET'
    });
  }

  captionChange(event) {
    event.target.closest('form').querySelector('.submit-button').classList.remove('hidden');
  }

  updateCaption(event) {
    var button = event.target.closest('form').querySelector('.auto-submit');
    button.click();
  }

  addFromTemplate(event) {
    event.preventDefault();
    var templateThumb = event.target.closest('.slide-thumb')

    var presentationId = templateThumb.getAttribute('data-presentation-id');
    var slideId = templateThumb.getAttribute('data-slide-id');

    Rails.ajax({
      url: `/presentations/${presentationId}/add_slide_from_template?slide_template_id=${slideId}`,
      type: "GET"
    });
  }
}

