import { Frame } from 'scenejs';
import uuidv4 from '../../../uuidv4';

const cloneLineObject = (targetContainer, e) => {
  const { itemId } = targetContainer.querySelector('.draggable-item-container').dataset;
  const clonedContainer = targetContainer.cloneNode(true);

  const tempId = uuidv4();

  clonedContainer.dataset.clone = true;
  clonedContainer.id = `item-container-${tempId}`;

  targetContainer.parentElement.appendChild(clonedContainer);

  const clone = clonedContainer.querySelector('.draggable-item-container');
  clone.dataset.tempId = tempId;
  clone.id = `draggable-item-container-${tempId}`;
  clone.dataset.clone = true;
  clone.dataset.cloneOf = itemId;
  const cloneLineSnapperContainer = clone.closest('.item-container').querySelector('.line-snapper-container');
  const cloneLineSnapper = clone.closest('.item-container').querySelector('.line-snapper');
  const cloneLineHandler = clone.closest('.item-container').querySelector('.line-handler');
  const cloneActualLine = clone.closest('.item-container').querySelector('.actual-line');
  cloneLineSnapperContainer.id = `line-snapper-container-${tempId}`;
  cloneLineSnapper.id = `line-snapper-${tempId}`;
  cloneLineHandler.id = `line-handler-${tempId}`;
  cloneActualLine.id = `actual-line-${tempId}`;

  const cloneFrame = new Frame({
    position: 'absolute',
    'z-index': cloneLineSnapper.style.zIndex,
    background: 'transparent',
    width: `${cloneLineSnapper.dataset.width}px`,
    height: `${cloneLineSnapper.dataset.height}px`,
    'transform-origin': 'top left',
    transform: {
      translateX: `${cloneLineSnapper.dataset.x1}px`,
      translateY: `${cloneLineSnapper.dataset.y1}px`,
      rotate: `${cloneLineSnapper.dataset.angle}deg`,
      scaleX: 1,
      scaleY: 1,
    },
  });

  const cloneHandlerFrame = new Frame({
    position: 'absolute',
    'z-index': cloneLineHandler.dataset.zIndex,
    background: 'transparent',
    cursor: 'move',
    width: `${cloneLineHandler.dataset.length}px`,
    height: `${cloneLineHandler.dataset.height}px`,
    'transform-origin': 'center left',
    transform: {
      translateX: `${cloneLineHandler.dataset.x1}px`,
      translateY: `${cloneLineHandler.dataset.y1}px`,
      rotate: `${cloneLineHandler.dataset.angle}deg`,
      scaleX: 1,
      scaleY: 1,
    },
  });

  cloneLineSnapper.style.cssText = cloneFrame.toCSS();
  cloneLineHandler.style.cssText = cloneHandlerFrame.toCSS();

  window.mapItems.set(cloneLineSnapper, cloneFrame);
  window.mapItems.set(cloneLineHandler, cloneHandlerFrame);
  return clone;
};

export default cloneLineObject;
