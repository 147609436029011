import ApplicationController from './application_controller.js'
import InfiniteViewer from "infinite-viewer";
import Guides from "@scena/guides";
import {
  isTouchDevice,
  updateMoveableHorizontalGuidelines,
  updateMoveableVerticalGuidelines,
} from '../helpers/utils';

export default class extends ApplicationController {
  static viewer;
  CANVAS_CONTAINER;
  LEFT_THUMBNAIL_WIDTH = 178;
  TOP_HEADER_BAR_HEIGHT = 78;
  RULER_SIZE = 30;
  RULER_BACKGROUND_COLOR = "white";
  RULER_LINE_COLOR = "#e0e0e0";
  RULER_TEXT_COLOR = "#919191";
  PRESENTATION_ID;
  RULERS_STATE_KEY;

  initialize() {
    this.PRESENTATION_ID = document.querySelector('.canvas').dataset.presentationId;
    this.RULERS_STATE_KEY = `Presentations:${this.PRESENTATION_ID}:RulerState`;
    const rulerState = localStorage.getItem(this.RULERS_STATE_KEY);
    window.Ruler = {
      state: {
        isRulersVisible: false,
        isGuidesVisible: false
      }
    }
    /* if (rulerState) {
      window.Ruler.state = JSON.parse(rulerState);
    } */
    this.CANVAS_CONTAINER = document.querySelector('.canvasContainer')
    document.addEventListener("keydown", event => {
      if ((event.ctrlKey == true || event.metaKey == true) && (event.which == '61' || event.which == '107' || event.which == '173' || event.which == '109' || event.which == '187' || event.which == '189')) {
        event.preventDefault();

        if ((event.ctrlKey || event.metaKey) && (event.code === '+' || event.code === 'Equal')) {
          this.zoomIn();
        }

        if ((event.ctrlKey || event.metaKey) && event.code === 'Minus') {
          this.zoomOut();
        }
      }
    });

    window.Diagrams.firstScroll = true;
    let scrollPosition = {
      x: 0,
      y: 0
    }

    this.viewer = new InfiniteViewer(
      document.querySelector(".viewer"),
      document.querySelector(".viewport"),
      {
        useWheelScroll: true,
        displayHorizontalScroll: false,
        displayVerticalScroll: false,
        zoomOffsetX: "50%",
        zoomOffsetY: "50%",
      }
    ).on("pinchStart", e => {
      if (!document.querySelector('.pan-zoom').classList.contains('active')) {
        e.stop();
        return;
      }
    }).on('dragStart', e => {
      if (!document.querySelector('.pan-zoom').classList.contains('active')) {
        // e.inputEvent.preventDefault();
        e.stop();
        return false;
      }
    }).on("pinch", (e) => {
      if (isTouchDevice() && !document.querySelector('.pan-zoom').classList.contains('active')) {
        e.stop();
        return;
      }
      const zoom = Math.max(0.1, e.zoom);
      this.viewer.zoom = zoom;
      window.zoomLevel = zoom;
      window.Ruler.guidesH.zoom = zoom;
      window.Ruler.guidesV.zoom = zoom;
      this.updateInputsAndLabels();
      window.Diagrams.updateDiagramPositions();

      updateMoveableHorizontalGuidelines(window.Ruler.guidesH.getGuides(), viewer.zoom);
      updateMoveableVerticalGuidelines(window.Ruler.guidesV.getGuides(), viewer.zoom);
    }).on("scroll", (e) => {
      // Remove path pointer events
      this.disableDiagramLineCursorEvents();
      window.Diagrams.scrolling.status = true;

      if (window.Diagrams.scrolling.timeoutId !== null) {
        clearTimeout(window.Diagrams.scrolling.timeoutId);
      }

      window.Diagrams.scrolling.timeoutId = setTimeout(() => {
        window.Diagrams.scrolling.status = false;
        this.enableDiagramLineCursorEvents();
        window.Diagrams.scrolling.timeoutId = null;
      }, 250);

      if (window.Diagrams.firstScroll) {
        scrollPosition.x = e.currentTarget.getScrollLeft();
        scrollPosition.y = e.currentTarget.getScrollTop();
        window.Diagrams.firstScroll = false;
      } else {
        const deltaX = (e.currentTarget.getScrollLeft() - scrollPosition.x)
        const deltaY = (e.currentTarget.getScrollTop() - scrollPosition.y)
        scrollPosition.x = e.currentTarget.getScrollLeft();
        scrollPosition.y = e.currentTarget.getScrollTop();
        const connectorContainer = document.querySelector('#connectors');
        connectorContainer.style.top = `${(parseFloat(connectorContainer.style.top) || 0) - deltaY * viewer.zoom}px`;
        connectorContainer.style.left = `${(parseFloat(connectorContainer.style.left) || 0) - deltaX * viewer.zoom}px`;
      }

      //position for canvas from the top and the current zoom level
      //find the relation between the canvas pinch value and the value provided by this event
      window.Ruler.guidesH.zoom = viewer.zoom;
      window.Ruler.guidesH.scroll((-Math.round(this.CANVAS_CONTAINER.getBoundingClientRect().x) + this.RULER_SIZE + this.LEFT_THUMBNAIL_WIDTH) / viewer.zoom);
      window.Ruler.guidesH.scrollGuides((-Math.round(this.CANVAS_CONTAINER.getBoundingClientRect().y) + this.TOP_HEADER_BAR_HEIGHT) / viewer.zoom);

      window.Ruler.guidesV.zoom = viewer.zoom;
      window.Ruler.guidesV.scroll((-Math.round(this.CANVAS_CONTAINER.getBoundingClientRect().y) + this.TOP_HEADER_BAR_HEIGHT) / viewer.zoom);
      window.Ruler.guidesV.scrollGuides((-Math.round(this.CANVAS_CONTAINER.getBoundingClientRect().x) + this.RULER_SIZE + this.LEFT_THUMBNAIL_WIDTH) / viewer.zoom);

      if (window.moveableForGroups) window.moveableForGroups.updateRect();
      if (window.moveableForItems) window.moveableForItems.updateRect();
      if (window.moveableForText) window.moveableForText.updateRect();
      if (window.moveableForLineHandle) window.moveableForLineHandle.updateRect();
      if (window.moveableForLineBody) window.moveableForLineBody.updateRect();
      if (window.moveableForCrop) window.moveableForCrop.updateRect();
      updateMoveableHorizontalGuidelines(window.Ruler.guidesH.getGuides(), viewer.zoom);
      updateMoveableVerticalGuidelines(window.Ruler.guidesV.getGuides(), viewer.zoom);
      this.updateInputsAndLabels();
    });

    window.viewer = this.viewer

    let snapsArray = this.createSnapsArray()
    const guidesH = new Guides(document.querySelector(".guides.horizontal"), {
      type: "horizontal",
      backgroundColor: this.RULER_BACKGROUND_COLOR,
      textColor: this.RULER_TEXT_COLOR,
      lineColor: this.RULER_LINE_COLOR,
      displayDragPos: "true",
      unit: 150,
      zoom: 1,
      snaps: snapsArray,
      dragPosFormat: v => `${v}px`,
    }).on("changeGuides", ({ guides }) => {
      let guidesV = document.getElementsByClassName("scena-guide scena-vertical")
      let guidesH = document.getElementsByClassName("scena-guide scena-horizontal")

      document.querySelector(".app.canvas").dataset.guidesHorizontal = JSON.stringify(guides)
      updateMoveableHorizontalGuidelines(guides, window.zoomLevel);

      this.stimulate("PresentationReflex#update_guides", { guides: guides, type: "horizontal" })

      for (let guide of guidesV) {
        if (guide.style.visibility === "hidden") {
          guide.style.visibility = "visible"
        }
      }

      for (let guide of guidesH) {
        if (guide.style.visibility === "hidden") {
          guide.style.visibility = "visible"
        }
      }
    });
    const guidesV = new Guides(document.querySelector(".guides.vertical"), {
      type: "vertical",
      backgroundColor: this.RULER_BACKGROUND_COLOR,
      textColor: this.RULER_TEXT_COLOR,
      lineColor: this.RULER_LINE_COLOR,
      displayDragPos: "true",
      unit: 150,
      zoom: 1,
      snaps: snapsArray,
      dragPosFormat: v => `${v}px`,
    }).on("changeGuides", ({ guides }) => {
      let guidesV = document.getElementsByClassName("scena-guide scena-vertical")
      let guidesH = document.getElementsByClassName("scena-guide scena-horizontal")

      document.querySelector(".app.canvas").dataset.guidesVertical = JSON.stringify(guides)
      this.stimulate("PresentationReflex#update_guides", { guides: guides, type: "vertical" })

      updateMoveableVerticalGuidelines(guides, window.zoomLevel)

      for (let guide of guidesV) {
        if (guide.style.visibility === "hidden") {
          guide.style.visibility = "visible"
        }
      }
      for (let guide of guidesH) {
        if (guide.style.visibility === "hidden") {
          guide.style.visibility = "visible"
        }
      }
    });
    window.Ruler.guidesH = guidesH
    window.Ruler.guidesV = guidesV

    this.updateRulerAndGuidesVisibilities(window.Ruler.state)

    this.zoomOutOnSmallScreens()

    requestAnimationFrame(() => {
      guidesH.resize();
      guidesV.resize();
      this.viewer.scrollCenter();

      const connectorContainer = document.querySelector('#connectors');
      connectorContainer.style.top = '';
      connectorContainer.style.left = '';
    });

    updateMoveableHorizontalGuidelines(window.Ruler.guidesH.getGuides(), viewer.zoom);
    updateMoveableVerticalGuidelines(window.Ruler.guidesV.getGuides(), viewer.zoom);

    window.addEventListener("resize", () => {
      this.viewer.scrollCenter();
      guidesH.resize();
      guidesV.resize();
      window.moveableForItems.updateRect();
      window.moveableForGroups.updateRect();
      window.moveableForText.updateRect();
      if (window.moveableForCrop) window.moveableForCrop.updateRect();
    });
  }

  zoomTo(event) {
    let zoomLevel = event.target.closest('.custom-zoom-level').dataset.zoomLevel;
    this.viewer.setZoom(zoomLevel);
    window.zoomLevel = this.viewer.zoom;
    this.updateInputsAndLabels();
    window.Diagrams.updateDiagramPositions();
  }

  zoomReset() {
    this.viewer.setZoom(.90);
    this.viewer.scrollCenter();
    this.viewer.scrollTo(this.viewer.getScrollLeft() - 88, this.viewer.getScrollTop())
    window.Diagrams.updateDiagramPositions();
  }

  zoomIn() {
    this.viewer.setZoom(this.viewer.zoom + 0.2)
    window.zoomLevel = this.viewer.zoom;
    this.updateInputsAndLabels();
    window.Diagrams.updateDiagramPositions();
  }

  zoomOut() {
    this.viewer.setZoom(this.viewer.zoom - 0.2)
    window.zoomLevel = this.viewer.zoom;
    this.updateInputsAndLabels();
    window.Diagrams.updateDiagramPositions();
  }

  updateInputsAndLabels() {
    document.querySelector("#currentZoomLevel").innerHTML = `${Math.round(this.viewer.zoom * 100, 2)}%`;
    document.querySelector("#customZoomValue").value = `${Math.round(this.viewer.zoom * 100, 2)}`;

    /* const connectorContainer = document.querySelector('#connectors');
      connectorContainer.style.top = '';
      connectorContainer.style.left = '';
   */
    if (window.moveableForItems) {
      window.moveableForItems.updateRect();
      window.moveableForGroups.updateRect();
      window.moveableForText.updateRect();
    }
    if (window.leaderLine) {
      window.leaderLine.position();
      window.leaderLine2.position();
    }
  }

  toggleZoomMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('#zoomMenu').classList.toggle('hidden');
  }

  submitZoomForm(event) {
    window.clearTimeout(this.formTimer);
    this.formTimer = window.setTimeout(() => {
      let target = event.target;
      let zoomLevel = parseInt(event.target.value);
      if (zoomLevel >= 10 && zoomLevel <= 200) {
        this.viewer.setZoom(zoomLevel / 100);
        window.zoomLevel = this.viewer.zoom;
        this.updateInputsAndLabels();
        window.Diagrams.updateDiagramPositions();
      }
    }, 1000);
  }

  zoomOutOnSmallScreens() {
    let topBarHeight = 48;
    let bottomBarHeight = 116;
    let padding = 50;
    let windowHeight = window.innerHeight;
    let visibleArea = windowHeight - (topBarHeight + bottomBarHeight + padding);

    if (visibleArea < 675) {
      // Visible area is smaller than canvas
      let zoomLevel = visibleArea / 675;
      this.viewer.setZoom(zoomLevel);
      window.zoomLevel = this.viewer.zoom;
      this.updateInputsAndLabels();
      if (window.Diagrams.updateDiagramPositions) {
        window.Diagrams.updateDiagramPositions();
      }
    }
  }

  updateRulerAndGuidesVisibilities(state) {
    const { isGuidesVisible, isRulersVisible } = state;
    if (isRulersVisible) {
      this.showRulers();
    } else {
      this.hideRulers();
    }
    if (isGuidesVisible) {
      this.showGuides();
    } else {
      this.hideGuides();
    }
  }

  hideRulersAndGuides() {
    this.hideRulers();
    this.hideGuides();
  }

  showRulers() {
    window.Ruler.state.isRulersVisible = true
    let rulerH = document.querySelector(".guides.horizontal").querySelector('canvas')
    let rulerV = document.querySelector(".guides.vertical").querySelector('canvas')
    let guideBox = document.querySelector(".guides.box")

    if (rulerH.style.visibility !== 'visible') {
      rulerH.style.visibility = 'visible'
      rulerV.style.visibility = 'visible'
      guideBox.style.visibility = 'visible'
    }

    window.Ruler.guidesH.scroll(-Math.round(this.CANVAS_CONTAINER.getBoundingClientRect().x) + this.RULER_SIZE + this.LEFT_THUMBNAIL_WIDTH);
    window.Ruler.guidesV.scroll(-Math.round(this.CANVAS_CONTAINER.getBoundingClientRect().y) + this.TOP_HEADER_BAR_HEIGHT);
    // Menu Handler
    window.hideContextMenus();
  }

  hideRulers() {
    let guidesV = document.getElementsByClassName("scena-guide scena-vertical")
    let guidesH = document.getElementsByClassName("scena-guide scena-horizontal")
    let rulerH = document.querySelector(".guides.horizontal").querySelector('canvas')
    let rulerV = document.querySelector(".guides.vertical").querySelector('canvas')
    let guideBox = document.querySelector(".guides.box")

    for (let item of guidesV) {
      if (item.style.visibility !== "hidden") {
        item.style.visibility = "hidden"
      }
    }
    for (let item of guidesH) {
      if (item.style.visibility !== "hidden") {
        item.style.visibility = "hidden"
      }
    }
    rulerH.style.visibility = 'hidden'
    rulerV.style.visibility = 'hidden'
    guideBox.style.visibility = 'hidden'
    window.Ruler.state.isRulersVisible = false;
    localStorage.setItem(this.RULERS_STATE_KEY, JSON.stringify(window.Ruler.state))
    // Menu Handler
    window.hideContextMenus();
  }

  toggleGuides() {
    if (window.Ruler.state.isGuidesVisible) {
      this.hideGuides()
    } else {
      this.showGuides()
    }
    localStorage.setItem(this.RULERS_STATE_KEY, JSON.stringify(window.Ruler.state))
    window.hideContextMenus();
  }

  showGuides() {
    if (!window.Ruler.state.isRulersVisible) {
      this.showRulers()
    }
    window.Ruler.state.isGuidesVisible = true
    const guidesVertical = JSON.parse(document.querySelector(".app.canvas").dataset.guidesVertical)
    const guidesHorizontal = JSON.parse(document.querySelector(".app.canvas").dataset.guidesHorizontal)
    //
    window.Ruler.guidesH.loadGuides(guidesHorizontal)
    window.Ruler.guidesV.loadGuides(guidesVertical)
    //
    updateMoveableHorizontalGuidelines(guidesHorizontal, viewer.zoom);
    updateMoveableVerticalGuidelines(guidesVertical, viewer.zoom);
  }

  hideGuides() {
    window.Ruler.state.isGuidesVisible = false
    window.Ruler.guidesH.loadGuides([])
    window.Ruler.guidesV.loadGuides([])
    //
    updateMoveableHorizontalGuidelines([]);
    updateMoveableVerticalGuidelines([]);
  }

  createSnapsArray() {
    let snapsArray = [];
    for (let x = 0; x < 801; x++) {
      snapsArray.push((-4000) + x * 5);
    }
    for (let x = 0; x < 801; x++) {
      snapsArray.push(x * 5);
    }
    return snapsArray
  }

  disableDiagramLineCursorEvents() {
    if (!window.Diagrams.scrolling.status) {
      document.querySelectorAll('svg.leader-line path.leader-line-line-path').forEach((lineItem) => {
        lineItem.setAttribute('style', 'pointer-events: none !important');
      })
    }
  }

  enableDiagramLineCursorEvents() {
    document.querySelectorAll('svg.leader-line path.leader-line-line-path').forEach((lineItem) => {
      lineItem.setAttribute('style', '');
    })
  }
}
