import ApplicationController from './application_controller.js';
import hideCommentPanel from "../helpers/editor/panels/hideCommentPanel";
import hideCommentBox from "../helpers/editor/comments/hideCommentBox";
import hideItemPanel from "../helpers/editor/panels/hideItemPanel";

export default class extends ApplicationController {
  connect() {
    super.connect();
    document.addEventListener('dispatchSelectCommentItem', this.dispatchSelectCommentItem);
  }

  toggleComments(event) {
    event.preventDefault();
    event.stopPropagation();

    this.hideItemsOnCanvas();
    this.deselectNotComment(event)
    const commentsContainer = document.querySelector('.commentsContainer');

    if (commentsContainer.innerHTML) {
      commentsContainer.innerHTML = '';
      event.target.closest('a').classList.remove('bg-pink', 'text-white');
      event.target.closest('a').classList.add('border-black');
    } else {
      this.stimulate('Comment#filter_current_slide', event.target);
      event.target.closest('a').classList.add('bg-pink', 'text-white');
      event.target.closest('a').classList.remove('border-black');
    }
  }

  newComment() {
    this.hideItemsOnCanvas();
    Array.from(document.querySelectorAll('.commentContainer')).forEach((comment) => {
      comment.classList.remove('selected');
    });
    this.stimulate('comment#new_comment', { resolveLate: true }).then(() => {
      setTimeout(() => {
        if (document.querySelector('textarea[name="comment_body"]')) {
          document.querySelector('textarea[name="comment_body"]').focus();
        }
      }, 500);
    });
  }

  select(event) {
    const currentSlideId = document.querySelector('.canvasContainer .slide-container').dataset.slideId;
    const commentSlideId = event.target.closest('.commentContainer').dataset.slideId;
    const { itemId } = event.target.closest('.commentContainer').dataset;
    this.stimulate('Comment#mark_as_read', event.target, itemId);

    if (currentSlideId !== commentSlideId) {
      const canvasController = document.querySelector('.canvas').canvas;
      canvasController.changeSlide(event, commentSlideId);

      setTimeout(() => {
        this.selectCommentItem(itemId);
      }, 500);
    } else {
      this.selectCommentItem(itemId);
    }
  }

  dispatchSelectCommentItem(event) {
    setTimeout(() => {
      const { itemId } = event.detail;
      const container = document.querySelector(`#commentContainer-${itemId}`);
      const commentPointer = document.querySelector(`#commentPointer-${itemId}`);

      const rect = container.getBoundingClientRect();
      const y = rect.top;

      commentPointer.classList.remove('hidden');
      commentPointer.style.top = `${y - 38}px`;
    }, 500);
  }

  selectCommentItem(itemId) {
    const { placedOnCanvas, resolved } = document.querySelector(`#commentContainer-${itemId}`).dataset;
    Array.from(document.querySelectorAll('.commentContainer')).forEach((comment) => {
      comment.classList.remove('selected');
    });
    document.querySelector(`#commentContainer-${itemId}`).classList.add('selected');
    this.hideItemsOnCanvas();
    window.hideContextMenus();
    const currentSlideId = document.querySelector('.canvasContainer .slide-container').dataset.slideId;
    const commentSlideId = document.querySelector(`#commentContainer-${itemId}`).dataset.slideId;

    if (currentSlideId === commentSlideId) {
      if (document.querySelector(`#draggable-item-container-${itemId} svg`) && placedOnCanvas == 'true' && resolved == 'false') {
        if (document.querySelector(`#draggable-item-container-${itemId}`)) {
          document.querySelector(`#draggable-item-container-${itemId}`).classList.remove('hidden');
        }

        document.querySelector(`#commentPointer-${itemId}`).classList.add('hidden');

        window.leaderLine = new LeaderLine(
          document.querySelector(`#commentLeaderLine-${itemId}`),
          document.querySelector(`#draggable-item-container-${itemId} circle.targetCircle`),
          {
            startSocket: 'left', endSocket: 'right', color: '#f35d91', dash: { len: 8, gap: 8 }, size: 2,
            path: 'straight', startPlug: 'behind', endPlug: 'behind',
          },
        );

        window.leaderLine2 = new LeaderLine(
          document.querySelector(`#commentLeaderLine-${itemId}`),
          document.querySelector(`#draggable-item-container-${itemId} circle.targetCircle`),
          {
            startSocket: 'left', endSocket: 'right', color: 'rgba(255, 255, 255, 0.4)', dash: { len: 8, gap: 8 }, size: 2,
            path: 'straight', startPlug: 'behind', endPlug: 'behind',
          },
        );

        if (document.querySelector('.leader-line use')) {
          document.querySelector('.leader-line use').style.strokeDashoffset = 8;
        }
      } else {
        this.showCommentPointer(itemId);
      }
    }
  }

  showCommentPointer(itemId) {
    const container = document.querySelector(`#commentContainer-${itemId}`);
    const resolved = container.dataset.resolved;
    const commentPointer = document.querySelector(`#commentPointer-${itemId}`);

    const rect = container.getBoundingClientRect();
    const y = rect.top;

    if (resolved == 'false') {
      commentPointer.classList.remove('hidden');
      commentPointer.style.top = `${y - 38}px`;
    }
  }

  placeOnCanvas(event) {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target.closest('.commentContainer');
    target.dataset.placedOnCanvas = 'true';
    const { itemId } = target.dataset;

    if (document.querySelector(`#draggable-item-container-${itemId}`)) {
      document.querySelector(`#draggable-item-container-${itemId}`).classList.remove('hidden');
    }

    document.querySelector(`#commentPointer-${itemId}`).classList.add('hidden');

    window.leaderLine = new LeaderLine(
      document.querySelector(`#commentLeaderLine-${itemId}`),
      document.querySelector(`#draggable-item-container-${itemId} circle.targetCircle`),
      {
        startSocket: 'left', endSocket: 'right', color: '#f35d91', dash: { len: 8, gap: 8 }, size: 2,
        path: 'straight', startPlug: 'behind', endPlug: 'behind',
      },
    );

    window.leaderLine2 = new LeaderLine(
      document.querySelector(`#commentLeaderLine-${itemId}`),
      document.querySelector(`#draggable-item-container-${itemId} circle.targetCircle`),
      {
        startSocket: 'left', endSocket: 'right', color: 'rgba(255, 255, 255, 0.4)', dash: { len: 8, gap: 8 }, size: 2,
        path: 'straight', startPlug: 'behind', endPlug: 'behind',
      },
    );

    if (document.querySelector('.leader-line use')) {
      document.querySelector('.leader-line use').style.strokeDashoffset = 8;
    }

    this.stimulate('comment#place_on_canvas', target);
  }

  cancelReply(event) {
    event.preventDefault();
    event.stopPropagation();

    const replyContainer = event.target.closest('.replyContainer');
    replyContainer.classList.remove('selected');
  }

  toggleCommentFilterMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('#commentFilterMenu').classList.toggle('hidden');
  }

  filterCurrentSlide(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hideItemsOnCanvas();

    this.stimulate('comment#filter_current_slide', event.target);
  }

  filterAllSlides(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hideItemsOnCanvas();

    this.stimulate('comment#filter_all_slides', event.target);
  }

  toggleCommentMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    Array.from(document.querySelectorAll('.commentMenu')).forEach((commentMenu) => {
      commentMenu.classList.add('hidden');
    });
    event.target.closest('.commentMenuContainer').querySelector('.commentMenu').classList.remove('hidden');
  }

  editItem(event) {
    event.preventDefault();
    event.stopPropagation();
    window.hideContextMenus();

    this.stimulate('comment#edit_item', event.target).then(() => {
      setTimeout(() => {
        if (document.querySelector('textarea[name="comment_body"]')) {
          const element = document.querySelector('textarea[name="comment_body"]');
          element.focus();
          element.setSelectionRange(element.value.length, element.value.length);
        }
      }, 500);
    });
  }

  deleteItem(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hideItemsOnCanvas();

    if (confirm('Are you sure you want to delete this comment?')) {
      this.stimulate('Comment#delete_item', event.target);
    }
  }

  resolveComment(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hideItemsOnCanvas();

    this.stimulate('comment#resolve_comment', event.target);
  }

  unresolveComment(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hideItemsOnCanvas();

    this.stimulate('comment#unresolve_comment', event.target);
  }

  editComment(event) {
    event.preventDefault();
    event.stopPropagation();
    window.hideContextMenus();

    this.stimulate('comment#edit_comment', event.target).then(() => {
      setTimeout(() => {
        if (document.querySelector('textarea[name="comment_body"]')) {
          const element = document.querySelector('textarea[name="comment_body"]');
          element.focus();
          element.setSelectionRange(element.value.length, element.value.length);
        }
      }, 500);
    });
  }

  deleteComment(event) {
    event.preventDefault();
    event.stopPropagation();
    this.hideItemsOnCanvas();

    if (confirm('Are you sure you want to delete this comment?')) {
      this.stimulate('Comment#delete_comment', event.target);
    }
  }

  deselectNotComment(event) {
    event.preventDefault();
    event.stopPropagation();

    hideCommentPanel();
    hideCommentBox();
    hideItemPanel();

    if (document.getElementById('left-template-button')) {
      Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('fill', 'black'));
      Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('stroke', ''));
    }
    Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('stroke', 'none'));
    Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('fill', 'black'));

    if (document.getElementsByClassName("share-button")[0].classList.length > 9){
      document.getElementById("shareButton").click()
    }

  }

  hideItemsOnCanvas() {
    Array.from(document.querySelectorAll('.draggable-item-container.item-type-comment, .commentPointer')).forEach((container) => {
      container.classList.add('hidden');
    });
    document.querySelector('#pen').classList.add('hidden');
    if (window.leaderLine) {
      window.leaderLine.remove();
      window.leaderLine = null;
      window.leaderLine2.remove();
      window.leaderLine2 = null;
    }
    window.Diagrams.updateDiagramPositions();
  }

  focusReply(event) {
    event.preventDefault();
    event.stopPropagation();

    Array.from(document.querySelectorAll('.replyContainer')).forEach((container) => {
      container.classList.remove('selected');
    });

    const replyContainer = event.target.closest('.replyContainer');
    replyContainer.classList.add('selected');
  }

  scrollCommentPanel() {
    if (window.leaderLine) {
      window.leaderLine.position();
      window.leaderLine2.position();
    }
    window.Diagrams.updateDiagramPositions();
  }
}
