import ApplicationController from './application_controller';
import { setButtonStates } from '../helpers/editor/history/manager';
import undo from '../helpers/editor/history/undo';
import redo from '../helpers/editor/history/redo';

export default class extends ApplicationController {
  // eslint-disable-next-line class-methods-use-this
  connect() {
    this.setButtonStates();
    super.connect();
  }

  // eslint-disable-next-line class-methods-use-this
  undo(event) {
    // Not using the buttons any more
    // if (event.target.classList.contains('disabled')) return;

    undo({
      presentationId: document.querySelector('.canvas').dataset.presentationId,
      slideId: document.querySelector('.canvasContainer .slide-container').dataset.slideId,
      userId: document.querySelector('.canvas').dataset.userId,
      controller: this,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  redo(event) {
    // if (event.target.classList.contains('disabled')) return;

    redo({
      presentationId: document.querySelector('.canvas').dataset.presentationId,
      slideId: document.querySelector('.canvasContainer .slide-container').dataset.slideId,
      userId: document.querySelector('.canvas').dataset.userId,
      controller: this,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  setButtonStates() {
    setButtonStates({
      presentationId: document.querySelector('.canvas').dataset.presentationId,
      slideId: document.querySelector('.canvasContainer .slide-container').dataset.slideId,
      userId: document.querySelector('.canvas').dataset.userId,
    });
  }
}
