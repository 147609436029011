const hideLinkPanel = () => {
    let contextMenus = document.querySelectorAll('.linkContextMenu');
    contextMenus.forEach((context_menu) => {
        context_menu.classList.add('hidden');
    });
    let linkContextCarrot = document.querySelectorAll('.linkContextCarrot');
    linkContextCarrot.forEach((carrot) => {
        carrot.classList.add('hidden');
    });
};

export default hideLinkPanel;
