import Quill from 'quill';
import SlimSelect from 'slim-select';
import ApplicationController from './application_controller';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';

export default class extends ApplicationController {
  beforeUpdateText(element) {
    console.log('text updated...');
    window.moveableForGroups.updateRect();
  }

  connect() {
    super.connect();
    this.bindInitialValues();
  }

  bindInitialValues() {
    const selectedTextTargets = document.querySelectorAll('.item-type-text.selected');
    const commonFontProperties = this._getCommonFontProperties(selectedTextTargets);

    console.log(commonFontProperties);

    if (commonFontProperties.align !== 'Mixed' && typeof commonFontProperties.align !== 'undefined') {
      document.querySelector(`.ql-align[data-alignment-value=${commonFontProperties.align}]`)
        .classList
        .add('ql-active');
    }

    ['font', 'fontweight', 'size', 'lineheight', 'letterspace'].forEach((prop) => {
      if (typeof commonFontProperties[prop] !== 'undefined') { document.querySelector(`.ql-${prop}`).value = commonFontProperties[prop].replace('px', ''); }
    });

    document.querySelector('select.ql-fontweight').style.fontFamily = document.querySelector('select.ql-font').value;

    ['italic', 'underline', 'strike'].forEach((prop) => {
      if (commonFontProperties[prop] !== 'Mixed' && typeof commonFontProperties[prop] !== 'undefined') {
        document.querySelector(`.ql-${prop}`)
          .classList
          .add('ql-active');
      }
    });

    if (commonFontProperties.list !== 'Mixed' && typeof commonFontProperties.list !== 'undefined') {
      document.querySelector(`.ql-list[data-list-type=${commonFontProperties.list}]`)
        .classList
        .add('ql-active');
    }

    document.querySelector('.text-color').style.fill = commonFontProperties.color;

    new SlimSelect({
      select: '.ql-font',
      showSearch: false,
      onChange: (info) => {
        document.querySelector('select.ql-font').style.fontFamily = info.value;
        document.querySelectorAll('.ql-fontweight')
          .forEach((el) => el.style.fontFamily = info.value);
      },
    });

    new SlimSelect({
      select: '.ql-fontweight',
      showSearch: false,
    });
  }

  async _checklement(selector) {
    while (document.querySelector(selector) === null) {
      await new Promise((resolve) => requestAnimationFrame(resolve));
    }
    return document.querySelector(selector);
  }

  changeSize(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const params = [];
    const selectionId = this.generateRandomString();

    document.querySelectorAll('.item-type-text.selected')
      .forEach((selectedTextTarget) => {
        const selectedValue = event.target.value;

        const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
        storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

        quill.formatText(0, quill.getText().length, 'size', false);
        selectedTextTarget.querySelector('.ql-editor').style.fontSize = `${selectedValue}px`;

        const content = quill.container.querySelector('.ql-editor').innerHTML;
        params.push({
          id: selectedTextTarget.dataset.itemId,
          size: selectedValue,
          content,
        });
      });

    this.stimulate('MultiItem#update_text', null, params);
  }

  toggleItalic(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const selectionId = this.generateRandomString();

    const params = [];

    document.querySelectorAll('.item-type-text.selected')
      .forEach((selectedTextTarget) => {
        const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
        storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

        quill.formatText(
          0,
          quill.getText().length,
          'italic',
          !document.querySelector('.ql-italic').classList.contains('ql-active'),
        );
        const content = quill.container.querySelector('.ql-editor').innerHTML;
        params.push({
          id: selectedTextTarget.dataset.itemId,
          content,
        });
      });

    document.querySelector('.ql-italic')
      .classList
      .toggle('ql-active');

    this.stimulate('MultiItem#update_text', null, params);
  }

  toggleUnderline(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const selectionId = this.generateRandomString();

    const params = [];

    document.querySelectorAll('.item-type-text.selected')
      .forEach((selectedTextTarget) => {
        const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
        storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

        quill.formatText(
          0,
          quill.getText().length,
          'underline',
          !document.querySelector('.ql-underline').classList.contains('ql-active'),
        );
        const content = quill.container.querySelector('.ql-editor').innerHTML;
        params.push({
          id: selectedTextTarget.dataset.itemId,
          content,
        });
      });

    document.querySelector('.ql-underline')
      .classList
      .toggle('ql-active');

    this.stimulate('MultiItem#update_text', null, params);
  }

  toggleStrike(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const selectionId = this.generateRandomString();

    const params = [];

    document.querySelectorAll('.item-type-text.selected')
      .forEach((selectedTextTarget) => {
        const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
        storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

        quill.formatText(
          0,
          quill.getText().length,
          'strike',
          !document.querySelector('.ql-strike').classList.contains('ql-active'),
        );
        const content = quill.container.querySelector('.ql-editor').innerHTML;
        params.push({
          id: selectedTextTarget.dataset.itemId,
          content,
        });
      });

    document.querySelector('.ql-strike')
      .classList
      .toggle('ql-active');

    this.stimulate('MultiItem#update_text', null, params);
  }

  selectColor(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const selectionId = this.generateRandomString();

    const params = [];

    document.querySelectorAll('.item-type-text.selected')
      .forEach((selectedTextTarget) => {
        const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
        storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

        quill.formatText(0, quill.getText().length, 'color', event.target.value);

        const content = quill.container.querySelector('.ql-editor').innerHTML;
        params.push({
          id: selectedTextTarget.dataset.itemId,
          content,
        });
      });

    this.stimulate('MultiItem#update_text', null, params);
  }

  toggleAlignment(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const selectionId = this.generateRandomString();

    const params = [];

    document.querySelectorAll('.item-type-text.selected')
      .forEach((selectedTextTarget) => {
        const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
        storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

        quill.formatText(0, quill.getText().length, 'align', event.currentTarget.dataset.alignmentValue);

        const content = quill.container.querySelector('.ql-editor').innerHTML;
        params.push({
          id: selectedTextTarget.dataset.itemId,
          content,
        });
      });

    const activeButton = document.querySelector('.ql-align.ql-active');

    if (activeButton) {
      activeButton.classList
        .remove('ql-active');
    }

    if (event.currentTarget.dataset.alignmentValue !== '') {
      document.querySelector(`.ql-align[data-alignment-value=${event.currentTarget.dataset.alignmentValue}]`)
        .classList
        .add('ql-active');
    } else {
      document.querySelector('.ql-align')
        .classList
        .add('ql-active');
    }

    this.stimulate('MultiItem#update_text', null, params);
  }

  toggleList(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const selectionId = this.generateRandomString();

    const selectedTextTargets = document.querySelectorAll('.item-type-text.selected');

    const commonFontProperties = this._getCommonFontProperties(selectedTextTargets);

    const currentListFormat = commonFontProperties.list;
    const selectedFormat = event.currentTarget.dataset.listType;
    let format;

    if (typeof currentListFormat === 'undefined' || currentListFormat !== selectedFormat) {
      format = selectedFormat;
    } else {
      format = false;
    }

    const params = [];

    selectedTextTargets.forEach((selectedTextTarget) => {
      const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
      storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

      quill.formatText(0, quill.getText().length, 'list', format);
      const content = quill.container.querySelector('.ql-editor').innerHTML;
      params.push({
        id: selectedTextTarget.dataset.itemId,
        content,
      });
    });

    document.querySelectorAll('.ql-list')
      .forEach((button) => button.classList.remove('ql-active'));
    if (format) {
      document.querySelector(`.ql-list[data-list-type=${format}]`)
        .classList
        .add('ql-active');
    }

    this.stimulate('MultiItem#update_text', null, params);
  }

  toggleLetterSpacing(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const selectionId = this.generateRandomString();

    const params = [];

    document.querySelectorAll('.item-type-text.selected')
      .forEach((selectedTextTarget) => {
        const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
        storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

        quill.formatText(0, quill.getText().length, 'letterspace', `${event.target.value}px`);

        const content = quill.container.querySelector('.ql-editor').innerHTML;
        params.push({
          id: selectedTextTarget.dataset.itemId,
          content,
        });
      });

    this.stimulate('MultiItem#update_text', null, params);
  }

  toggleLineHeight(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    document.querySelectorAll('.item-type-text.selected .text-content').forEach((el) => el.style.lineHeight = event.target.value);
    const ids = [...document.querySelectorAll('.item-type-text.selected')].map((el) => el.dataset.itemId);
    this.stimulate('MultiItem#update_text_item', null, ids, { line_height: event.target.value });
    this.beforeUpdateText(null);
  }

  toggleFontWeight(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const selectionId = this.generateRandomString();

    const params = [];

    document.querySelectorAll('.item-type-text.selected')
      .forEach((selectedTextTarget) => {
        const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
        storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

        quill.formatText(0, quill.getText().length, 'fontweight', event.target.value);

        const content = quill.container.querySelector('.ql-editor').innerHTML;
        params.push({
          id: selectedTextTarget.dataset.itemId,
          content,
        });
      });

    this.stimulate('MultiItem#update_text', null, params);
  }

  toggleFont(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const params = [];
    const selectionId = this.generateRandomString();

    document.querySelectorAll('.item-type-text.selected')
      .forEach((selectedTextTarget) => {
        const quill = Quill.find(selectedTextTarget.querySelector('.text-content'));
        storeItemSnapshotForUndo(quill.container.closest('.draggable-item-container').dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);

        quill.formatText(0, quill.getText().length, 'font', event.target.value);

        const content = quill.container.querySelector('.ql-editor').innerHTML;
        params.push({
          id: selectedTextTarget.dataset.itemId,
          content,
        });
      });

    this.stimulate('MultiItem#update_text', null, params);
  }

  stopEmptySpaceClickOnPanel(event) {
    if (event.target.classList.contains('itemPanel') || event.target.classList.contains('panelRow')) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  _getCommonFontProperties(elements) {
    const propList = ['align', 'font', 'fontweight', 'size', 'letterspace', 'italic', 'underline', 'strike', 'color', 'list'];
    const props = {};
    propList.forEach((prop) => {
      props[prop] = new Set();
    });
    props.lineheight = new Set();

    elements.forEach((element) => {
      const textContainer = element.querySelector('.text-content');
      const quill = Quill.find(textContainer);
      const format = quill.getFormat(0, quill.getText().length);

      propList.forEach((prop) => {
        if (prop === 'size') {
          format[prop] = getComputedStyle(textContainer).fontSize;
        }
        props[prop].add(format[prop]);
      });

      // Special case for line-height since the value is set on css
      props.lineheight.add(textContainer.style.lineHeight === '' ? undefined : textContainer.style.lineHeight);
    });

    const calculatedProps = {};
    Object.keys(props).forEach((prop) => (props[prop].size > 1 ? calculatedProps[prop] = 'Mixed' : calculatedProps[prop] = props[prop].values()
      .next().value));

    return calculatedProps;
  }

  generateRandomString() {
    return Math.round((Math.random() * 36 ** 12)).toString(36);
  }
}
