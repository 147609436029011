import ApplicationController from './application_controller';
import { isTouchDevice } from '../helpers/utils';
import MicroModal from 'micromodal';

export default class extends ApplicationController {
  initialize() {
    super.initialize();
    if (isTouchDevice()) {
      document.querySelector('.pan-zoom').classList.remove('hidden');
    }
  }

  togglePresentationMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('#presentationMenu').classList.toggle('hidden');
  }

  exportPresentation(event) {
    event.preventDefault();
    event.stopPropagation();

    window.location = event.target.dataset.href;
    window.hideContextMenus();
  }

  toggleRenamePanel(event) {
    event.preventDefault();
    event.stopPropagation();

    // let presentationId = event.target.closest("a").dataset.presentationId;
    document.querySelector('#presentationMenu').classList.add('hidden');
    const navigationElement = document.querySelector('#topbar .presentation-navigation');
    const titleElement = document.querySelector('#topbar .presentation-title');

    if (navigationElement.classList.contains('hidden')) {
      navigationElement.classList.remove('hidden');
      titleElement.classList.add('hidden');
    } else {
      navigationElement.classList.add('hidden');
      titleElement.classList.remove('hidden');

      const input = titleElement.querySelector('input');
      input.focus();
      input.select();
    }
  }

  handlePresentationTitleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const titleInput = document.querySelector('#input-presentation-title');
    const title = titleInput.value;

    this.stimulate('Presentation#rename', title).then(() => {
      const titleElement = document.querySelector('#topbar .presentation-title');
      const navigationElement = document.querySelector('#topbar .presentation-navigation');

      navigationElement.classList.remove('hidden');
      titleElement.classList.add('hidden');

      document.getElementById('studioName').innerText = title.length > 25 ? `${title.slice(0, 25)}...` : title;
    });
  }

  disablePanZoom() {
    document.querySelector('.pan-zoom').classList.remove('active');
    document.querySelector('#eventDisabler').classList.add('hidden');
  }

  getEmbedCode(event) {
    event.preventDefault();
    event.stopPropagation();

    MicroModal.init({
      disableFocus: true,
    });

    MicroModal.show('embed-code-modal');
    this.togglePresentationMenu(event);
  }
}
