import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  center(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemIds } = event.target.dataset;
    const targets = [];
    const selectionId = this.generateRandomString();

    this.stimulate('MultiItem#center', itemIds, selectionId).then(() => {
      setTimeout(() => {
        itemIds.split(',').forEach((itemId) => {
          let target = document.querySelector(`#draggable-item-container-${itemId}`);
          targets.push(target);
        });

        window.moveableForGroups.target = targets;
        window.moveableForGroups.updateRect();
        window.hideContextMenus();
      }, 10);
    });
  }

  maximize(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemIds } = event.target.dataset;
    const targets = [];
    const selectionId = this.generateRandomString();

    this.stimulate('MultiItem#maximize', itemIds, selectionId).then(() => {
      setTimeout(() => {
        itemIds.split(',').forEach((itemId) => {
          let target = document.querySelector(`#draggable-item-container-${itemId}`);
          targets.push(target);
        });

        window.moveableForGroups.target = targets;
        window.moveableForGroups.updateRect();
        window.hideContextMenus();
      }, 10);
    });
  }

  reset(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemIds } = event.target.dataset;
    const targets = [];
    const selectionId = this.generateRandomString();

    this.stimulate('MultiItem#reset', itemIds, selectionId).then(() => {
      setTimeout(() => {
        itemIds.split(',').forEach((itemId) => {
          let target = document.querySelector(`#draggable-item-container-${itemId}`);
          targets.push(target);
        });

        window.moveableForGroups.target = targets;
        window.moveableForGroups.updateRect();
        window.hideContextMenus();
      }, 10);
    });
  }

  delete(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemIds } = event.target.dataset;
    const selectionId = this.generateRandomString();

    this.stimulate('MultiItem#delete', itemIds, selectionId).then(() => {
      setTimeout(() => {
        window.moveableForGroups.target = null;
        window.moveableForGroups.updateRect();
        window.hideContextMenus();
      }, 10);
    });
  }

  duplicate(event) {
    event.preventDefault();
    event.stopPropagation();
    const { itemIds } = event.target.dataset;
    const targets = [];
    const selectionId = this.generateRandomString();

    this.stimulate('MultiItem#duplicate', itemIds, selectionId).then(() => {
      setTimeout(() => {
        itemIds.split(',').forEach((itemId) => {
          let target = document.querySelector(`#draggable-item-container-${itemId}`);
          targets.push(target);
        });

        window.moveableForGroups.target = targets;
        window.moveableForGroups.updateRect();
        window.hideContextMenus();
      }, 10);
    });
  }

  bringForward(event) {
    event.preventDefault();
    event.stopPropagation();

    const targets = [];
    const randomSelectId = this.generateRandomString();
    const selectedItems = this.selectedItems();
    const selectedItemIds = this.selectedItemIds();

    this.selectedItemIds().forEach((itemId) => {
      storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM', randomSelectId);
    });

    this.stimulate('MultiItem#bring_forward', JSON.stringify(selectedItemIds)).then(() => {
      setTimeout(() => {
        selectedItems.forEach((item) => {
          targets.push(item);
        });

        window.moveableForGroups.target = targets;
        window.moveableForGroups.updateRect();
        window.hideContextMenus();
      }, 10);
    });
  }

  bringToFront(event) {
    event.preventDefault();
    event.stopPropagation();

    const targets = [];
    const randomSelectId = this.generateRandomString();
    const selectedItems = this.selectedItems();
    const selectedItemIds = this.selectedItemIds();

    this.selectedItemIds().forEach((itemId) => {
      storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM', randomSelectId);
    });

    this.stimulate('MultiItem#bring_to_front', JSON.stringify(selectedItemIds)).then(() => {
      setTimeout(() => {
        selectedItems.forEach((item) => {
          targets.push(item);
        });

        window.moveableForGroups.target = targets;
        window.moveableForGroups.updateRect();
        window.hideContextMenus();
      }, 10);
    });
  }

  sendBackward(event) {
    event.preventDefault();
    event.stopPropagation();

    const targets = [];
    const randomSelectId = this.generateRandomString();
    const selectedItems = this.selectedItems();
    const selectedItemIds = this.selectedItemIds();

    this.selectedItemIds().forEach((itemId) => {
      storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM', randomSelectId);
    });

    this.stimulate('MultiItem#send_backward', JSON.stringify(selectedItemIds)).then(() => {
      setTimeout(() => {
        selectedItems.forEach((item) => {
          targets.push(item);
        });

        window.moveableForGroups.target = targets;
        window.moveableForGroups.updateRect();
        window.hideContextMenus();
      }, 10);
    });
  }

  sendToBack(event) {
    event.preventDefault();
    event.stopPropagation();

    const targets = [];
    const randomSelectId = this.generateRandomString();
    const selectedItems = this.selectedItems();
    const selectedItemIds = this.selectedItemIds();

    this.selectedItemIds().forEach((itemId) => {
      storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM', randomSelectId);
    });

    this.stimulate('MultiItem#send_to_back', JSON.stringify(selectedItemIds)).then(() => {
      setTimeout(() => {
        selectedItems.forEach((item) => {
          targets.push(item);
        });

        window.moveableForGroups.target = targets;
        window.moveableForGroups.updateRect();
        window.hideContextMenus();
      }, 10);
    });
  }

  generateRandomString() {
    return Math.round((Math.random() * 36 ** 12)).toString(36);
  }

  selectedItems() {
    return document.querySelectorAll('.item-container .selected');
  }

  selectedItemIds() {
    return Array.from(this.selectedItems()).map((div) => div.dataset.itemId);
  }
}
