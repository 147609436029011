import updateRect from '../updateRect';
import setDataAttributes from '../setDataAttributes';
import calculateCropAttributes from './calculateCropAttributes';

const setWidthForText = (item, width) => {
  if (width <= 0) {
    return;
  }

  const frame = window.mapItems.get(item);

  frame.set('width', width);
  item.style.width = `${width}px`;
  item.style.cssText += frame.toCSS();

  setDataAttributes(item.dataset.itemId);

  updateRect(item.dataset.itemId);
  window.hideContextMenus();
};

const setDimension = (itemId, width, height, retainProportions, whatChanged) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;
  let newWidth = parseFloat(width || 0, 0);
  let newHeight = parseFloat(height || 0, 0);

  if (target.dataset.itemType === 'text') {
    setWidthForText(target, newWidth);
    return;
  }

  if (newWidth <= 0 || newHeight <= 0 || isNaN(newWidth) || isNaN(newHeight)) {
    return;
  }

  if (retainProportions || target.dataset.hasCrop === 'true') {
    const originalWidth = parseFloat(target.dataset.width, 0);
    const originalHeight = parseFloat(target.dataset.height, 0);

    if (whatChanged === 'width') {
      newHeight = originalHeight * (newWidth / originalWidth);
    } else if (whatChanged === 'height') {
      newWidth = originalWidth * (newHeight / originalHeight);
    }
  }

  const frame = window.mapItems.get(target);

  frame.set('width', newWidth);
  frame.set('height', newHeight);
  target.style.width = `${newWidth}px`;
  target.style.height = `${newHeight}px`;

  target.style.cssText += frame.toCSS();

  if (target.dataset.itemShape === 'rect') {
    const svg = target.querySelector('svg');
    svg.setAttribute('viewBox', `0 0 ${newWidth} ${newHeight}`);

    const rects = svg.querySelectorAll('rect');
    rects.forEach((rect) => {
      rect.setAttribute('width', newWidth);
      rect.setAttribute('height', newHeight);
    });
  }

  const moveableContainer = target.querySelector('.moveableContainer');
  if (moveableContainer) {
    moveableContainer.style.width = `${newWidth}px`;
    moveableContainer.style.height = `${newHeight}px`;
  }

  calculateCropAttributes(target);
  setDataAttributes(target.dataset.itemId);

  updateRect(itemId);
  window.hideContextMenus();
};

export default setDimension;
