function handleClick(e) {
  e.preventDefault();
  e.stopImmediatePropagation();
  const items = document.querySelectorAll('.item-container .selected');
  const rightClick = new MouseEvent('contextmenu', {
    bubbles: true,
    cancelable: false,
    view: window,
    button: 2,
    buttons: 0,
    clientX: e.target.getBoundingClientRect().x,
    clientY: e.target.getBoundingClientRect().y,
  });
  if (items.length > 1) {
    document.querySelector('.moveable-area').dispatchEvent(rightClick);
  } else {
    items[0].dispatchEvent(rightClick);
  }
}

const menuAble = {
  name: 'dropdown',
  render(moveable, React) {
    const { renderPoses } = moveable.state;
    return React.createElement(
      'div',
      {
        onClick: handleClick,
        onTouchEnd: handleClick,
        class: 'moveable-dropdown',
        style: {
          position: 'absolute',
          transform: `translate(50%, -5px) translate(${renderPoses[1][0]}px, ${renderPoses[1][1]}px) translateZ(-50px)`,
          zIndex: 200,
          cursor: 'pointer',
        },
      },
      React.createElement('i', { class: 'fas fa-bars' }),
    );
  },
};

export default menuAble;
