/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ["childTabTemplateTemplate", "addNewChildTabTemplateLink"]

  connect() {
  }

  addNewChildTabTemplate(event) {
    event.preventDefault();
    const id = new Date().getTime();
    var content = this.childTabTemplateTemplateTarget.innerHTML.replace(/CHILD_TAB_TEMPLATE_TMPL/g, id);
    this.addNewChildTabTemplateLinkTarget.insertAdjacentHTML('beforebegin', content)
    window.activateSlimSelect();
  }

  removeChildTabTemplate(event) {
    const childTabTemplateForm = event.target.parentElement;
    childTabTemplateForm.querySelector("input[name*='_destroy']").value = 1;
    childTabTemplateForm.style.display = 'none';
  }
}
