import updateRect from '../updateRect';
import itemType from '../itemType';

const setShapeStyle = (itemId, borderStyle, borderSize, borderRadius, borderColor) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);

  const strokeSize = borderSize * 2; // stroke-width=
  const strokeColor = borderColor; // stroke=
  let strokeDasharray; // stroke-dasharray=
  switch (borderStyle) {
    case 'dashed':
      strokeDasharray = '5';
      break;
    case 'dotted':
      strokeDasharray = '2';
      break;
    default:
      strokeDasharray = '0';
      break;
  }

  switch (target.dataset.itemShape) {
    case 'circle':
      target.querySelectorAll('circle').forEach((item) => {
        item.setAttribute('stroke-width', strokeSize);
        item.setAttribute('stroke-dasharray', strokeDasharray);
        item.setAttribute('stroke', strokeColor);
      });
      break;
    case 'pentagon':
    case 'octagon':
    case 'star':
    case 'triangle':
      target.querySelectorAll('polygon').forEach((item) => {
        item.setAttribute('stroke-width', strokeSize);
        item.setAttribute('stroke-dasharray', strokeDasharray);
        item.setAttribute('stroke', strokeColor);
      });
      break;
    case 'rect':
      target.querySelectorAll('rect').forEach((item) => {
        item.setAttribute('stroke-width', strokeSize);
        item.setAttribute('stroke-dasharray', strokeDasharray);
        item.setAttribute('stroke', strokeColor);
        item.setAttribute('rx', borderRadius);
        item.setAttribute('ry', borderRadius);
      });
      break;
    default:
      break;
  }
};

const setBorder = (itemIds, hasBorder, borderStyle, borderSize, borderRadius, borderColor) => {
  itemIds.forEach((itemId) => {
    const target = document.querySelector(`#draggable-item-container-${itemId}`);
    if (!target) return;

    const borderableContent = target.querySelector('.borderableContent');
    if (!borderableContent) return;

    if (hasBorder && itemType(itemId) === 'shape') {
      setShapeStyle(itemId, borderStyle, borderSize, borderRadius, borderColor);
    } else if (hasBorder) {
      borderableContent.style.border = `${borderSize}px ${borderStyle} ${borderColor}`;
      borderableContent.style.borderRadius = `${borderRadius}px`;
    } else if (itemType(itemId) === 'shape') {
      setShapeStyle(itemId, borderStyle, 0, borderRadius, borderColor);
    } else {
      borderableContent.style.border = 'none';
      borderableContent.style.borderRadius = '0px';
    }

    target.dataset.hasBorder = hasBorder ? 1 : 0;
    target.dataset.borderStyle = borderStyle;
    target.dataset.borderSize = borderSize;
    target.dataset.borderRadius = borderRadius;
    target.dataset.borderColor = borderColor;

    updateRect(itemId);
  });
};

export default setBorder;
