import {
  ITEM_ACTIONS,
  getHistory,
  addFuture,
  setButtonStates,
} from './manager';
import batchSave from '../items/batchSave';
import deleteItems from '../items/actions/delete';
import undeleteItems from '../items/actions/undelete';
import updateFrame from '../items/updateFrame';

function contextExists(context) {
  return Object.keys(context).length !== 0;
}

const undoItem = (params) => {
  const pastHistories = getHistory({ ...params, key: 'Past' });
  if (pastHistories.length > 0) {
    let popSize = 1;
    if (pastHistories[pastHistories.length - 1].selectionId) {
      const { selectionId } = pastHistories[pastHistories.length - 1];
      popSize = pastHistories.filter((ph) => ph.selectionId === selectionId).length;
    }
    const groupedHistories = pastHistories.splice(-popSize, popSize);
    let newFutureItem;
    const updatedItemIds = [];
    const deletedItemIds = [];
    const addedItemIds = [];

    let allowBatchUpdate = false;
    groupedHistories.forEach((pastHistory) => {
      // Item Actions
      if (ITEM_ACTIONS.includes(pastHistory.actionType)) {
        if (pastHistory.actionType === 'DELETE_SLIDE_ITEM') {
          deletedItemIds.push(pastHistory.contentId);
        } else if (pastHistory.actionType === 'ADD_SLIDE_ITEM') {
          deleteItems(pastHistory.contentId);
          window.moveableForItems.target = null;
          window.moveableForGroups.target = null;
          window.moveableForText.target = null;
          addedItemIds.push({ itemId: pastHistory.contentId });
        } else {
          allowBatchUpdate = true;
          const historyItem = pastHistory.content;
          const updatedItem = document.querySelector(`.canvasContainer #item-container-${pastHistory.contentId}`);
          newFutureItem = updatedItem.cloneNode(true);
          // Update item
          if (pastHistory.actionType === 'UPDATE_SLIDE_ITEM') {
            updatedItem.innerHTML = historyItem;
            updateFrame(updatedItem.querySelector('.draggable-item-container').dataset.itemId);
          }
          updatedItemIds.push(pastHistory.contentId);
        }
      }

      addFuture({
        presentationId: params.presentationId,
        slideId: params.slideId,
        userId: params.userId,
        actionType: pastHistory.actionType,
        contentType: 'Item',
        content: newFutureItem ? newFutureItem.innerHTML : '',
        contentId: pastHistory.contentId,
        selectionId: pastHistory.selectionId,
      });
    });

    if (deletedItemIds.length > 0) {
      undeleteItems(params, deletedItemIds);
    }

    if (addedItemIds.length > 0) {
      params.controller.stimulate('Item#delete', addedItemIds);
    }

    if (allowBatchUpdate) {
      batchSave(updatedItemIds, params.controller);
    }

    // Remove from past
    localStorage.setItem(
      `Presentations:${params.presentationId}:Slide:${params.slideId}:User:${params.userId}:History:Past`,
      JSON.stringify(pastHistories),
    );

    setButtonStates(params);
  }
};

export default undoItem;
