/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import Sortable from 'sortablejs';
import { htmlToElement } from '.././helpers/dom';

export default class extends Controller {
  static targets = [ "skillCategoryTemplate", "skillCategoriesFormContainer", "skillCategoryName", "skillCategoryForm" ]

  connect() {
    if (this.hasSkillCategoryFormTarget) {
      // Show the first skill category while all others are hiding
      this.skillCategoryFormTargets[0].classList.toggle('hidden');
    }

    // Make the skill category selectors sortable
    const sortable = document.querySelector('#skill-categories-sortable.sortable');
    var el = Sortable.create(sortable, {
      animation: 150,
      draggable: "li.btn",
      filter: ".ignore-sort",
      forceFallback: true,
      onEnd: function(event) {
        let skillCategoryId = event.item.getAttribute('data-skill-category-id');
        document.querySelector(`[data-skill-category-id="${skillCategoryId}"] input.position`).value = event.newIndex + 1;
      }
    });

    // Highlight initial skill category
    document.querySelector('#skill-categories-sortable.sortable li').classList.add('btn-pink');
    window.expandableTextArea();
  }

  switchTo(event) {
    this.skillCategoryFormTargets.forEach((el, i) => {
      el.classList.toggle("hidden", el.dataset.skillCategoryId != event.target.dataset.skillCategoryId)
    });

    // Highlight selected tab
    document.querySelector('#skill-categories-sortable.sortable li.btn-pink').classList.remove('btn-pink');
    event.target.classList.add('btn-pink');
    window.expandableTextArea();
  }

  addNewSkillCategory(event) {
    const id = new Date().getTime();
    const template = `<li class='inline-block mr-10 mb-14 btn' data-target='schools--skill-categories.skillCategoryName' data-action='click->schools--skill-categories#switchTo' data-skill-category-id='${id}'>New Skill Category</li>`
    const newSkillCategoryLink = htmlToElement(template);
    const skillCategoryParent = document.getElementById("skill-categories-sortable");
    skillCategoryParent.insertAdjacentElement("beforeend", newSkillCategoryLink);
    newSkillCategoryLink.addEventListener("click", (ev) => {
      this.switchTo(ev);
    });
    const newSkillCategoryForm = htmlToElement(this.skillCategoryTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, id));
    newSkillCategoryForm.dataset.skillCategoryId = id;
    this.skillCategoriesFormContainerTarget.insertAdjacentElement('beforeend', newSkillCategoryForm);

    window.expandableTextArea();
    newSkillCategoryLink.click();
  }

  onSubDivisionChanged(event) {
    let text = "You're about to change the number of subdivisions. It affects the transcript layout. Are you sure?";
    if (!confirm(text) == true) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  updateTitle(event) {
    const skillCategoryId = event.target.parentElement.parentElement.parentElement.dataset.skillCategoryId;
    this.skillCategoryNameTargets.find(el => el.dataset.skillCategoryId  == skillCategoryId).innerText = event.target.value;
  }

  removeSkillCategory(event) {
    if (event.currentTarget.dataset.hasGrade === 'yes') {
      if (confirm('This skill category has grading data. Are you sure to delete?')) {
        this.removeSkillCategoryFromList(event)
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      this.removeSkillCategoryFromList(event)
    }
  }

  removeSkillCategoryFromList(event) {
    const skillCategoryForm = event.target.parentElement;
    const skillCategoryId = skillCategoryForm.dataset.skillCategoryId;
    this.skillCategoryNameTargets.find(el => el.dataset.skillCategoryId  == skillCategoryId).style.display = 'none';
    skillCategoryForm.querySelector("input[name*='_destroy']").value = 1;
    skillCategoryForm.style.display = 'none';
  }

  _insertBefore(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode);
  }

  _insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }
}
