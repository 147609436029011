import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    const scrollpos = sessionStorage.getItem('scrollpos');
    if (scrollpos) {
      window.scrollTo(0, scrollpos);
      sessionStorage.removeItem('scrollpos');
    }
  }

  connect() {
    window.activateSlimSelect();
  }

  closeAll(event) {
    const menus = document.getElementsByClassName('menu');
    for (const menu of menus) {
      menu.classList.add('hidden');
    }

    const drawerMenus = document.getElementsByClassName('drawer-menu');
    if (!event.target.closest('.drawer-menu') && !event.target.classList.contains('drawer-menu-opener') && event.target.tagName != 'LI') {
      for (const drawerMenu of drawerMenus) {
        drawerMenu.classList.add('hidden');
      }
    }
  }

  keyPress(event) {
    if (event.code === 'Escape') {
      this.closeAll(event);
      window.closeMediaBrowser();
    }
  }

  openSubMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    const menu = event.target.closest('.action-menu').querySelector('.menu');
    menu.classList.toggle('hidden');
  }

  redirectToEditorWithScrollPos(event) {
    sessionStorage.setItem('scrollpos', window.scrollY);
  }

  closeModal(event) {
    if (event.target.parentElement.id === 'modal-content') {
      event.target.parentElement.innerHTML = '';
    }
  }

  notify(event) {
    window.notice(event.detail.message, 10000, true, event.detail.animate);
  }
}
