import Quill from 'quill';
import { debounce } from 'lodash';
import SlimSelect from 'slim-select';
import ApplicationController from './application_controller';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';

export default class extends ApplicationController {

  currentSelectionStart;
  isCellSelectionDragging = false;
  isTableResizing = false;

  connect() {
    super.connect();

    if (this.element.id === 'toolbar') {
      new SlimSelect({
        select: '.ql-font',
        showSearch: false,
        onChange: (info) => {
          document.querySelector('select.ql-font').style.fontFamily = info.value;
          document.querySelectorAll('.ql-fontweight')
            .forEach((el) => el.style.fontFamily = info.value);
        },
      });

      new SlimSelect({
        select: '.ql-fontweight',
        showSearch: false,
      });

      const table = document.querySelector('.item-type-grid.selected').querySelector('table');
      if (table) {
        const cell = table.rows[0].cells[0];
        this.bindPanelValuesForTable(this.element, table, cell);
      }
    } else if(this.element.querySelector('table')) {
      const table = this.element.querySelector('table');
      this._resizableGrid(table);
    }
  }

  create(event) {
    document.querySelector('#pen').pen.deactivate();
    this.stimulate('Item#create_table', { resolveLate: true }).then((payload) => {
      window.moveableForGroups.target = null;
      window.moveableForText.target = null;
      window.moveableForLineHandle.target = null;
      window.moveableForLineBody.target = null;

      const item = document.querySelector('.item-type-grid.selected');
      storeItemSnapshotForUndo(item.dataset.itemId, 'ADD_SLIDE_ITEM');

      window.moveableForItems.target = item;
    });
  }

  bindPanelValuesForTable(panel, table, cell) {
    const td = cell;
    const tdStyle = getComputedStyle(td);
    const contentCell = td.querySelector('.table-cell-content');
    const contentStyle = getComputedStyle(contentCell);
    if(!panel) {
      return
    }
    panel.querySelector('#item_padding').value = parseInt(contentStyle.padding);
    panel.querySelector('#border_width').value = parseInt(tdStyle.borderWidth);
    panel.querySelector('.border-color').style.fill = td.style.borderColor === '' ? '#000' : td.style.borderColor;

    if(td.style.borderColor == 'transparent') {
        const checkerTemplate = '<mask id="mask_bg_color" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">\n'
          + '                <circle cx="7" cy="7" r="6.5" fill="white" stroke="white"/>\n'
          + '              </mask>\n'
          + '              <g mask="url(#mask_bg_color)">\n'
          + '                <rect x="12.7144" y="6.42859" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="12.7144" y="0.142822" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="12.7144" y="12.7142" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="9.57129" y="3.28577" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="9.57129" y="9.57141" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="6.42822" y="6.42859" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="6.42822" y="0.142822" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="6.42822" y="12.7142" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="3.28516" y="3.28577" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="3.28516" y="9.57141" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="0.143066" y="6.42859" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="0.143066" y="0.142822" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="0.143066" y="12.7142" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
          + '                <rect x="9.57129" y="6.42859" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="9.57129" y="0.142822" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="9.57129" y="12.7142" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="6.42822" y="3.28577" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="6.42822" y="9.57141" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="12.7144" y="3.28577" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="12.7144" y="9.57141" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="3.28516" y="6.42859" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="3.28516" y="0.142822" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="3.28516" y="12.7142" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="0.143066" y="3.28577" width="3.14286" height="3.14286" fill="white"/>\n'
          + '                <rect x="0.143066" y="9.57141" width="3.14286" height="3.14286" fill="white"/>\n'
          + '              </g>';
        panel.querySelector('.border-color').insertAdjacentHTML('afterend', checkerTemplate);
    }

    const tableStyle = getComputedStyle(table);

    const fontSelect = document.querySelector('select.ql-font');
    if(fontSelect.slim) {
      fontSelect.slim.destroy();
    }
    fontSelect.value = contentStyle.fontFamily.replaceAll('"', '');

    const fontWeightSelect = document.querySelector('select.ql-fontweight');
    if(fontWeightSelect.slim) {
      fontWeightSelect.slim.destroy();
    }

    // Safari returns 'normal' or 'bold' for font-weight instead of numerical values
    let fontWeight = contentStyle.fontWeight
    if(fontWeight === 'normal') {
      fontWeight = 400;
    } else if(fontWeight === 'bold') {
      fontWeight = 700;
    }

    fontWeightSelect.value = fontWeight;
    fontWeightSelect.style.fontFamily = fontSelect.style.fontFamily;

    new SlimSelect({
      select: '.ql-font',
      showSearch: false,
      onChange: info => {
        document.querySelector('select.ql-font').style.fontFamily = info.value;
        document.querySelectorAll('.ql-fontweight').forEach(el => el.style.fontFamily = info.value);
      }
    })

    new SlimSelect({
      select: '.ql-fontweight',
      showSearch: false
    });

    panel.querySelector('.ql-size').value = parseInt(contentStyle.fontSize);

    panel.querySelector('.text-color').style.fill = contentStyle.color;

    const bgColor = panel.querySelector('.bg-color');
    bgColor.style.fill = tdStyle.backgroundColor;
    if(bgColor.style.fill !== "rgba(0, 0, 0, 0)") {
      const mask = bgColor.parentElement.querySelector('g');
      if (mask) {
        mask.remove();
      }
    }

    if(contentCell.style.lineHeight !== '') {
      panel.querySelector('.ql-lineheight').value = contentCell.style.lineHeight;
    }
    if(contentCell.style.letterSpacing !== '') {
      panel.querySelector('.ql-letterspace').value = parseInt(contentCell.style.letterSpacing);
    }
  }

  // Placeholder for updating table container
  // when table size is changed
  beforeUpdateTable(element) {
  }

  afterUpdateTable(element) {
    const selected = document.querySelector('.item-type-grid.selected');
    if(selected) {
      const table = selected.querySelector('table')
      this.adjustResizers(table);
    }
  }

  click(event) {
    if(event.button !== 0) {
      return;
    }

    if(this.isCellSelectionDragging) {
      this.isCellSelectionDragging = false;
      return;
    }

    const { target } = event;
    const selectedItem = document.querySelector('.item-type-grid.selected');

    if(selectedItem && selectedItem.dataset.moved === '1') {
      return;
    }

    const editorContainer = target.closest('.table-cell-content');
    const cell = editorContainer.parentElement;
    const table = cell.closest('table');

    // Inline editing
    if (cell.querySelector('.ql-editor[contenteditable="true"]')) {
      return;
    }

    if (cell.classList.contains('selected')) {
      event.preventDefault();
      event.stopPropagation();

      table.querySelectorAll('.table-cell-content')
        .forEach((tableCellContent) => {
          tableCellContent.parentElement.classList.remove('selected');
        });
      // Disable all quill instances on all cells
      table.querySelectorAll('.ql-editor[contenteditable="true"]')
        .forEach((el) => {
          const elCell = el.parentElement;
          elCell.innerHTML = el.innerHTML;
        });

      // const panel = document.querySelector('#toolbar');
      // const panelClone = panel.cloneNode(true);
      //
      // panel.parentNode.replaceChild(panelClone, panel);

      // const fontWeightSelect = document.querySelector('.ql-fontweight');
      // const clone = fontWeightSelect.cloneNode(true);
      // fontWeightSelect.parentNode.replaceChild(clone, fontWeightSelect);

      const quill = new Quill(editorContainer, {
        modules: {
          clipboard: {
            matchVisual: false,
          },
        },
      });
      quill.on('text-change',
        debounce(() => {
          this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
        }, 200));
      // }

      quill.enable();
      quill.focus();
      quill.setSelection(quill.getText().length);
    } else {
      table.querySelectorAll('.table-cell-content')
        .forEach((tableCellContent) => {
          tableCellContent.parentElement.classList.remove('selected');
          const editor = tableCellContent.querySelector('.ql-editor');
          if (editor) {
            tableCellContent.innerHTML = editor.innerHTML;
          }
        });
      cell.classList.add('selected');
      this.currentSelectionStart = cell;
      const panel = document.querySelector('#toolbar');
      this.bindPanelValuesForTable(panel, table, cell);
      window.moveableForItems.target = null;
    }
  }

  activateQuill(event) {
    if(event.target.getAttribute('contenteditable') === 'true') {
      return;
    }
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');
    const editorContainer = selectedItem.querySelector('td.selected .table-cell-content');
    const quill = new Quill(editorContainer, {
      modules: {
        clipboard: {
          matchVisual: false,
        },
      },
    });
    quill.on('text-change',
        debounce(() => {
          this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
        }, 200));
    // }

    quill.enable();
    quill.focus();
    quill.setSelection(quill.getText().length);
  }

  selectBackgroundColor(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.style.backgroundColor = event.target.value;
      })
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('td')
        .forEach((el) => {
          el.style.backgroundColor = event.target.value;
        });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  selectFontColor(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    // Inline editing
    const quillContainer = table.querySelector('.ql-editor[contenteditable="true"]')
    if (quillContainer) {
      let quill = Quill.find(quillContainer.parentElement);
      var range = quill.getSelection();
      if (range) {
        if (range.length == 0) {
          console.log('User cursor is at index', range.index);
        } else {
          var text = quill.getText(range.index, range.length);
          quill.formatText(range.index, range.length, 'color', event.target.value);
        }
      }
      return;
    }

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        for(let node of cell.getElementsByTagName("*")) {
          node.style.color = null
        }
        cell.style.color = event.target.value;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('td')
        .forEach((el) => {
          el.style.color = event.target.value;
        });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  changeSize(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    // Inline editing
    const quillContainer = table.querySelector('.ql-editor[contenteditable="true"]')
    if (quillContainer) {
      let quill = Quill.find(quillContainer.parentElement);
      var range = quill.getSelection();
      if (range) {
        if (range.length == 0) {
          console.log('User cursor is at index', range.index);
        } else {
          var text = quill.getText(range.index, range.length);
          quill.formatText(range.index, range.length, 'size', event.target.value);
        }
      }
      return;
    }

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        for(let node of cell.getElementsByTagName("*")) {
          node.style.fontSize = null
        }
        cell.style.fontSize = `${event.target.value}px`;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('td')
        .forEach((el) => {
          el.style.fontSize = null;
        });
      table.style.fontSize = `${event.target.value}px`;
      window.moveableForItems.updateRect();
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  changePadding(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.querySelector('.table-cell-content').style.padding = `${event.target.value}px`;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('.table-cell-content')
        .forEach((el) => {
          el.style.padding = `${event.target.value}px`;
        });

      window.moveableForItems.updateRect();
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  changeBorderWidth(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    storeItemSnapshotForUndo(selectedItem.dataset.itemId, 'UPDATE_SLIDE_ITEM');

    const table = selectedItem.querySelector('table');

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.style.borderWidth = `${event.target.value}px`;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('td')
        .forEach((el) => {
          el.style.borderWidth = `${event.target.value}px`;
        });

      window.moveableForItems.updateRect();
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  selectBorderColor(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.style.borderColor = event.target.value;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('td')
        .forEach((el) => {
          el.style.borderColor = event.target.value;
        });

      window.moveableForItems.updateRect();
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  setBorderStyle(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.style.borderStyle = event.target.value;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('td')
        .forEach((el) => {
          el.style.borderStyle = event.target.value;
        });

      window.moveableForItems.updateRect();
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  toggleFont(event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.style.fontFamily = event.target.value;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('td')
        .forEach((el) => {
          el.style.removeProperty('font-family');
        });

      table.style.fontFamily = event.target.value;
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  toggleFontWeight(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    // Inline editing
    const quillContainer = table.querySelector('.ql-editor[contenteditable="true"]')
    if (quillContainer) {
      return;
    }

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        for(let node of cell.getElementsByTagName("*")) {
          node.style.fontWeight = null;
        }
        cell.style.fontWeight = event.target.value;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('td')
        .forEach((el) => {
          el.style.removeProperty('font-weight');
        });

      table.style.fontWeight = event.target.value;
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  toggleAlignment(event) {
    const align = event.currentTarget.dataset.alignmentValue;
    let props;
    if (align === 'left') {
      props = { 'text-align': 'left', 'align-items': 'flex-start' };
    } else if (align === 'center') {
      props = { 'text-align': 'center', 'align-items': 'center' };
    } else if (align === 'right') {
      props = { 'text-align': 'right', 'align-items': 'flex-end' };
    }

    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        Object.assign(cell.querySelector('.table-cell-content').style, props);
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('td')
        .forEach((el) => {
          Object.assign(el.querySelector('.table-cell-content').style, props);
        });

      window.moveableForItems.updateRect();
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  toggleLineHeight(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.querySelector('.table-cell-content').style.lineHeight = `${event.target.value}`;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('.table-cell-content')
          .forEach((el) => {
            el.style.lineHeight = `${event.target.value}`;
          });

      window.moveableForItems.updateRect();
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  toggleLetterSpacing(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    const cells = document.querySelectorAll('table td.selected');
    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.querySelector('.table-cell-content').style.letterSpacing = `${event.target.value}px`;
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }

    if (selectedItem) {
      selectedItem.querySelectorAll('.table-cell-content')
          .forEach((el) => {
            el.style.letterSpacing = `${event.target.value}px`;
          });

      window.moveableForItems.updateRect();
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
    }
  }

  toggleItalic(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    // Inline editing
    const quillContainer = table.querySelector('.ql-editor[contenteditable="true"]')
    if (quillContainer) {
      return;
    }

    let cells = document.querySelectorAll('table td.selected');

    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.style.fontStyle = cell.style.fontStyle == 'italic' ? 'normal' : 'italic';
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }
  }

  toggleUnderline(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    // Inline editing
    const quillContainer = table.querySelector('.ql-editor[contenteditable="true"]')
    if (quillContainer) {
      return;
    }

    let cells = document.querySelectorAll('table td.selected');

    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.style.textDecoration = cell.style.textDecoration == 'underline' ? 'none' : 'underline'
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }
  }

  toggleStrike(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    // Inline editing
    const quillContainer = table.querySelector('.ql-editor[contenteditable="true"]')
    if (quillContainer) {
      return;
    }

    let cells = document.querySelectorAll('table td.selected');

    if (cells.length > 0) {
      cells.forEach((cell) => {
        cell.style.textDecoration = cell.style.textDecoration == 'line-through' ? 'none' : 'line-through'
      });
      this.stimulate('Item#update_table', selectedItem.dataset.itemId, this._getTableHTML(table));
      return;
    }
  }

  toggleList(event) {
    const selectedItem = document.querySelector('.item-type-grid.selected');
    const table = selectedItem.querySelector('table');

    // Inline editing
    const quillContainer = table.querySelector('.ql-editor[contenteditable="true"]')
    if (quillContainer) {
      let quill = Quill.find(quillContainer.parentElement);
      const range = quill.getSelection();
      if (range) {
        const currentListFormat = quill.getFormat(range).list;
        const selectedFormat = event.currentTarget.dataset.listType;
        let format;
        if (typeof currentListFormat === 'undefined' || currentListFormat !== selectedFormat) {
          format = selectedFormat;
        } else {
          format = false;
        }

        quill.formatLine(range.index, range.length, 'list', format);

      }
    }
  }

  _getTableHTML(table) {
    const clone = table.cloneNode(true);

    clone.querySelectorAll('.resizer').forEach((resizer) => resizer.remove());
    clone.querySelectorAll('.ql-editor[contenteditable="true"]')
      .forEach((el) => {
        const elCell = el.parentElement;
        elCell.innerHTML = el.innerHTML;
      });

    return clone.outerHTML.replaceAll('selected', '');
  }

  _resizableGrid(table) {
    const resizer = table.querySelector('.resizer');
    if (resizer) {
      return;
    }

    console.log('initializing resizer');

    const rows = table.getElementsByTagName('tr');
    const row = rows[0];
    const cols = row ? row.children : undefined;
    if (!cols) return;
    // Loop over them
    [].forEach.call(cols, (col) => {
      // Create a resizer element
      const resizer = document.createElement('div');
      resizer.classList.add('resizer');

      // Set the height
      resizer.style.height = `${table.offsetHeight}px`;

      // Adjust position based on the border width of td
      const width = parseInt(col.style.borderWidth);
      resizer.style.transform = `translate(${1 + width}px ,${-width}px)`


      // Add a resizer element to the column
      col.appendChild(resizer);

      // Will be implemented in the next section
      this._createResizableColumn(table, col, resizer);
    });

    let currentPosForResizer = 0;
    for (let i = 0; i < rows.length; i++) {
        // Create a resizer element
        const resizer = document.createElement('div');
        resizer.classList.add('row-resizer');

        currentPosForResizer = currentPosForResizer +  rows[i].children[0].offsetHeight;
        resizer.style.transform = `translateY(${currentPosForResizer}px`

        table.parentElement.querySelector('.row-resizers').appendChild(resizer);
        this._createResizableRow(table, i, resizer)
    }
  }

  _createResizableRow(table, index, resizer) {
    const controller = this
    let y = 0
    let h = 0
    let resizerPos = [];

    const mouseDownHandler = function (e) {
      // Don't do anything if it's a right click
      if(e.button !== 0) {
        return
      }

      e.preventDefault();
      e.stopPropagation();

      controller.isTableResizing = true;

      // Get the current mouse position
      y = e.clientY;

      // Calculate the current height of row
      // const styles = window.getComputedStyle(col);
      // w = parseInt(styles.width, 10);
      h = parseInt(getComputedStyle(table.rows[index].querySelector('.table-cell-content')).height);

      resizerPos = [...table.parentElement.querySelectorAll('.row-resizer')].map(resizer => new DOMMatrixReadOnly(getComputedStyle(resizer).transform).m42)

      // Attach listeners for document's events
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      resizer.classList.add('resizing');
      // Determine how far the mouse has been moved
      const dy = e.clientY - y;

      // Update the height of column
      // if(h + dy < parseFloat(getComputedStyle(table.rows[index].cells[0]).height)) {
      //   return;
      // }

      table.rows[index].querySelectorAll('.table-cell-content').forEach((cell) => {
        cell.style.minHeight = `${h + dy}px`
      })

      const resizers =  table.parentElement.querySelectorAll('.row-resizer');
      const currentResizerIndex = [].indexOf.call(resizers, resizer)

      for (let i = currentResizerIndex; i < resizerPos.length; i++) {
        resizers[i].style.transform = `translateY(${resizerPos[i] + dy}px)`
      }

    };

    const mouseUpHandler = function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();

      controller.isTableResizing = false;

      resizer.classList.remove('resizing');
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      if (window.moveableForItems.target) {
        // window.moveableForItems.target.style.height = `${table.scrollHeight}px`;
        window.moveableForItems.updateRect();
      }
      const currentItem = table.closest('.item-type-grid');
      currentItem.classList.add('selected');
      controller.stimulate('Item#update_table', currentItem.dataset.itemId, controller._getTableHTML(table));
    };

    resizer.addEventListener('mousedown', mouseDownHandler);
  }

  _createResizableColumn(table, col, resizer) {
    // Track the current position of mouse
    const controller = this;
    let x = 0;
    let w = 0;
    let gridSize;

    const mouseDownHandler = function (e) {
      if(e.button !== 0) {
        return
      }
      e.preventDefault();
      e.stopPropagation();

      controller.isTableResizing = true;

      // Get the current mouse position
      x = e.clientX;

      // Calculate the current width of column
      // const styles = window.getComputedStyle(col);
      // w = parseInt(styles.width, 10);
      gridSize = table.style.gridTemplateColumns.split(/\s+/).map((size) => parseFloat(size));
      w = gridSize[col.cellIndex];

      // Attach listeners for document's events
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      resizer.classList.add('resizing');
      // Determine how far the mouse has been moved
      const dx = e.clientX - x;

      // Update the width of column
      // console.log(dx);
      gridSize[col.cellIndex] = w + dx;
      table.style.gridTemplateColumns = gridSize.map((size) => `${size}px`).join(' ');
    };

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();

      controller.isTableResizing = false;

      resizer.classList.remove('resizing');
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      if (window.moveableForItems.target) {
        window.moveableForItems.target.style.width = `${gridSize.reduce((a, b) => a + b, 0)}px`;
        window.moveableForItems.updateRect();
      }
      const currentItem = table.closest('.item-type-grid');
      currentItem.classList.add('selected');
      controller.stimulate('Item#update_table', currentItem.dataset.itemId, controller._getTableHTML(table));
    };

    resizer.addEventListener('mousedown', mouseDownHandler);
  }

  // Received from context menu
  update(event) {
    const colIndex = parseInt(this.element.dataset.colIndex);
    const rowIndex = parseInt(this.element.dataset.rowIndex);
    const { itemId } = event.target.dataset;

    const table = document.querySelector(`.item-type-grid[data-item-id="${itemId}"] table`);
    const numOfCols = table.rows[0].cells.length;
    const { op } = event.target.dataset;

    console.log(colIndex, rowIndex, itemId, op, table);

    switch (op) {
      case 'insertRowAbove': {
        // const newRowIndex = rowIndex === 0 ? 0 : rowIndex - 1;
        const row = table.rows[rowIndex];
        const newRow = row.cloneNode(true);
        for (const cell of newRow.cells) {
          cell.querySelector('.table-cell-content').innerHTML = '';
        }
        row.insertAdjacentElement('beforebegin', newRow);

        if (window.moveableForItems.target) {
          window.moveableForItems.target.style.height = `${table.scrollHeight}px`;
          window.moveableForItems.updateRect();
        }

        this.stimulate('Item#update_table', itemId, this._getTableHTML(table));
        break;
      }

      case 'insertRowBelow': {
        const row = table.rows[rowIndex];
        const newRow = row.cloneNode(true);
        for (const cell of newRow.cells) {
          cell.querySelector('.table-cell-content').innerHTML = '';
        }
        row.insertAdjacentElement('afterend', newRow);

        if (window.moveableForItems.target) {
          window.moveableForItems.target.style.height = `${table.scrollHeight}px`;
          window.moveableForItems.updateRect();
        }

        this.stimulate('Item#update_table', itemId, this._getTableHTML(table));
        break;
      }

      case 'insertColumnLeft': {
        for (const row of table.rows) {
          const cell = row.cells[colIndex];
          const newCell = cell.cloneNode(true);
          newCell.querySelector('.table-cell-content').innerHTML = '';
          cell.insertAdjacentElement('beforebegin', newCell);
        }

        const gridSize = table.style.gridTemplateColumns.split(/\s+/).map((size) => parseFloat(size));
        gridSize.splice(colIndex, 0, 100);
        table.style.gridTemplateColumns = gridSize.map((size) => `${size}px`).join(' ');

        table.closest('.item-type-grid').style.width = `${gridSize.reduce((a, b) => a + b, 0)}px`;

        if (window.moveableForItems.target) {
          window.moveableForItems.updateRect();
        }

        this.stimulate('Item#update_table', itemId, this._getTableHTML(table));
        break;
      }

      case 'insertColumnRight': {
        for (const row of table.rows) {
          const cell = row.cells[colIndex];
          const newCell = cell.cloneNode(true);
          newCell.querySelector('.table-cell-content').innerHTML = '';
          cell.insertAdjacentElement('afterend', newCell);
        }

        const gridSize = table.style.gridTemplateColumns.split(/\s+/).map((size) => parseFloat(size));
        gridSize.splice(colIndex, 0, 100);
        table.style.gridTemplateColumns = gridSize.map((size) => `${size}px`).join(' ');

        table.closest('.item-type-grid').style.width = `${gridSize.reduce((a, b) => a + b, 0)}px`;

        if (window.moveableForItems.target) {
          window.moveableForItems.updateRect();
        }
        this.stimulate('Item#update_table', itemId, this._getTableHTML(table));
        break;
      }

      case 'deleteRow': {
        table.deleteRow(rowIndex);
        this.stimulate('Item#update_table', itemId, this._getTableHTML(table));
        break;
      }

      case 'deleteColumn': {
        for (const row of table.rows) {
          row.deleteCell(colIndex);
        }

        const gridSize = table.style.gridTemplateColumns.split(/\s+/).map((size) => parseFloat(size));
        gridSize.splice(colIndex, 1);
        table.style.gridTemplateColumns = gridSize.map((size) => `${size}px`).join(' ');
        this.stimulate('Item#update_table', itemId, this._getTableHTML(table));
        break;
      }

      case 'equalColumns': {
        const numOfCols = table.rows[0].cells.length;
        const tableWidth = parseFloat(getComputedStyle(table).width).toFixed(2);
        const newColWidth = tableWidth / numOfCols;
        table.style.gridTemplateColumns = Array(numOfCols).fill(`${newColWidth}px`).join(' ');
        this.stimulate('Item#update_table', itemId, this._getTableHTML(table));
        break;
      }

      default:
        console.log(`${op} not supported yet`);
    }

    this.adjustResizers(table);
  }

  adjustResizers(table) {
    table.querySelectorAll('.resizer').forEach((resizer) => resizer.remove());
    table.parentElement.querySelectorAll('.row-resizer').forEach(resizer => resizer.remove());
    this._resizableGrid(table);
  }

  adjustMinHeights(table) {
    const cells = table.querySelectorAll('.table-cell-content');
    const minHeights = [...cells].map(cell => getComputedStyle(cell).height);
    for (let i = 0; i < minHeights.length; i++) {
      cells[i].style.minHeight = minHeights[i];
    }
  }

  changeStartingSelection(event) {
    const editorContainer = event.target.closest('.table-cell-content');
    const cell = editorContainer.parentElement;
    this.currentSelectionStart = cell;
  }

  selectCell(event) {
    if(event.buttons === 1) {
      if(window.selectoForItems.target.clientWidth > 0 || window.selectoForItems.target.clientHeight > 0 ) {
        return;
      }

      if(this.isTableResizing) {
        return;
      }

      const selectedItem = document.querySelector('.item-type-grid.selected');
      if(!selectedItem) {
        return
      }

      if(window.moveableForItems.target == selectedItem) {
        return;
      }

      if(selectedItem.dataset.moved === '1') {
        return;
      }

      const colIndex = event.target.closest('td').cellIndex;
      const rowIndex = event.target.closest('td').parentElement.rowIndex;

      const startColIndex = this.currentSelectionStart.cellIndex;
      const startRowIndex = this.currentSelectionStart.parentElement.rowIndex;

      const startRow = Math.min(startRowIndex, rowIndex)
      const endRow = Math.max(startRowIndex, rowIndex )

      const startCol = Math.min(startColIndex, colIndex);
      const endCol = Math.max(startColIndex, colIndex);
      const table = event.target.closest('table');
      table.querySelectorAll('td').forEach((cell) => cell.classList.remove('selected'));
      for (let i = startRow; i <= endRow; i++) {
        for (let j = startCol; j <= endCol; j++) {
          table.rows[i].cells[j].classList.add('selected');
        }

      }

      this.isCellSelectionDragging = true;
    }
  }
}
