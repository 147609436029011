import updateRect from '../updateRect';
import setDataAttributes from '../setDataAttributes';
import calculateCropAttributes from './calculateCropAttributes';

const setPosition = (itemId, top, left) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;

  const frame = window.mapItems.get(target);

  frame.set('transform', 'translateX', `${parseInt(left, 0)}px`);
  frame.set('transform', 'translateY', `${parseInt(top, 0)}px`);

  target.style.cssText += frame.toCSS();
  calculateCropAttributes(target);
  setDataAttributes(target.dataset.itemId);

  updateRect(itemId);
  window.hideContextMenus();
};

export default setPosition;
