import Moveable, { getElementInfo } from 'moveable';
import Selecto from 'selecto';
import clearCloneCursor from '../helpers/editor/items/clone/clearCloneCursor';
import cloneDomObject from '../helpers/editor/items/clone/cloneDomObject';
import cloneLineObject from '../helpers/editor/items/clone/cloneLineObject';
import ApplicationController from './application_controller';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';
import calculateCropAttributes from '../helpers/editor/items/actions/calculateCropAttributes';
import hideItemPanel from '../helpers/editor/panels/hideItemPanel';
import { isTouchDevice } from '../helpers/utils';
import menuAble from '../helpers/editor/moveable/dropdown';

export default class extends ApplicationController {
  connect() {
    super.connect();
    this.activateMoveableForGuides();
    this.activateMoveableForItems();
    this.activateMoveableForGroups();
    this.activateMoveableForText();
    this.activateMoveableForLineHandle();
    this.activateMoveableForLineBody();
    this.activateMoveableForCrop();
    this.activateSelecto();
    document.addEventListener('snapshotHistoryForClonedItems', this.snapshotHistoryForClonedItems);
  }

  initialize() {
    window.dragDuplicate = false;
  }

  activateMoveableForGuides() {
    const moveableForGuides = new Moveable(document.querySelector('.viewer'), {
      elementGuidelines: [],
    });
    window.moveableForGuides = moveableForGuides;
  }

  activateMoveableForItems() {
    window.mapItems = new Map();
    const dragAxis = { direction: 'xy', locked: false };


    const moveableForItems = new Moveable(document.querySelector('.viewer'), {
      target: null,
      container: null,
      draggable: true,
      resizable: true,
      rotatable: true,
      keepRatio: false,
      origin: false,
      edge: true,
      renderDirections: ['nw', 'ne', 'sw', 'se'],
      throttleDrag: 0,
      throttleResize: 0,
      throttleRotate: 0,
      elementGuidelines: [...document.querySelectorAll('.draggable-item-container'), document.querySelector('.canvasContainer')],
      snappable: true,
      snapThreshold: 10,
      isDisplaySnapDigit: false,
      snapGap: false,
      snapDigit: 0,
      snapContainer: document.querySelector('.canvasContainer'),
      snapDirections: {
        left: true, top: true, right: true, bottom: true, center: true, middle: true,
      },
      elementSnapDirections: {
        left: true, top: true, right: true, bottom: true, center: true, middle: true,
      },
      dropdown: isTouchDevice(),
    });

    moveableForItems.ables = [menuAble];
    moveableForItems.on('dragStart', (e) => {
      this.makeThumbnailsDragTargets();

      const { target } = e;

      if (e.inputEvent.altKey && target.dataset.clone != 'true') {
        const targetContainer = target.closest('.item-container');
        const clone = cloneDomObject(targetContainer, e);
        window.moveableForItems.target = clone;
        window.moveableForItems.dragStart(e.inputEvent);
        window.moveableForItems.elementGuidelines.push(clone);
        window.moveableForText.elementGuidelines.push(clone);
        window.dragDuplicate = true;
        this.stimulate('Item#insert_with_temp_id', { resolveLate: true }, `${clone.dataset.cloneOf},${clone.dataset.tempId}`).then(() => {
          clone.dataset.cloneOf = '';
        });

        return;
      }

      if (!target.dataset.cloneOf) {
        storeItemSnapshotForUndo(target.dataset.itemId, 'UPDATE_SLIDE_ITEM');
      }

      const frame = window.mapItems.get(target);

      const tx = parseFloat(frame.get('transform', 'translateX')) || 0;
      const ty = parseFloat(frame.get('transform', 'translateY')) || 0;

      e.set([tx, ty]);
      if (!e.target.classList.contains('selected')) {
        e.target.classList.add('selected');
        this.stimulate('Item#select', e.target.dataset.itemId);
      }

      const elementGuidelineMembers = Array.from(document.querySelectorAll('.draggable-item-container')).filter((el) => el !== target);
      moveableForItems.elementGuidelines = [...elementGuidelineMembers, document.querySelector('.canvasContainer')];
    });

    moveableForItems.on('drag', ({
      target, beforeTranslate, inputEvent, delta,
    }) => {
      const frame = window.mapItems.get(target);

      if (inputEvent && inputEvent.shiftKey) {
        if (!dragAxis.locked) {
          dragAxis.direction = Math.abs(delta[0]) > Math.abs(delta[1]) ? 'x' : 'y';
          dragAxis.locked = true;
        }
      } else {
        dragAxis.direction = 'xy';
        dragAxis.locked = false;
      }

      switch (dragAxis.direction) {
        case 'x':
          frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
          break;
        case 'y':
          frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
          break;
        default:
          frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
          frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
      }

      target.style.cssText += frame.toCSS();
      target.dataset.moved = '1';
      window.Diagrams.updateDiagramPositions();
    });

    moveableForItems.on('dragEnd', ({ target, inputEvent }) => {
      this.removeThumbnailsDragTargets();

      if (this.isDragToMoveTarget(inputEvent)) {
        return this.moveDraggedItemToNewSlide(inputEvent, target);
      }

      if (target.dataset.moved === '1') {
        target.dataset.moved = '0';

        this.updateCropAttributes(target);
        this.setItemAttributes(target);
        if (window.dragDuplicate) {
          clearCloneCursor();
          window.dragDuplicate = false;
          target.dataset.clone = 'false';
          this.stimulate('Item#update_without_undo', target);
        } else {
          this.stimulate('Item#update', target);
        }
      }

      // set defaults for drag axis
      dragAxis.direction = 'xy';
      dragAxis.locked = false;
    });

    moveableForItems.on('rotateStart', (e) => {
      const frame = window.mapItems.get(e.target);
      const rotate = parseFloat(frame.get('transform', 'rotate')) || 0;
      storeItemSnapshotForUndo(e.target.dataset.itemId, 'UPDATE_SLIDE_ITEM');

      e.set(rotate);
    });

    moveableForItems.on('rotate', ({ target, beforeRotate, inputEvent }) => {
      const frame = window.mapItems.get(target);

      if (inputEvent && inputEvent.shiftKey) {
        window.moveableForItems.throttleRotate = 15;
      } else {
        window.moveableForItems.throttleRotate = 0;
      }

      frame.set('transform', 'rotate', `${beforeRotate}deg`);

      target.style.cssText += frame.toCSS();
    });

    moveableForItems.on('rotateEnd', ({ target }) => {
      this.setItemAttributes(target);
      this.stimulate('Item#update', target);
      this.disallowDeselect(10);
      window.moveableForItems.throttleRotate = 0;
    });

    moveableForItems.on('resizeStart', (event) => {
      const frame = window.mapItems.get(event.target);
      event.dragStart && event.dragStart.set([parseFloat(frame.properties.transform.translateX), parseFloat(frame.properties.transform.translateY)]);
      storeItemSnapshotForUndo(event.target.dataset.itemId, 'UPDATE_SLIDE_ITEM');
    });

    moveableForItems.on('resize', ({
      target, width, height, inputEvent, drag, delta,
    }) => {
      const retainProportionsInput = document.getElementById('item_retain_proportions');
      if (inputEvent.shiftKey === true || target.dataset.hasCrop === 'true' || target.dataset.itemType === 'video'
        || (retainProportionsInput && retainProportionsInput.checked)) {
        window.moveableForItems.keepRatio = true;
      } else {
        window.moveableForItems.keepRatio = false;
      }

      if (target.dataset.itemShape === 'rect') {
        const svg = target.querySelector('svg');
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`);

        const rects = svg.querySelectorAll('rect');
        rects.forEach((rect) => {
          rect.setAttribute('width', width);
          rect.setAttribute('height', height);
        });
      }

      if (target.dataset.itemType === 'grid') {
        const table = target.querySelector('table');
        // Adjust table width
        const gridSize = table.style.gridTemplateColumns.split(/\s+/).map((size) => parseFloat(size));
        gridSize.forEach((val, index) => gridSize[index] = val + (parseFloat(delta[0]) / table.rows[0].cells.length));
        table.style.gridTemplateColumns = gridSize.map((size) => `${size}px`).join(' ');

        // Adjust table height
        table.querySelectorAll('.table-cell-content').forEach((cell) => {
          const currentMinHeight = parseFloat(cell.style.minHeight);
          const updatedMinHeight = currentMinHeight + (parseFloat(delta[1]) / table.rows.length);
          cell.style.minHeight = `${updatedMinHeight}px`;
        });
      }

      const frame = window.mapItems.get(target);
      frame.set('width', width);
      frame.set('height', height);
      target.style.width = `${width}px`;
      target.style.height = `${height}px`;
      frame.set('transform', 'translateX', `${drag.beforeTranslate[0]}px`);
      frame.set('transform', 'translateY', `${drag.beforeTranslate[1]}px`);
      target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px) rotate(${frame.get('transform', 'rotate')})`;

      const moveableContainer = target.querySelector('.moveableContainer');
      if (moveableContainer) {
        moveableContainer.style.width = `${width}px`;
        moveableContainer.style.height = `${height}px`;
      }
    });

    moveableForItems.on('resizeEnd', ({ target }) => {
      this.disallowDeselect(20);
      if (target.dataset.itemType === 'grid') {
        target.style.height = 'auto';
        const tableContainer = target.querySelector('.grid-v2-container');
        const table = tableContainer.querySelector('table');
        const controller = tableContainer.grid;
        controller.adjustResizers(table);
        controller.stimulate('Item#update_table', target.dataset.itemId, controller._getTableHTML(table), false);
      }
      this.updateCropAttributes(target);
      this.setItemAttributes(target);
      if (!window.moveableForItems.keepRatio) target.dataset.hasAutoCrop = true;
      this.stimulate('Item#update', target);
    });

    window.moveableForItems = moveableForItems;
  }

  activateMoveableForCrop() {
    const moveableForCrop = new Moveable(document.querySelector('.viewer'), {
      target: null,
      container: null,
      origin: false,
      edge: false,
      throttleDrag: 0,
      clippable: true,
      clipRelative: false,
      clipArea: true,
      dragWithClip: false,
      defaultClipPath: 'rect',
      clipTargetBounds: true,
      className: 'moveable-crop',
    });

    moveableForCrop.on('clip', (e) => {
      const frame = window.mapItems.get(e.target);
      if (e.clipType === 'rect') {
        e.target.style.clip = e.clipStyle;
      } else {
        e.target.style.clipPath = e.clipStyle;
      }
      frame.clipStyle = e.clipStyle;
    });

    moveableForCrop.on('clipEnd', (e) => {
      this.disallowDeselect(20);
      this.setItemAttributes(e.target);
      const { target } = e;

      const imageContainer = target.querySelector('.moveableContainer');
      const imagePath = imageContainer.style.backgroundImage.slice(4, -1).replace(/"/g, '');

      const widthRatio = parseFloat(target.dataset.assetWidth) / parseFloat(target.dataset.width);
      const heightRatio = parseFloat(target.dataset.assetHeight) / parseFloat(target.dataset.height);

      const clipValues = target.dataset.clip.match(/([0-9]\d*(\.\d+)?)/g);

      const x1 = parseInt(clipValues[3] * widthRatio, 0);
      const y1 = parseInt(clipValues[0] * heightRatio, 0);
      const x2 = parseInt(clipValues[1] * widthRatio, 0);
      const y2 = parseInt(clipValues[2] * heightRatio, 0);

      const croppedArea = `${x1}x${y1}:${x2}x${y2}`;

      const img = new Image();
      img.src = imagePath.replace('/900x0', `/${croppedArea}/900x0`);
    });

    window.moveableForCrop = moveableForCrop;
  }

  activateMoveableForText() {
    const dragAxis = { direction: 'xy', locked: false };

    const moveableForText = new Moveable(document.querySelector('.viewer'), {
      target: null,
      container: null,
      draggable: true,
      rotatable: true,
      resizable: true,
      renderDirections: ['nw', 'ne', 'sw', 'se'],
      keepRatio: false,
      origin: false,
      edge: true,
      throttleDrag: 0,
      throttleResize: 0,
      throttleScale: 0,
      throttleRotate: 0,
      elementGuidelines: [...document.querySelectorAll('.draggable-item-container'), document.querySelector('.canvasContainer')],
      snappable: true,
      snapThreshold: 10,
      isDisplaySnapDigit: false,
      snapGap: false,
      snapDigit: 0,
      snapContainer: document.querySelector('.canvasContainer'),
      snapDirections: {
        left: true, top: true, right: true, bottom: true, center: true, middle: true,
      },
      elementSnapDirections: {
        left: true, top: true, right: true, bottom: true, center: true, middle: true,
      },
      padding: {
        left: 0, top: 0, right: 0, bottom: 0,
      },
      className: 'moveable-text',
      dropdown: isTouchDevice(),
    });

    moveableForText.ables = [menuAble];

    moveableForText.on('dragStart', (e) => {
      const { target, set, inputEvent } = e;
      target.dataset.moved = '1';
      const elementGuidelineMembersForText = Array.from(document.querySelectorAll('.draggable-item-container')).filter((el) => el !== target);
      moveableForItems.elementGuidelines = [...elementGuidelineMembersForText, document.querySelector('.canvasContainer')];
      this.makeThumbnailsDragTargets();

      if (target.querySelector('.text-content.ql-editor').classList.contains('.ql-disabled')) {
        return;
      }

      if (inputEvent.altKey && target.dataset.clone != 'true') {
        const targetContainer = target.closest('.item-container');
        const clone = cloneDomObject(targetContainer, e);
        if (clone.dataset.itemType === 'text') {
          window.moveableForText.target = clone;
          window.moveableForText.dragStart(e.inputEvent);
        } else {
          window.moveableForItems.target = clone;
          window.moveableForItems.dragStart(e.inputEvent);
        }
        window.moveableForItems.elementGuidelines.push(clone);
        window.moveableForText.elementGuidelines.push(clone);
        window.dragDuplicate = true;
        this.stimulate('Item#insert_with_temp_id', { resolveLate: true }, `${clone.dataset.cloneOf},${clone.dataset.tempId}`).then(() => {
          clone.dataset.cloneOf = '';
        });

        return;
      }

      const frame = window.mapItems.get(target);
      if (!target.dataset.cloneOf) {
        storeItemSnapshotForUndo(target.dataset.itemId, 'UPDATE_SLIDE_ITEM');
      }

      const tx = parseFloat(frame.get('transform', 'translateX')) || 0;
      const ty = parseFloat(frame.get('transform', 'translateY')) || 0;

      set([tx, ty]);
      if (!target.classList.contains('selected')) {
        target.classList.add('selected');
        this.stimulate('Item#select', target.dataset.itemId);
      }

      const elementGuidelineMembers = Array.from(document.querySelectorAll('.draggable-item-container')).filter((el) => el !== target);
      moveableForText.elementGuidelines = [...elementGuidelineMembers, document.querySelector('.canvasContainer')];
    });

    moveableForText.on('drag', ({
      target, beforeTranslate, inputEvent, delta,
    }) => {
      if (target.querySelector('.text-content.ql-editor').classList.contains('.ql-disabled')) {
        return;
      }

      const frame = window.mapItems.get(target);

      if (inputEvent && inputEvent.shiftKey) {
        if (!dragAxis.locked) {
          dragAxis.direction = Math.abs(delta[0]) > Math.abs(delta[1]) ? 'x' : 'y';
          dragAxis.locked = true;
        }
      } else {
        dragAxis.direction = 'xy';
        dragAxis.locked = false;
      }

      switch (dragAxis.direction) {
        case 'x':
          frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
          break;
        case 'y':
          frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
          break;
        default:
          frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
          frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
      }

      target.style.cssText += frame.toCSS();
    });

    moveableForText.on('dragEnd', ({ target, isDrag, inputEvent }) => {
      target.dataset.moved = '0';
      this.removeThumbnailsDragTargets();

      if (this.isDragToMoveTarget(inputEvent)) {
        return this.moveDraggedItemToNewSlide(inputEvent, target);
      }

      if (isDrag) {
        this.setItemAttributes(target);

        if (window.dragDuplicate) {
          clearCloneCursor();
          window.dragDuplicate = false;
          target.dataset.clone = 'false';
          this.stimulate('Item#update_without_undo', target);
        } else {
          this.stimulate('Item#update', target);
        }
      }

      // set defaults for drag axis
      dragAxis.direction = 'xy';
      dragAxis.locked = false;
    });

    moveableForText.on('rotateStart', ({ target, set }) => {
      const frame = window.mapItems.get(target);
      const rotate = parseFloat(frame.get('transform', 'rotate')) || 0;
      storeItemSnapshotForUndo(target.dataset.itemId, 'UPDATE_SLIDE_ITEM');

      set(rotate);
    });

    moveableForText.on('rotate', ({ target, beforeRotate, inputEvent }) => {
      const frame = window.mapItems.get(target);

      if (inputEvent && inputEvent.shiftKey) {
        window.moveableForText.throttleRotate = 15;
      } else {
        window.moveableForText.throttleRotate = 0;
      }

      frame.set('transform', 'rotate', `${beforeRotate}deg`);

      target.style.cssText += frame.toCSS();
    });

    moveableForText.on('rotateEnd', ({ target }) => {
      this.setItemAttributes(target);
      // Set the panel form value here because we don't re-render the panel for text
      const rotateInput = document.querySelector('#item_rotate');
      if (rotateInput) {
        rotateInput.value = parseFloat(target.dataset.rotate);
      }
      this.stimulate('Item#update', target);
      this.disallowDeselect(10);
      window.moveableForText.throttleRotate = 0;
    });

    moveableForText.on('resizeStart', (event) => {
      storeItemSnapshotForUndo(event.target.dataset.itemId, 'UPDATE_SLIDE_ITEM');
    });

    moveableForText.on('resize', ({
      target, width, height, drag,
    }) => {
      const frame = window.mapItems.get(target);
      frame.set('width', width);
      target.style.width = `${width}px`;
      target.dataset.width = width;
      target.dataset.translateX = drag.beforeTranslate[0];
      frame.set('transform', 'translateX', `${drag.beforeTranslate[0]}px`);
      target.style.transform = `translate(${drag.beforeTranslate[0]}px, ${frame.get('transform', 'translateY')}) rotate(${frame.get('transform', 'rotate')})`;
    });

    moveableForText.on('resizeEnd', ({ target, isDrag }) => {
      this.disallowDeselect(20);
      this.setItemAttributes(target);
      this.stimulate('Item#update', target);
    });

    window.moveableForText = moveableForText;
  }

  activateMoveableForGroups() {
    const dragAxis = { direction: 'xy', locked: false };

    const moveableForGroups = new Moveable(document.querySelector('.viewer'), {
      target: null,
      container: null,
      draggable: true,
      resizable: true,
      rotatable: true,
      keepRatio: true,
      origin: false,
      edge: false,
      throttleDrag: 0,
      throttleResize: 0,
      throttleScale: 0,
      throttleRotate: 0,
      elementGuidelines: [],
      snappable: true,
      snapThreshold: 10,
      isDisplaySnapDigit: false,
      snapGap: false,
      snapElement: true,
      snapVertical: true,
      snapHorizontal: true,
      snapCenter: true,
      snapDigit: 0,
      dropdown: false,
    });

    moveableForGroups.ables = [menuAble];

    moveableForGroups.on('dragGroupStart', ({ events }) => {
      window.moveableForGroups.snappable = true;
      const clones = [];
      const cloneData = [];
      const selectionId = this._generateRandomString();

      this.makeThumbnailsDragTargets();

      events.forEach((e) => {
        const { target, set, inputEvent } = e;

        if (inputEvent.altKey && target.dataset.clone != 'true') {
          const targetContainer = target.closest('.item-container');
          let clone;
          if (target.classList.contains('line-snapper')) {
            clone = cloneLineObject(targetContainer, e);
            clone.dataset.selectionId = selectionId;
            clones.push(clone.closest('.item-container').querySelector('.line-snapper'));
          } else {
            clone = cloneDomObject(targetContainer, e);
            clone.dataset.selectionId = selectionId;
            clones.push(clone);
          }

          cloneData.push(`${clone.dataset.cloneOf},${clone.dataset.tempId},${selectionId}`);
        } else {
          storeItemSnapshotForUndo(target.dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);
        }

        const frame = window.mapItems.get(target);
        const tx = parseFloat(frame.get('transform', 'translateX')) || 0;
        const ty = parseFloat(frame.get('transform', 'translateY')) || 0;

        set([tx, ty]);
      });

      if (clones.length > 0) {
        this.stimulate('Item#insert_with_temp_id', { resolveLate: true }, cloneData.join('|')).then(() => {
          clones.forEach((clone) => {
            clone.dataset.cloneOf = '';
          });
        });
        window.moveableForGroups.target = clones;
        window.dragDuplicate = true;
      }
    });

    moveableForGroups.on('dragGroup', ({ events, targets }) => {
      events.forEach(({
        target, beforeTranslate, inputEvent, delta,
      }) => {
        target.dataset.moved = '1';
        if (target.classList.contains('line-snapper')) {
          const actualTarget = target.closest('.item-container').querySelector('.item-type-line');
          actualTarget.dataset.moved = '1';
          const frame = window.mapItems.get(target);

          const lineHandler = target.closest('.item-container').querySelector('.line-handler');
          const lineSnapper = target.closest('.item-container').querySelector('.line-snapper');

          const handlerFrame = window.mapItems.get(lineHandler);

          const diffX = parseFloat(frame.get('transform', 'translateX')) - parseFloat(handlerFrame.get('transform', 'translateX'));
          const diffY = parseFloat(frame.get('transform', 'translateY')) - parseFloat(handlerFrame.get('transform', 'translateY'));

          if (inputEvent && inputEvent.shiftKey) {
            if (!dragAxis.locked) {
              dragAxis.direction = Math.abs(delta[0]) > Math.abs(delta[1]) ? 'x' : 'y';
              dragAxis.locked = true;
            }
          } else {
            dragAxis.direction = 'xy';
            dragAxis.locked = false;
          }

          const lineStart = actualTarget.closest('.item-container').querySelector('.handle-left');
          const lineEnd = actualTarget.closest('.item-container').querySelector('.handle-right');
          const snapHandleLeft = target.closest('.item-container').querySelector('.snap-handle-left');
          const snapHandleRight = target.closest('.item-container').querySelector('.snap-handle-right');

          const frameLineStart = window.mapItems.get(lineStart);
          const frameLineEnd = window.mapItems.get(lineEnd);
          const frameSnapHandleLeft = window.mapItems.get(snapHandleLeft);
          const frameSnapHandleRight = window.mapItems.get(snapHandleRight);

          switch (dragAxis.direction) {
            case 'x':
              frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
              handlerFrame.set('transform', 'translateX', `${beforeTranslate[0] - diffX}px`);
              frameLineStart.set('transform', 'translateX', `${beforeTranslate[0] - 10}px`);
              frameLineEnd.set('transform', 'translateX', `${parseFloat(target.dataset.x2) - parseFloat(target.dataset.x1) - 10 + beforeTranslate[0]}px`);
              frameSnapHandleLeft.set('transform', 'translateX', `${beforeTranslate[0]}px`);
              frameSnapHandleRight.set('transform', 'translateX', `${parseFloat(target.dataset.x2) - parseFloat(target.dataset.x1) + beforeTranslate[0]}px`);
              break;
            case 'y':
              frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
              handlerFrame.set('transform', 'translateY', `${beforeTranslate[1] - diffY}px`);
              frameLineStart.set('transform', 'translateY', `${beforeTranslate[1] - 10}px`);
              frameLineEnd.set('transform', 'translateY', `${parseFloat(target.dataset.y2) - parseFloat(target.dataset.y1) - 10 + beforeTranslate[1]}px`);
              frameSnapHandleLeft.set('transform', 'translateY', `${beforeTranslate[1]}px`);
              frameSnapHandleRight.set('transform', 'translateY', `${parseFloat(target.dataset.y2) - parseFloat(target.dataset.y1) + beforeTranslate[1]}px`);
              break;
            default:
              frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
              frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
              handlerFrame.set('transform', 'translateX', `${beforeTranslate[0] - diffX}px`);
              handlerFrame.set('transform', 'translateY', `${beforeTranslate[1] - diffY}px`);
              frameLineStart.set('transform', 'translateX', `${beforeTranslate[0] - 10}px`);
              frameLineStart.set('transform', 'translateY', `${beforeTranslate[1] - 10}px`);
              frameLineEnd.set('transform', 'translateX', `${parseFloat(target.dataset.x2) - parseFloat(target.dataset.x1) - 10 + beforeTranslate[0]}px`);
              frameLineEnd.set('transform', 'translateY', `${parseFloat(target.dataset.y2) - parseFloat(target.dataset.y1) - 10 + beforeTranslate[1]}px`);
              frameSnapHandleLeft.set('transform', 'translateX', `${beforeTranslate[0]}px`);
              frameSnapHandleLeft.set('transform', 'translateY', `${beforeTranslate[1]}px`);
              frameSnapHandleRight.set('transform', 'translateX', `${parseFloat(target.dataset.x2) - parseFloat(target.dataset.x1) + beforeTranslate[0]}px`);
              frameSnapHandleRight.set('transform', 'translateY', `${parseFloat(target.dataset.y2) - parseFloat(target.dataset.y1) + beforeTranslate[1]}px`);
          }

          lineStart.style.cssText += frameLineStart.toCSS();
          lineEnd.style.cssText += frameLineEnd.toCSS();
          snapHandleLeft.style.cssText += frameSnapHandleLeft.toCSS();
          snapHandleRight.style.cssText += frameSnapHandleRight.toCSS();
          target.style.cssText += frame.toCSS();
          lineHandler.style.cssText += handlerFrame.toCSS();

          const actualLine = actualTarget.querySelector('.actual-line');
          actualTarget.setAttribute('data-x1', parseFloat(frameLineStart.get('transform', 'translateX')) + 10);
          actualTarget.setAttribute('data-y1', parseFloat(frameLineStart.get('transform', 'translateY')) + 10);
          actualTarget.setAttribute('data-x2', parseFloat(frameLineEnd.get('transform', 'translateX')) + 10);
          actualTarget.setAttribute('data-y2', parseFloat(frameLineEnd.get('transform', 'translateY')) + 10);
          actualLine.setAttribute('x1', parseFloat(frameLineStart.get('transform', 'translateX')) + 10);
          actualLine.setAttribute('y1', parseFloat(frameLineStart.get('transform', 'translateY')) + 10);
          actualLine.setAttribute('x2', parseFloat(frameLineEnd.get('transform', 'translateX')) + 10);
          actualLine.setAttribute('y2', parseFloat(frameLineEnd.get('transform', 'translateY')) + 10);
          lineSnapper.setAttribute('data-x1', parseFloat(frameLineStart.get('transform', 'translateX')) + 10);
          lineSnapper.setAttribute('data-y1', parseFloat(frameLineStart.get('transform', 'translateY')) + 10);
          lineSnapper.setAttribute('data-x2', parseFloat(frameLineEnd.get('transform', 'translateX')) + 10);
          lineSnapper.setAttribute('data-y2', parseFloat(frameLineEnd.get('transform', 'translateY')) + 10);
          lineHandler.setAttribute('data-x1', parseFloat(frameLineStart.get('transform', 'translateX')) + 10);
          lineHandler.setAttribute('data-y1', parseFloat(frameLineStart.get('transform', 'translateY')));
          lineHandler.setAttribute('data-x2', parseFloat(frameLineEnd.get('transform', 'translateX')) + 10);
          lineHandler.setAttribute('data-y2', parseFloat(frameLineEnd.get('transform', 'translateY')));
        } else {
          const frame = window.mapItems.get(target);

          if (inputEvent && inputEvent.shiftKey) {
            if (!dragAxis.locked) {
              dragAxis.direction = Math.abs(delta[0]) > Math.abs(delta[1]) ? 'x' : 'y';
              dragAxis.locked = true;
            }
          } else {
            dragAxis.direction = 'xy';
            dragAxis.locked = false;
          }

          switch (dragAxis.direction) {
            case 'x':
              frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
              break;
            case 'y':
              frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
              break;
            default:
              frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
              frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
          }

          target.style.cssText += frame.toCSS();
        }
      });
    });

    moveableForGroups.on('dragGroupEnd', ({ targets, inputEvent }) => {
      if (targets[0].dataset.moved !== '1') {
        return;
      }

      targets.forEach((target) => target.dataset.moved = '0');

      if (this.isDragToMoveTarget(inputEvent)) {
        return this.moveDraggedItemToNewSlide(inputEvent, targets);
      }

      setTimeout((_) => {
        const selectionId = this._generateRandomString();
        const data = [];

        targets.forEach((target) => {
          if (target.classList.contains('line-snapper')) {
            const actualTarget = target.closest('.item-container')
              .querySelector('.item-type-line');
            actualTarget.dataset.moved = '0';
            const actualLine = actualTarget.querySelector('.actual-line');

            const lineStart = actualTarget.closest('.item-container')
              .querySelector('.handle-left');
            const lineEnd = actualTarget.closest('.item-container')
              .querySelector('.handle-right');
            const snapHandleLeft = actualTarget.closest('.item-container')
              .querySelector('.snap-handle-left');
            const snapHandleRight = actualTarget.closest('.item-container')
              .querySelector('.snap-handle-right');

            lineStart.dataset.cx = actualLine.getAttribute('x1');
            lineStart.dataset.cy = actualLine.getAttribute('y1');
            lineEnd.dataset.cx = actualLine.getAttribute('x2');
            lineEnd.dataset.cy = actualLine.getAttribute('y2');
            snapHandleLeft.dataset.cx = actualLine.getAttribute('x1');
            snapHandleLeft.dataset.cy = actualLine.getAttribute('y1');
            snapHandleRight.dataset.cx = actualLine.getAttribute('x2');
            snapHandleRight.dataset.cy = actualLine.getAttribute('y2');

            window.weAreDraggingLine = null;

            const { itemId } = actualLine.dataset;
            const x1 = parseFloat(actualLine.getAttribute('x1'));
            const y1 = parseFloat(actualLine.getAttribute('y1'));
            const x2 = parseFloat(actualLine.getAttribute('x2'));
            const y2 = parseFloat(actualLine.getAttribute('y2'));
            if (window.dragDuplicate) {
              target.closest('.item-container').dataset.clone = 'false';
              target.closest('.item-container')
                .querySelector('.item-type-line').dataset.clone = 'false';
            }

            data.push({
              itemId,
              selectionId,
              x1,
              y1,
              x2,
              y2,
            });
          } else {
            this.updateCropAttributes(target);
            this.setItemAttributes(target);
            target.dataset.selectionId = selectionId;

            if (window.dragDuplicate) {
              target.dataset.clone = 'false';
            }

            const {
              itemId,
              translateX,
              translateY,
              rotate,
              width,
              height,
            } = target.dataset;
            data.push({
              itemId,
              translateX,
              translateY,
              rotate,
              width,
              height,
              selectionId,
            });
          }
        });

        if (window.dragDuplicate) {
          window.dragDuplicate = false;
          clearCloneCursor();
          this.stimulate('Item#batch_update_without_undo', data);
        } else {
          this.stimulate('Item#batch_update', data);
        }

        const newTargetList = [];
        setTimeout(() => {
          window.moveableForGroups.target.forEach((target) => {
            if (target.classList.contains('line-snapper')) {
              newTargetList.push(document.getElementById(target.id));
            } else {
              newTargetList.push(target);
            }
          });
          window.moveableForGroups.target = newTargetList;
        }, 1000);

        window.Diagrams.initializeDiagrams();
        this.disallowDeselect(10);

        // set defaults for drag axis
        dragAxis.direction = 'xy';
        dragAxis.locked = false;
      }, 300);
    });

    moveableForGroups.on('rotateGroupStart', ({ events }) => {
      const selectionId = this._generateRandomString();

      events.forEach(({
        target, set, dragStart, inputEvent,
      }) => {
        const frame = window.mapItems.get(target);

        const tx = parseFloat(frame.get('transform', 'translateX')) || 0;
        const ty = parseFloat(frame.get('transform', 'translateY')) || 0;
        const rotate = parseFloat(frame.get('transform', 'rotate')) || 0;

        set(rotate);
        dragStart.set([tx, ty]);

        storeItemSnapshotForUndo(target.dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);
      });
    });

    moveableForGroups.on('rotateGroup', ({ events }) => {
      events.forEach(({
        target, beforeRotate, drag, inputEvent,
      }) => {
        const frame = window.mapItems.get(target);

        if (inputEvent && inputEvent.shiftKey) {
          window.moveableForGroups.throttleRotate = 15;
        } else {
          window.moveableForGroups.throttleRotate = 0;
        }

        const { beforeTranslate } = drag;

        frame.set('transform', 'rotate', `${beforeRotate}deg`);
        frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
        frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);

        if (target.classList.contains('line-snapper')) {
          const { width } = target.dataset;
          const itemContainer = target.closest('.item-container');
          const actualLine = itemContainer.querySelector('.actual-line');
          const lineStart = target.closest('.item-container').querySelector('.handle-left');
          const lineEnd = target.closest('.item-container').querySelector('.handle-right');
          const snapHandleLeft = target.closest('.item-container').querySelector('.snap-handle-left');
          const snapHandleRight = target.closest('.item-container').querySelector('.snap-handle-right');

          const frameLineStart = window.mapItems.get(lineStart);
          const frameLineEnd = window.mapItems.get(lineEnd);
          const frameSnapHandleLeft = window.mapItems.get(snapHandleLeft);
          const frameSnapHandleRight = window.mapItems.get(snapHandleRight);

          let x1 = drag.beforeTranslate[0];
          let y1 = drag.beforeTranslate[1];

          actualLine.setAttribute('x1', x1);
          actualLine.setAttribute('y1', y1);
          let angle = parseFloat(frame.get('transform', 'rotate'));
          const triangleHeight = width * Math.sin(angle * (Math.PI / 180));
          const triangleWidth = width * Math.cos(angle * (Math.PI / 180));

          let x2 = drag.beforeTranslate[0] + triangleWidth;
          let y2 = drag.beforeTranslate[1] + triangleHeight;

          actualLine.setAttribute('x2', x2);
          actualLine.setAttribute('y2', y2);

          frameLineStart.set('transform', 'translateX', `${x1 - 10}px`);
          frameLineStart.set('transform', 'translateY', `${y1 - 10}px`);
          frameLineEnd.set('transform', 'translateX', `${x2 - 10}px`);
          frameLineEnd.set('transform', 'translateY', `${y2 - 10}px`);
          frameSnapHandleLeft.set('transform', 'translateX', `${x1}px`);
          frameSnapHandleLeft.set('transform', 'translateY', `${y1}px`);
          frameSnapHandleRight.set('transform', 'translateX', `${x2}px`);
          frameSnapHandleRight.set('transform', 'translateY', `${y2}px`);

          lineStart.style.cssText += frameLineStart.toCSS();
          lineEnd.style.cssText += frameLineEnd.toCSS();
          snapHandleLeft.style.cssText += frameSnapHandleLeft.toCSS();
          snapHandleRight.style.cssText += frameSnapHandleRight.toCSS();

          x1 = parseFloat(actualLine.getAttribute('x1'));
          y1 = parseFloat(actualLine.getAttribute('y1')) - 10;
          x2 = parseFloat(actualLine.getAttribute('x2'));
          y2 = parseFloat(actualLine.getAttribute('y2')) - 10;

          const lineHandler = target.closest('.item-container').querySelector('.line-handler');
          const handlerFrame = window.mapItems.get(lineHandler);

          let xValue = Math.abs(x2 - x1);
          let yValue = Math.abs(y2 - y1);
          let length = Math.hypot(xValue, yValue);
          angle = Math.acos(xValue / length) / (Math.PI / 180);
          let calculatedAngle = 0;
          if (x2 < x1 && y2 > y1) calculatedAngle = -angle - 180;
          else if (x2 < x1) calculatedAngle = angle - 180;
          else if (y2 > y1) calculatedAngle = angle;
          else calculatedAngle = -angle;

          handlerFrame.set('width', `${length}px`);
          handlerFrame.set('transform', 'translateX', `${x1}px`);
          handlerFrame.set('transform', 'translateY', `${y1}px`);
          handlerFrame.set('transform', 'rotate', `${calculatedAngle}deg`);
          lineHandler.setAttribute('data-angle', `${calculatedAngle}`);

          x1 = parseFloat(actualLine.getAttribute('x1'));
          y1 = parseFloat(actualLine.getAttribute('y1'));
          x2 = parseFloat(actualLine.getAttribute('x2'));
          y2 = parseFloat(actualLine.getAttribute('y2'));

          const snapperTarget = target.closest('.item-container').querySelector('.line-snapper');
          const snapperFrame = window.mapItems.get(snapperTarget);

          xValue = Math.abs(x2 - x1);
          yValue = Math.abs(y2 - y1);
          length = Math.hypot(xValue, yValue);
          angle = Math.acos(xValue / length) / (Math.PI / 180);
          calculatedAngle = 0;
          if (x2 < x1 && y2 > y1) calculatedAngle = -angle - 180;
          else if (x2 < x1) calculatedAngle = angle - 180;
          else if (y2 > y1) calculatedAngle = angle;
          else calculatedAngle = -angle;

          snapperFrame.set('width', `${length}px`);
          snapperFrame.set('transform', 'translateX', `${x1}px`);
          snapperFrame.set('transform', 'translateY', `${y1}px`);
          snapperFrame.set('transform', 'rotate', `${calculatedAngle}deg`);
          snapperTarget.setAttribute('data-angle', `${calculatedAngle}`);

          target.style.cssText += frame.toCSS();
          lineHandler.style.cssText += handlerFrame.toCSS();
          snapperTarget.style.cssText += snapperFrame.toCSS();

          snapperTarget.setAttribute('data-x1', x1);
          snapperTarget.setAttribute('data-y1', y1);
          snapperTarget.setAttribute('data-x2', x2);
          snapperTarget.setAttribute('data-y2', y2);
          lineHandler.setAttribute('data-x1', x1);
          lineHandler.setAttribute('data-y1', y1);
          lineHandler.setAttribute('data-x2', x2);
          lineHandler.setAttribute('data-y2', y2);
        }

        target.style.cssText += frame.toCSS();
      });
    });

    moveableForGroups.on('rotateGroupEnd', ({ targets }) => {
      const selectionId = this._generateRandomString();
      const data = [];

      targets.forEach((target) => {
        this.setItemAttributes(target);
        target.dataset.selectionId = selectionId;

        let x1; let x2; let y1; let y2 = null;

        const {
          translateX, translateY, rotate, width, height,
        } = target.dataset;
        let { itemId } = target.dataset;

        if (target.classList.contains('line-snapper')) {
          const actualLine = target.closest('.item-container').querySelector('.actual-line');
          itemId = actualLine.dataset.itemId;
          x1 = parseFloat(actualLine.getAttribute('x1'));
          y1 = parseFloat(actualLine.getAttribute('y1'));
          x2 = parseFloat(actualLine.getAttribute('x2'));
          y2 = parseFloat(actualLine.getAttribute('y2'));
        }

        data.push({
          itemId, translateX, translateY, rotate, width, height, selectionId, x1, y1, x2, y2,
        });
      });
      this.stimulate('Item#batch_update', data);
      const newTargetList = [];

      setTimeout(() => {
        window.moveableForGroups.target.forEach((target) => {
          if (target.classList.contains('line-snapper')) {
            newTargetList.push(document.getElementById(target.id));
          } else {
            newTargetList.push(target);
          }
        });
        window.moveableForGroups.target = newTargetList;
      }, 1000);

      this.disallowDeselect(10);
      window.moveableForGroups.throttleRotate = 0;
    });

    moveableForGroups.on('resizeGroupStart', ({ events }) => {
      const selectionId = this._generateRandomString();

      events.forEach((event) => {
        const frame = window.mapItems.get(event.target);
        event.dragStart && event.dragStart.set([parseFloat(frame.properties.transform.translateX), parseFloat(frame.properties.transform.translateY)]);
        storeItemSnapshotForUndo(event.target.dataset.itemId, 'UPDATE_SLIDE_ITEM', selectionId);
      });
    });

    moveableForGroups.on('resizeGroup', ({ events }) => {
      events.forEach((event) => {
        const {
          target, width, height, drag,
        } = event;

        const frame = window.mapItems.get(target);
        frame.translate = drag.beforeTranslate;
        frame.set('width', width);
        frame.set('height', height);
        target.style.width = `${width}px`;
        target.style.height = `${height}px`;
        frame.set('transform', 'translateX', `${drag.beforeTranslate[0]}px`);
        frame.set('transform', 'translateY', `${drag.beforeTranslate[1]}px`);

        if (target.classList.contains('line-snapper')) {
          const itemContainer = target.closest('.item-container');
          const actualLine = itemContainer.querySelector('.actual-line');
          const lineStart = target.closest('.item-container').querySelector('.handle-left');
          const lineEnd = target.closest('.item-container').querySelector('.handle-right');
          const snapHandleLeft = target.closest('.item-container').querySelector('.snap-handle-left');
          const snapHandleRight = target.closest('.item-container').querySelector('.snap-handle-right');

          const frameLineStart = window.mapItems.get(lineStart);
          const frameLineEnd = window.mapItems.get(lineEnd);
          const frameSnapHandleLeft = window.mapItems.get(snapHandleLeft);
          const frameSnapHandleRight = window.mapItems.get(snapHandleRight);

          let x1 = drag.beforeTranslate[0];
          let y1 = drag.beforeTranslate[1];

          actualLine.setAttribute('x1', x1);
          actualLine.setAttribute('y1', y1);
          let angle = parseFloat(target.dataset.angle);
          const triangleHeight = width * Math.sin(angle * (Math.PI / 180));
          const triangleWidth = width * Math.cos(angle * (Math.PI / 180));

          let x2 = drag.beforeTranslate[0] + triangleWidth;
          let y2 = drag.beforeTranslate[1] + triangleHeight;

          actualLine.setAttribute('x2', x2);
          actualLine.setAttribute('y2', y2);

          frameLineStart.set('transform', 'translateX', `${x1 - 10}px`);
          frameLineStart.set('transform', 'translateY', `${y1 - 10}px`);
          frameLineEnd.set('transform', 'translateX', `${x2 - 10}px`);
          frameLineEnd.set('transform', 'translateY', `${y2 - 10}px`);
          frameSnapHandleLeft.set('transform', 'translateX', `${x1}px`);
          frameSnapHandleLeft.set('transform', 'translateY', `${y1}px`);
          frameSnapHandleRight.set('transform', 'translateX', `${x2}px`);
          frameSnapHandleRight.set('transform', 'translateY', `${y2}px`);

          lineStart.style.cssText += frameLineStart.toCSS();
          lineEnd.style.cssText += frameLineEnd.toCSS();
          snapHandleLeft.style.cssText += frameSnapHandleLeft.toCSS();
          snapHandleRight.style.cssText += frameSnapHandleRight.toCSS();

          x1 = parseFloat(actualLine.getAttribute('x1'));
          y1 = parseFloat(actualLine.getAttribute('y1')) - 10;
          x2 = parseFloat(actualLine.getAttribute('x2'));
          y2 = parseFloat(actualLine.getAttribute('y2')) - 10;

          const lineHandler = target.closest('.item-container').querySelector('.line-handler');
          const handlerFrame = window.mapItems.get(lineHandler);

          let xValue = Math.abs(x2 - x1);
          let yValue = Math.abs(y2 - y1);
          let length = Math.hypot(xValue, yValue);
          angle = Math.acos(xValue / length) / (Math.PI / 180);
          let calculatedAngle = 0;
          if (x2 < x1 && y2 > y1) calculatedAngle = -angle - 180;
          else if (x2 < x1) calculatedAngle = angle - 180;
          else if (y2 > y1) calculatedAngle = angle;
          else calculatedAngle = -angle;

          handlerFrame.set('width', `${length}px`);
          handlerFrame.set('transform', 'translateX', `${x1}px`);
          handlerFrame.set('transform', 'translateY', `${y1}px`);
          handlerFrame.set('transform', 'rotate', `${calculatedAngle}deg`);
          lineHandler.setAttribute('data-angle', `${calculatedAngle}`);

          x1 = parseFloat(actualLine.getAttribute('x1'));
          y1 = parseFloat(actualLine.getAttribute('y1'));
          x2 = parseFloat(actualLine.getAttribute('x2'));
          y2 = parseFloat(actualLine.getAttribute('y2'));

          const snapperTarget = target.closest('.item-container').querySelector('.line-snapper');
          const snapperFrame = window.mapItems.get(snapperTarget);

          xValue = Math.abs(x2 - x1);
          yValue = Math.abs(y2 - y1);
          length = Math.hypot(xValue, yValue);
          angle = Math.acos(xValue / length) / (Math.PI / 180);
          calculatedAngle = 0;
          if (x2 < x1 && y2 > y1) calculatedAngle = -angle - 180;
          else if (x2 < x1) calculatedAngle = angle - 180;
          else if (y2 > y1) calculatedAngle = angle;
          else calculatedAngle = -angle;

          snapperFrame.set('width', `${length}px`);
          snapperFrame.set('transform', 'translateX', `${x1}px`);
          snapperFrame.set('transform', 'translateY', `${y1}px`);
          snapperFrame.set('transform', 'rotate', `${calculatedAngle}deg`);
          snapperTarget.setAttribute('data-angle', `${calculatedAngle}`);

          target.style.cssText += frame.toCSS();
          lineHandler.style.cssText += handlerFrame.toCSS();
          snapperTarget.style.cssText += snapperFrame.toCSS();

          snapperTarget.setAttribute('data-x1', x1);
          snapperTarget.setAttribute('data-y1', y1);
          snapperTarget.setAttribute('data-x2', x2);
          snapperTarget.setAttribute('data-y2', y2);
          lineHandler.setAttribute('data-x1', x1);
          lineHandler.setAttribute('data-y1', y1);
          lineHandler.setAttribute('data-x2', x2);
          lineHandler.setAttribute('data-y2', y2);
        }

        target.style.cssText += frame.toCSS();

        const moveableContainer = target.querySelector('.moveableContainer');
        if (moveableContainer) {
          moveableContainer.style.width = `${width}px`;
          moveableContainer.style.height = `${height}px`;
        }
      });
    });

    moveableForGroups.on('resizeGroupEnd', ({ targets }) => {
      const selectionId = this._generateRandomString();
      const data = [];

      targets.forEach((target) => {
        this.updateCropAttributes(target);
        this.setItemAttributesForGroup(target);
        target.dataset.selectionId = selectionId;

        let x1; let x2; let y1; let y2 = null;

        const {
          translateX, translateY, rotate,
        } = target.dataset;
        let { itemId, width, height } = target.dataset;

        if (target.classList.contains('line-snapper')) {
          const actualLine = target.closest('.item-container').querySelector('.actual-line');
          itemId = actualLine.dataset.itemId;
          x1 = parseFloat(actualLine.getAttribute('x1'));
          y1 = parseFloat(actualLine.getAttribute('y1'));
          x2 = parseFloat(actualLine.getAttribute('x2'));
          y2 = parseFloat(actualLine.getAttribute('y2'));
        }

        data.push({
          itemId, translateX, translateY, rotate, width, height, selectionId, x1, y1, x2, y2,
        });
      });
      this.stimulate('Item#batch_update', data);
      this.disallowDeselect(20);
    });
    moveableForGroups.on('clickGroup', ({
      inputTarget, inputEvent,
    }) => {
      const item = inputTarget.closest('.draggable-item-container');
      if (!item) { // Clicked on an empty space on, de-select all items
        document.querySelector('.canvas').canvas.unSelectAllItems();
        hideItemPanel();
        return;
      }

      // Figma like selection when shift is pressed
      if (inputEvent && inputEvent.shiftKey) {
        if (item.classList.contains('selected')) {
          item.item.unSelectItem(item);
        } else {
          item.item.selectItem(item);
        }
        document.querySelector('.canvas').canvas.selectMultiItems();
        this.stimulate('Item#select', this.selectedItemIds());
        return;
      }

      if (item.dataset.groupId !== '') {
        window.currentActiveGroupId = item.dataset.groupId;
      }
      const itemCtrl = item.item;
      itemCtrl.unSelectOtherItems(item);
      itemCtrl.selectItem(item, 'groupClick');
      this.stimulate('Item#select', this.selectedItemIds());
    });

    window.moveableForGroups = moveableForGroups;
  }

  activateMoveableForLineHandle() {
    const dragAxisForLineHandle = { direction: 'xy', locked: false };

    const moveableForLineHandle = new Moveable(document.querySelector('.viewport'), {
      target: null,
      container: null,
      draggable: true,
      origin: false,
      edge: true,
      throttleDrag: 0,
      elementGuidelines: [document.querySelector('.canvasContainer')],
      elementSnapDirections: {
        left: true, top: true, right: true, bottom: true, center: true, middle: true,
      },
      snappable: true,
      snapThreshold: 0,
      isDisplaySnapDigit: false,
      snapGap: true,
      snapElement: true,
      snapCenter: true,
      snapDigit: 0,
      className: 'hidden-controlbox',
    });

    moveableForLineHandle.on('dragStart', (e) => {
      const frame = window.mapItems.get(e.target);
      const target = e.target.closest('.item-container').querySelector('.item-type-line');

      target.classList.add('selected');
      target.closest('.item-container').querySelector('.handle-left').classList.remove('hidden');
      target.closest('.item-container').querySelector('.handle-right').classList.remove('hidden');

      const tx = parseFloat(frame.get('transform', 'translateX')) || 0;
      const ty = parseFloat(frame.get('transform', 'translateY')) || 0;
      storeItemSnapshotForUndo(target.dataset.itemId, 'UPDATE_SLIDE_ITEM');

      e.set([tx, ty]);
    });

    moveableForLineHandle.on('drag', ({
      target, beforeTranslate, inputEvent, delta,
    }) => {
      const frame = window.mapItems.get(target);
      const actualLine = target.closest('.item-container').querySelector('.actual-line');

      let handle;
      let otherHandle;
      if (target.classList.contains('snap-handle-left')) {
        handle = target.closest('.item-container').querySelector('.handle-left');
        otherHandle = target.closest('.item-container').querySelector('.handle-right');
      } else if (target.classList.contains('snap-handle-right')) {
        handle = target.closest('.item-container').querySelector('.handle-right');
        otherHandle = target.closest('.item-container').querySelector('.handle-left');
      }

      if (inputEvent && inputEvent.shiftKey) {
        if (!dragAxisForLineHandle.locked) {
          dragAxisForLineHandle.direction = Math.abs(delta[0]) > Math.abs(delta[1]) ? 'x' : 'y';
          dragAxisForLineHandle.locked = true;
        }
      } else {
        dragAxisForLineHandle.direction = 'xy';
        dragAxisForLineHandle.locked = false;
      }

      switch (dragAxisForLineHandle.direction) {
        case 'x':
          frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
          frame.set('transform', 'translateY', otherHandle.dataset.cy);

          if (target.classList.contains('snap-handle-left')) {
            actualLine.setAttribute('x1', beforeTranslate[0]);
            actualLine.setAttribute('y1', otherHandle.dataset.cy);
          }

          if (target.classList.contains('snap-handle-right')) {
            actualLine.setAttribute('x2', beforeTranslate[0]);
            actualLine.setAttribute('y2', otherHandle.dataset.cy);
          }
          break;
        case 'y':
          frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
          frame.set('transform', 'translateX', otherHandle.dataset.cx);

          if (target.classList.contains('snap-handle-left')) {
            actualLine.setAttribute('y1', beforeTranslate[1]);
            actualLine.setAttribute('x1', otherHandle.dataset.cx);
          }

          if (target.classList.contains('snap-handle-right')) {
            actualLine.setAttribute('y2', beforeTranslate[1]);
            actualLine.setAttribute('x2', otherHandle.dataset.cx);
          }
          break;
        default:
          if (target.classList.contains('snap-handle-left')) {
            const diffX = Math.abs(parseInt(actualLine.getAttribute('x2')) - parseInt(beforeTranslate[0]));
            const diffY = Math.abs(parseInt(actualLine.getAttribute('y2')) - parseInt(beforeTranslate[1]));

            // Vertical axis
            if (diffX <= 10) {
              actualLine.setAttribute('x1', parseInt(actualLine.getAttribute('x2')));
              frame.set('transform', 'translateX', `${parseInt(actualLine.getAttribute('x2'))}px`);
            } else {
              actualLine.setAttribute('x1', parseInt(beforeTranslate[0]));
              frame.set('transform', 'translateX', `${parseInt(beforeTranslate[0])}px`);
            }

            // Horizontal axis
            if (diffY <= 10) {
              actualLine.setAttribute('y1', parseInt(actualLine.getAttribute('y2')));
              frame.set('transform', 'translateY', `${parseInt(actualLine.getAttribute('y2'))}px`);
            } else {
              actualLine.setAttribute('y1', parseInt(beforeTranslate[1]));
              frame.set('transform', 'translateY', `${parseInt(beforeTranslate[1])}px`);
            }
          }

          if (target.classList.contains('snap-handle-right')) {
            const diffX = Math.abs(parseInt(actualLine.getAttribute('x1')) - parseInt(beforeTranslate[0]));
            const diffY = Math.abs(parseInt(actualLine.getAttribute('y1')) - parseInt(beforeTranslate[1]));

            // Vertical axis
            if (diffX <= 10) {
              actualLine.setAttribute('x2', parseInt(actualLine.getAttribute('x1')));
              frame.set('transform', 'translateX', `${parseInt(actualLine.getAttribute('x1'))}px`);
            } else {
              actualLine.setAttribute('x2', parseInt(beforeTranslate[0]));
              frame.set('transform', 'translateX', `${parseInt(beforeTranslate[0])}px`);
            }

            // Horizontal axis
            if (diffY <= 10) {
              actualLine.setAttribute('y2', parseInt(actualLine.getAttribute('y1')));
              frame.set('transform', 'translateY', `${parseInt(actualLine.getAttribute('y1'))}px`);
            } else {
              actualLine.setAttribute('y2', parseInt(beforeTranslate[1]));
              frame.set('transform', 'translateY', `${parseInt(beforeTranslate[1])}px`);
            }
          }
      }

      let x1 = parseFloat(actualLine.getAttribute('x1'));
      let y1 = parseFloat(actualLine.getAttribute('y1')) - 10;
      let x2 = parseFloat(actualLine.getAttribute('x2'));
      let y2 = parseFloat(actualLine.getAttribute('y2')) - 10;

      const lineHandler = target.closest('.item-container').querySelector('.line-handler');
      const handlerFrame = window.mapItems.get(lineHandler);

      let xValue = Math.abs(x2 - x1);
      let yValue = Math.abs(y2 - y1);
      let length = Math.hypot(xValue, yValue);
      let angle = Math.acos(xValue / length) / (Math.PI / 180);
      let calculatedAngle = 0;
      if (x2 < x1 && y2 > y1) calculatedAngle = -angle - 180;
      else if (x2 < x1) calculatedAngle = angle - 180;
      else if (y2 > y1) calculatedAngle = angle;
      else calculatedAngle = -angle;

      handlerFrame.set('width', `${length}px`);
      handlerFrame.set('transform', 'translateX', `${x1}px`);
      handlerFrame.set('transform', 'translateY', `${y1}px`);
      handlerFrame.set('transform', 'rotate', `${calculatedAngle}deg`);
      lineHandler.setAttribute('data-angle', `${calculatedAngle}`);
      lineHandler.setAttribute('data-width', `${length}`);
      lineHandler.setAttribute('data-length', `${length}`);

      x1 = parseFloat(actualLine.getAttribute('x1'));
      y1 = parseFloat(actualLine.getAttribute('y1'));
      x2 = parseFloat(actualLine.getAttribute('x2'));
      y2 = parseFloat(actualLine.getAttribute('y2'));

      const snapperTarget = target.closest('.item-container').querySelector('.line-snapper');
      const snapperFrame = window.mapItems.get(snapperTarget);

      xValue = Math.abs(x2 - x1);
      yValue = Math.abs(y2 - y1);
      length = Math.hypot(xValue, yValue);
      angle = Math.acos(xValue / length) / (Math.PI / 180);
      calculatedAngle = 0;
      if (x2 < x1 && y2 > y1) calculatedAngle = -angle - 180;
      else if (x2 < x1) calculatedAngle = angle - 180;
      else if (y2 > y1) calculatedAngle = angle;
      else calculatedAngle = -angle;

      snapperFrame.set('width', `${length}px`);
      snapperFrame.set('transform', 'translateX', `${x1}px`);
      snapperFrame.set('transform', 'translateY', `${y1}px`);
      snapperFrame.set('transform', 'rotate', `${calculatedAngle}deg`);
      snapperTarget.setAttribute('data-angle', `${calculatedAngle}`);
      snapperTarget.setAttribute('data-width', `${length}`);
      snapperTarget.setAttribute('data-length', `${length}`);

      if (target.classList.contains('snap-handle-left')) {
        const rightSnap = target.closest('.item-container').querySelector('.snap-handle-right');
        const rightSnapFrame = window.mapItems.get(rightSnap);

        let rightSnapAngle = calculatedAngle;

        let diffRightSnapX = -1;
        let diffRightSnapY = -0.5;
        if (rightSnapAngle === -90) diffRightSnapY = 0.5;
        if (rightSnapAngle === -180) diffRightSnapX = 0;

        if (rightSnapAngle === -0) rightSnapAngle = 0;
        if (rightSnapAngle === 90) rightSnapAngle = -90;
        if (rightSnapAngle === -180) rightSnapAngle = -0;

        frame.set('transform', 'translateY', `${parseInt(frame.get('transform', 'translateY')) - 0.5}px`);
        frame.set('transform', 'rotate', `${calculatedAngle}deg`);

        rightSnapFrame.set('transform', 'translateX', `${parseInt(rightSnap.getAttribute('data-cx')) + diffRightSnapX}px`);
        rightSnapFrame.set('transform', 'translateY', `${parseInt(rightSnap.getAttribute('data-cy')) + diffRightSnapY}px`);
        rightSnapFrame.set('transform', 'rotate', `${rightSnapAngle}deg`);

        rightSnap.style.cssText += rightSnapFrame.toCSS();
      }

      if (target.classList.contains('snap-handle-right')) {
        const leftSnap = target.closest('.item-container').querySelector('.snap-handle-left');
        const leftSnapFrame = window.mapItems.get(leftSnap);

        let rightSnapAngle = calculatedAngle;

        let diffRightSnapX = -1;
        let diffRightSnapY = -0.5;
        if (rightSnapAngle === -90) diffRightSnapY = 0.5;
        if (rightSnapAngle === -180) diffRightSnapX = 0;

        if (rightSnapAngle === -0) rightSnapAngle = 0;
        if (rightSnapAngle === 90) rightSnapAngle = -90;
        if (rightSnapAngle === -180) rightSnapAngle = -0;

        frame.set('transform', 'translateX', `${parseInt(frame.get('transform', 'translateX')) + diffRightSnapX}px`);
        frame.set('transform', 'translateY', `${parseInt(frame.get('transform', 'translateY')) + diffRightSnapY}px`);
        frame.set('transform', 'rotate', `${rightSnapAngle}deg`);

        leftSnapFrame.set('transform', 'translateY', `${parseInt(leftSnap.getAttribute('data-cy')) - 0.5}px`);
        leftSnapFrame.set('transform', 'rotate', `${calculatedAngle}deg`);

        leftSnap.style.cssText += leftSnapFrame.toCSS();
      }

      target.style.cssText += frame.toCSS();
      lineHandler.style.cssText += handlerFrame.toCSS();
      snapperTarget.style.cssText += snapperFrame.toCSS();

      snapperTarget.setAttribute('data-x1', x1);
      snapperTarget.setAttribute('data-y1', y1);
      snapperTarget.setAttribute('data-x2', x2);
      snapperTarget.setAttribute('data-y2', y2);
      lineHandler.setAttribute('data-x1', x1);
      lineHandler.setAttribute('data-y1', y1);
      lineHandler.setAttribute('data-x2', x2);
      lineHandler.setAttribute('data-y2', y2);

      let x = parseFloat(frame.get('transform', 'translateX')) - 10;
      // Right handle is 1 px off when moved.
      if (handle.classList.contains('handle-right')) {
        x += 1;
      }
      const y = parseFloat(frame.get('transform', 'translateY')) - 10;

      handle.style.transform = `translateX(${x}px) translateY(${y}px) rotate(0deg) scaleX(1) scaleY(1)`;
    });

    moveableForLineHandle.on('dragEnd', ({ target }) => {
      const actualLine = target.closest('.item-container').querySelector('.actual-line');

      this.stimulate('Item#update', actualLine);
      this.disallowDeselect(50);

      if (target.classList.contains('snap-handle-left')) {
        const lineStart = actualLine.closest('.item-container').querySelector('.handle-left');

        target.dataset.cx = actualLine.getAttribute('x1');
        target.dataset.cy = actualLine.getAttribute('y1');
        lineStart.dataset.cx = actualLine.getAttribute('x1');
        lineStart.dataset.cy = actualLine.getAttribute('y1');
      }

      if (target.classList.contains('snap-handle-right')) {
        const lineEnd = actualLine.closest('.item-container').querySelector('.handle-right');

        target.dataset.cx = actualLine.getAttribute('x2');
        target.dataset.cy = actualLine.getAttribute('y2');
        lineEnd.dataset.cx = actualLine.getAttribute('x2');
        lineEnd.dataset.cy = actualLine.getAttribute('y2');
      }

      // set defaults for drag axis
      dragAxisForLineHandle.direction = 'xy';
      dragAxisForLineHandle.locked = false;
    });

    window.moveableForLineHandle = moveableForLineHandle;
  }

  activateMoveableForLineBody() {
    const dragAxisForLineBody = { direction: 'xy', locked: false };

    const moveableForLineBody = new Moveable(document.querySelector('.viewport'), {
      target: null,
      container: null,
      draggable: true,
      resizable: false,
      scalable: false,
      origin: false,
      edge: true,
      throttleDrag: 0,
      elementGuidelines: [document.querySelector('.canvasContainer')],
      elementSnapDirections: {
        left: true, top: true, right: true, bottom: true, center: true, middle: true,
      },
      snappable: true,
      snapThreshold: 10,
      isDisplaySnapDigit: false,
      snapGap: true,
      snapElement: true,
      snapVertical: true,
      snapHorizontal: true,
      snapCenter: false,
      snapDigit: 0,
      className: 'hidden-controlbox',
      stopPropagation: true,
    });

    moveableForLineBody.on('dragStart', (e) => {
      const target = e.target.closest('.item-container').querySelector('.item-type-line');
      target.dataset.moved = '1';
      this.makeThumbnailsDragTargets();

      // Unselect other items
      const selectedItems = document.querySelectorAll('.item-container .selected');
      for (const selectedItem of selectedItems) {
        selectedItem.classList.remove('selected');
        if (selectedItem.closest('.item-container').querySelector('.handle')) {
          selectedItem.closest('.item-container').querySelector('.handle-left').classList.add('hidden');
          selectedItem.closest('.item-container').querySelector('.handle-right').classList.add('hidden');
        }
      }

      window.moveableForItems.target = null;
      window.moveableForGroups.target = null;
      window.moveableForText.target = null;

      if (e.inputEvent.altKey && target.dataset.clone != 'true') {
        const targetContainer = target.closest('.item-container');
        const clone = cloneLineObject(targetContainer, e);
        window.moveableForLineBody.target = clone.closest('.item-container').querySelector('.line-snapper');
        window.moveableForLineBody.dragStart(e.inputEvent);
        window.dragDuplicate = true;
        this.stimulate('Item#insert_with_temp_id', { resolveLate: true }, `${clone.dataset.cloneOf},${clone.dataset.tempId}`).then(() => {
          clone.dataset.cloneOf = '';
        });

        return;
      }

      target.classList.add('selected');
      target.closest('.item-container').querySelector('.handle-left').classList.remove('hidden');
      target.closest('.item-container').querySelector('.handle-right').classList.remove('hidden');

      if (!target.dataset.cloneOf) {
        storeItemSnapshotForUndo(target.dataset.itemId, 'UPDATE_SLIDE_ITEM');
      }
      window.weAreDraggingLine = target;
      this.stimulate('Item#select', target.dataset.itemId);

      const frame = window.mapItems.get(e.target);

      const tx = parseFloat(frame.get('transform', 'translateX')) || 0;
      const ty = parseFloat(frame.get('transform', 'translateY')) || 0;

      e.set([tx, ty]);
    });

    moveableForLineBody.on('drag', ({
      target, beforeTranslate, inputEvent, delta,
    }) => {
      const actualTarget = target.closest('.item-container').querySelector('.item-type-line');
      const frame = window.mapItems.get(target);

      const lineHandler = target.closest('.item-container').querySelector('.line-handler');
      const lineSnapper = target.closest('.item-container').querySelector('.line-snapper');

      const handlerFrame = window.mapItems.get(lineHandler);

      const diffX = parseFloat(frame.get('transform', 'translateX')) - parseFloat(handlerFrame.get('transform', 'translateX'));
      const diffY = parseFloat(frame.get('transform', 'translateY')) - parseFloat(handlerFrame.get('transform', 'translateY'));

      let angle = parseInt(frame.get('transform', 'rotate'));

      let diffRightHandleX = -1;
      let diffRightHandleY = -0.5;
      if (angle === -90) diffRightHandleY = 0.5;
      if (angle === -180) diffRightHandleX = 0;

      if (angle === -0) angle = 0;
      if (angle === 90) angle = -90;
      if (angle === -180) angle = -0;

      if (inputEvent && inputEvent.shiftKey) {
        if (!dragAxisForLineBody.locked) {
          dragAxisForLineBody.direction = Math.abs(delta[0]) > Math.abs(delta[1]) ? 'x' : 'y';
          dragAxisForLineBody.locked = true;
        }
      } else {
        dragAxisForLineBody.direction = 'xy';
        dragAxisForLineBody.locked = false;
      }

      const lineStart = actualTarget.closest('.item-container').querySelector('.handle-left');
      const lineEnd = actualTarget.closest('.item-container').querySelector('.handle-right');
      const snapHandleLeft = target.closest('.item-container').querySelector('.snap-handle-left');
      const snapHandleRight = target.closest('.item-container').querySelector('.snap-handle-right');

      const frameLineStart = window.mapItems.get(lineStart);
      const frameLineEnd = window.mapItems.get(lineEnd);
      const frameSnapHandleLeft = window.mapItems.get(snapHandleLeft);
      const frameSnapHandleRight = window.mapItems.get(snapHandleRight);

      switch (dragAxisForLineBody.direction) {
        case 'x':
          frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
          handlerFrame.set('transform', 'translateX', `${beforeTranslate[0] - diffX}px`);
          frameLineStart.set('transform', 'translateX', `${beforeTranslate[0] - 10}px`);
          frameLineEnd.set('transform', 'translateX', `${parseFloat(target.dataset.x2) - parseFloat(target.dataset.x1) - 10 + beforeTranslate[0]}px`);
          frameSnapHandleLeft.set('transform', 'translateX', `${beforeTranslate[0]}px`);
          frameSnapHandleRight.set('transform', 'translateX', `${parseFloat(target.dataset.x2) - parseFloat(target.dataset.x1) + beforeTranslate[0] + diffRightHandleX}px`);
          break;
        case 'y':
          frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
          handlerFrame.set('transform', 'translateY', `${beforeTranslate[1] - diffY}px`);
          frameLineStart.set('transform', 'translateY', `${beforeTranslate[1] - 10}px`);
          frameLineEnd.set('transform', 'translateY', `${parseFloat(target.dataset.y2) - parseFloat(target.dataset.y1) - 10 + beforeTranslate[1]}px`);
          frameSnapHandleLeft.set('transform', 'translateY', `${beforeTranslate[1] - 0.5}px`);
          frameSnapHandleRight.set('transform', 'translateY', `${parseFloat(target.dataset.y2) - parseFloat(target.dataset.y1) + beforeTranslate[1] + diffRightHandleY}px`);
          break;
        default:
          frame.set('transform', 'translateX', `${beforeTranslate[0]}px`);
          frame.set('transform', 'translateY', `${beforeTranslate[1]}px`);
          handlerFrame.set('transform', 'translateX', `${beforeTranslate[0] - diffX}px`);
          handlerFrame.set('transform', 'translateY', `${beforeTranslate[1] - diffY}px`);
          frameLineStart.set('transform', 'translateX', `${beforeTranslate[0] - 10}px`);
          frameLineStart.set('transform', 'translateY', `${beforeTranslate[1] - 10}px`);
          frameLineEnd.set('transform', 'translateX', `${parseFloat(target.dataset.x2) - parseFloat(target.dataset.x1) - 10 + beforeTranslate[0]}px`);
          frameLineEnd.set('transform', 'translateY', `${parseFloat(target.dataset.y2) - parseFloat(target.dataset.y1) - 10 + beforeTranslate[1]}px`);
          frameSnapHandleLeft.set('transform', 'translateX', `${beforeTranslate[0]}px`);
          frameSnapHandleLeft.set('transform', 'translateY', `${beforeTranslate[1] - 0.5}px`);
          frameSnapHandleRight.set('transform', 'translateX', `${parseFloat(target.dataset.x2) - parseFloat(target.dataset.x1) + beforeTranslate[0] + diffRightHandleX}px`);
          frameSnapHandleRight.set('transform', 'translateY', `${parseFloat(target.dataset.y2) - parseFloat(target.dataset.y1) + beforeTranslate[1] + diffRightHandleY}px`);
      }

      lineStart.style.cssText += frameLineStart.toCSS();
      lineEnd.style.cssText += frameLineEnd.toCSS();
      snapHandleLeft.style.cssText += frameSnapHandleLeft.toCSS();
      snapHandleRight.style.cssText += frameSnapHandleRight.toCSS();
      target.style.cssText += frame.toCSS();
      lineHandler.style.cssText += handlerFrame.toCSS();

      const actualLine = actualTarget.querySelector('.actual-line');
      actualTarget.setAttribute('data-x1', parseFloat(frameLineStart.get('transform', 'translateX')) + 10);
      actualTarget.setAttribute('data-y1', parseFloat(frameLineStart.get('transform', 'translateY')) + 10);
      actualTarget.setAttribute('data-x2', parseFloat(frameLineEnd.get('transform', 'translateX')) + 10);
      actualTarget.setAttribute('data-y2', parseFloat(frameLineEnd.get('transform', 'translateY')) + 10);
      actualLine.setAttribute('x1', parseFloat(frameLineStart.get('transform', 'translateX')) + 10);
      actualLine.setAttribute('y1', parseFloat(frameLineStart.get('transform', 'translateY')) + 10);
      actualLine.setAttribute('x2', parseFloat(frameLineEnd.get('transform', 'translateX')) + 10);
      actualLine.setAttribute('y2', parseFloat(frameLineEnd.get('transform', 'translateY')) + 10);
      lineSnapper.setAttribute('data-x1', parseFloat(frameLineStart.get('transform', 'translateX')) + 10);
      lineSnapper.setAttribute('data-y1', parseFloat(frameLineStart.get('transform', 'translateY')) + 10);
      lineSnapper.setAttribute('data-x2', parseFloat(frameLineEnd.get('transform', 'translateX')) + 10);
      lineSnapper.setAttribute('data-y2', parseFloat(frameLineEnd.get('transform', 'translateY')) + 10);
      lineHandler.setAttribute('data-x1', parseFloat(frameLineStart.get('transform', 'translateX')) + 10);
      lineHandler.setAttribute('data-y1', parseFloat(frameLineStart.get('transform', 'translateY')));
      lineHandler.setAttribute('data-x2', parseFloat(frameLineEnd.get('transform', 'translateX')) + 10);
      lineHandler.setAttribute('data-y2', parseFloat(frameLineEnd.get('transform', 'translateY')));
    });

    moveableForLineBody.on('dragEnd', ({ target, inputEvent }) => {
      this.removeThumbnailsDragTargets();

      if (this.isDragToMoveTarget(inputEvent)) {
        return this.moveDraggedItemToNewSlide(inputEvent, target.closest('.item-container').firstElementChild);
      }

      const actualTarget = target.closest('.item-container').querySelector('.item-type-line');
      actualTarget.dataset.moved = '0';
      const actualLine = actualTarget.querySelector('.actual-line');

      const lineStart = actualTarget.closest('.item-container').querySelector('.handle-left');
      const lineEnd = actualTarget.closest('.item-container').querySelector('.handle-right');
      const snapHandleLeft = actualTarget.closest('.item-container').querySelector('.snap-handle-left');
      const snapHandleRight = actualTarget.closest('.item-container').querySelector('.snap-handle-right');

      lineStart.dataset.cx = actualLine.getAttribute('x1');
      lineStart.dataset.cy = actualLine.getAttribute('y1');
      lineEnd.dataset.cx = actualLine.getAttribute('x2');
      lineEnd.dataset.cy = actualLine.getAttribute('y2');
      snapHandleLeft.dataset.cx = actualLine.getAttribute('x1');
      snapHandleLeft.dataset.cy = actualLine.getAttribute('y1');
      snapHandleRight.dataset.cx = actualLine.getAttribute('x2');
      snapHandleRight.dataset.cy = actualLine.getAttribute('y2');

      window.weAreDraggingLine = null;

      if (window.dragDuplicate) {
        clearCloneCursor();
        window.dragDuplicate = false;
        target.closest('.item-container').dataset.clone = 'false';
        target.closest('.item-container').querySelector('.item-type-line').dataset.clone = 'false';
        this.stimulate('Item#update_without_undo', actualLine);
      } else {
        this.stimulate('Item#update', actualLine);
      }

      this.disallowDeselect(10);

      // set defaults for drag axis
      dragAxisForLineBody.direction = 'xy';
      dragAxisForLineBody.locked = false;
    });

    window.moveableForLineBody = moveableForLineBody;
  }

  setItemAttributes(target) {
    const frame = window.mapItems.get(target);
    const attributes = frame.get('transform');

    target.dataset.width = parseFloat(frame.get('width'));
    target.dataset.height = parseFloat(frame.get('height'));
    target.dataset.translateX = parseFloat(attributes.translateX);
    target.dataset.translateY = parseFloat(attributes.translateY);
    target.dataset.rotate = `${parseInt(attributes.rotate, 0) || 0}deg`;
    if (frame.clipStyle && frame.clipStyle !== 'undefined') target.dataset.clip = frame.clipStyle;
  }

  updateCropAttributes(target) {
    calculateCropAttributes(target);
  }

  setItemAttributesForGroup(target) {
    const frame = window.mapItems.get(target);
    const attributes = frame.get('transform');

    target.dataset.width = parseFloat(frame.get('width'));
    target.dataset.height = parseFloat(frame.get('height'));
    target.dataset.translateX = parseFloat(attributes.translateX);
    target.dataset.translateY = parseFloat(attributes.translateY);
    target.dataset.rotate = `${parseInt(attributes.rotate, 0) || 0}deg`;
    if (frame.clipStyle && frame.clipStyle !== 'undefined') target.dataset.clip = frame.clipStyle;
  }

  activateSelecto() {
    if (window.selectoForItems) {
      return;
    }

    const selecto = new Selecto({
      container: document.body,
      dragContainer: document.querySelector('.viewer'),
      selectableTargets: ['.draggable-item-container:not(.line-container)', '.line-snapper'],
      selectByClick: false,
      selectFromInside: false,
      continueSelect: false,
      toggleContinueSelect: 'shift',
      keyContainer: window,
      hitRate: 0,
      getElementRect: getElementInfo,
    });

    window.selectoForItems = selecto;

    selecto.on('dragStart', (e) => {
      const { target } = e.inputEvent;
      if (e.inputEvent.type === 'touchstart' && e.inputEvent.touches.length > 1) {
        e.stop();
        return;
      }
      if (window.cropEventItem) {
        e.stop();
        return;
      }

      if (window.moveableForItems.isMoveableElement(target) || target.closest('.moveable-dropdown')) {
        e.stop();
        return;
      }

      if (target.classList.contains('handle')) {
        e.stop();
        return;
      }

      if (target.classList.contains('line-handler')) {
        e.stop();
        return;
      }

      if (target.classList.contains('line-snapper')) {
        e.stop();
        return;
      }

      if (document.body.classList.contains('handMode')) {
        e.stop();
        return;
      }

      // Right click on slide
      if (target.closest('.slideContextMenu')) {
        e.stop();
        return;
      }

      if (!e.inputEvent.shiftKey) {
        const selectedItems = document.querySelectorAll('.item-container .selected');
        for (const selectedItem of selectedItems) {
          selectedItem.classList.remove('selected');
          if (selectedItem.closest('.item-container').querySelector('.handle')) {
            selectedItem.closest('.item-container').querySelector('.handle-left').classList.add('hidden');
            selectedItem.closest('.item-container').querySelector('.handle-right').classList.add('hidden');
          }
        }
        window.moveableForGroups.target = null;
        window.moveableForItems.target = null;
        window.moveableForText.target = null;
        window.moveableForLineHandle.target = null;
        window.moveableForLineBody.target = null;
        hideContextMenus();
        hideItemPanel();
      }
    });

    // let selectedGroups = false;
    selecto.on('select', (e) => {
      let targets = e.selected;

      // Do we have a hit on a grouped item? If so, select all other group items, too
      const groupIds = [...new Set(targets.map((target) => target.dataset.groupId).filter((id) => id !== ''))];
      if (groupIds.length > 0) {
        groupIds.forEach((groupId) => {
          let groupItems = [...document.querySelectorAll(`
          .item-container .draggable-item-container[data-group-id="${groupId}"]:not(.item-type-line),
          .item-container .line-snapper[data-group-id="${groupId}"]
        `)];
          // Filter items that are already in targets, otherwise moveable goes crazy
          groupItems = groupItems.filter((item) => !targets.includes(item));
          targets.push(...groupItems);
        });
        targets = [...new Set(targets)];
      }

      targets.forEach((target) => {
        if (target.classList.contains('line-snapper')) {
          const selectedLine = target.closest('.item-container').querySelector('.item-type-line');
          selectedLine.classList.add('selected');
          target.classList.add('selected');
        } else {
          target.classList.add('selected');
        }
      });
      window.moveableForGroups.target = targets;
    });

    selecto.on('selectEnd', (e) => {
      window.selectoActive = false;
      const preSelectedItems = Array.from(document.querySelectorAll('.item-container .selected:not(.item-type-line), .item-container .selected.line-snapper'));
      const selectedItems = e.selected;
      if (!selectedItems) return;

      // Unselect items
      preSelectedItems.forEach((item) => {
        if (selectedItems.indexOf(item) === -1) {
          if (item.classList.contains('line-snapper')) {
            const selectedLine = item.closest('.item-container').querySelector('.item-type-line');
            selectedLine.classList.remove('selected');
            item.classList.remove('selected');
          } else {
            item.classList.remove('selected');
          }
        }
      });

      if (selectedItems.length > 0) {
        if (selectedItems.length === 1) {
          window.moveableForGroups.target = null;
          const target = selectedItems[0];

          if (target.dataset.itemType === 'text') {
            window.moveableForText.target = target;
          } else if (target.classList.contains('line-snapper')) {
            const selectedLine = target.closest('.item-container').querySelector('.item-type-line');
            selectedLine.classList.add('selected');
            selectedLine.closest('.item-container').querySelector('.handle-left').classList.remove('hidden');
            selectedLine.closest('.item-container').querySelector('.handle-right').classList.remove('hidden');
          } else {
            window.moveableForItems.target = target;
          }
        } else {
          window.moveableForGroups.target = selectedItems;
          window.moveableForItems.target = null;
          window.moveableForText.target = null;
          window.moveableForLineHandle.target = null;
          window.moveableForLineBody.target = null;
          window.moveableForGroups.elementGuidelines = [...document.querySelectorAll('.draggable-item-container:not(.line-container)'), document.querySelector('.canvasContainer')];
        }

        this.disallowDeselect(20);
        this.stimulate('Item#select', { resolveLate: true }, this.selectedItemIds()).then(() => {
          if (selectedItems.length > 1) {
            if (document.querySelector('.moveable-area')) {
              const target = document.querySelector('.moveable-area');
              target.dataset.controller = 'editor--context-menu';
              target.dataset.action = 'contextmenu->editor--context-menu#displayContextMenu';
            }
          }
        });
      } else {
        for (const selectedItem of selectedItems) {
          selectedItem.classList.remove('selected');
          if (selectedItem.closest('.item-container').querySelector('.handle')) {
            selectedItem.closest('.item-container').querySelector('.handle-left').classList.add('hidden');
            selectedItem.closest('.item-container').querySelector('.handle-right').classList.add('hidden');
          }
        }

        window.moveableForItems.target = null;
        window.moveableForGroups.target = null;
        window.moveableForText.target = null;
        window.moveableForLineHandle.target = null;
        window.moveableForLineBody.target = null;
      }
    });
  }

  disallowDeselect(ms = 10) {
    window.draggingElement = true;
    setTimeout(() => {
      window.draggingElement = false;
    }, ms);
  }

  _generateRandomString() {
    return Math.round((Math.random() * 36 ** 12)).toString(36);
  }

  selectedItems() {
    return document.querySelectorAll('.item-container .selected');
  }

  selectedItemIds() {
    return Array.from(this.selectedItems())
      .map((div) => div.dataset.itemId)
      .join();
  }

  getZoomScale() {
    const zoomLevel = parseFloat(window.zoomLevel);
    return zoomLevel;
  }

  snapshotHistoryForClonedItems(event) {
    const randomSelectId = Math.round((Math.random() * 36 ** 12)).toString(36);
    event.detail.newItemIds.forEach((itemId) => {
      storeItemSnapshotForUndo(itemId, 'ADD_SLIDE_ITEM', randomSelectId);
    });
  }

  makeThumbnailsDragTargets() {
    document.querySelectorAll('#pageThumbs .slide').forEach((el) => {
      el.classList.add('slide-drag-target');
    });
  }

  removeThumbnailsDragTargets() {
    document.querySelectorAll('#pageThumbs .slide').forEach((el) => {
      el.classList.remove('slide-drag-target');
    });
  }

  isDragToMoveTarget(inputEvent) {
    return inputEvent.target.closest('.slideContainer') !== null;
  }

  moveDraggedItemToNewSlide(inputEvent, targets) {
    const slideTarget = inputEvent.target.closest('.slideContainer');
    let sourceSlideId;
    let targetSlideId;
    const itemIds = [];

    [].concat(targets || []).forEach((target) => {
      // Move item back before cloning
      target.style.visibility = 'hidden';
      const frame = window.mapItems.get(target);
      frame.set('transform', 'translateX', `${target.dataset.translateX}px`);
      frame.set('transform', 'translateY', `${target.dataset.translateY}px`);
      target.style.cssText += frame.toCSS();

      targetSlideId = slideTarget.dataset.slideId;
      sourceSlideId = target.dataset.slideId;

      itemIds.push(target.dataset.itemId);
      document.querySelector('.canvas').canvas.unSelectAllItems();
    });

    // Clone element
    this.stimulate('Item#clone_to_slide', { itemIds, sourceSlideId, targetSlideId });

    return true;
  }
}
