const items = (itemIds) => (
  itemIds.map((itemId) => document.querySelector(`#draggable-item-container-${itemId}`))
);

const batchSave = (itemIds, controller) => {
  controller.stimulate('Item#batch_update_via_frontend', items(itemIds).map((item) => item.dataset)).then(() => {
    window.Diagrams.initializeDiagrams();
  });
};

export default batchSave;
