import DragScroll from "dragscroll";
import hideCommentBox from "../comments/hideCommentBox";
import hideItemPanel from "../panels/hideItemPanel";
import hideCommentPanel from "../panels/hideCommentPanel";

const switchMode = (controller, event, mode) => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  if (mode === 'Hand') {
    switchToHand();
  } else if (mode === 'Pointer') {
    switchToPointer(controller);
  } else if (mode === 'Comment') {
    switchToComment(controller);
  }

  DragScroll.reset();
  document.querySelector('#pen').classList.add('hidden');
};

const switchToHand = () => {
  document.body.classList.remove('pointerMode', 'commentMode', 'insertCommentMode');
  document.body.classList.add('handMode', 'dragscroll');
  document.querySelector('.infinite-viewer-wrapper').classList.add('dragscroll');

  hideItemPanel();
  hideCommentPanel();
  hideCommentBox();
}

const switchToPointer = (controller) => {
  let resetCanvas = false;
  if (document.body.classList.contains('commentMode')) {
    resetCanvas = true;
  }
  document.body.classList.remove('handMode', 'dragscroll', 'commentMode', 'insertCommentMode');
  document.querySelector('.infinite-viewer-wrapper').classList.remove('dragscroll');
  document.body.classList.add('pointerMode');

  if (resetCanvas) controller.stimulate('Comment#render_canvas');
  hideItemPanel();
  hideCommentPanel();
  hideCommentBox();
}

const switchToComment = (controller) => {
  document.body.classList.remove('handMode', 'dragscroll', 'pointerMode');
  document.querySelector('.infinite-viewer-wrapper').classList.remove('dragscroll');
  document.body.classList.add('commentMode', 'insertCommentMode');

  controller.stimulate('Comment#show_panel');
}

export default switchMode;
