import { Controller } from "stimulus";
import InfiniteScroll from "infinite-scroll";
import Rails from 'rails-ujs';
import {htmlToElement} from '../helpers/dom';

export default class extends Controller {
  static targets = ["users", "input", "resultContent"]

  selectCoach(event) {
    var formData = new FormData();
    formData.append('user_id', event.detail.value);

    Rails.ajax({
      url: `/studios/${this.data.get("studio-id")}/add_coach_to_studio`,
      type: 'POST',
      data: formData,
      success: (resp, status, xhr) => {
        const existing = this.usersTarget.querySelector(`[data-user-id="${event.detail.value}"]`);
        if (existing !== null) {
          existing.replaceWith(htmlToElement(xhr.responseText));
        } else {
          this.usersTarget.insertAdjacentHTML('beforeend', xhr.responseText);
        }

        this.inputTarget.value = '';
      }
    });

  }

  selectStudent(event) {
    var formData = new FormData();
    formData.append('user_id', event.detail.value);

    Rails.ajax({
      url: `/studios/${this.data.get("studio-id")}/add_student_to_studio`,
      type: 'POST',
      data: formData,
      success: (resp, status, xhr) => {
        this.usersTarget.insertAdjacentHTML('beforeend', xhr.responseText);
        this.inputTarget.value = '';
      }
    });
  }

  selectTA(event) {
    var formData = new FormData();
    formData.append('user_id', event.detail.value);

    Rails.ajax({
      url: `/studios/${this.data.get("studio-id")}/add_ta_to_studio`,
      type: 'POST',
      data: formData,
      success: (resp, status, xhr) => {
        this.usersTarget.insertAdjacentHTML('beforeend', xhr.responseText);
        this.inputTarget.value = '';
      }
    });
  }

  onFeedAutocompletePressEnter(event) {
    var query = event.target.value
    if (event.keyCode === 13 && !query.startsWith('#') && !query.startsWith('@')) {

      if (query === '') {
        window.location = `/studios/${this.data.get("studio-id")}`
      }
      var searchParams = `query:${query}`
      this.searchPosts(searchParams)
    }
  }

  feedSearchSelectItem(event) {
    var searchParams = event.detail.value
    this.searchPosts(searchParams)
  }

  searchPosts(searchParams) {
    var target = document.getElementById('feed-posts')
    target.innerHTML =  "<div class='text-center mt-5'>searching...</div>";
    var formData = new FormData();
    var parts = searchParams.split(':')
    var modelName = parts[0]
    var modelValue = parts[1]
    formData.append(modelName, modelValue)

    // Make rest button visible
    document.getElementById("feed-reset-button").classList.remove("invisible")

    const queryString = new URLSearchParams(formData).toString()
    Rails.ajax({
      url: `/studios/${this.data.get("studio-id")}/search_results?${queryString}`,
      type: 'GET',
      success: (resp, status, xhr) => {
        var target = document.getElementById('feed-posts')
        target.innerHTML =  xhr.responseText;
        if (window.infScroll) {
          window.infScroll.destroy()
        }
        window.infScroll = new InfiniteScroll('.infinite-scroll', {
          // options
          path: '.pagination__next',
          append: '.post',
          history: false,
          debug: false
        });
      }
    });
  }
}
