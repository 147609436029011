/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "menuItem" ]

  toggle(event) {
    event.preventDefault();
    var menu_to_show = event.target.parentElement.dataset.item;

    document.querySelectorAll('.right-drawer-container > div').forEach((el, i) => {
      if(el.id != menu_to_show) {
        el.classList.toggle('hidden', true)
      } else {
        el.classList.toggle('hidden')
      }
    })
    if (document.getElementById(menu_to_show)) {
      document.getElementById(menu_to_show).style.height = document.getElementById('content-height').offsetHeight + 'px';
    }
  }
}
