/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import Sortable from 'sortablejs';
import { htmlToElement } from '.././helpers/dom';

export default class extends Controller {
  static targets = [ "studioCreditScoreTemplate", "studioCreditScoresFormContainer", "studioCreditScoreName", "studioCreditScoreForm" ]

  connect() {
    // if (this.hasSchoolFieldFormTarget) {
    //   // Show the first skill category while all others are hiding
    //   this.studioCreditScoreFormTargets[0].classList.toggle('hidden');
    // }

    // Make the skill category selectors sortable
    const sortable = document.querySelector('#studio-credit-scores-sortable.sortable');
    var el = Sortable.create(sortable, {
      animation: 150,
      draggable: "li.btn",
      filter: ".ignore-sort",
      forceFallback: true,
      onEnd: function(event) {
        let studioCreditScoreId = event.item.getAttribute('data-studio-credit-score-id');
        document.querySelector(`[data-studio-credit-score-id="${studioCreditScoreId}"] input.position`).value = event.newIndex + 1;
      }
    });

    // Highlight initial skill category
    // document.querySelector('#studio-credit-scores-sortable.sortable li').classList.add('btn-pink');
  }

  switchTo(event) {
    this.studioCreditScoreFormTargets.forEach((el, i) => {
      el.classList.toggle("hidden", el.dataset.studioCreditScoreId != event.target.dataset.studioCreditScoreId)
    });

    // Highlight selected tab
    let sortableSelector = '#studio-credit-scores-sortable.sortable li.btn-pink'
    if (document.querySelector(sortableSelector) != null) {
      document.querySelector(sortableSelector).classList.remove('btn-pink');
    }
    event.target.classList.add('btn-pink');
  }

  addNewItem(event) {
    const id = new Date().getTime();
    const template = `<li class='inline-block mr-10 mb-14 btn' data-target='studios--studio-credit-scores.studioCreditScoreName' data-action='click->studios--studio-credit-scores#switchTo' data-studio-credit-score-id='${id}'>New Field</li>`
    const newElementLink = htmlToElement(template);
    this._insertBefore(newElementLink, event.target);
    newElementLink.addEventListener("click", (ev) => {
      this.switchTo(ev);
    });
    const newElementForm = htmlToElement(this.studioCreditScoreTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, id));
    newElementForm.dataset.studioCreditScoreId = id;
    this.studioCreditScoresFormContainerTarget.insertAdjacentElement('beforeend', newElementForm);

    window.expandableTextArea();
    newElementLink.click();
    window.activateSlimSelect();
  }

  updateTitle(event) {
    const studioCreditScoreId = event.target.parentElement.parentElement.parentElement.parentElement.dataset.studioCreditScoreId;
    const parentHolder = event.target.parentElement.parentElement.parentElement;
    const subjectAreaText = parentHolder.querySelector('.subject-area').options[parentHolder.querySelector('.subject-area').selectedIndex].text;
    const creditScoreText = parentHolder.querySelector('.credit-score').options[parentHolder.querySelector('.credit-score').selectedIndex].text;
    this.studioCreditScoreNameTargets.find(el => el.dataset.studioCreditScoreId  == studioCreditScoreId).innerText = `${subjectAreaText} - ${creditScoreText}`;

  }

  removeItem(event) {
    const itemForm = event.target.parentElement;
    const studioCreditScoreId = itemForm.dataset.studioCreditScoreId;
    this.studioCreditScoreNameTargets.find(el => el.dataset.studioCreditScoreId  == studioCreditScoreId).style.display = 'none';
    itemForm.querySelector("input[name*='_destroy']").value = 1;
    itemForm.style.display = 'none';
  }

  _insertBefore(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode);
  }

  _insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }
}
