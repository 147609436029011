import ApplicationController from './application_controller.js'
import interact from "interactjs";

export default class extends ApplicationController {
  connect() {
    super.connect();

    interact('.croppable-item').unset();

    interact('.croppable-item')
      .draggable({
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ]
      })
      .on('dragmove', (event) => {
        let zoomLevel = this.getZoomScale();

        var target = event.target;
        var x = (parseFloat(target.getAttribute('data-x')) || 0) + (event.dx / zoomLevel);
        var y = (parseFloat(target.getAttribute('data-y')) || 0) + (event.dy / zoomLevel);

        target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);
        target.setAttribute('data-width', (event.rect.width / zoomLevel));
        target.setAttribute('data-height', (event.rect.height / zoomLevel));
        window.Diagrams.updateDiagramPositions();
      })
      .resizable({
        edges: { left: true, right: true, bottom: true, top: true },

        modifiers: [
          interact.modifiers.restrictSize({
            min: { width: 10, height: 10 },
          }),
          interact.modifiers.restrict({
            restriction: 'parent',
            endOnly: true
          })
        ],

        inertia: true
      })
      .on('resizemove', (event) => {
        var target = event.target;
        var x = (parseFloat(target.getAttribute('data-x')) || 0);
        var y = (parseFloat(target.getAttribute('data-y')) || 0);

        let zoomLevel = this.getZoomScale();

        target.style.width = (event.rect.width / zoomLevel) + 'px';
        target.style.height = (event.rect.height / zoomLevel) + 'px';

        x += event.deltaRect.left / zoomLevel;
        y += event.deltaRect.top / zoomLevel;

        target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)';

        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);
        target.setAttribute('data-width', event.rect.width / zoomLevel);
        target.setAttribute('data-height', event.rect.height / zoomLevel);
      })
  }

  startCrop(event) {
    this.stimulate("Crop#enter_crop_mode", event.target.closest(".draggable-item-container"));
  }

  updateItem(event) {
    let item = event.target.closest('.item-container').querySelector('.croppable-item');

    this.stimulate("Crop#update", item);
  }

  getZoomScale() {
    let zoomLevel = parseFloat(window.zoomLevel);
    return zoomLevel;
  }
}
