/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ["templateSkill", "containerSkill", "templateSkillCategoryLevel", "containerSkillCategoryLevel", "containerSkillCategoryInput", "templateSkillCategoryInput", "skillLevelItem"]

  connect() {
    // Make the skill category selectors sortable
    const sortables = document.querySelectorAll('#skills-sortable.sortable');
    sortables.forEach(function (sortable, index) {
      var el = Sortable.create(sortable, {
        animation: 150,
        group: {
          name: 'child-group'
        },
        draggable: "div.skill-field-li",
        handle: '.sort-handle',
        forceFallback: true,
        onEnd: function (event) {
          el.toArray().forEach(function (item, index) {
            document.querySelector(`[data-id=${item}] .skill_position`).value = index + 1;
          })
        }
      })
    })
    // Highlight initial skill category
    // document.querySelector('#skills-sortable.sortable li').classList.add('btn-pink');
  }

  get skillCategoryLevels() {
    return JSON.parse(this.data.get("skillCategoryLevels"))
  }

  set skillCategoryLevels(value) {
    this.data.set("skillCategoryLevels", JSON.stringify(value))
  }

  // Beginner, Advanced
  addSkillCategoryLevel(event) {
    event.preventDefault()
    var uniqueId = Math.floor(Math.random() * 100000000)
    var content = this.templateSkillCategoryLevelTarget.innerHTML.replace(/SKILL_CATEGORY_LEVEL_ID/g, uniqueId).replace(/UNIQUE_ID/g, uniqueId)
    var newSkillCategoryLevels = this.skillCategoryLevels
    newSkillCategoryLevels.push({ id: uniqueId, name: '' })
    this.skillCategoryLevels = newSkillCategoryLevels
    this.containerSkillCategoryLevelTarget.insertAdjacentHTML('beforeend', content)
    this.containerSkillCategoryInputTargets.forEach((el, i) => {
      var skillIndex = el.closest('.skill').getAttribute('data-skill-index')
      var oldSkillRegex = new RegExp('(skills_attributes\\]\\[)\\d+', "g")
      var levelContent = this.templateSkillCategoryInputTarget.innerHTML.replace(/SKILL_LEVEL_ID/g, Math.floor(Math.random() * 100000000)).replace(/SKILL_CATEGORY_LEVEL_ID/g, uniqueId).replace(/LEVEL_NAME/g, 'NAME').replace(oldSkillRegex, `skills_attributes][${skillIndex.toString()}`)
      el.insertAdjacentHTML('beforeend', levelContent)
    })
  }

  // Engagement, Critique
  addSkill(event) {
    event.preventDefault()
    var uniqueSkillId = Math.floor(Math.random() * 100000000);
    var content = this.templateSkillTarget.innerHTML.replace(/SKILL_ID/g, uniqueSkillId)
    var levels = this.skillCategoryLevels
    this.containerSkillTarget.insertAdjacentHTML('afterbegin', content)
    levels.forEach((levelEl, i) => {
      this.containerSkillCategoryInputTargets.forEach((el, i) => {
        if (el.getAttribute('data-skill-id') == uniqueSkillId) {
          // We replace skill id here. Because it is already previous skill id assigned
          var skillIndex = el.closest('.skill').getAttribute('data-skill-index')
          var oldSkillRegex = new RegExp(this.templateSkillCategoryInputTarget.content.querySelector('.flex').getAttribute('data-skill-id'), "g")
          var levelContent = this.templateSkillCategoryInputTarget.innerHTML.replace(/SKILL_LEVEL_ID/g, Math.floor(Math.random() * 100000000)).replace(/SKILL_CATEGORY_LEVEL_ID/g, levelEl['id']).replace(/LEVEL_NAME/g, levelEl['name']).replace(oldSkillRegex, uniqueSkillId.toString())
          el.insertAdjacentHTML('beforeend', levelContent)
        }
      })
    })
    window.expandableTextArea();
  }

  onSkillCategoryLevelChanged(event) {
    var input = event.currentTarget
    var inputValue = input.value
    var skillCategoryLevelId = input.getAttribute('data-skill-category-level-id')
    var newSkillCategoryLevels = this.skillCategoryLevels
    for (var i in newSkillCategoryLevels) {
      if (newSkillCategoryLevels[i].id == skillCategoryLevelId) {
        newSkillCategoryLevels[i].name = inputValue;
        break;
      }
    }
    this.skillCategoryLevels = newSkillCategoryLevels
    this.skillLevelItemTargets.forEach((el, i) => {
      if (el.getAttribute('data-skill-category-level-id') == skillCategoryLevelId) {
        var levelNameArea = el.querySelector('.level_name')
        levelNameArea.innerHTML = inputValue
      }
    })
  }

  onSkillNameChanged(event) {
    var input = event.currentTarget
    var inputValue = input.value
    var labelElement = input.closest(".accordion").querySelector('label.skill_name')
    labelElement.innerHTML = inputValue
  }

  onSkillNameFocused(event) {
    var input = event.currentTarget;
    const accordions = document.querySelectorAll('input.accordion-trigger');
    accordions.forEach(accordion => {
      accordion.checked = false;
    });
    input.closest('.accordion').querySelector('input.accordion-trigger').checked = true;

  }

  onChangeSkillFocus(event) {
    window.expandableTextArea()
  }

  removeSkill(event) {
    if (event.currentTarget.dataset.hasGrade === 'yes') {
      if (confirm('This skill has grading data. Are you sure to delete?')) {
        this.removeSkillFromList(event)
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      this.removeSkillFromList(event)
    }
  }

  removeSkillFromList(event) {
    const formContainer = event.target.closest('.accordion');
    formContainer.querySelector("input[name*='_destroy']").value = 1;
    formContainer.classList.add('hidden');
  }

  removeSkillCategoryLevel(event) {
    const formContainer = event.target.closest('.skill-category-level-container');
    formContainer.querySelector("input[name*='_destroy']").value = 1;
    formContainer.classList.add('hidden');
  }
}
