import { Controller } from "stimulus"
import Rails from 'rails-ujs';

export default class extends Controller {
  addItemType(event) {
    event.preventDefault();
    event.stopPropagation();

    const itemType = event.target.parentNode.getAttribute('data-item-type');
    const slideId = event.target.parentNode.getAttribute('data-slide-id');
    const studioId = event.target.parentNode.getAttribute('data-studio-id');

    Rails.ajax({
      url: `/items/create_via_link?item_type=${itemType}&slide_id=${slideId}&studio_id=${studioId}`,
      type: 'GET'
    });
  }
}
