import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["source"];

  copy(event) {
    event.preventDefault()
    const text = this.sourceTarget.value || this.sourceTarget.innerHTML
    console.log(text)
    navigator.clipboard.writeText(text).then(() => this.copied())
  }

  copied () {
    if(this.data.has("copyMessage")) {
      notice(this.data.get("copyMessage"))
    }
    window.hideContextMenus()
  }
}
