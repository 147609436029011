/* jshint esversion: 6 */
/* global FroalaEditor */
import { Controller } from "stimulus";
import Rails from 'rails-ujs';
import interact from "interactjs"

export default class extends Controller {
  dragModifiers = [
    interact.modifiers.restrict({
      restriction: document.querySelector('.slide-editor'),
      elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
      endOnly: true
    })
  ];

  dragModifiersForLine = [
    interact.modifiers.restrict({
      restriction: document.querySelector('.slide-editor'),
      elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
      endOnly: true
    })
  ];

  resizeModifiers = [
    interact.modifiers.restrict({
      restriction: document.querySelector('.slide-editor'),
      endOnly: true
    })
  ];

  connect() {
    this.checkReady();
  }

  checkReady() {
    var self = this;

    if (document.readyState === 'complete') {
      this.ready();
    } else {
      window.setTimeout(self.checkReady.bind(self), 25);
    }
  }

  ready() {
    this.initializeImageItemType();
    this.initializeCameraAssetItemType();
    this.initializeTextItemType();
    this.initializeCodeItemType();
    this.initializeVideoItemType();
    this.initializeLineItemType();
    this.initializeTemplateItemType();
  }

  dragStartListener(event) {
    event.preventDefault();
    event.stopPropagation();

    var target = event.target.closest(".draggable-item-container");

    if (target.classList.contains('selected')) {
      // Already selected
    } else {
      window.unselectAllItems();
      target.classList.add('selected');
      Rails.ajax({ url: `/items/${target.getAttribute('data-item-id')}/select`, type: 'GET' });
    }

    let selectedItems = document.querySelectorAll('.item-container .selected');
    for (const target of selectedItems) {
      target.setAttribute('data-lock-x', target.getAttribute('data-x'));
      target.setAttribute('data-lock-y', target.getAttribute('data-y'));
      target.setAttribute('lock-direction', 'x');
    }
  }

  dragMoveListener(event) {
    event.preventDefault();
    event.stopPropagation();

    let selectedItems = document.querySelectorAll('.item-container .selected');

    for (const target of selectedItems) {
      // Get movement
      let diff_x = event.dx;
      let diff_y = event.dy;

      // Find axis & lock if Shift key is pressed.
      let currentDirection = (Math.abs(diff_x) < Math.abs(diff_y)) ? 'y' : 'x';
      let lastDirection = target.getAttribute('lock-direction');

      // keep the dragged position in the data-x/data-y attributes
      var x = (parseFloat(target.getAttribute('data-x')) || 0) + diff_x;
      var y = (parseFloat(target.getAttribute('data-y')) || 0) + diff_y;

      if (event.shiftKey) {
        if (lastDirection === 'x') {

          if (currentDirection === 'x') {
            y = parseFloat(target.getAttribute('data-lock-y'));
          } else if (currentDirection === 'y') {
            if (Math.abs(parseFloat(target.getAttribute('data-lock-x')) - x) < 25) {
              target.setAttribute('lock-direction', 'y');
              x = parseFloat(target.getAttribute('data-lock-x'));
            } else {
              y = parseFloat(target.getAttribute('data-lock-y'));
            }
          }

        } else if (lastDirection === 'y') {

          if (currentDirection === 'y') {
            x = parseFloat(target.getAttribute('data-lock-x'));
          } else if (currentDirection === 'x') {
            if (Math.abs(parseFloat(target.getAttribute('data-lock-y')) - y) < 25) {
              target.setAttribute('lock-direction', 'x');
              y = parseFloat(target.getAttribute('data-lock-y'));
            } else {
              x = parseFloat(target.getAttribute('data-lock-x'));
            }
          }

        }

      }

      // translate the element
      target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

      // update the position attributes
      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);

      if (document.querySelectorAll('.item-container .selected').length == 1) {
        var final_top = parseInt(y);
        var final_bottom = final_top + parseInt(target.clientHeight);
        var final_left = parseInt(x);
        var final_right = final_left + parseInt(target.style.width);

        if (final_top % 25 == 0) {
          document.getElementById('horizontal-guide-top').classList.remove('hidden');
          document.getElementById('horizontal-guide-top').style.top = final_top + 'px';
        } else {
          document.getElementById('horizontal-guide-top').classList.add('hidden');
        }

        if (final_bottom % 25 == 0) {
          document.getElementById('horizontal-guide-bottom').classList.remove('hidden');
          document.getElementById('horizontal-guide-bottom').style.top = (final_bottom - 2) + 'px';
        } else {
          document.getElementById('horizontal-guide-bottom').classList.add('hidden');
        }

        if (final_left % 25 == 0) {
          document.getElementById('vertical-guide-left').classList.remove('hidden');
          document.getElementById('vertical-guide-left').style.left = final_left + 'px';
        } else {
          document.getElementById('vertical-guide-left').classList.add('hidden');
        }

        if (final_right % 25 == 0) {
          document.getElementById('vertical-guide-right').classList.remove('hidden');
          document.getElementById('vertical-guide-right').style.left = (final_right - 2) + 'px';
        } else {
          document.getElementById('vertical-guide-right').classList.add('hidden');
        }
      }
    }
  }

  dragEndListener(event) {
    event.preventDefault();
    event.stopPropagation();

    let selectedItems = document.querySelectorAll('.item-container .selected');

    // If multiple items, calculate the outer borders
    if (selectedItems.length > 1) {
      let left = Math.min(...Array.from(selectedItems).map(x => parseFloat(x.getAttribute('data-x'))));
      let right = Math.max(...Array.from(selectedItems).map(x => (parseFloat(x.getAttribute('data-x')) + parseFloat(x.style.width))));
      let top = Math.min(...Array.from(selectedItems).map(x => parseFloat(x.getAttribute('data-y'))));
      let bottom = Math.max(...Array.from(selectedItems).map(x => (parseFloat(x.getAttribute('data-y')) + parseFloat(x.style.height))));

      if (left < 0) {
        for (const target of selectedItems) {
          var x = (parseFloat(target.getAttribute('data-x')) || 0);
          var y = (parseFloat(target.getAttribute('data-y')) || 0);
          x -= left;
          target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        }
      }

      if (right > 1200) {
        for (const target of selectedItems) {
          var x = (parseFloat(target.getAttribute('data-x')) || 0);
          var y = (parseFloat(target.getAttribute('data-y')) || 0);
          x -= (right - 1200);
          target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        }
      }

      if (top < 0) {
        for (const target of selectedItems) {
          var x = (parseFloat(target.getAttribute('data-x')) || 0);
          var y = (parseFloat(target.getAttribute('data-y')) || 0);
          y -= top;
          target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        }
      }

      if (bottom > 675) {
        for (const target of selectedItems) {
          var x = (parseFloat(target.getAttribute('data-x')) || 0);
          var y = (parseFloat(target.getAttribute('data-y')) || 0);
          y -= (bottom - 675);
          target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        }
      }
    }

    for (const target of selectedItems) {
      target.setAttribute('data-lock-x', target.getAttribute('data-x'));
      target.setAttribute('data-lock-y', target.getAttribute('data-y'));

      var itemId = target.getAttribute('data-item-id');
      var x = (parseFloat(target.getAttribute('data-x')) || 0);
      var y = (parseFloat(target.getAttribute('data-y')) || 0);

      let formData = new FormData();
      formData.append("item[left]", parseFloat(x));
      formData.append("item[top]", parseFloat(y));
      formData.append("item[update_ui]", false);
      formData.append("item[update_toolbar]", true);

      Rails.ajax({
        url: `/items/${itemId}`,
        type: "PATCH",
        data: formData
      });
    }
  }

  resizeMoveListener (event) {
    var target = event.target
    var x = (parseFloat(target.getAttribute('data-x')) || 0)
    var y = (parseFloat(target.getAttribute('data-y')) || 0)

    if ((x + event.deltaRect.left + event.rect.width) <= 1200 && (y + event.deltaRect.top + event.rect.height) <= 675) {
      // update the element's style
      target.style.width = event.rect.width + 'px'
      target.style.height = event.rect.height + 'px'

      // translate when resizing from top or left edges
      x += event.deltaRect.left
      y += event.deltaRect.top

      target.style.webkitTransform = target.style.transform =
          'translate(' + x + 'px,' + y + 'px)'

      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
    }

    if (target.classList.contains('item-type-grid')) {
      let table = target.querySelector('table');
      table.style.width = target.style.width;
      // Update each cell width
      var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
      for (let col of cols) {
        col.style.width =  (parseInt(table.style.width) * parseFloat(col.getAttribute('data-width-ratio'))) + 'px';
        col.setAttribute('width', (parseInt(table.style.width) * parseFloat(col.getAttribute('data-width-ratio'))) + 'px');
      }
    }

    var final_top = parseInt(y)
    var final_bottom = final_top + parseInt(target.clientHeight)
    var final_left = parseInt(x)
    var final_right = final_left + parseInt(target.style.width)

    if (final_top % 25 == 0) {
      document.getElementById('horizontal-guide-top').classList.remove('hidden');
      document.getElementById('horizontal-guide-top').style.top = final_top + 'px';
    } else {
      document.getElementById('horizontal-guide-top').classList.add('hidden');
    }

    if (final_bottom % 25 == 0) {
      document.getElementById('horizontal-guide-bottom').classList.remove('hidden');
      document.getElementById('horizontal-guide-bottom').style.top = (final_bottom - 2) + 'px';
    } else {
      document.getElementById('horizontal-guide-bottom').classList.add('hidden');
    }

    if (final_left % 25 == 0) {
      document.getElementById('vertical-guide-left').classList.remove('hidden');
      document.getElementById('vertical-guide-left').style.left = final_left + 'px';
    } else {
      document.getElementById('vertical-guide-left').classList.add('hidden');
    }

    if (final_right % 25 == 0) {
      document.getElementById('vertical-guide-right').classList.remove('hidden');
      document.getElementById('vertical-guide-right').style.left = (final_right - 2) + 'px';
    } else {
      document.getElementById('vertical-guide-right').classList.add('hidden');
    }
  }

  resizeEndListener (event) {
    var target = event.target
    var itemId = event.target.getAttribute('data-item-id')

    var x = (parseFloat(target.getAttribute('data-x')) || 0)
    var y = (parseFloat(target.getAttribute('data-y')) || 0)

    if (x < 0) {
      x = 0;
      target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
    }

    if (y < 0) {
      y = 0;
      target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
    }

    let formData = new FormData()
    formData.append("item[width]", parseInt(target.style.width));
    formData.append("item[height]", parseInt(target.style.height));
    formData.append("item[left]", x);
    formData.append("item[top]", y);
    formData.append("item[update_ui]", false);

    Rails.ajax({
      url: `/items/${itemId}`,
      type: "PATCH",
      data: formData
    });

    if (target.classList.contains('item-type-grid')) {
      var table = event.target.querySelector('table');
      var row = table.getElementsByTagName('tr')[0],
      cols = row ? row.children : undefined;
      if (!cols) return;

      let cellformData = new FormData();
      for (var i=0;i<cols.length;i++) {
        cellformData.append(`column_width_${cols[i].getAttribute('data-column-index')}`, cols[i].offsetWidth);
      }

      Rails.ajax({
        url: `/items/${itemId}/update_grid_column_width`,
        type: "POST",
        data: cellformData
      });
    }
  }

  initializeImageItemType() {
    // Image Item Type
    var elements = document.querySelectorAll('.draggable-item-container.item-type-image');
    for (let element of elements) {
      if (!element.classList.contains('interact-activated')) {
        element.classList.add('interact-activated');

        interact(element)
          .draggable({
            origin: 'parent',
            modifiers: this.dragModifiers,
            listeners: {
              end (event) {
              }
            }
          })
          .on('dragstart', this.dragStartListener)
          .on('dragmove', this.dragMoveListener)
          .on('dragend', this.dragEndListener)
          .resizable({
            origin: 'parent',
            edges: { left: true, right: true, bottom: true, top: true },
            preserveAspectRatio: true,
            modifiers: [...this.resizeModifiers, interact.modifiers.aspectRatio({ ratio: 'preserve' })],
            inertia: true,
            restriction: '.slide-editor',
            listeners: {
              move (event) {
                var target = event.target
                var x = (parseFloat(target.getAttribute('data-x')) || 0)
                var y = (parseFloat(target.getAttribute('data-y')) || 0)

                // update the element's style
                target.style.width = event.rect.width + 'px'
                target.style.height = event.rect.height + 'px'

                // translate when resizing from top or left edges
                x += event.deltaRect.left
                y += event.deltaRect.top

                target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

                target.setAttribute('data-x', x)
                target.setAttribute('data-y', y)

                // update image if crop exists
                var horizontal_crop_ratio = parseFloat(target.getAttribute('data-horizontal-crop-ratio'))
                var vertical_crop_ratio = parseFloat(target.getAttribute('data-vertical-crop-ratio'))
                var original_width = parseFloat(target.getAttribute('data-original-width'))
                var original_height = parseFloat(target.getAttribute('data-original-height'))
                var crop_left = parseFloat(target.getAttribute('data-crop-x'))
                var crop_top = parseFloat(target.getAttribute('data-crop-y'))

                if (horizontal_crop_ratio > 0 && vertical_crop_ratio > 0) {
                  var image = target.getElementsByClassName("item-type-image")[0]

                  image.style.width = (event.rect.width * horizontal_crop_ratio) + 'px'
                  image.style.height = (event.rect.height * vertical_crop_ratio) + 'px'
                  var image_top = event.rect.height * crop_top / original_height * vertical_crop_ratio
                  var image_left = event.rect.width * crop_left / original_width * horizontal_crop_ratio
                  image.style.top = -(image_top) + 'px'
                  image.style.left = -(image_left) + 'px'
                }

                var final_top = parseInt(y)
                var final_bottom = final_top + parseInt(target.clientHeight)
                var final_left = parseInt(x)
                var final_right = final_left + parseInt(target.style.width)

                if (final_top % 25 == 0) {
                  document.getElementById('horizontal-guide-top').classList.remove('hidden');
                  document.getElementById('horizontal-guide-top').style.top = final_top + 'px';
                } else {
                  document.getElementById('horizontal-guide-top').classList.add('hidden');
                }

                if (final_bottom % 25 == 0) {
                  document.getElementById('horizontal-guide-bottom').classList.remove('hidden');
                  document.getElementById('horizontal-guide-bottom').style.top = (final_bottom - 2) + 'px';
                } else {
                  document.getElementById('horizontal-guide-bottom').classList.add('hidden');
                }

                if (final_left % 25 == 0) {
                  document.getElementById('vertical-guide-left').classList.remove('hidden');
                  document.getElementById('vertical-guide-left').style.left = final_left + 'px';
                } else {
                  document.getElementById('vertical-guide-left').classList.add('hidden');
                }

                if (final_right % 25 == 0) {
                  document.getElementById('vertical-guide-right').classList.remove('hidden');
                  document.getElementById('vertical-guide-right').style.left = (final_right - 2) + 'px';
                } else {
                  document.getElementById('vertical-guide-right').classList.add('hidden');
                }
              },
              end (event) {
                var target = event.target
                var itemId = event.target.getAttribute('data-item-id')

                var x = (parseFloat(target.getAttribute('data-x')) || 0)
                var y = (parseFloat(target.getAttribute('data-y')) || 0)

                if (x < 0) {
                  x = 0;
                  target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
                  target.setAttribute('data-x', x)
                  target.setAttribute('data-y', y)
                }

                if (y < 0) {
                  y = 0;
                  target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
                  target.setAttribute('data-x', x)
                  target.setAttribute('data-y', y)
                }

                let formData = new FormData();
                formData.append("item[width]", parseInt(target.style.width));
                formData.append("item[height]", parseInt(target.style.height));
                formData.append("item[left]", parseInt(x));
                formData.append("item[top]", parseInt(y));
                formData.append("item[update_ui]", true);
                formData.append("item[update_toolbar]", true);

                var horizontal_crop_ratio = parseFloat(target.getAttribute('data-horizontal-crop-ratio'))
                var vertical_crop_ratio = parseFloat(target.getAttribute('data-vertical-crop-ratio'))

                if (horizontal_crop_ratio > 0 && vertical_crop_ratio > 0) {
                  var image = target.getElementsByClassName("item-type-image")[0]

                  formData.append("item[crop_top]", -(parseInt(image.style.top)));
                  formData.append("item[crop_left]", -(parseInt(image.style.left)));
                  formData.append("item[crop_w]", parseFloat(target.style.width));
                  formData.append("item[crop_h]", parseFloat(target.style.height));
                  formData.append("item[width]", parseFloat(target.style.width) * horizontal_crop_ratio);
                  formData.append("item[height]", parseFloat(target.style.height) * vertical_crop_ratio);
                }

                Rails.ajax({
                  url: `/items/${itemId}`,
                  type: "PATCH",
                  data: formData
                });
              }
            }
          })
          .on('resizestart', this.dragStartListener)
      }
    }
  }

  initializeCameraAssetItemType() {
    // Image Item Type
    var elements = document.querySelectorAll('.draggable-item-container.item-type-camera_asset');
    for (let element of elements) {
      if (!element.classList.contains('interact-activated')) {
        element.classList.add('interact-activated');

        interact(element)
          .draggable({
            origin: 'parent',
            modifiers: this.dragModifiers,
            listeners: {
              end (event) {
              }
            }
          })
          .on('dragstart', this.dragStartListener)
          .on('dragmove', this.dragMoveListener)
          .on('dragend', this.dragEndListener)
          .resizable({
            origin: 'parent',
            edges: { left: true, right: true, bottom: true, top: true },
            preserveAspectRatio: true,
            modifiers: [...this.resizeModifiers, interact.modifiers.aspectRatio({ ratio: 'preserve' })],
            inertia: true,
            restriction: '.slide-editor',
            listeners: {
              move (event) {
                var target = event.target
                var x = (parseFloat(target.getAttribute('data-x')) || 0)
                var y = (parseFloat(target.getAttribute('data-y')) || 0)

                // update the element's style
                target.style.width = event.rect.width + 'px'
                target.style.height = event.rect.height + 'px'

                // translate when resizing from top or left edges
                x += event.deltaRect.left
                y += event.deltaRect.top

                target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

                target.setAttribute('data-x', x)
                target.setAttribute('data-y', y)

                // update image if crop exists
                var horizontal_crop_ratio = parseFloat(target.getAttribute('data-horizontal-crop-ratio'))
                var vertical_crop_ratio = parseFloat(target.getAttribute('data-vertical-crop-ratio'))
                var original_width = parseFloat(target.getAttribute('data-original-width'))
                var original_height = parseFloat(target.getAttribute('data-original-height'))
                var crop_left = parseFloat(target.getAttribute('data-crop-x'))
                var crop_top = parseFloat(target.getAttribute('data-crop-y'))

                if (horizontal_crop_ratio > 0 && vertical_crop_ratio > 0) {
                  var image = target.getElementsByClassName("item-type-camera_asset")[0]

                  image.style.width = (event.rect.width * horizontal_crop_ratio) + 'px'
                  image.style.height = (event.rect.height * vertical_crop_ratio) + 'px'
                  var image_top = event.rect.height * crop_top / original_height * vertical_crop_ratio
                  var image_left = event.rect.width * crop_left / original_width * horizontal_crop_ratio
                  image.style.top = -(image_top) + 'px'
                  image.style.left = -(image_left) + 'px'
                }

                var final_top = parseInt(y)
                var final_bottom = final_top + parseInt(target.clientHeight)
                var final_left = parseInt(x)
                var final_right = final_left + parseInt(target.style.width)

                if (final_top % 25 == 0) {
                  document.getElementById('horizontal-guide-top').classList.remove('hidden');
                  document.getElementById('horizontal-guide-top').style.top = final_top + 'px';
                } else {
                  document.getElementById('horizontal-guide-top').classList.add('hidden');
                }

                if (final_bottom % 25 == 0) {
                  document.getElementById('horizontal-guide-bottom').classList.remove('hidden');
                  document.getElementById('horizontal-guide-bottom').style.top = (final_bottom - 2) + 'px';
                } else {
                  document.getElementById('horizontal-guide-bottom').classList.add('hidden');
                }

                if (final_left % 25 == 0) {
                  document.getElementById('vertical-guide-left').classList.remove('hidden');
                  document.getElementById('vertical-guide-left').style.left = final_left + 'px';
                } else {
                  document.getElementById('vertical-guide-left').classList.add('hidden');
                }

                if (final_right % 25 == 0) {
                  document.getElementById('vertical-guide-right').classList.remove('hidden');
                  document.getElementById('vertical-guide-right').style.left = (final_right - 2) + 'px';
                } else {
                  document.getElementById('vertical-guide-right').classList.add('hidden');
                }
              },
              end (event) {
                var target = event.target
                var itemId = event.target.getAttribute('data-item-id')

                var x = (parseFloat(target.getAttribute('data-x')) || 0)
                var y = (parseFloat(target.getAttribute('data-y')) || 0)

                if (x < 0) {
                  x = 0;
                  target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
                  target.setAttribute('data-x', x)
                  target.setAttribute('data-y', y)
                }

                if (y < 0) {
                  y = 0;
                  target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
                  target.setAttribute('data-x', x)
                  target.setAttribute('data-y', y)
                }

                let formData = new FormData();
                formData.append("item[width]", parseInt(target.style.width));
                formData.append("item[height]", parseInt(target.style.height));
                formData.append("item[left]", parseInt(x));
                formData.append("item[top]", parseInt(y));
                formData.append("item[update_ui]", true);
                formData.append("item[update_toolbar]", true);

                var horizontal_crop_ratio = parseFloat(target.getAttribute('data-horizontal-crop-ratio'))
                var vertical_crop_ratio = parseFloat(target.getAttribute('data-vertical-crop-ratio'))

                if (horizontal_crop_ratio > 0 && vertical_crop_ratio > 0) {
                  var image = target.getElementsByClassName("item-type-camera_asset")[0]

                  formData.append("item[crop_top]", -(parseInt(image.style.top)));
                  formData.append("item[crop_left]", -(parseInt(image.style.left)));
                  formData.append("item[crop_w]", parseFloat(target.style.width));
                  formData.append("item[crop_h]", parseFloat(target.style.height));
                  formData.append("item[width]", parseFloat(target.style.width) * horizontal_crop_ratio);
                  formData.append("item[height]", parseFloat(target.style.height) * vertical_crop_ratio);
                }

                Rails.ajax({
                  url: `/items/${itemId}`,
                  type: "PATCH",
                  data: formData
                });
              }
            }
          })
          .on('resizestart', this.dragStartListener)
      }
    }
  }

  initializeTextItemType() {
    // Text Item Type
    var elements = document.querySelectorAll('.draggable-item-container.item-type-text');
    for (let element of elements) {
      if (!element.classList.contains('interact-activated')) {
        element.classList.add('interact-activated');
        interact(element)
          .draggable({
            modifiers: this.dragModifiers,
            origin: 'parent'
          })
          .on('dragstart', this.dragStartListener)
          .on('dragmove', this.dragMoveListener)
          .on('dragend', this.dragEndListener)
          .resizable({
            origin: 'parent',
            edges: { left: true, right: true},
            preserveAspectRatio: false,
            modifiers: this.resizeModifiers,
            inertia: true,
          })
          .on('resizestart', this.dragStartListener)
          .on('resizemove', this.resizeMoveListener)
          .on('resizeend', this.resizeEndListener)
      }
    }
  }

  initializeCodeItemType() {
    // Code Item Type
    var elements = document.querySelectorAll('.draggable-item-container.item-type-code');
    for (let element of elements) {
      if (!element.classList.contains('interact-activated')) {
        element.classList.add('interact-activated');
        interact(element)
          .draggable({
            origin: 'parent',
            modifiers: this.dragModifiers,
          })
          .on('dragstart', this.dragStartListener)
          .on('dragmove', this.dragMoveListener)
          .on('dragend', this.dragEndListener)
          .resizable({
            origin: 'parent',
            edges: { left: true, right: true, top: true, bottom: true },
            preserveAspectRatio: true,
            modifiers: [...this.resizeModifiers, interact.modifiers.aspectRatio({ ratio: 'preserve' })],
            inertia: true,
          })
          .on('resizestart', this.dragStartListener)
          .on('resizemove', this.resizeMoveListener)
          .on('resizeend', this.resizeEndListener)
      }
    }
  }

  initializeVideoItemType() {
    // Video Item Type
    var elements = document.querySelectorAll('.draggable-item-container.item-type-video');
    for (let element of elements) {
      if (!element.classList.contains('interact-activated')) {
        element.classList.add('interact-activated');
        interact(element)
          .draggable({
            origin: 'parent',
            modifiers: this.dragModifiers,
          })
          .on('dragstart', this.dragStartListener)
          .on('dragmove', this.dragMoveListener)
          .on('dragend', this.dragEndListener)
          .resizable({
            origin: 'parent',
            edges: { left: true, right: true, top: true, bottom: true },
            preserveAspectRatio: true,
            modifiers: [...this.resizeModifiers, interact.modifiers.aspectRatio({ ratio: 'preserve' })],
            inertia: true,
          })
          .on('resizestart', this.dragStartListener)
          .on('resizemove', this.resizeMoveListener)
          .on('resizeend', this.resizeEndListener)
      }
    }
  }

  initializeLineItemType() {
    // Line Item Type
    var line_elements = document.getElementsByClassName('line-container');
    for (let line_element of line_elements) {
      if (!line_element.classList.contains('interact-activated')) {
        line_element.classList.add('interact-activated');
        var helper_line = line_element.querySelector('.helper-line');
        var line_handle_left = line_element.querySelector('.line-handle-left');
        var line_handle_right = line_element.querySelector('.line-handle-right');

        interact(helper_line)
          .draggable({
            modifiers: [
              interact.modifiers.restrictRect({
                restriction: '.slide-editor',
                endOnly: true
              })
            ],
            listeners: {
              move (event) {
                var target = event.target;
                var actual_line = target.closest('svg').querySelector('.actual-line');

                // keep the dragged position in the data-x/data-y attributes
                var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                var left_handler = event.target.closest('.line-container').querySelector('.line-handle-left');
                var right_handler = event.target.closest('.line-container').querySelector('.line-handle-right');

                target.setAttribute('x1', x);
                target.setAttribute('y1', y);
                target.setAttribute('x2', parseFloat(target.getAttribute('x2')) + event.dx);
                target.setAttribute('y2', parseFloat(target.getAttribute('y2')) + event.dy);
                actual_line.setAttribute('x1', x);
                actual_line.setAttribute('y1', y);
                actual_line.setAttribute('x2', parseFloat(target.getAttribute('x2')) + event.dx);
                actual_line.setAttribute('y2', parseFloat(target.getAttribute('y2')) + event.dy);

                left_handler.setAttribute('cx', parseFloat(target.getAttribute('x1')));
                left_handler.setAttribute('cy', parseFloat(target.getAttribute('y1')));
                right_handler.setAttribute('cx', parseFloat(target.getAttribute('x2')));
                right_handler.setAttribute('cy', parseFloat(target.getAttribute('y2')));
                left_handler.setAttribute('data-x', parseFloat(target.getAttribute('x1')));
                left_handler.setAttribute('data-y', parseFloat(target.getAttribute('y1')));
                right_handler.setAttribute('data-x', parseFloat(target.getAttribute('x2')));
                right_handler.setAttribute('data-y', parseFloat(target.getAttribute('y2')));

                // update the position attributes
                target.setAttribute('data-x', x);
                target.setAttribute('data-y', y);
                actual_line.setAttribute('data-x', x);
                actual_line.setAttribute('data-y', y);

                let x1 = left_handler.getAttribute('data-x');
                let y1 = left_handler.getAttribute('data-y');
                let x2 = right_handler.getAttribute('data-x');
                let y2 = right_handler.getAttribute('data-y');

                if (y1 % 25 == 0) {
                  document.getElementById('guide-y1').classList.remove('hidden');
                  document.getElementById('guide-y1').style.top = y1 + 'px';
                } else {
                  document.getElementById('guide-y1').classList.add('hidden');
                }

                if (x1 % 25 == 0) {
                  document.getElementById('guide-x1').classList.remove('hidden');
                  document.getElementById('guide-x1').style.left = x1 + 'px';
                } else {
                  document.getElementById('guide-x1').classList.add('hidden');
                }

                if (y2 % 25 == 0) {
                  document.getElementById('guide-y2').classList.remove('hidden');
                  document.getElementById('guide-y2').style.top = y2 + 'px';
                } else {
                  document.getElementById('guide-y2').classList.add('hidden');
                }

                if (x2 % 25 == 0) {
                  document.getElementById('guide-x2').classList.remove('hidden');
                  document.getElementById('guide-x2').style.left = x2 + 'px';
                } else {
                  document.getElementById('guide-x2').classList.add('hidden');
                }
              },
              end (event) {
                var target = event.target;
                var itemId = event.target.getAttribute('data-item-id');
                var x = (parseFloat(target.getAttribute('data-x')) || 0);
                var y = (parseFloat(target.getAttribute('data-y')) || 0);

                let formData = new FormData();
                formData.append("item[x1]", x);
                formData.append("item[y1]", y);
                formData.append('item[x2]', parseInt(target.getAttribute('x2')));
                formData.append('item[y2]', parseInt(target.getAttribute('y2')));
                formData.append("item[update_ui]", false);

                Rails.ajax({
                  url: `/items/${itemId}`,
                  type: "PATCH",
                  data: formData
                });
              }
            }
          })
          .on('dragstart', this.dragStartListener)

        interact(line_handle_left)
          .draggable({
            origin: 'parent',
            modifiers: this.dragModifiersForLine,
            onmove: function (event) {
              var target = event.target;
              var lines = event.target.closest('.draggable-item-container').querySelectorAll('.line');

              // keep the dragged position in the data-x/data-y attributes
              var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
              var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

              // update the position attributes
              target.setAttribute('data-x', x);
              target.setAttribute('data-y', y);

              target.setAttribute('cx', x);
              target.setAttribute('cy', y);

              lines.forEach((line) => {
                line.setAttribute('x1', x);
                line.setAttribute('y1', y);
                line.setAttribute('data-x', x);
                line.setAttribute('data-y', y);
              });

              if (y % 25 == 0) {
                document.getElementById('guide-y1').classList.remove('hidden');
                document.getElementById('guide-y1').style.top = y + 'px';
              } else {
                document.getElementById('guide-y1').classList.add('hidden');
              }

              if (x % 25 == 0) {
                document.getElementById('guide-x1').classList.remove('hidden');
                document.getElementById('guide-x1').style.left = x + 'px';
              } else {
                document.getElementById('guide-x1').classList.add('hidden');
              }
            },
            onend: function (event) {
              var target = event.target;
              var itemId = event.target.getAttribute('data-item-id');
              var x = (parseFloat(target.getAttribute('data-x')) || 0);
              var y = (parseFloat(target.getAttribute('data-y')) || 0);

              let formData = new FormData();
              formData.append("item[x1]", parseFloat(x));
              formData.append("item[y1]", parseFloat(y));
              formData.append("item[update_ui]", false);

              Rails.ajax({
                url: `/items/${itemId}`,
                type: "PATCH",
                data: formData
              });
            },
          });

        interact(line_handle_right)
          .draggable({
            origin: 'parent',
            modifiers: this.dragModifiersForLine,
            onmove: function (event) {
              var target = event.target;
              var lines = event.target.closest('.draggable-item-container').querySelectorAll('.line');

              // keep the dragged position in the data-x/data-y attributes
              var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
              var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

              // update the position attributes
              target.setAttribute('data-x', x);
              target.setAttribute('data-y', y);

              target.setAttribute('cx', x);
              target.setAttribute('cy', y);

              lines.forEach((line) => {
                line.setAttribute('x2', x);
                line.setAttribute('y2', y);
              });

              if (y % 25 == 0) {
                document.getElementById('guide-y1').classList.remove('hidden');
                document.getElementById('guide-y1').style.top = y + 'px';
              } else {
                document.getElementById('guide-y1').classList.add('hidden');
              }

              if (x % 25 == 0) {
                document.getElementById('guide-x1').classList.remove('hidden');
                document.getElementById('guide-x1').style.left = x + 'px';
              } else {
                document.getElementById('guide-x1').classList.add('hidden');
              }
            },
            onend: function (event) {
              var target = event.target;
              var itemId = event.target.getAttribute('data-item-id');
              var x = (parseFloat(target.getAttribute('data-x')) || 0);
              var y = (parseFloat(target.getAttribute('data-y')) || 0);

              let formData = new FormData();
              formData.append("item[x2]", parseInt(x));
              formData.append("item[y2]", parseInt(y));
              formData.append("item[update_ui]", false);

              Rails.ajax({
                url: `/items/${itemId}`,
                type: "PATCH",
                data: formData
              });
            },
          });
      }
    }
  }

  initializeTemplateItemType() {
    // Template Item Type
    var elements = document.querySelectorAll('.draggable-item-container.item-type-template');
    for (let element of elements) {
      if (!element.classList.contains('interact-activated')) {
        element.classList.add('interact-activated');
        interact(element)
          .draggable({
            origin: 'parent',
            modifiers: this.dragModifiers,
          })
          .on('dragstart', this.dragStartListener)
          .on('dragmove', this.dragMoveListener)
          .on('dragend', this.dragEndListener)
      }
    }
  }

  select(event) {
    const target = event.target.closest(".draggable-item-container");
    const multiSelect = ((event.shiftKey) ? true : false);

    if (multiSelect) {
      this.multiSelectItem(target);
    } else {
      this.selectSingleItem(target);
    }
  }

  edit(event) {
    var target = event.target.closest(".draggable-item-container")
    this.editItem(target)
  }

  selectSingleItem(target) {
    window.hideContextMenus();

    if (!target.classList.contains('selected')) {
      window.unselectAllItems();

      if (target.classList.contains('edit-item-container')) return false;
      target.classList.add('selected');

      var final_top = parseInt(target.getAttribute('data-y'))
      var final_bottom = final_top + parseInt(target.clientHeight)
      var final_left = parseInt(target.getAttribute('data-x'))
      var final_right = final_left + parseInt(target.style.width)

      if (final_top % 25 == 0) {
        document.getElementById('horizontal-guide-top').classList.remove('hidden');
        document.getElementById('horizontal-guide-top').style.top = final_top + 'px';
      } else {
        document.getElementById('horizontal-guide-top').classList.add('hidden');
      }

      if (final_bottom % 25 == 0) {
        document.getElementById('horizontal-guide-bottom').classList.remove('hidden');
        document.getElementById('horizontal-guide-bottom').style.top = (final_bottom -2) + 'px';
      } else {
        document.getElementById('horizontal-guide-bottom').classList.add('hidden');
      }

      if (final_left % 25 == 0) {
        document.getElementById('vertical-guide-left').classList.remove('hidden');
        document.getElementById('vertical-guide-left').style.left = final_left + 'px';
      } else {
        document.getElementById('vertical-guide-left').classList.add('hidden');
      }

      if (final_right % 25 == 0) {
        document.getElementById('vertical-guide-right').classList.remove('hidden');
        document.getElementById('vertical-guide-right').style.left = (final_right - 2) + 'px';
      } else {
        document.getElementById('vertical-guide-right').classList.add('hidden');
      }

      Rails.ajax({ url: `/items/${target.getAttribute('data-item-id')}/select`, type: 'GET' });
    }
  }

  multiSelectItem(target) {
    if (target.classList.contains('edit-item-container')) return false;

    window.hideContextMenus();
    window.hideGuides();
    target.classList.add('selected');

    let items = document.querySelectorAll('.draggable-item-container.selected');
    let itemIds = [...items].map(a => a.getAttribute('data-item-id'));
    let slideId = target.getAttribute('data-slide-id');

    Rails.ajax({ url: `/items/multi_select?item_ids=${itemIds}&slide_id=${slideId}`, type: 'GET' });
  }

  editItem(target) {
    window.unselectAllItems();
    window.hideContextMenus();
    window.hideGuides();
    target.classList.add('selected');

    Rails.ajax({
      url: `/items/${target.getAttribute('data-item-id')}/edit`,
      type: 'GET',
      success: function(data) {
        FroalaEditor('.fr-editor-text', {
          attribution: false,
          toolbarInline: true,
          charCounterCount: false,
          toolbarVisibleWithoutSelection: false,
          quickInsertEnabled: false,
          keepFormatOnDelete: true,
          key: 'HQD1uB3A1D4C1A1D2lFe1a1PVWEc1Fd1XHTHc1THMMe1NCb1tA1A1A1E1H4A1B3B1D7C4==',
          // toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'color', 'strikeThrough', 'alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'clearFormatting'],
          toolbarButtons: {
            'moreText': {
              'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'clearFormatting']
            },
            'moreParagraph': {
              'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
            },
            'moreRich': {
              'buttons': ['insertLink', 'insertTable', 'insertHR']
            },
            'moreMisc': {
              'buttons': ['undo', 'redo', 'spellChecker'],
              'align': 'right',
              'buttonsVisible': 4
            }
          },
          fontFamily: {
            "Roboto,sans-serif": 'Roboto',
            "Arial,Helvetica,sans-serif": 'Arial',
            "Georgia,serif": 'Georgia',
            "Impact,Charcoal,sans-serif": 'Impact',
            "Tahoma,Geneva,sans-serif": 'Tahoma',
            "Times New Roman,Times,serif,-webkit-standard": 'Times New Roman',
            "Verdana,Geneva,sans-serif": 'Verdana'
          },
          fontFamilySelection: true,
          events: {
            'blur': function () {
              console.log('Blurred');
              var itemId = this.$box[0].getAttribute('data-item-id')

              let formData = new FormData()
              formData.append("item[content]", this.html.get())
              formData.append("item[update_ui]", true)
              formData.append("item[unselect]", true)

              Rails.ajax({
                url: `/items/${itemId}`,
                type: "PATCH",
                data: formData
              });
            }
          }
        }, function () {
          this.events.focus();
          // this.commands.selectAll();
        });

        FroalaEditor('.fr-editor-grid-cell', {
          attribution: false,
          toolbarInline: true,
          charCounterCount: false,
          toolbarVisibleWithoutSelection: false,
          quickInsertEnabled: false,
          key: 'HQD1uB3A1D4C1A1D2lFe1a1PVWEc1Fd1XHTHc1THMMe1NCb1tA1A1A1E1H4A1B3B1D7C4==',
          toolbarButtons: [['bold', 'italic', 'underline', 'strikeThrough', 'textColor', 'clearFormatting']],
          events: {
            'blur': function () {
              var itemId = this.$box[0].getAttribute('data-item-id')

              let formData = new FormData()
              formData.append("data_cell_id", this.$box[0].getAttribute('data-cell-id'))
              formData.append("content", this.html.get())
              formData.append("refresh_view", true)

              Rails.ajax({
                url: `/items/${itemId}/update_grid_cell`,
                type: "POST",
                data: formData,
              });
            }
          }
        });
      },
      error: function(data) {}
    });
  }

  updateInlineEdits() {
    // Check if slide has inline edit?
    var inline_edits = document.getElementsByClassName('edit-item-container')
    if (inline_edits.length > 0) {
      var inline_edit = inline_edits[0]
      var itemId = inline_edit.getAttribute('data-item-id')

      let formData = new FormData()
      formData.append("item[content]", editor.html.get())
      formData.append("item[refresh_view]", true)

      Rails.ajax({
        url: `/items/${itemId}`,
        type: "PATCH",
        data: formData
      });
    }
  }

  videoClick(event) {
    event.preventDefault();
    event.stopPropagation();
    this.select(event);
  }
}
