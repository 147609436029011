import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    document.querySelectorAll(".pagination a").forEach(function (element) {
      element.addEventListener("click", function (event) {
        event.preventDefault();
        let pageNum = event.currentTarget.getAttribute("href").match(/page=([0-9]+)/)[1]
        document.getElementById('query_page').value = pageNum
        document.getElementById('new_query').submit()
      });
    });
  }
}
