import { Controller } from 'stimulus';
import io from 'socket.io-client';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    const { socketServerUrl } = this.element.dataset;
    const socket = io(socketServerUrl);
    const { presentationId } = this.containerTarget.dataset

    socket.emit('presentationLogin', {
      presentationId: presentationId,
      slideId: null,
    });

    socket.on('PresentationUsers', (data) => {
      let container = this.containerTarget;
      if (container) {
        let content = '';
        container.innerHTML = '';
        data.users.forEach((user) => {
          // Online users for topbar
          let userContent = `
            <div
              class='onlineUser relative'
              style='background: ${user.color};'
            >
              <div class="toolbar-tooltip">${user.name}</div>
              <span
                class='onlineUserName'
                data-user-id='${user.id}'
              >
                ${user.name[0]}
              </span>
            </div>
          `;
          content += userContent;
          container.innerHTML = content;
        });
      }
    });
  }
}
