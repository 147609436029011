// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import { Autocomplete } from 'stimulus-autocomplete';

import StimulusReflex from 'stimulus_reflex';

import consumer from '../channels/consumer';
import controller from './editor/application_controller';
import CableReady from 'cable_ready'

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));
application.consumer = consumer;

application.register('autocomplete', Autocomplete);

StimulusReflex.initialize(application, { consumer, controller, isolate: true });
StimulusReflex.debug = ['development', 'staging'].includes(process.env.RAILS_ENV);
