import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  initialize() {
    document.querySelector('#post_files').addEventListener('change', (event) => {
      const input = event.target;
      const url = input.dataset.directUploadUrl;
      input.closest('.file-upload-container').querySelector('.file-upload-progress').classList.remove('hidden');

      Array.from(input.files).forEach(file => {
        let upload = new DirectUpload(file, url);
        upload.create((error, blob) => {
          if (error) {
            console.log(error);
          } else {
            // Add item to list
            var ul = input.closest('.post').querySelector('.file-upload-list');
            var li = document.createElement("li");
            li.classList .add('relative', 'inline-block', 'mr-10', 'mb-14', 'btn', 'no-hover');
            li.appendChild(document.createTextNode(blob.filename));

            // Create input for form
            let el = document.createElement("input");
            el.type = 'hidden';
            el.name = "post[files][]";
            el.value = blob.signed_id;
            li.appendChild(el);

            ul.appendChild(li);
            input.closest('.file-upload-container').querySelector('.file-upload-progress').classList.add('hidden');
          }
        });
      });
    });
  }
}
