import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  select(event) {
    if (!event.target.classList.contains('disable-select')) {
      event.preventDefault();
      event.stopPropagation();

      event.target.closest('.selectable').classList.toggle('selected');
      event.target.closest('.selectable').classList.toggle('border-pink');
    }
  }

  fetchAndPreview() {
    event.preventDefault();
    event.stopPropagation();

    let embed_url_field = document.getElementById('embed-url-input');
    let embed_url_data = embed_url_field.value;
    let studioId = event.target.getAttribute('data-studio-id');

    let formData = new FormData();
    formData.append('url', embed_url_data);
    formData.append('studio_id', studioId);

    Rails.ajax({
      url: `/embeds`,
      type: 'POST',
      data: formData
    });

    embed_url_field.value = '';
  }

  selectItemForTemplate(event) {
    event.preventDefault();
    event.stopPropagation();

    if (document.querySelectorAll('.asset.selected').length === 0) {
      alert('Please select something.');
      return;
    } else if (document.querySelectorAll('.asset.selected').length > 1) {
      alert('Please select only one item.');
      return;
    }

    if (event.target.classList.contains('in-progress')) {
      return;
    }

    var target = event.target.closest('a');

    target.querySelector('.loader').classList.remove('hidden');
    target.classList.add('in-progress');

    let embedId = document.querySelector('.asset.selected').getAttribute('data-embed-id');
    let itemId = target.getAttribute('data-item-id');

    let formData = new FormData();
    formData.append("content_type", 'Embed');
    formData.append("embed_id", embedId);
    formData.append("post_id", target.getAttribute('data-post-id'));
    formData.append("slide_id", target.getAttribute('data-slide-id'));
    formData.append('studio_id', target.getAttribute('data-studio-id'));

    Rails.ajax({
      url: `/items/${itemId}/update_template`,
      type: "POST",
      data: formData
    });
  }

  createItems(event) {
    event.preventDefault();
    event.stopPropagation();

    if (document.querySelectorAll('.asset.selected').length === 0) {
      alert('Please select something.');
      return;
    }

    if (event.target.classList.contains('in-progress')) {
      return;
    }

    var target = event.target.closest('a');
    target.querySelector('.loader').classList.remove('hidden');
    target.classList.add('in-progress');

    var embed_ids = [];
    let studioId = target.getAttribute('data-studio-id');

    document.querySelectorAll('.embed.selected').forEach(function(el) {
      embed_ids.push(el.getAttribute('data-embed-id'));
    });

    let formData = new FormData();
    formData.append('slide_type', target.getAttribute('data-slide-type'));
    formData.append('embed_ids', embed_ids);
    formData.append('post_id', target.getAttribute('data-post-id'));
    formData.append('slide_id', target.getAttribute('data-slide-id'));
    formData.append('studio_id', studioId);

    Rails.ajax({
      url: `/items/create_via_embeds`,
      type: 'POST',
      data: formData
    });
  }

  deleteEmbed(event) {
    event.preventDefault();
    event.stopPropagation();

    if (confirm('Are you sure?') == true) {
      let embedId = event.target.getAttribute('data-embed-id');
      Rails.ajax({
        url: `/embeds/${embedId}`,
        type: 'DELETE'
      });
    }
  }
}
