/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ["tabTemplateForm", "tabTemplateName", "tabTemplateTemplate", "tabTemplateFormContainer"]

  connect() {
    if (this.hasTabTemplateFormTarget) {
      // Show the first tab while all others are hiding
      this.tabTemplateFormTargets[0].classList.toggle('hidden');

      // Show all dynamic fields
      this.tabTemplateFormTargets.forEach((el) => {
        const fields = JSON.parse(el.dataset.fieldsToShow);
        const fieldsToClassNames = fields.map(field => "." + field);
        el.querySelectorAll(fieldsToClassNames.join(",")).forEach((field) => {
          field.classList.toggle('hidden', false);
        });
      });
    }

    // Make the tab selectors sortable
    const sortables = document.querySelectorAll('#edit-tab-template .sortable');
    sortables.forEach(sortable =>
      Sortable.create(sortable, {
        group: 'tabs',
        animation: 150,
        draggable: ".draggable",
        filter: ".ignore-sort",
        forceFallback: true
      })
    );

    // Highlight initial tab
    document.querySelector('#edit-tab-template ul.sortable li').classList.add('btn-pink');
  }

  switchTo(event) {
    this.tabTemplateFormTargets.forEach((el, i) => {
      el.classList.toggle("hidden", el.dataset.tabTemplateId != event.target.dataset.tabTemplateId);
    });

    // Highlight selected tab
    document.querySelector('#edit-tab-template ul.sortable li.btn-pink').classList.remove('btn-pink');
    event.target.classList.add('btn-pink');
    window.activateSlimSelect();
  }

  addNewTabTemplate(event) {
    const id = new Date().getTime();
    const template = `<li class='inline-block mr-10 mb-14 btn draggable' data-target='schools--edit-tab-templates.tabTemplateName' data-action='click->schools--edit-tab-templates#switchTo' data-tab-template-id='${id}'>New Tab</li>`
    const newTabTemplateLink = this._htmlToElement(template);
    this._insertBefore(newTabTemplateLink, event.target);
    newTabTemplateLink.addEventListener("click", (ev) => {
      this.switchTo(ev);
    });
    const newTabTemplateForm = this._htmlToElement(this.tabTemplateTemplateTarget.innerHTML.replace(/ROOT_TAB_TEMPLATE_TMPL/g, id));
    newTabTemplateForm.dataset.tabTemplateId = id;
    this.tabTemplateFormContainerTarget.insertAdjacentElement('beforeend', newTabTemplateForm);

    newTabTemplateLink.click();
    window.activateSlimSelect();
  }

  updateShowableFields(event) {
    const selectedTabType = event.target.selectedOptions[0];
    const container = event.target.parentNode.parentNode.parentNode;
    // Hide all dynamic fields first
    container.querySelectorAll('.type-dynamic-field').forEach(el => el.classList.toggle('hidden', true));

    // Show only the releavant ones
    if (selectedTabType.value != '') {
      container.querySelectorAll('.type-dynamic-field').forEach(el => el.classList.toggle('hidden', true));
      const fields = JSON.parse(selectedTabType.dataset.fieldsToShow);
      if (fields.length > 0) {
        const fieldsToClassNames = fields.map(field => "." + field);
        container.querySelectorAll(fieldsToClassNames.join(",")).forEach((field) => {
          field.classList.toggle('hidden', false);
        });
      }
    }
  }

  updateTitle(event) {
    const tabTemplateId = event.target.parentElement.parentElement.parentElement.dataset.tabTemplateId;
    this.tabTemplateNameTargets.find(el => el.dataset.tabTemplateId  == tabTemplateId).innerText = event.target.value;
  }

  removeTabTemplate(event) {
    const tabTemplateForm = event.target.parentElement;
    const tabTemplateId = tabTemplateForm.dataset.tabTemplateId;
    this.tabTemplateNameTargets.find(el => el.dataset.tabTemplateId  == tabTemplateId).style.display = 'none';
    tabTemplateForm.querySelector("input[name*='_destroy']").value = 1;
    tabTemplateForm.style.display = 'none';
  }

  _htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
  }

  _insertBefore(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode);
  }

  _insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }
}
