/**
   * Create Items
   * @param {Object} CreateItemParams
   * @param {HTMLElement} CreateItemParams.sourceItem
   * @param {HTMLElement} CreateItemParams.targetItem
   * @param {String} CreateItemParams.path
   * @param {String} CreateItemParams.color
   * @param {String} CreateItemParams.dash
   * @param {String} CreateItemParams.thickness
   * @param {String} CreateItemParams.startSocket
   * @param {String} CreateItemParams.endSocket
   */
export const createItem = ({
  sourceItem,
  targetItem,
  path = 'fluid',
  color = 'black',
  dash = 'false',
  thickness = 3,
  itemId,
  slideId,
  startSocket = 'auto',
  endSocket = 'auto',
}) => {
  if (!sourceItem || !targetItem) {
    return {};
  }
  const leaderLine = new LeaderLine(
    sourceItem,
    targetItem,
    {
      path,
      color,
      dash: (dash === 'true'),
      size: parseInt(thickness,
        0),
      startSocket,
      endSocket,
    },
  );

  leaderLine.itemId = itemId;
  leaderLine.slideId = slideId;
  const properties = {
    source_item_id: sourceItem.dataset.itemId,
    target_item_id: targetItem.dataset.itemId,
    path,
    color,
    dash,
    thickness,
    slide_id: slideId,
    item_id: itemId,
    start_socket: startSocket,
    end_socket: endSocket,
  };

  const line1 = document.getElementById(`draggable-item-container-${sourceItem.dataset.itemId}`);
  const line2 = document.getElementById(`draggable-item-container-${targetItem.dataset.itemId}`);
  if (line1 !== null && line2 !== null) {
    leaderLine.position();
    const svgItem = document.querySelector(`#leader-line-${leaderLine._id}-line-path`).closest('svg');
    svgItem.dataset.itemId = leaderLine.itemId;
    document.querySelector('#connectors').appendChild(svgItem);
  }
  return { leaderLine, properties };
};

export const addLineToSlideDiagrams = (line) => {
  const { slideId } = document.querySelector('.slideContainer.selected').dataset;
  if (!window.Diagrams.currentDiagrams[slideId]) {
    window.Diagrams.currentDiagrams[slideId] = { diagrams: [] };
  }
  window.Diagrams.currentDiagrams[slideId].diagrams.push(line);
};

export const setSlideDiagrams = (diagrams) => {
  const { slideId } = document.querySelector('.slideContainer.selected').dataset;
  if (!window.Diagrams.currentDiagrams[slideId]) {
    window.Diagrams.currentDiagrams[slideId] = { diagrams: [] };
  }
  window.Diagrams.currentDiagrams[slideId].diagrams = diagrams;
};

export const setDiagramZoomLevel = () => {
  document.querySelectorAll('#connectors .leader-line').forEach((line) => {
    const svgLineItem = line.querySelector('defs use');
    if (svgLineItem.dataset.originalStrokeWidth === undefined) {
      svgLineItem.dataset.originalStrokeWidth = svgLineItem.style.strokeWidth;
    }
    const strokePx = parseFloat(svgLineItem.dataset.originalStrokeWidth.split('px')[0]);
    svgLineItem.style.strokeWidth = `${window.zoomLevel * strokePx}px`;
  });
};

export const reselectAllItems = () => {
  console.log('reselecting items', window.Diagrams.selectedItemIds);
  window.Diagrams.selectedItemIds.forEach((itemId) => {
    const svg = document.querySelector(`#connectors svg.leader-line[data-item-id='${itemId}']`);
    svg.classList.add('active');
    document.getElementById(`draggable-item-container-${itemId}`).classList.add('selected');
  });
}
