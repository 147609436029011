/* jshint esversion: 6 */
import { Controller } from 'stimulus';

export default class extends Controller {
  deleteConfirm(event) {
    if (confirm(this.data.get('message'))) {
      if (confirm('Are you sure?')) {
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
