import { Controller } from 'stimulus';
import Rails from 'rails-ujs';
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    const sortable = document.querySelector('.sortable');

    // Simplest mobile detector
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (!isMobile) {
      let positions = [];
      const el = Sortable.create(sortable, {
        animation: 150,
        draggable: '.draggable',
        delay: 100,
        forceFallback: true,
        onEnd(event) {
          el.toArray().forEach((item, index) => {
            // acts_as_list for slide is setup to have 1 as the top of the list
            positions.push({ project_id: item, position: index + 1 });
          });

          Rails.ajax({
            url: `/projects/update_positions`,
            type: 'POST',
            data: `project[positions]=${JSON.stringify(positions)}`,
          });
        },
      });
    }
  }
}
