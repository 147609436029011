import Quill from 'quill';
import 'quill-paste-smart';
import SlimSelect from 'slim-select';
import ApplicationController from './application_controller';


export default class extends ApplicationController {
  connect() {
    super.connect();
    this.bindInitialValues();
  }

  bindInitialValues() {
    // new SlimSelect({
    //   select: '.ql-font',
    //   showSearch: false,
    //   onChange: info => {
    //     document.querySelector('select.ql-font').style.fontFamily = info.value;
    //     document.querySelectorAll('.ql-fontweight')
    //       .forEach(el => el.style.fontFamily = info.value);
    //   }
    // });

    new SlimSelect({
      select: '#ql-connector-type',
      showSearch: false,
    });
    if (document.querySelector('#ql-connector-start')) {
      new SlimSelect({
        select: '#ql-connector-start',
        showSearch: false
      });

      new SlimSelect({
        select: '#ql-connector-end',
        showSearch: false
      });
    }
  }
}
