import ApplicationController from './application_controller';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';
import { reselectAllItems } from '../helpers/editor/items/diagram';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  changeShapeStyle(event) {
    const { slideId } = document.querySelector('.slideContainer.selected').dataset;
    const selectBoxValue = event.currentTarget.value;
    const { controlType } = event.currentTarget.dataset;
    const { itemId, itemIds } = event.currentTarget.closest('.itemPanel').dataset;
    let items = [];
    if (itemIds) {
      items = itemIds.split(',');
    } else {
      items = [itemId];
    }
    window.Diagrams.currentDiagrams[slideId].diagrams.forEach((diagram) => {
      if (!items.includes(diagram.itemId)) {
        return;
      }

      if (controlType === 'path') {
        diagram.setOptions({ path: selectBoxValue });
      } else if (controlType === 'color') {
        diagram.setOptions({ color: selectBoxValue });
      } else if (controlType === 'dash') {
        diagram.setOptions({ dash: selectBoxValue === 'true' });
      } else if (controlType === 'thickness') {
        diagram.setOptions({ size: parseInt(selectBoxValue, 0) });
      } else if (controlType === 'start_socket') {
        diagram.setOptions({ startSocket: selectBoxValue });
      } else if (controlType === 'end_socket') {
        diagram.setOptions({ endSocket: selectBoxValue });
      }
      const item = document.querySelector(`.item-type-diagram-line[data-item-id='${diagram.itemId}']`);
      item.dataset.path = diagram.path;
      item.dataset.color = diagram.color;
      item.dataset.dash = diagram.dash;
      item.dataset.thickness = diagram.size;
      item.dataset.startSocket = diagram.startSocket;
      item.dataset.endSocket = diagram.endSocket;
      storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');

      this.stimulate('Item#update_diagram_line', {
        itemId: diagram.itemId,
        path: diagram.path,
        color: diagram.color,
        dash: diagram.dash,
        thickness: diagram.size,
        start_socket: diagram.startSocket,
        end_socket: diagram.endSocket,
      }).then(() => {
        // add try catch block
        try {
          this.stimulate('Item#update_diagram_line_content', { itemId: diagram.itemId, content: diagram.getRelativeSVG(diagram.sourceItemId) });
          reselectAllItems();
        } catch (e) {
          setTimeout(() => {
            console.log('trying again', e)
            this.stimulate('Item#update_diagram_line_content', { itemId: diagram.itemId, content: diagram.getRelativeSVG(diagram.sourceItemId) });
          }, 300);
        }
      });
    });
    window.Diagrams.updateDiagramPositions();
  }

  delete(event) {
    // add confirm and if yes, delete
    if (confirm('Are you sure you want to delete')) {
      const { slideId } = document.querySelector('.slideContainer.selected').dataset;
      const { itemId } = event.currentTarget.closest('.itemPanel').dataset;
      document.querySelector(`#draggable-item-container-${itemId}`).closest('.item-container').remove();
      this.stimulate('Item#delete', [{ itemId }]);
      window.Diagrams.currentDiagrams[slideId].diagrams.forEach((diagram) => {
        if (diagram.itemId === itemId) {
          diagram.hide('none');
          diagram.remove();
        }
      });
      window.Diagrams.currentDiagrams[slideId].diagrams = { ...window.Diagrams.currentDiagrams[slideId].diagrams.filter((diagram) => diagram.itemId !== itemId) }
      // debugger
    }
  }

  selectAll(event) {
    event.preventDefault();
    event.stopPropagation();
    const slideId = Object.keys(window.Diagrams.currentDiagrams)[0];
    const { diagrams } = window.Diagrams.currentDiagrams[slideId];
    diagrams.forEach((diagram) => {
      const { itemId } = diagram;
      window.Diagrams.selectedItemIds = window.Diagrams.selectedItemIds.includes(itemId) ? window.Diagrams.selectedItemIds : [...window.Diagrams.selectedItemIds, itemId];
      const svg = document.querySelector(`#connectors svg.leader-line[data-item-id='${itemId}']`);
      svg.classList.add('active');

      document.getElementById(`draggable-item-container-${itemId}`).classList.add('selected');
      //
    });
    const itemIds = window.Diagrams.selectedItemIds;
    this.stimulate('Item#select_line_tool', { itemIds });
  }
}
