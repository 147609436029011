import { Controller } from "stimulus"
import Rails from 'rails-ujs';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import { BlobRecord} from '@rails/activestorage/src/blob_record';

const Uppy = require('uppy');
const {Plugin} = require('@uppy/core');

export default class extends Controller {
  static uppy;

  activateUppyForAssets() {
    // Check if upload button exists
    if (!document.querySelector(".asset-upload-btn")) {
      console.log("No element for UPPY");
      return;
    }

    let assetIds = [];

    // Core Uppy definition
    this.uppy = Uppy.Core({ autoProceed: true, restrictions: { allowedFileTypes: ['image/*', 'video/*', '.heic',  '.heif'] } });

    // Bind Uppy to a file input
    this.uppy.use(Uppy.FileInput, { target: '.asset-upload-btn', replaceTargetContent: false, pretty: true });

    // Activate drag & drop feature
    this.uppy.use(Uppy.DragDrop, { target: '.uppy-paste-container', height: '200px' });

    // Enable upload progress bar
    this.uppy.use(Uppy.StatusBar, {
      id: 'StatusBar',
      target: '.uppyProgressBar',
      hideAfterFinish: false,
      showProgressDetails: false,
      hideUploadButton: false,
      hideRetryButton: false,
      hidePauseResumeButton: false,
      hideCancelButton: false,
      doneButtonHandler: null
    });

    // Activate S3 Multipart support
    this.uppy.use(AwsS3Multipart, { limit: 4, companionUrl: '/' })

    class UppyS3DirectUpload extends Plugin {
      constructor (uppy, opts) {
        super(uppy, opts)
        this.id = opts.id || 'UppyS3DirectUpload'
        this.type = 'example'
        this.prepareUpload = this.prepareUpload.bind(this) // ← this!
      }

      prepareUpload (fileIDs) {
        const promises = fileIDs.map((fileID) => {
          const file = this.uppy.getFile(fileID)
          this.uppy.emit('preprocess-progress', file, {
            mode: 'indeterminate',
            message: 'Preparing files'
          })

          return new Promise((resolve, reject) => {
            FileChecksum.create(file.data, (error, checksum) => {
              if(error) {
                console.log(error);
                return reject(error)
              } else {
                const blob = new BlobRecord(file.data, checksum, document.querySelector('meta[name=\'direct-upload-url\']').getAttribute('content'));
                blob.create(error => {
                  if(error) {
                    console.log(error);
                    return reject(error)
                  } else {
                    this.uppy.setFileMeta(file.id, {
                      blob: blob.toJSON()
                    });
                    return resolve();
                  }
                });
              }
            });
          });
        })

        const emitPreprocessCompleteForAll = () => {
          fileIDs.forEach((fileID) => {
            const file = this.uppy.getFile(fileID)
            this.uppy.emit('preprocess-complete', file)
          })
        }

        // Why emit `preprocess-complete` for all files at once, instead of
        // above when each is processed?
        // Because it leads to StatusBar showing a weird “upload 6 files” button,
        // while waiting for all the files to complete pre-processing.
        return Promise.all(promises)
          .then(emitPreprocessCompleteForAll)
      }

      install () {
        this.uppy.addPreProcessor(this.prepareUpload)
      }

      uninstall () {
        this.uppy.removePreProcessor(this.prepareUpload)
      }
    }

    // Activate direct S3 upload support
    this.uppy.use(UppyS3DirectUpload, {});

    let addedFilesCount = 0;
    let uploadedFilesCount = 0;

    this.uppy.on('file-added', (file) => {
      // Set meta data
      addedFilesCount += 1;
      this.uppy.setFileMeta(file.id, {
        studio_id: document.getElementById("studio-id").value
      });
    });

    this.uppy.on('upload-success', (file, response) => {
      let formData = new FormData();
      formData.append('studio_id', file.meta.studio_id);
      formData.append('blob_signed_id', file.meta.blob.signed_id);
      formData.append('key', new URL(response.uploadURL).pathname.split("/")[1]);
      if(!(file.meta.width === undefined))
        formData.append('width', file.meta.width);
      if(!(file.meta.height === undefined))
        formData.append('height', file.meta.height);
      fetch('/assets', {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          if (data.asset_id) {
            assetIds.push(data.asset_id);
            uploadedFilesCount += 1;
            if (addedFilesCount == uploadedFilesCount) {
              document.querySelector(".uppy-StatusBar-statusPrimary").innerHTML = "Storing files. Please wait...";
              let studioId = document.getElementById("studio-id").value;
              let postId = document.getElementById("post-id").value;

              Rails.ajax({
                url: `/presentations/create_with_assets?studio_id=${studioId}&post_id=${postId}&asset_ids=${assetIds.join(",")}`,
                type: 'POST'
              });
            }
          } else {
            alert('Error in file');
          }
        });
    });
  }

  createEmbed(event) {
    event.preventDefault();
    event.stopPropagation();

    let studioId = document.getElementById("studio-id").value;
    let postId = document.getElementById("post-id").value;
    let url = document.getElementById("embedCreatorInput").value;
    Rails.ajax({
      url: `/presentations/create_with_embed?studio_id=${studioId}&post_id=${postId}&url=${url}`,
      type: 'POST'
    });
  }

  showAssetUploader(event) {
    this.colorizeSelectedToolbarItem(event.target.closest('.post-v2-toolbar-item'));

    Rails.ajax({
      url: '/presentations/show_asset_uploader',
      type: 'GET',
      success: (resp, status, xhr) => {
        if (resp && !resp.success) {
          this.activateUppyForAssets();
        }
      }
    });
  }

  colorizeSelectedToolbarItem(target) {
    Array.from(document.querySelectorAll('.post-v2-toolbar-item')).map(
      (item) => {
        item.classList.remove('text-pink');
        Array.from(item.querySelectorAll('svg path')).map((path) => path.setAttribute('stroke', 'black'));
      }
    );
    target.classList.add('text-pink');
    Array.from(target.querySelectorAll('svg path')).map((path) => path.setAttribute('stroke', '#f35d91'));
  }

  showCameraAssetPicker() {
    this.colorizeSelectedToolbarItem(event.target.closest('.post-v2-toolbar-item'));

    let studioId = event.target.closest('.studio-id-container').dataset.studioId;

    Rails.ajax({
      url: `/presentations/show_camera_asset_picker?studio_id=${studioId}`,
      type: 'GET'
    });
  }

  showEmbedCreator() {
    this.colorizeSelectedToolbarItem(event.target.closest('.post-v2-toolbar-item'));

    Rails.ajax({
      url: '/presentations/show_embed_creator',
      type: 'GET'
    });
  }

  deleteSlide(event) {
    event.preventDefault();
    event.stopPropagation();

    if (confirm("Are you sure?")) {
      let slideId = event.target.dataset.slideId;
      Rails.ajax({
        url: `/slides/${slideId}`,
        type: 'DELETE'
      });

      document.querySelector(`#slide-container-${slideId}`).remove();
    }
  }

  editPresentation(event) {
    event.preventDefault();
    event.stopPropagation();

    let presentationId = event.target.closest("a").dataset.presentationId;
    Rails.ajax({
      url: `/presentations/${presentationId}/edit`,
      type: 'GET'
    });
  }

  doneEditing(event) {
    event.preventDefault();
    event.stopPropagation();

    let presentationId = event.target.closest("a").dataset.presentationId;
    let url = `/presentations/${presentationId}`;

    fetch(url, {method: 'GET'}).then(function(response) {
      return response.text().then(function(text) {
        document.getElementById(`presentation-container-${presentationId}`).innerHTML = text;
      });
    });
  }

  showThumbs(event) {
    event.preventDefault();
    event.stopPropagation();

    const presentationId = event.target.closest('a').getAttribute('data-presentation-id');

    Rails.ajax({
      url: `/presentations/${presentationId}/thumbs`,
      type: 'GET',
    });
  }

  filterCameraAssets(event) {
    event.preventDefault();
    event.stopPropagation();
    document.querySelectorAll('.camera-asset-folder').forEach((el) => { el.classList.remove('text-pink') });
    event.target.classList.add('text-pink');

    const path = event.target.dataset.path;
    const studioId = event.target.dataset.studioId;

    Rails.ajax({
      url: `/presentations/filter_camera_assets?studio_id=${studioId}&path=${path}`,
      type: 'GET'
    });
  }

  selectCameraAsset(event) {
    event.target.classList.toggle('selected');
    event.target.classList.toggle('border-transparent');
    event.target.classList.toggle('border-pink');
  }

  insertCameraAssets(event) {
    event.preventDefault();
    event.stopPropagation();

    let studioId = document.getElementById("studio-id").value;
    let postId = document.getElementById("post-id").value;
    let cameraAssetIds = Array.from(document.querySelectorAll('.camera-asset.selected')).map(div => div.dataset.cameraAssetId).join();

    Rails.ajax({
      url: `/presentations/create_with_camera_assets?studio_id=${studioId}&post_id=${postId}&camera_asset_ids=${cameraAssetIds}`,
      type: 'POST'
    });
  }

  redirectToEditor(event) {
    event.preventDefault();
    event.stopPropagation();

    let input = [...document.querySelectorAll("#new_post_title")].pop();

    if (input.value !== "") {
      const formElement = document.querySelector('.edit_post');
      const formData = new FormData(formElement);
      formData.set("create_presentation", "true")
      sessionStorage.setItem('scrollpos', window.scrollY);
      Rails.ajax({
        url: formElement.action,
        type: 'POST',
        data: formData,
      });
    } else {
      alert("Please set a post title");
    }
  }
}
