import { Controller } from 'stimulus';
import { SlimSelect } from 'slim-select';

export default class extends Controller {
  connect() {
    window.datePicker();
  }

  addSession(event) {
    const template = event.target.closest('.tab').querySelector('#new-session-template');
    const index = document.querySelectorAll('.session-container').length + 1;
    const innerHTML = template.innerHTML.replace(/TEMPLATE_RECORD/g, index);
    event.target.closest('.tab').querySelector('#new-session-container').insertAdjacentHTML('beforeend', innerHTML);
    window.datePicker();
  }

  addTermHoliday(event) {
    const template = document.getElementById('new-term-holiday-template');
    const index = document.querySelectorAll('.term-holiday-container').length + 1;
    const innerHTML = template.innerHTML.replace(/TEMPLATE_RECORD/g, index);
    document.getElementById('new-term-holiday-container').insertAdjacentHTML('beforeend', innerHTML);
    window.datePicker();
  }

  destroySession(event) {
    event.preventDefault();
    event.stopPropagation();

    event.target.closest('.session-container').querySelector("input[name*='_destroy']").value = 1;
    event.target.closest('.session-container').classList.add('hidden');
  }

  destroyTermHoliday(event) {
    event.preventDefault();
    event.stopPropagation();

    event.target.closest('.term-holiday-container').querySelector("input[name*='_destroy']").value = 1;
    event.target.closest('.term-holiday-container').classList.add('hidden');
  }

  addStudio(event) {
    const template = event.target.closest('.session-container').querySelector('.new-studio-template');
    const index = event.target.closest('.session-container').querySelectorAll('.studio-container').length + 1;
    const innerHTML = template.innerHTML.replace(/TEMPLATE_RECORD/g, index);
    event.target.closest('.session-container').querySelector('.new-studio-container').insertAdjacentHTML('beforeend', innerHTML);

    let elements = event.target.closest('.session-container').querySelector('.new-studio-container').querySelectorAll('select.custom-select');
    let last_element = elements[elements.length - 1];
    window.activateSlimSelect();
  }

  destroyStudio(event) {
    event.preventDefault();
    event.stopPropagation();

    event.target.closest('.studio-container').remove();
  }
}
