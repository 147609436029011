import MicroModal from 'micromodal';
import fixSlideIndexes from './fixSlideIndexes';
import switchMode from '../modes/switch';
import { setButtonStates, storeSlideSnapshotForUndo } from '../history/manager';

const confirmDeleteSlide = () => {
  // document.querySelector('#delete--modal').classList.remove('hidden');
  MicroModal.init({
    disableFocus: true,
  });

  MicroModal.show('delete-slide-modal');
};

const deleteSlide = (event, controller) => {
  // Will update with slide_ids should be deleted
  let slideIdsToDelete = [];

  // Is the event triggered via context menu or the delete key?
  const contextMenuEvent = event.target.closest('.contextMenu');

  // Get selected slides based on class
  const selectedThumbs = document.querySelectorAll('#pageThumbsContainer .slideContainer.selected, #pageThumbsContainer .slideContainer.multiSelected');
  const selectedSlideIds = Array.from(selectedThumbs).map((thumb) => thumb.dataset.id);

  if (contextMenuEvent) {
    // SlideId of context menu event
    const contextMenuSlideId = event.target.dataset.slideId;

    // Check if context menu event slide is selected
    if (selectedSlideIds.includes(contextMenuSlideId)) {
      // If so, delete all selected slides
      slideIdsToDelete = selectedSlideIds;
    } else {
      // We should only delete the context menu slide
      slideIdsToDelete = [contextMenuSlideId];
    }
  } else {
    // If the event is triggered via the delete key,
    // we need to delete all selected slides
    slideIdsToDelete = selectedSlideIds;
  }

  // Delete slides
  // Remove selection class and set data-is-deleted to true
  slideIdsToDelete.forEach((thumbId) => {
    const thumb = document.querySelector(`#pageThumbsContainer .slideContainer[data-id="${thumbId}"]`);
    thumb.classList.remove('selected');
    thumb.classList.remove('multiSelected');
    thumb.dataset.isDeleted = true;
  });

  // Fix slide indexes
  fixSlideIndexes();

  // Store slide history
  slideIdsToDelete.forEach((slideId) => {
    storeSlideSnapshotForUndo(slideId, 'DELETE_SLIDE');
  });

  // Switch to pointer mode
  switchMode(this, event, 'Pointer');

  // Set button states for undo - redo
  setButtonStates({
    presentationId: document.querySelector('.canvas').dataset.presentationId,
    slideId: '',
    userId: document.querySelector('.canvas').dataset.userId,
  });

  // Backend call
  controller.stimulate('Slide#delete', JSON.stringify(slideIdsToDelete)).then();

  // Switch to an undeleted slide
  // Or add a new slide
  const undeletedThumbs = document.querySelectorAll('#pageThumbsContainer .slideContainer[data-is-deleted="false"]');
  if (undeletedThumbs.length > 0) {
    // Do we need to change slide?
    // Do we still have selected slides?
    if (document.querySelectorAll('#pageThumbsContainer .slideContainer.selected, #pageThumbsContainer .slideContainer.multiSelected').length > 0) {
      // We are good.
    } else {
      // Find nearest undeleted slide
      const canvasController = document.querySelector('.canvas').canvas;
      canvasController.changeSlide(event, undeletedThumbs[0].dataset.slideId);
    }
  } else {
    // Add a new slide
    const canvasController = document.querySelector('.canvas').canvas;
    canvasController.addSlide(event);
  }

  MicroModal.close('delete-slide-modal');
};

export { confirmDeleteSlide, deleteSlide };
