// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import LocalTime from 'local-time';

import 'intl-tel-input/build/css/intlTelInput.min.css';
import 'slim-select/dist/slimselect.min.css';
import '@yaireo/tagify/dist/tagify.css';
import 'quill/dist/quill.core.css';
import '@fortawesome/fontawesome-free/js/all';
import '../css/application.css';
import 'controllers';
import debounced from 'debounced';
import ahoy from 'ahoy.js';
import TurbolinksPrefetch from 'turbolinks-prefetch';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('application');

LocalTime.start();
debounced.initialize();

TurbolinksPrefetch.start();



