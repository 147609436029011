import itemType from './itemType';

const updateRect = (itemId) => {
  const type = itemType(itemId);

  switch (type) {
    case 'image':
    case 'code':
    case 'camera_asset':
    case 'video':
    case 'shape':
    case 'grid':
    case 'vector':
      window.moveableForItems.updateRect();
      break;
    case 'text':
      window.moveableForText.updateRect();
      break;
    case 'line':
      // Skip
      break;
    default:
      console.error(`Unhandled item type! ${type}`);
      break;
  }
};

export default updateRect;
