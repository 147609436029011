import ApplicationController from './application_controller.js';
import setPosition from '../helpers/editor/items/actions/setPosition';
import setDimension from '../helpers/editor/items/actions/setDimension';
import setRotation from '../helpers/editor/items/actions/setRotation';
import setOpacity from '../helpers/editor/items/actions/setOpacity';
import setBorder from '../helpers/editor/items/actions/setBorder';
import setLineAttributes from '../helpers/editor/items/actions/setLineAttributes';
import setShapeAttributes from '../helpers/editor/items/actions/setShapeAttributes';
import { multiAlign } from '../helpers/editor/items/actions/multiAlign';
import save from '../helpers/editor/items/save';
import batchSave from '../helpers/editor/items/batchSave';
import { storeItemSnapshotForUndo } from '../helpers/editor/history/manager';

export default class extends ApplicationController {
  static formTimer;

  multiAlign(event) {
    event.preventDefault();
    event.stopPropagation();

    const { target } = event;
    const randomSelectId = this.generateRandomString();

    this.selectedItemIds().map((itemId) => {
      storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM', randomSelectId);
    });

    multiAlign(this.selectedItemIds().join(','), event.target.closest('.panelRow').dataset.alignType);
    batchSave(this.selectedItemIds(), this);
  }

  center(event) {
    event.preventDefault();
    event.stopPropagation();

    const origin = window.moveableForGroups.getRect().origin;
    const diffX = 620 - origin[0] // Hard coded number grabbed by centering manually
    const diffY = 0 - origin[1]

    window.moveableForGroups.request("draggable", {deltaX: diffX, deltaY: diffY}, true);
  }

  selectedItems() {
    return document.querySelectorAll('.item-container .selected');
  }

  selectedItemIds() {
    return Array.from(this.selectedItems()).map(div => div.dataset.itemId);
  }

  submitForm(event) {
    window.clearTimeout(this.formTimer);
    const { itemId } = event.target.dataset;

    this.formTimer = window.setTimeout(() => {
      this.stimulate("Item#update_via_form", event.target).then((payload) => {
        setTimeout(() => {
          const target = document.querySelector(`#draggable-item-container-${itemId}`);
          if (target.classList.contains('item-type-text')) {
            window.moveableForText.target = target;
            window.moveableForText.updateRect();
          } else {
            window.moveableForItems.target = target;
            window.moveableForItems.updateRect();
          }
          window.hideContextMenus();
        }, 10);
      })
    }, 200);
  }

  submitMultiForm(event) {
    window.clearTimeout(this.formTimer);
    const { itemIds } = event.target.dataset;
    const targets = [];
    const selectionId = this.generateRandomString();

    itemIds.split(',').forEach(id => storeItemSnapshotForUndo(id, 'UPDATE_SLIDE_ITEM', selectionId));

    this.formTimer = window.setTimeout(() => {
      this.stimulate("MultiItem#update_via_form", event.target, itemIds).then(() => {
        setTimeout(() => {
          itemIds.split(',').forEach((itemId) => {
            let target = document.querySelector(`#draggable-item-container-${itemId}`);
            if (target.classList.contains('item-type-line')) {
              targets.push(target.closest('.item-container').querySelector('.line-snapper'));
            } else {
              targets.push(target);
            }
          });

          window.moveableForGroups.target = targets;
          window.moveableForGroups.updateRect();
          // window.hideContextMenus();
        }, 10);
      })
    }, 200);
  }

  setPosition(event) {
    const { itemId } = event.target.dataset;
    const top = event.target.closest('.itemPanel').querySelector('#item_translate_y').value;
    const left = event.target.closest('.itemPanel').querySelector('#item_translate_x').value;

    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    setPosition(itemId, top, left);
    save(itemId, this);
  }

  setDimension(event) {
    const { itemId } = event.target.dataset;
    const width = event.target.closest('.itemPanel').querySelector('#item_width').value;
    const height = event.target.closest('.itemPanel').querySelector('#item_height').value;
    let retainProportions = false;
    if (event.target.closest('.itemPanel').querySelector('#item_retain_proportions')) {
      retainProportions = event.target.closest('.itemPanel').querySelector('#item_retain_proportions').checked;
    }
    const whatChanged = event.target.id === 'item_width' ? 'width' : 'height';

    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    setDimension(itemId, width, height, retainProportions, whatChanged);
    save(itemId, this);
  }

  setRotation(event) {
    const { itemId } = event.target.dataset;
    const rotate = event.target.closest('.itemPanel').querySelector('#item_rotate').value + ' °';
  console.log(rotate);
    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    setRotation(itemId, rotate);
    save(itemId, this);
  }

  setOpacity(event) {
    const { itemId } = event.target.dataset;
    const opacity = event.target.closest('.itemPanel').querySelector('#item_opacity').value;

    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    setOpacity(itemId, opacity);
    save(itemId, this);
  }

  setBorder(event) {
    const { itemId } = event.target.dataset;
    const hasBorder = event.target.closest('.itemPanel').querySelector('#item_has_border').checked;
    const borderStyle = event.target.closest('.itemPanel').querySelector('#item_border_style').value;
    const borderSize = event.target.closest('.itemPanel').querySelector('#item_border_size').value;
    const borderRadius = event.target.closest('.itemPanel').querySelector('#item_border_radius').value;
    const borderColor = event.target.closest('.itemPanel').querySelector('#item_border_color').value;

    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');

    if (hasBorder) {
      document.querySelector(`#item_panel_${itemId}`).querySelector('.borderPanel').classList.remove('hidden');
    } else {
      document.querySelector(`#item_panel_${itemId}`).querySelector('.borderPanel').classList.add('hidden');
    }

    setBorder([itemId], hasBorder, borderStyle, borderSize, borderRadius, borderColor);
    save(itemId, this);
  }

  setMultiItemBorder(event) {
    const itemIds = event.target.dataset.itemIds.split(',');
    const selectionId = this.generateRandomString();

    const hasBorder = event.target.closest('.itemPanel').querySelector('#item_has_border').checked;
    const borderStyle = event.target.closest('.itemPanel').querySelector('#item_border_style').value;
    const borderSize = event.target.closest('.itemPanel').querySelector('#item_border_size').value;
    const borderRadius = event.target.closest('.itemPanel').querySelector('#item_border_radius').value;
    const borderColor = event.target.closest('.itemPanel').querySelector('#item_border_color').value;

    if (hasBorder) {
      document.querySelector('.itemPanel .borderPanel').classList.remove('hidden');
    } else {
      document.querySelector('.itemPanel .borderPanel').classList.add('hidden');
    }

    itemIds.map((itemId) => {
      storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM', selectionId);
    });

    setBorder(itemIds, hasBorder, borderStyle, borderSize, borderRadius, borderColor);
    batchSave(itemIds, this);
  }

  setLineAttributes(event) {
    const { itemId } = event.target.dataset;
    const markerStart = event.target.closest('.itemPanel').querySelector('#item_marker_start').value;
    const markerEnd = event.target.closest('.itemPanel').querySelector('#item_marker_end').value;
    const strokeDasharray = event.target.closest('.itemPanel').querySelector('#item_stroke_dasharray').value;
    const color = event.target.closest('.itemPanel').querySelector('#item_color').value;
    const thickness = event.target.closest('.itemPanel').querySelector('#item_thickness').value;

    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    setLineAttributes(itemId, markerStart, markerEnd, strokeDasharray, color, thickness);
    save(itemId, this);
  }

  setShapeAttributes(event) {
    const { itemId } = event.target.dataset;
    const color = event.target.closest('.itemPanel').querySelector('#item_color').value;

    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    setShapeAttributes(itemId, color);
    save(itemId, this);
  }

  setRetainProportions(event) {
    const { itemId } = event.target.dataset;
    const retainProportions = event.target.closest('.itemPanel').querySelector('#item_retain_proportions').checked;

    const target = document.querySelector(`#draggable-item-container-${itemId}`);
    if (!target) return;

    target.dataset.retainProportions = retainProportions === true ? 1 : 0;
    save(itemId, this);
  }

  setVectorColor(event) {
    const { itemId } = event.target.dataset;
    const color = event.target.closest('.itemPanel').querySelector('#item_color').value;

    storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');
    const target = document.querySelector(`#draggable-item-container-${itemId}`);
    target.querySelector('svg').style.fill = color;
    target.setAttribute('data-color', color);

    save(itemId, this);
  }

  generateRandomString() {
    return Math.round((Math.random() * 36 ** 12)).toString(36);
  }

  setPenValues(e) {
    const button = document.querySelector('#topbar .button.pen ');
    button.dataset.penThickness = document.querySelector('#pencil_thickness').value;
    button.dataset.penColor = document.querySelector('#pencil_color').value;
  }

  selectInputText(event) {
    // Safari clears the selection after focus, so adding a delay to select the text.
    setTimeout(() => event.target.select(), 2);
  }
}
