import { Controller } from "stimulus";
import InfiniteScroll from "infinite-scroll";

export default class extends Controller {
  connect() {
    if (document.getElementById('infinite-scroll')) {
      window.infScroll = new InfiniteScroll('#infinite-scroll', {
        path: '.pagination__next',
        append: '.record',
        history: false
      });
    }

    if (window.location.hash) {
      this.fetchContent(window.location.hash);
    }
  }

  disconnect() {
    if (window.infScroll) {
      window.infScroll.destroy();
    }
  }

  fetchContent(hash) {
    let type = hash.replace('#/c/', '')
    let valid_types = ['school', 'user', 'term', 'studio'];

    if (valid_types.includes(type)) {
      window.location.replace(`/discover/${type}`)
    }
  }
}
