// Utility to sort slides on dom
import save from './save';
import fixSlideIndexes from './fixSlideIndexes';

const sortSlides = (slidePositions, controller) => {
  const container = document.querySelector('#pageThumbsContainer');
  const thumbs = Array.from(container.querySelectorAll('.slideContainer'));

  container.innerHTML = '';
  slidePositions.forEach((slidePosition) => {
    const slideId = slidePosition.slide_id;
    const { position } = slidePosition;
    const thumb = thumbs.find((obj) => obj.dataset.id === slideId);
    container.insertAdjacentHTML('beforeend', thumb.outerHTML);

    const target = document.querySelector(`#page-thumb-container-with-index-${slideId}`);
    target.dataset.position = position;

    save(
      slideId,
      controller,
    );

    fixSlideIndexes();
  });
};

export default sortSlides;
