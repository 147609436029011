/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tabTemplateGroupForm", "tabTemplateGroupName", "tabTemplateGroupTemplate", "tabTemplateGroupFormContainer"]

  connect() {
    if (this.hasTabTemplateGroupFormTarget) {
      // Show the first tab while all others are hiding
      this.tabTemplateGroupFormTargets[0].classList.toggle('hidden');
    }

    // Highlight initial tab
    document.querySelector('#edit-tab-template-groups ul.sortable li').classList.add('btn-pink');
  }

  switchTo(event) {
    this.tabTemplateGroupFormTargets.forEach((el, i) => {
      el.classList.toggle("hidden", el.dataset.tabTemplateGroupId != event.target.dataset.tabTemplateGroupId);
    });

    // Highlight selected tab
    document.querySelector('#edit-tab-template-groups ul.sortable li.btn-pink').classList.remove('btn-pink');
    event.target.classList.add('btn-pink');
  }

  addNewTabTemplateGroup(event) {
    const id = new Date().getTime();
    const template = `<li class='inline-block mr-10 mb-14 btn' data-target='schools--edit-tab-template-groups.tabTemplateGroupName' data-action='click->schools--edit-tab-template-groups#switchTo' data-tab-template-group-id='${id}'>New Template</li>`
    const newTabTemplateGroupLink = this._htmlToElement(template);
    this._insertBefore(newTabTemplateGroupLink, event.target);
    newTabTemplateGroupLink.addEventListener("click", (ev) => {
      this.switchTo(ev);
    });
    const newTabTemplateGroupForm = this._htmlToElement(this.tabTemplateGroupTemplateTarget.innerHTML.replace(/TAB_TEMPLATE_GROUP_TMPL/g, id));
    newTabTemplateGroupForm.dataset.tabTemplateGroupId = id;
    this.tabTemplateGroupFormContainerTarget.insertAdjacentElement('beforeend', newTabTemplateGroupForm);

    newTabTemplateGroupLink.click();
  }

  updateTitle(event) {
    const tabTemplateGroupId = event.target.parentElement.parentElement.parentElement.dataset.tabTemplateGroupId;
    this.tabTemplateGroupNameTargets.find(el => el.dataset.tabTemplateGroupId  == tabTemplateGroupId).innerText = event.target.value;
  }

  removeTabTemplateGroup(event) {
    const tabTemplateGroupForm = event.target.parentElement;
    const tabTemplateGroupId = tabTemplateGroupForm.dataset.tabTemplateGroupId;
    this.tabTemplateGroupNameTargets.find(el => el.dataset.tabTemplateGroupId  == tabTemplateGroupId).style.display = 'none';
    tabTemplateGroupForm.querySelector("input[name*='_destroy']").value = 1;
    tabTemplateGroupForm.style.display = 'none';
  }

  _htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
  }

  _insertBefore(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode);
  }

  _insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }
}
