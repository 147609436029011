import { Controller } from "stimulus";
import Rails from 'rails-ujs';

export default class extends Controller {
  initialize() {
  }

  showHoveredImage(event) {
    event.preventDefault();
    event.stopPropagation();

    let imageUploader = event.target.closest('.image-uploader-container').querySelector('.image-uploader');
    let overlay = event.target.closest('.image-uploader-container').querySelector('.overlay-container');

    imageUploader.setAttribute('src', imageUploader.getAttribute('data-hovered'));
    imageUploader.classList.remove('hidden');
    if (this.hasImage(event)) {
      overlay.classList.remove('hidden');
    }
  }

  showOriginalImage(event) {
    event.preventDefault();
    event.stopPropagation();

    let imageUploader = event.target.closest('.image-uploader-container').querySelector('.image-uploader');
    let overlay = event.target.closest('.image-uploader-container').querySelector('.overlay-container');

    if (imageUploader.getAttribute('data-original')) {
      imageUploader.setAttribute('src', imageUploader.getAttribute('data-original'));
    } else {
      imageUploader.classList.add('hidden');
    }
    overlay.classList.add('hidden');
  }

  showFileDialog() {
    let file_input = event.target.closest('.image-uploader-container').querySelector('input[type=file]');
    file_input.click();
  }

  showAssetPicker(event) {
    let studioId = event.target.closest('.image-uploader-container').getAttribute('data-studio-id');
    let contentType = event.target.closest('.image-uploader-container').getAttribute('data-content-type');
    let contentId = event.target.closest('.image-uploader-container').getAttribute('data-content-id');
    let contentAttribute = event.target.closest('.image-uploader-container').getAttribute('data-content-attribute');

    Rails.ajax({
      url: `/media-browser/show?studio_id=${studioId}&content_type=${contentType}&content_id=${contentId}&content_attribute=${contentAttribute}`,
      type: 'GET'
    });
  }

  hasImage(event) {
    if (event.target.closest('.image-uploader-container').style.backgroundImage != '')
      return true
    else
      return false
  }

  previewImage(event) {
    let imageUploader = event.target.closest('.image-uploader-container');
    let oFReader = new FileReader();
    oFReader.readAsDataURL(event.target.files[0]);

    oFReader.onload = function(oFREvent) {
      imageUploader.style.background = `transparent url(${oFREvent.target.result}) center/cover no-repeat`;
    };
  }
}
