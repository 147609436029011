import { stringify } from 'transform-parser/index';
import ApplicationController from './application_controller.js';
import color_controller from './color_controller';
import setShapeAttributes from '../helpers/editor/items/actions/setShapeAttributes';

export default class extends ApplicationController {
  toggleMenu(event) {
    event.preventDefault();
    const menu = event.target.closest('.hoverMenuTrigger').querySelector('.hoverMenu');

    // First, hide all other current hovering menus
    document.querySelectorAll('.hoverMenu').forEach((el) => {
      if (menu === el) {
        return;
      }
      el.classList.add('hidden');
    });

    if (menu.classList.contains('hidden')) {
      let y = event.pageY;
      menu.classList.remove('hidden');

      if ((y + menu.offsetHeight) > document.documentElement.clientHeight) {
        y -= menu.offsetHeight;
      }

      // Update if custom input exists
      const customInput = menu.querySelector('.custom-input');
      if (customInput) {
        customInput.value = event.target.closest('.hoverMenuTrigger').querySelector('input').value;
      }
    } else {
      menu.classList.add('hidden');
    }
  }

  selectItem(event) {
    event.preventDefault();
    const canvasController = document.querySelector('.canvas').canvas;
    canvasController.preventEmptySpaceClick = true;

    const menuItem = event.target.closest('a');

    const container = event.target.closest('.hoverMenuTrigger');
    const input = document.querySelector(`#${menuItem.dataset.targetInputId}`);

    if (menuItem.dataset.value) {
      input.value = menuItem.dataset.value;

      const colorSelector = document.querySelector('#colorParentElement');
      if (colorSelector && colorSelector.color) {
        const colorController = colorSelector.color;
        const colorMode = colorController.data.get('colorMode');
        if (colorMode === 'gradient') {
          const stopEl = document.querySelector(`.gradientStop${colorController.data.get('selectedStop')} circle`);
          stopEl.style.fill = input.value;
          colorController.data.set(`gradientStop${colorController.data.get('selectedStop')}`, input.value);
          colorController.picker.setColor(input.value === 'transparent' ? '#00FFFFFF' : input.value, true);

          const gradientStop1 = colorController.data.get('gradientStop1');
          const gradientStop2 = colorController.data.get('gradientStop2');

          const slider = document.querySelector('.sliderOffset');
          slider.style.background = `linear-gradient(to right, ${gradientStop1}, ${gradientStop2})`;
          const gradientPercentage = document.querySelector('.sliderOffset').value;

          const gradientAngle = document.getElementsByName('gradientAngle')[0].value;

          input.value = JSON.stringify({
            gradientStop1,
            gradientStop2,
            gradientPercentage,
            gradientAngle,
          });
        } else {
          colorController.picker.setColor(input.value === 'transparent' ? '#00FFFFFF' : input.value, true);
        }
      }

      if (menuItem.dataset.itemShape === 'line') {
        const preview = menuItem.closest('.hoverMenuTrigger').querySelector('.preview');
        if (preview) {
          preview.innerHTML = menuItem.innerHTML;
        }
      }

      if (menuItem.dataset.targetInputId === 'item_border_style') {
        const preview = menuItem.closest('.hoverMenuTrigger').querySelector('.preview');
        if (preview) {
          preview.innerHTML = menuItem.innerHTML;
        }
      }

      if (menuItem.classList.contains('a-saved-color')) {
        console.log(event.target);
        const circle = container.querySelector('circle');
        circle.style.fill = input.value;

        const changeEvent = new Event('change', { bubbles: true, cancelable: true });
        input.dispatchEvent(changeEvent);

        setTimeout(() => canvasController.preventEmptySpaceClick = false, 200);
      } else if (container.querySelector('circle')) {
        const circle = container.querySelector('circle');
        if (input.value === 'transparent') {
          const checkerTemplate = '<mask id="mask_bg_color" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">\n'
            + '                <circle cx="7" cy="7" r="6.5" fill="white" stroke="white"/>\n'
            + '              </mask>\n'
            + '              <g mask="url(#mask_bg_color)">\n'
            + '                <rect x="12.7144" y="6.42859" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="12.7144" y="0.142822" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="12.7144" y="12.7142" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="9.57129" y="3.28577" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="9.57129" y="9.57141" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="6.42822" y="6.42859" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="6.42822" y="0.142822" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="6.42822" y="12.7142" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="3.28516" y="3.28577" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="3.28516" y="9.57141" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="0.143066" y="6.42859" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="0.143066" y="0.142822" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="0.143066" y="12.7142" width="3.14286" height="3.14286" fill="#D5D5D5"/>\n'
            + '                <rect x="9.57129" y="6.42859" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="9.57129" y="0.142822" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="9.57129" y="12.7142" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="6.42822" y="3.28577" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="6.42822" y="9.57141" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="12.7144" y="3.28577" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="12.7144" y="9.57141" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="3.28516" y="6.42859" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="3.28516" y="0.142822" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="3.28516" y="12.7142" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="0.143066" y="3.28577" width="3.14286" height="3.14286" fill="white"/>\n'
            + '                <rect x="0.143066" y="9.57141" width="3.14286" height="3.14286" fill="white"/>\n'
            + '              </g>';
          circle.insertAdjacentHTML('afterend', checkerTemplate);
        } else {
          circle.style.fill = input.value;
          const mask = circle.parentElement.querySelector('g');
          if (mask) {
            mask.remove();
          }
          this.createColorNodes(input.id, input.value);
        }
      }
    } else {
      input.value = '';
    }

    const changeEvent = new Event('change', { bubbles: true, cancelable: true });
    input.dispatchEvent(changeEvent);

    setTimeout(() => canvasController.preventEmptySpaceClick = false, 200);
    // this.toggleMenu(event)
  }

  loadRecentColors(event) {
    const menuItem = event.target.closest('div');
    const inputSource = menuItem.dataset.value;
    this.createColorNodes(inputSource);
  }

  getColorIndex() {
    let index = JSON.parse(localStorage.getItem('index'));
    index += 1;
    localStorage.setItem('index', index);
    return index;
  }

  createColorNodes(panelId, color) {
    switch (panelId) {
      case 'item_color':
      case 'textColor':
      case 'gridBorder':
      case 'lineColor':
      case 'shape':
        this.createColorNodesHelper('document_color', 'a.a-saved-color[data-target-input-id="item_color"]', 'item_color', color);
        break;
      case 'item_border_color':
      case 'border':
        this.createColorNodesHelper('document_color', 'a.a-saved-color[data-target-input-id="item_border_color"]', 'item_border_color', color);
        break;
      case 'item_background_color':
      case 'bgColor':
      case 'gridBGColor':
        this.createColorNodesHelper('document_color', 'a.a-saved-color[data-target-input-id="item_background_color"]', 'item_background_color', color);
        break;
      case 'grid_text_color':
      case 'gridTextColor':
        this.createColorNodesHelper('document_color', 'a.a-saved-color[data-target-input-id="grid_text_color"]', 'grid_text_color', color);
        break;
      default:
        break;
    }
  }

  createColorNodesHelper(name, selector, attribute, color) {
    if (color === undefined) { return; }
    // get all the colors and check vs that. client side.
    // append to the node, data color. check if that exists before appending new
    const adjustedSelector = `div.saved-color ${selector}`;
    const documentColorsNodes = document.querySelectorAll(adjustedSelector);
    const documentColorsArray = [];
    documentColorsNodes.forEach((colorNode) => {
      documentColorsArray.push(colorNode.getElementsByTagName('circle')[0].getAttribute('fill'));
    });
    if (!(documentColorsArray.includes(color))) {
      this.createDocumentColorNode(selector, attribute, color);
    }
  }

  createDocumentColorNode(selector, attribute, color) {
    const { parentElement } = document.querySelector(selector).parentElement;
    const nodeColor = document.createElement('div');
    nodeColor.classList.add('saved-color');
    nodeColor.innerHTML = `<a class="a-saved-color" data-value="${color}" data-target-input-id="${attribute}" data-action="mousedown->editor--hover-menu#selectItem"> <svg width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle class="circleColor"cx="7" cy="7" r="6.5" fill="${color}" stroke="black" style="stroke-width: .5;" /></svg></a>`;
    parentElement.appendChild(nodeColor);

    this.stimulate('Presentation#update_document_colors', color);
  }

  selectLink(event) {
    event.preventDefault();
    const canvasController = document.querySelector('.canvas').canvas;
    canvasController.preventEmptySpaceClick = true;

    const menuItem = event.target.closest('a');
    const container = event.target.closest('.hoverMenuTrigger');
    const input = document.querySelector(`#${menuItem.dataset.targetInputId}`);
    const selectedItem = container.querySelector('.active-item');

    if (menuItem.dataset.value) {
      input.value = menuItem.dataset.value;
    } else {
      input.value = '';
    }

    if (menuItem.dataset.linkType === 'url') {
      selectedItem.text = 'Website';
    } else if (menuItem.dataset.linkType === 'slide') {
      selectedItem.text = `Slide ${menuItem.dataset.slideIndex}`;
    }

    const changeEvent = new Event('change', { bubbles: true, cancelable: true });
    input.dispatchEvent(changeEvent);

    this.toggleMenu(event);

    setTimeout(() => canvasController.preventEmptySpaceClick = false, 200);
  }

  updateViaCustomInput(event) {
    const { targetInputId } = event.target.dataset;
    const container = event.target.closest('.hoverMenuTrigger');
    const input = document.querySelector(`#${targetInputId}`);
    input.value = event.target.value;

    const changeEvent = new Event('change', { bubbles: true, cancelable: true });
    input.dispatchEvent(changeEvent);

    if (event.code === 'Escape' || event.code === 'Enter') {
      this.toggleMenu(event);
    }
  }
}
