const isHorizontal = (itemId) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return false;

  const { width, height } = target.getBoundingClientRect();

  return (width / height).toFixed(2) >= window.slideRatio;
};

export default isHorizontal;
