import { Controller } from "stimulus"
import Rails from 'rails-ujs';

export default class extends Controller {
  connect() {
    document.querySelector('#query').focus();
  }

  open_menu(event) {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.closest('.filter-container').querySelector('.action-menu').classList.contains('hidden')) {
      this.close_all_menus();
      event.target.closest('.filter-container').querySelector('.action-menu').classList.remove('hidden');
    } else {
      event.target.closest('.filter-container').querySelector('.action-menu').classList.add('hidden');
    }
  }

  close_all_menus() {
    var menus = document.querySelectorAll('.action-menu');
    menus.forEach((menu) => {
      menu.classList.add('hidden');
    });
  }

  toggleDiscoverTag(event) {
    event.preventDefault();

    let urlParams = new URLSearchParams(window.location.search);
    let tags = '';
    let tag_array = [];

    if (urlParams.get('tags') && urlParams.get('tags') !== '') {
      tag_array = urlParams.get('tags').split('_');
    }

    let selectedTagId = event.target.getAttribute('data-tag-id');

    if (tag_array.includes(selectedTagId)) {
      let index = tag_array.indexOf(selectedTagId);
      if (index !== -1) tag_array.splice(index, 1);
    } else {
      tag_array.push(selectedTagId);
    }

    urlParams.set('tags', tag_array.join('_'));
    Turbolinks.visit(`${location.pathname}?${urlParams.toString()}`);
  }

  submit(event) {
    const url = new URL(window.location);
    url.searchParams.set('query', event.target.value);
    Turbolinks.visit(url.toString());
  }

}
