const endCrop = (controller, event) => {
  if (window.draggingElement) return;

  if (window.cropEventItem) {
    event.stopPropagation();
    event.preventDefault();
    const item = window.cropEventItem;
    if (item.dataset.clip === '' || item.dataset.clip === 'undefined') {
      document.querySelector('#croppable-item-container').remove();
      window.moveableForCrop.target = null;
      window.moveableForItems.target = item;
      window.cropEventItem = null;
      return;
    }

    item.dataset.hasCrop = 'true';
    item.dataset.hasAutoCrop = 'false';
    const clipValues = item.dataset.clip.match(/([0-9]\d*(\.\d+)?)/g);
    const imageContainer = item.querySelector('.moveableContainer');

    const widthRatio = parseFloat(item.dataset.assetWidth) / parseFloat(item.dataset.width);
    const heightRatio = parseFloat(item.dataset.assetHeight) / parseFloat(item.dataset.height);

    const x1 = parseInt(clipValues[3] * widthRatio, 0);
    const y1 = parseInt(clipValues[0] * heightRatio, 0);
    const x2 = parseInt(clipValues[1] * widthRatio, 0);
    const y2 = parseInt(clipValues[2] * heightRatio, 0);

    const croppedArea = `${x1}x${y1}:${x2}x${y2}`;

    const img = new Image();
    const imagePath = imageContainer.style.backgroundImage.slice(4, -1).replace(/"/g, '');

    if (/900x0/.test(imagePath)) {
      img.src = imagePath.replace('/900x0', `/${croppedArea}/900x0`);
    } else { // For gif
      const url = new URL(imagePath);
      img.src = imagePath.replace(url.pathname, `/unsafe/${croppedArea}${url.pathname}`);
      const loaderContainer = document.createElement('div');
      loaderContainer.classList.add('loading-item');
      imageContainer.appendChild(loaderContainer);
    }

    item.dataset.updatedImageUrlForCrop = img.src;

    const imagePreloader = item.querySelector('.crop-preloader');

    function loaded() {
      const loadingDiv = imageContainer.querySelector('.loading-item');
      if (loadingDiv) {
        loadingDiv.remove();
      }
      imageContainer.style.backgroundImage = `url("${item.dataset.updatedImageUrlForCrop}")`;
      window.moveableForCrop.target = null;
      window.moveableForItems.target = null;
      window.cropEventItem = null;

      item.setAttribute('data-translate-x-before-crop', item.getAttribute('data-translate-x'));
      item.setAttribute('data-translate-y-before-crop', item.getAttribute('data-translate-y'));
      item.setAttribute('data-rotate-before-crop', item.getAttribute('data-rotate'));
      item.setAttribute('data-width-was', item.getAttribute('data-width'));
      item.setAttribute('data-height-was', item.getAttribute('data-height'));
      item.setAttribute('data-translate-x', parseFloat(clipValues[3]) + parseFloat(item.getAttribute('data-translate-x')));
      item.setAttribute('data-translate-y', parseFloat(clipValues[0]) + parseFloat(item.getAttribute('data-translate-y')));
      item.setAttribute('data-width', parseFloat(clipValues[1]) - parseFloat(clipValues[3]));
      item.setAttribute('data-height', parseFloat(clipValues[2]) - parseFloat(clipValues[0]));

      item.style.width = `${parseFloat(item.getAttribute('data-width'))}px`;
      item.style.height = `${parseFloat(item.getAttribute('data-height'))}px`;

      const frame = window.mapItems.get(item);

      frame.set('width', `${parseFloat(item.getAttribute('data-width'))}`);
      frame.set('height', `${parseFloat(item.getAttribute('data-height'))}`);
      frame.set('transform', 'translateX', `${parseFloat(item.getAttribute('data-translate-x'))}px`);
      frame.set('transform', 'translateY', `${parseFloat(item.getAttribute('data-translate-y'))}px`);
      frame.set('transform', 'rotate', `${parseInt(item.getAttribute('data-rotate'), 0)}deg`);
      frame.clipStyle = null;

      window.mapItems.set(item, frame);
      item.style.cssText += frame.toCSS();
      item.style.clip = '';
      window.moveableForItems.updateRect();

      imageContainer.style.width = `${parseFloat(item.getAttribute('data-width'))}px`;
      imageContainer.style.height = `${parseFloat(item.getAttribute('data-height'))}px`;

      controller.stimulate('Crop#store_crop', item);

      if (document.querySelector('#croppable-item-container')) {
        document.querySelector('#croppable-item-container').remove();
      }

      if (imagePreloader) imagePreloader.removeEventListener('load', loaded);
    }

    imagePreloader.addEventListener('load', loaded);
    imagePreloader.src = item.dataset.updatedImageUrlForCrop;
  }
};

export default endCrop;
