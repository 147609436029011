import {
  SLIDE_ACTIONS,
  getHistory,
  addFuture,
  setButtonStates,
} from './manager';
import save from '../slides/save';
import deleteSlide from '../slides/delete';
import undeleteSlide from '../slides/undelete';
import sortSlides from '../slides/sortSlides';
import fixSlideIndexes from '../slides/fixSlideIndexes';

const undoSlide = (params) => {
  const pastHistories = getHistory({ ...params, key: 'Past', slideId: '' });
  if (pastHistories.length > 0) {
    let popSize = 1;
    if (pastHistories[pastHistories.length - 1].selectionId) {
      const { selectionId } = pastHistories[pastHistories.length - 1];
      popSize = pastHistories.filter((ph) => ph.selectionId === selectionId).length;
    }
    const groupedHistories = pastHistories.splice(-popSize, popSize);
    groupedHistories.forEach((pastHistory) => {
      // Slide Actions
      if (SLIDE_ACTIONS.includes(pastHistory.actionType)) {
        // Update slide
        if (pastHistory.actionType === 'ADD_SLIDE') {
          deleteSlide(pastHistory.contentId);
        } else if (pastHistory.actionType === 'DELETE_SLIDE') {
          undeleteSlide(pastHistory.contentId);
        } else if (pastHistory.actionType === 'UPDATE_SLIDE') {
          // updatedItem.innerHTML = historyItem;
        } else if (pastHistory.actionType === 'UPDATE_SLIDE_POSITIONS') {
          sortSlides(pastHistory.content, params.controller);
        } else if (pastHistory.actionType === 'UPDATE_SLIDE_BACKGROUND') {
          params.controller.stimulate('Slide#set_background_color', pastHistory.content);
        }

        if (pastHistory.contentId) {
          save(
            pastHistory.contentId,
            params.controller,
          );
        }
        fixSlideIndexes();
      }

      if (pastHistory.actionType !== 'UPDATE_SLIDE_POSITIONS') {
        addFuture({
          presentationId: params.presentationId,
          slideId: '',
          userId: params.userId,
          actionType: pastHistory.actionType,
          contentType: 'Slide',
          content: pastHistory.content,
          contentId: pastHistory.contentId,
          selectionId: pastHistory.selectionId,
        });
      }
    });

    // Remove from past
    localStorage.setItem(
      `Presentations:${params.presentationId}:Slide:${''}:User:${params.userId}:History:Past`,
      JSON.stringify(pastHistories),
    );

    setButtonStates(params);
  }
};

export default undoSlide;
