import itemType from '../itemType';
import updateRect from '../updateRect';
import setDataAttributes from '../setDataAttributes';
import isHorizontal from '../isHorizontal';
import center from './center';
import calculateCropAttributes from './calculateCropAttributes';

const maximizeItem = (target) => {
  const frame = window.mapItems.get(target);
  const { width, height } = target.getBoundingClientRect();
  let newWidth; let newHeight;

  frame.set('transform', 'translateX', '0px');
  frame.set('transform', 'translateY', '0px');

  if (isHorizontal(target.dataset.itemId)) {
    newWidth = window.slideWidth;
    newHeight = (height * window.slideWidth) / width;
  } else { // Vertical
    newHeight = window.slideHeight;
    newWidth = (width * window.slideHeight) / height;
  }

  if (target.dataset.itemShape === 'rect') {
    const svg = target.querySelector('svg');
    svg.setAttribute('viewBox', `0 0 ${newWidth} ${newHeight}`);

    const rects = svg.querySelectorAll('rect');
    rects.forEach((rect) => {
      rect.setAttribute('width', newWidth);
      rect.setAttribute('height', newHeight);
    });
  }

  const moveableContainer = target.querySelector('.moveableContainer');
  if (moveableContainer) {
    moveableContainer.style.width = `${newWidth}px`;
    moveableContainer.style.height = `${newHeight}px`;
  }

  frame.set('width', newWidth);
  frame.set('height', newHeight);
  target.style.width = `${newWidth}px`;
  target.style.height = `${newHeight}px`;

  target.style.cssText += frame.toCSS();
  calculateCropAttributes(target);
  setDataAttributes(target.dataset.itemId);
};

const maximizeNoHeightItem = (target) => {
  const frame = window.mapItems.get(target);

  frame.set('width', window.slideWidth);
  frame.set('transform', 'translateX', '0px');

  target.style.width = `${window.slideWidth}px`;
  target.style.cssText += frame.toCSS();
  setDataAttributes(target.dataset.itemId);
};

const maximize = (itemId) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;

  switch (itemType(itemId)) {
    case 'text':
    case 'grid':
      maximizeNoHeightItem(target);
      break;
    case 'image':
    case 'code':
    case 'video':
    case 'camera_asset':
    case 'shape':
      maximizeItem(target);
      break;
    default:
      break;
  }

  center(itemId);
  updateRect(itemId);
  window.hideContextMenus();
};

export default maximize;
