import { Controller } from 'stimulus';
import { htmlToElement } from '../helpers/dom';

export default class extends Controller {
  static targets = ['terms', 'holidays', 'holidayTemplates']

  connect() {

  }

  changeYear(event) {
    this.termsTargets.forEach((target) => target.classList.add('hidden'));
    this.termsTargets.find((target) => target.dataset.yearId == event.target.value).classList.remove('hidden');

    this.holidaysTargets.forEach((target) => target.classList.add('hidden'));
    this.holidaysTargets.find((target) => target.dataset.yearId == event.target.value).classList.remove('hidden');
  }

  openCalendar(event) {
    flatpickr(event.target,
      {
        onChange: (selectedDates, dateStr, instance) => {
        }
      })
      .open();
  }

  addNewHoliday(event) {
    const id = new Date().getTime();
    const currentSelectedYear = document.querySelector('#school_year_id').value;
    const template = this.holidayTemplatesTargets.find(target => target.parentElement.dataset.yearId === currentSelectedYear)
    const newHolidayForm = htmlToElement(template.innerHTML.replace(/HOLIDAY_TMPL/g, id));
    const holidays = this.holidaysTargets.find((target) => target.dataset.yearId == currentSelectedYear)
    holidays.insertAdjacentElement('beforeend', newHolidayForm);
  }

  deleteHoliday(event) {
    event.target.nextElementSibling.value = 1;
    event.target.parentElement.style.display = 'none';
  }

}
