import { template, templateSettings } from 'lodash';
import { updateRulerStateAtContextMenu } from '../helpers/utils.js';
import ApplicationController from './application_controller.js';

export default class extends ApplicationController {
  initialize() {
    templateSettings.interpolate = /{{([\s\S]+?)}}/g;
  }

  displayContextMenu(event) {
    // Skip context menu in text edit mode.
    if (event.target.closest('.ql-editor[contenteditable="true"]')) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    window.hideContextMenus();

    /* We have right-click on moveable-line. Handling it in a custom way since the click
    propagates all the way up without knowing click's target. */
    if (event.target.classList.contains('moveable-line')) {
      const evt = new MouseEvent('contextmenu', {
        bubbles: true,
        cancelable: true,
        clientX: event.clientX,
        clientY: event.clientY,
      });

      const items = this.selectedItems();
      if (items.length > 1) {
        document.querySelector('.moveable-area').dispatchEvent(evt);
      } else {
        items[0].dispatchEvent(evt);
      }
      return false;
    }

    this.multiSelectionContextMenu(event);
    this.itemContextMenu(event);
    this.setZoomLevelForContextMenu();
    // We don't change zoom level for canvas context menu, so it's at bottom
    this.canvasContextMenu(event);

    this.slideThumbContextMenu(event);
    this.assetContextMenu(event);
    this.embedContextMenu(event);
  }

  slideThumbContextMenu(event) {
    if (event.target.closest('.slideContainer')) {
      // Context menu for slide thumbs
      const container = event.target.closest('.slideContainer');
      const contextMenu = container.querySelector('.contextMenu');

      const rect = event.target.closest('#pageThumbs').getBoundingClientRect();
      const x = event.clientX - rect.left + 10;
      let y = event.clientY - rect.top + 25;

      contextMenu.classList.remove('hidden');
      // alert(`${y}-${contextMenu.getBoundingClientRect().height}-${rect.height}`);

      if ((y + contextMenu.getBoundingClientRect().height) > rect.height) {
        const diff = y + contextMenu.getBoundingClientRect().height - rect.height;
        y -= diff;
      }

      // Set the position for menu
      contextMenu.style.top = `${y}px`;
      contextMenu.style.left = `${x}px`;
      contextMenu.style.position = 'fixed';
    }
  }

  assetContextMenu(event) {
    if (event.target.closest('.assetContainer')) {
      // Context menu for slide thumbs
      const container = event.target.closest('.assetContainer');
      const contextMenu = container.querySelector('.contextMenu');

      const rect = event.target.closest('.mediaHubPanel').getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      contextMenu.classList.remove('hidden');

      if ((y + contextMenu.getBoundingClientRect().height) > rect.height) {
        // y = y - contextMenu.getBoundingClientRect().height;
      }
      if ((x + contextMenu.getBoundingClientRect().width) > rect.width) {
        // x = x - contextMenu.getBoundingClientRect().width;
      }

      // Set the position for menu
      contextMenu.style.top = `${y}px`;
      contextMenu.style.left = `${x}px`;
    }
  }

  embedContextMenu(event) {
    if (event.target.closest('.embedContainer')) {
      const container = event.target.closest('.embedContainer');
      const contextMenu = container.querySelector('.contextMenu');

      const rect = event.target.closest('.mediaHubPanel').getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      contextMenu.classList.remove('hidden');

      // Set the position for menu
      contextMenu.style.top = `${y}px`;
      contextMenu.style.left = `${x}px`;
    }
  }

  itemContextMenu(event) {
    if (event.target.closest('.item-container')) {
      if (event.target.closest('.thumb-item-container')) {
        return;
      }

      const { itemId } = event.target.classList.contains('line-handler')
        ? event.target.closest('.item-container').querySelector('.draggable-item-container').dataset
        : event.target.closest('.draggable-item-container').dataset;

      this.stimulate('Item#context_menu', itemId).then(() => {
        const menu = document.querySelector('#contextMenuContainer .contextMenu');
        let x = event.pageX;
        let y = event.pageY;

        // If this is a context menu for table, set the row - col
        // receiving the right click, so we can use them to reference table operations
        if (menu.classList.contains('grid')) {
          const cell = event.target.closest('td');
          const colIndex = cell.cellIndex;
          const { rowIndex } = cell.parentElement;

          menu.dataset.colIndex = colIndex;
          menu.dataset.rowIndex = rowIndex;
        }

        menu.classList.remove('hidden');

        if ((y + menu.offsetHeight) > document.documentElement.clientHeight) {
          y -= menu.offsetHeight;
        }
        if ((x + menu.offsetWidth) > document.documentElement.clientWidth) {
          x -= menu.offsetWidth;
        }

        menu.style.top = `${y}px`;
        menu.style.left = `${x}px`;
      });
    }
  }

  canvasContextMenu(event) {
    if (event.target.closest('#draggable-multi-item-selection-container')) return;

    if (event.target.closest('.canvasContainer')) {
      if (event.target.closest('.item-container')) return;

      // Context menu for slide
      // const container = event.target.closest('.canvasContainer');

      const contextMenu = document.querySelector('.contextMenu.slideContextMenu');

      const rect = event.target.closest('.canvasContainer').getBoundingClientRect();
      // updateRulerStateAtContextMenu(window.Ruler.state);

      const x = event.clientX;
      const y = event.clientY;

      contextMenu.classList.remove('hidden');

      if ((y + contextMenu.getBoundingClientRect().height) > rect.height) {
        // y = y - contextMenu.getBoundingClientRect().height;
      }
      if ((x + contextMenu.getBoundingClientRect().width) > rect.width) {
        // x = x - contextMenu.getBoundingClientRect().width;
      }

      // Set the position for menu
      contextMenu.style.top = `${y}px`;
      contextMenu.style.left = `${x}px`;
    }
  }

  multiSelectionContextMenu(event) {
    if (event.target.closest('.moveable-area')) {
      this.stimulate('Item#multi_item_context_menu').then(() => {
        const menu = document.querySelector('#contextMenuContainer .contextMenu');
        let x = event.pageX;
        let y = event.pageY;

        menu.classList.remove('hidden');

        if ((y + menu.offsetHeight) > document.documentElement.clientHeight) {
          y -= menu.offsetHeight;
        }
        if ((x + menu.offsetWidth) > document.documentElement.clientWidth) {
          x -= menu.offsetWidth;
        }

        menu.style.top = `${y}px`;
        menu.style.left = `${x}px`;

        if (this.selectedItems().length > 2) {
          document.querySelectorAll('li.more-than-two-items').forEach((li) => {
            li.classList.remove('hidden');
          });
        } else {
          document.querySelectorAll('li.more-than-two-items').forEach((li) => {
            li.classList.add('hidden');
          });
        }
      });
    }
  }

  setZoomLevelForContextMenu() {
    const openContextMenus = document.querySelectorAll('.contextMenu:not(.hidden):not(.itemContextMenu)');
    const zoomScale = this.getZoomScale();
    openContextMenus.forEach((menu) => {
      menu.style.transform = `scale(${1 / zoomScale})`;
      menu.style.transformOrigin = 'top left';
    });
  }

  getZoomScale() {
    const zoomLevel = parseFloat(window.zoomLevel);
    return zoomLevel;
  }

  selectedItems() {
    return document.querySelectorAll('.item-container .selected');
  }

  triggerSlideContextMenu(e) {
    e.preventDefault();
    e.stopPropagation();
    const thumb = e.currentTarget.parentElement.querySelector('.slide');
    const rightClick = new MouseEvent('contextmenu', {
      bubbles: true,
      cancelable: false,
      view: window,
      button: 2,
      buttons: 0,
      clientX: e.target.getBoundingClientRect().x,
      clientY: e.target.getBoundingClientRect().y,
    });
    thumb.dispatchEvent(rightClick);
  }

  finalizeShowColorPanel(element, reflex, noop, reflexId) {
    const picker = document.querySelector('.colorPicker');
    picker.style.right = '5px';
    picker.style.setProperty('top', '80px', 'important');
    picker.classList.remove('hidden');

    document.querySelector('.slideColorController').click();
  }
}
