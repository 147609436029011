/*jshint esversion: 6 */
/* global interact */
import { Controller } from "stimulus";
import Sortable from 'sortablejs';
import { htmlToElement } from '.././helpers/dom';

export default class extends Controller {
  static targets = [ "schoolFieldTemplate", "schoolFieldsFormContainer", "schoolFieldName", "schoolFieldForm" ]

  connect() {
    // if (this.hasSchoolFieldFormTarget) {
    //   // Show the first skill category while all others are hiding
    //   this.schoolFieldFormTargets[0].classList.toggle('hidden');
    // }

    // Make the skill category selectors sortable
    const sortable = document.querySelector('#school-fields-sortable.sortable');
    var el = Sortable.create(sortable, {
      animation: 150,
      draggable: "li.btn",
      filter: ".ignore-sort",
      forceFallback: true,
      onEnd: function(event) {
        let schoolFieldId = event.item.getAttribute('data-school-field-id');
        document.querySelector(`[data-school-field-id="${schoolFieldId}"] input.position`).value = event.newIndex + 1;
      }
    });

    // Highlight initial skill category
    // document.querySelector('#school-fields-sortable.sortable li').classList.add('btn-pink');
  }

  switchTo(event) {
    this.schoolFieldFormTargets.forEach((el, i) => {
      el.classList.toggle("hidden", el.dataset.schoolFieldId != event.target.dataset.schoolFieldId)
    });

    // Highlight selected tab
    let sortableSelector = '#school-fields-sortable.sortable li.btn-pink'
    if (document.querySelector(sortableSelector) != null) {
      document.querySelector(sortableSelector).classList.remove('btn-pink');
    }
    event.target.classList.add('btn-pink');
  }

  addNewItem(event) {
    const id = new Date().getTime();
    const template = `<li class='inline-block mr-10 mb-14 btn' data-target='schools--school-fields.schoolFieldName' data-action='click->schools--school-fields#switchTo' data-school-field-id='${id}'>New Field</li>`
    const newElementLink = htmlToElement(template);
    this._insertBefore(newElementLink, event.target);
    const parentElement = document.getElementById("school-fields-sortable");
    parentElement.insertAdjacentElement("beforeend", newElementLink);
    newElementLink.addEventListener("click", (ev) => {
      this.switchTo(ev);
    });
    const newElementForm = htmlToElement(this.schoolFieldTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, id));
    newElementForm.dataset.schoolFieldId = id;
    this.schoolFieldsFormContainerTarget.insertAdjacentElement('beforeend', newElementForm);

    window.expandableTextArea();
    newElementLink.click();
  }

  updateTitle(event) {
    const schoolFieldId = event.target.parentElement.parentElement.parentElement.dataset.schoolFieldId;
    this.schoolFieldNameTargets.find(el => el.dataset.schoolFieldId  == schoolFieldId).innerText = event.target.value;
  }

  removeItem(event) {
    if (confirm('Are you sure?') == true) {
      const itemForm = event.target.parentElement;
      const schoolFieldId = itemForm.dataset.schoolFieldId;
      this.schoolFieldNameTargets.find(el => el.dataset.schoolFieldId  == schoolFieldId).style.display = 'none';
      itemForm.querySelector("input[name*='_destroy']").value = 1;
      itemForm.style.display = 'none';
    }
  }

  _insertBefore(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode);
  }

  _insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }
}
