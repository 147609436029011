import CableReady from 'cable_ready';
import consumer from './consumer';

consumer.subscriptions.create({ channel: 'PresentationChannel', presentation_id: window.presentationId }, {
  connected() {
    const element = document.getElementById('connectionErrorMessage');
    if (element) {
      element.classList.add('hidden');
    }
  },

  disconnected() {
    const element = document.getElementById('connectionErrorMessage');
    if (element) {
      element.classList.remove('hidden');
    }
  },

  received(data) {
    if (data.cableReady) {
      CableReady.perform(data.operations);
    }
  },
});
