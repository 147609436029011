import ApplicationController from './application_controller.js'
import interact from 'interactjs';

export default class extends ApplicationController {
  static targets = ['container'];

  connect() {
    super.connect();

    console.log('Initialized multi selection container');
    let canvasController = document.querySelector('.canvas').canvas;

    let position = {
      x: parseFloat(this.containerTarget.dataset.x),
      y: parseFloat(this.containerTarget.dataset.y),
    };

    interact(this.containerTarget)
      .draggable({})
      .on('dragstart', (event) => {
        console.log('Multi selection dragstart event');
        // Drag started
      })
      .on('dragmove', (event) => {
        let zoomLevel = this.getZoomScale();
        position.x += event.dx / zoomLevel;
        position.y += event.dy / zoomLevel;

        event.target.style.transform = `translate(${position.x}px, ${position.y}px)`;
        event.target.dataset.x = position.x;
        event.target.dataset.y = position.y;


        let selectedItems = canvasController.selectedItems();
        for (const selectedItem of selectedItems) {
          if (selectedItem.dataset.itemType === 'line') {
            // Adaption of line controller. It could be refactored to shared method.
            let zoomLevel = this.getZoomScale();

            var target = selectedItem;
            var actual_line = target.closest('svg').querySelector('.actual-line');

            let itemPosition = {
              x: parseFloat(selectedItem.dataset.x),
              y: parseFloat(selectedItem.dataset.y),
              x1: parseFloat(actual_line.getAttribute('x1')) || 0,
              y1: parseFloat(actual_line.getAttribute('y1')) || 0,
              x2: parseFloat(actual_line.getAttribute('x2')) || 0,
              y2: parseFloat(actual_line.getAttribute('y2')) || 0,
            };

            itemPosition.x += event.dx / zoomLevel;
            itemPosition.y += event.dy / zoomLevel;
            itemPosition.x1 += event.dx / zoomLevel;
            itemPosition.y1 += event.dy / zoomLevel;
            itemPosition.x2 += event.dx / zoomLevel;
            itemPosition.y2 += event.dy / zoomLevel;
            // keep the dragged position in the data-x/data-y attributes
            var left_handler = target.closest('.line-container').querySelector('.line-handle-left');
            var right_handler = target
              .closest('.line-container')
              .querySelector('.line-handle-right');

            target.setAttribute('x1', itemPosition.x1);
            target.setAttribute('y1', itemPosition.y1);
            target.setAttribute('x2', itemPosition.x2);
            target.setAttribute('y2', itemPosition.y2);
            actual_line.setAttribute('x1', itemPosition.x1);
            actual_line.setAttribute('y1', itemPosition.y1);
            actual_line.setAttribute('x2', itemPosition.x2);
            actual_line.setAttribute('y2', itemPosition.y2);

            left_handler.setAttribute('cx', itemPosition.x1);
            left_handler.setAttribute('cy', itemPosition.y1);
            right_handler.setAttribute('cx', itemPosition.x2);
            right_handler.setAttribute('cy', itemPosition.y2);
            left_handler.setAttribute('data-x', itemPosition.x1);
            left_handler.setAttribute('data-y', itemPosition.y1);
            right_handler.setAttribute('data-x', itemPosition.x2);
            right_handler.setAttribute('data-y', itemPosition.y2);

            // update the position attributes
            target.setAttribute('data-x', itemPosition.x);
            target.setAttribute('data-y', itemPosition.y);
            actual_line.setAttribute('data-x', itemPosition.x);
            actual_line.setAttribute('data-y', itemPosition.y);
          } else {
            let itemPosition = {
              x: parseFloat(selectedItem.dataset.x),
              y: parseFloat(selectedItem.dataset.y),
            };

            itemPosition.x += event.dx / zoomLevel;
            itemPosition.y += event.dy / zoomLevel;

            selectedItem.style.transform = `translate(${itemPosition.x}px, ${itemPosition.y}px) rotate(${selectedItem.dataset.rotation}deg)`;
            selectedItem.dataset.x = itemPosition.x;
            selectedItem.dataset.y = itemPosition.y;
          }
        }
      })
      .on('dragend', (event) => {
        console.log('Multi selection dragend event');
        if (!document.body.classList.contains('handMode')) {
          let selectedItems = document.querySelectorAll('.item-container .selected');
          let randomSelectId = this._generateRandomString();
          for (const selectedItem of selectedItems) {
            selectedItem.dataset.selectionId = randomSelectId;
            this.stimulate('Item#update', selectedItem);
          }
        }
      });

    let containerInitialDims = {};
    const boxNormals = {};

    interact(this.containerTarget)
      .resizable({
        edges: {
          top: true,
          left: true,
          bottom: true,
          right: true,
        },
        modifiers: [interact.modifiers.aspectRatio({ ratio: 'preserve' })],
      })
      .on('resizestart', (event) => {
        console.log('multiselection resize started');
        position = {
          x: parseFloat(this.containerTarget.dataset.x),
          y: parseFloat(this.containerTarget.dataset.y),
        };
        containerInitialDims = event.target.getBoundingClientRect();

        for (const selectedElement of canvasController.selectedItems()) {
          let dim = this._isLine(selectedElement)
            ? selectedElement.querySelector('line').getBoundingClientRect()
            : selectedElement.getBoundingClientRect();
          boxNormals[selectedElement.id] = {
            x: (dim.x - containerInitialDims.x) / containerInitialDims.width,
            y: (dim.y - containerInitialDims.y) / containerInitialDims.height,
            w: dim.width / containerInitialDims.width,
            h: dim.height / containerInitialDims.height,
          };
        }
      })
      .on('resizemove', (event) => {
        // Enable aspect ratio preserving on shift key state
        // like Figma
        if (event.shiftKey) {
          event.interactable.options.resize.modifiers[0].enable();
        } else {
          event.interactable.options.resize.modifiers[0].disable();
        }

        let { x, y } = position;

        let zoomLevel = this.getZoomScale();

        x += event.deltaRect.left / zoomLevel;
        y += event.deltaRect.top / zoomLevel;

        event.target.style.webkitTransform = event.target.style.transform =
          'translate(' + x + 'px,' + y + 'px)';

        Object.assign(event.target.style, {
          width: `${parseInt(event.rect.width / zoomLevel)}px`,
          height: `${parseInt(event.rect.height / zoomLevel)}px`,
        });

        Object.assign(position, { x, y });

        event.target.dataset.x = x;
        event.target.dataset.y = y;
        event.target.dataset.width = event.rect.width / zoomLevel;
        event.target.dataset.height = event.target.getBoundingClientRect().height / zoomLevel;

        let scaleOnX = (event.rect.width + event.deltaRect.width) / event.rect.width;
        let scaleOnY = (event.rect.height + event.deltaRect.height) / event.rect.height;

        let translateOnX = event.dx / scaleOnX;
        let translateOnY = event.dy / scaleOnY;

        const containerDim = event.target.getBoundingClientRect();
        for (const selectedElement of canvasController.selectedItems()) {
          const nBox = boxNormals[selectedElement.id];

          let newWidth = nBox.w * containerDim.width;
          let newHeigth = nBox.h * containerDim.height;

          let newX = this._getTranslateXY(event.target).translateX + nBox.x * containerDim.width;
          let newY = this._getTranslateXY(event.target).translateY + nBox.y * containerDim.height;

          if (!this._isLine(selectedElement)) {
            selectedElement.style.height = newHeigth + 'px';
            selectedElement.style.width = newWidth + 'px';
            selectedElement.style.transform = `translate(${newX}px, ${newY}px)`;
            selectedElement.dataset.x = newX;
            selectedElement.dataset.y = newY;
            selectedElement.dataset.width = newWidth;
            selectedElement.dataset.height = newHeigth;
          } else {
            const line = selectedElement.querySelector('line');
            const newX2 = newX + newWidth;
            const newY2 = newY + newHeigth;

            if (line.x1.baseVal.value > line.x2.baseVal.value) {
              line.setAttribute('x2', newX);
              line.setAttribute('x1', newX2);
            } else {
              line.setAttribute('x1', newX);
              line.setAttribute('x2', newX2);
            }

            line.setAttribute('y1', newY);
            line.setAttribute('y2', newY2);

            const left_handler = line.closest('.line-container').querySelector('.line-handle-left');
            const right_handler = line
              .closest('.line-container')
              .querySelector('.line-handle-right');

            left_handler.setAttribute('cx', line.getAttribute('x1'));
            left_handler.setAttribute('cy', line.getAttribute('y1'));
            right_handler.setAttribute('cx', line.getAttribute('x2'));
            right_handler.setAttribute('cy', line.getAttribute('y2'));

            selectedElement.setAttribute('x1', line.getAttribute('x1'));
            selectedElement.setAttribute('y1', line.getAttribute('y1'));
            selectedElement.setAttribute('x2', line.getAttribute('x2'));
            selectedElement.setAttribute('y2', line.getAttribute('y2'));
          }
        }
      })
      .on('resizeend', (event) => {
        if (!document.body.classList.contains('handMode')) {
          let selectedItems = document.querySelectorAll('.item-container .selected');
          let randomSelectId = this._generateRandomString();
          for (const selectedItem of selectedItems) {
            selectedItem.dataset.selectionId = randomSelectId;
            this.stimulate('Item#update', selectedItem);
          }
          window.draggingElement = true;
          setTimeout(function () {
            window.draggingElement = false;
          }, 10);
        }
      });
  }

  _isLine(element) {
    return element.dataset.itemType == 'line';
  }

   _getTranslateXY(element) {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);
    return {
      translateX: matrix.m41,
      translateY: matrix.m42,
    };
  }

  _generateRandomString() {
    return Math.round((Math.random() * 36 ** 12)).toString(36);
  }

  getZoomScale() {
    let zoomLevel = parseFloat(window.zoomLevel);
    return zoomLevel;
  }
}
