import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
	beforeConvertToTemplate(element) {
		this.studioName = document.getElementById('studioName').innerText;
		document.getElementById('studioName').innerText = 'Saving as template, please wait...';
	}

	afterConvertToTemplate(element) {
		document.getElementById('studioName').innerText = this.studioName;
	}
}