import CableReady from 'cable_ready';
import consumer from "./consumer";

consumer.subscriptions.create({channel: "SlideChannel", slide_id: window.slideId}, {
  received(data) {
    if (data.cableReady) {
      CableReady.perform(data.operations);
    }
  }
});
