import ApplicationController from './application_controller';
import { addLineToSlideDiagrams, createItem, setDiagramZoomLevel, reselectAllItems } from '../helpers/editor/items/diagram';
import { throttle } from 'lodash';

if (window.LeaderLine) {
  window.LeaderLine.prototype.getRelativeSVG = function (itemId) {
    const svg = document.querySelector(`#connectors [data-item-id='${this.itemId}']`);
    const cloneSvg = svg.cloneNode(true);
    cloneSvg.classList.add('static-line');
    const canvas = document.querySelector('.canvasContainer');
    const canvasRect = canvas.getBoundingClientRect();
    const svgRect = svg.getBoundingClientRect();
    const relativeLeft = (svgRect.x - canvasRect.x) / zoomLevel;
    const relativeTop = (svgRect.y - canvasRect.y) / zoomLevel;
    const relativeWidth = svgRect.width / zoomLevel;
    const relativeHeight = svgRect.height / zoomLevel;
    cloneSvg.style.left = `${relativeLeft}px`;
    cloneSvg.style.top = `${relativeTop}px`;
    cloneSvg.style.width = `${relativeWidth}px`;
    cloneSvg.style.height = `${relativeHeight}px`;
    return cloneSvg.outerHTML.replaceAll(`leader-line-${this._id}`, `leader-line-${itemId}-${Math.floor(Math.random() * 10000) + 1}`);
  };
}

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  // storeSnapshot(event) {
  //   // console.log(event);
  // }

  initialize() {
    super.initialize();
    this.isDrawing = false;
    window.Diagrams.isDiagramToolActive = false;
    window.Diagrams.selectedItemIds = [];

    window.Diagrams.updateDiagramPositions = throttle(() => {
      const connectorContainer = document.querySelector('#connectors');
      connectorContainer.style.top = `0px`;
      connectorContainer.style.left = `0px`;
      const { slideId } = document.querySelector('.slideContainer.selected').dataset;
      if (!slideId || !(slideId in window.Diagrams.currentDiagrams)) {
        // console.log('no diagram in the current slide');
        return;
      }
      window.Diagrams.currentDiagrams[slideId].diagrams.forEach((diagram) => {
        diagram.position();
      });
      setDiagramZoomLevel();
    }, 10);

    window.Diagrams.initializeDiagrams = (touchedItems = []) => {
      document.querySelectorAll('#connectors .leader-line').forEach((line) => {
        line.remove();
      });
      const connectorContainer = document.querySelector('#connectors');
      connectorContainer.style.top = `0px`;
      connectorContainer.style.left = `0px`;
      window.Diagrams.firstScroll = true;
      const allDiagramItems = document.querySelectorAll('#canvas .diagram-type-attributes.item-type-diagram-line[data-is-deleted="false"]');
      window.Diagrams.currentDiagrams = {};
      allDiagramItems.forEach((item) => {
        const {
          slideId, color, dash, path, sourceItemId, targetItemId, itemId, thickness, startSocket, endSocket
        } = item.dataset;
        if (!window.Diagrams.currentDiagrams[slideId]) {
          window.Diagrams.currentDiagrams[slideId] = { diagrams: [] };
        }
        const line1 = document.getElementById(`draggable-item-container-${sourceItemId}`);
        const line2 = document.getElementById(`draggable-item-container-${targetItemId}`);

        const { leaderLine } = createItem({
          sourceItem: line1,
          targetItem: line2,
          path,
          color,
          dash,
          thickness,
          startSocket,
          endSocket,
          itemId,
          slideId,
        });
        if (leaderLine) {
          window.Diagrams.currentDiagrams[slideId].diagrams.push(leaderLine);
          leaderLine.position();
          if (touchedItems.includes(parseInt(sourceItemId)) || touchedItems.includes(parseInt(targetItemId))) {
            this.stimulate('Item#update_diagram_line_content', { itemId, content: leaderLine.getRelativeSVG(sourceItemId) });
          }
        }
      });
      document.querySelectorAll('.leader-line').forEach((ll) => {
        ll.addEventListener('click', this.addClickListener.bind(this));
      });
      setDiagramZoomLevel();
      reselectAllItems();
    };
  }

  clearOtherItemSelections() {
    window.moveableForGroups.target = null;
    window.moveableForItems.target = null;
    window.moveableForText.target = null;
    window.moveableForLineHandle.target = null;
    window.moveableForLineBody.target = null;
  }

  addClickListener(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!e.shiftKey) {
      window.unselectAllItems();
      let selectedItems = document.querySelectorAll('.item-container .selected');
      for (const selectedItem of selectedItems) {
          selectedItem.classList.remove('selected');
          selectedItem.classList.remove('multi-select');
          selectedItem.closest('.item-container').querySelector('.handle-left')?.classList.add('hidden');
          selectedItem.closest('.item-container').querySelector('.handle-right')?.classList.add('hidden');
      }
    }

    let leaderLineId;
    try {
      if (e.target.href) {
        leaderLineId = (e.target.href.baseVal.match(/\d+/g) || []).join('');
      } else {
        leaderLineId = (e.target.querySelector('use').id.match(/\d+/g) || []).join('');
      }
    } catch (error) {
      console.log('leaderline click error', error);
    }

    if (leaderLineId) {
      this.clearOtherItemSelections();

      const slideId = Object.keys(window.Diagrams.currentDiagrams)[0];
      const currentleaderLine = window.Diagrams.currentDiagrams[slideId].diagrams.find((i) => i._id == leaderLineId);
      const { itemId } = currentleaderLine;
      if (e.shiftKey) {
        window.Diagrams.selectedItemIds = window.Diagrams.selectedItemIds.includes(itemId) ? window.Diagrams.selectedItemIds : [...window.Diagrams.selectedItemIds, itemId];
      } else {
        window.Diagrams.selectedItemIds = [itemId]
        document.querySelectorAll('.leader-line').forEach((el) => el.classList.remove('active'));
      }
      const svg = e.currentTarget;
      svg.classList.add('active');

      document.getElementById(`draggable-item-container-${itemId}`).classList.add('selected');
      const itemIds = window.Diagrams.selectedItemIds
      this.stimulate('Item#select_line_tool', { itemIds });
    }
  }

  mouseup(e) {
    if (window.Diagrams.isDiagramToolActive) {
      const paths = e.path || e.composedPath();
      const items = paths.filter((el) => el.classList && el.classList.contains('draggable-item-container'));
      if (items[0]) {
        if (!window.Diagrams.currentDiagramItemSelection.includes(items[0])) {
          window.Diagrams.currentDiagramItemSelection.push(items[0]);
        }
        if (window.Diagrams.currentDiagramItemSelection.length > 1) {
          const [item1, item2] = window.Diagrams.currentDiagramItemSelection;

          // leader line
          const { leaderLine, properties } = createItem({
            sourceItem: item1,
            targetItem: item2,
          });
          addLineToSlideDiagrams(leaderLine);
          this.stimulate('Item#create_diagram_line', properties, leaderLine.getRelativeSVG(properties.source_item_id)).then((data) => {
            window.Diagrams.clearCurrentDiagramSelection();
            window.Diagrams.isDiagramToolActive = false;
            document.querySelector('#canvas').canvas.clearHighlightPanelTrigger();
            setTimeout(() => {
              window.Diagrams.initializeDiagrams();
            }, 200);
          });
        }
      }
    }
  }
}
