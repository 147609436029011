import { Frame } from 'scenejs';
import uuidv4 from '../../../uuidv4';

const cloneDomObject = (targetContainer, e) => {
  const target = targetContainer.querySelector('.draggable-item-container');
  const clonedContainer = targetContainer.cloneNode(true);
  target.classList.remove('selected');

  const tempId = uuidv4();

  clonedContainer.dataset.clone = true;
  clonedContainer.id = `item-container-${tempId}`;

  targetContainer.parentElement.appendChild(clonedContainer);

  const clone = clonedContainer.querySelector('.draggable-item-container');
  delete clone.dataset.itemId;
  clone.dataset.tempId = tempId;
  clone.id = `draggable-item-container-${tempId}`;
  clone.dataset.clone = true;
  clone.dataset.cloneOf = target.dataset.itemId;

  let height = parseFloat(clone.dataset.height, 0);
  // Don't set height for text, it is auto
  if (clone.dataset.itemType === 'text') {
    height = '';
  }

  const cloneFrame = new Frame({
    'z-index': clone.style.zIndex + 1,
    width: `${parseFloat(clone.dataset.width, 0)}px`,
    height: `${height}px`,
    opacity: parseInt(target.dataset.opacity, 0) / 100,
    transform: {
      translateX: `${clone.dataset.translateX || 0}px`,
      translateY: `${clone.dataset.translateY || 0}px`,
      rotate: `${clone.dataset.rotate || 0}`,
      scaleX: `${clone.dataset.scaleX || 1}`,
      scaleY: `${clone.dataset.scaleY || 1}`,
    },
  });

  clone.style.cssText = cloneFrame.toCSS();
  if (clone.dataset.itemType === 'line') {
    clone.style.overflow = 'visible';
  }

  window.mapItems.set(clone, cloneFrame);
  return clone;
};

export default cloneDomObject;
