import Picker from 'vanilla-picker';
import ApplicationController from './application_controller';
import setShapeAttributes from '../helpers/editor/items/actions/setShapeAttributes';
import save from '../helpers/editor/items/save';

let parentBasic;
let popupBasic;
let hiddenItemColor;
let hiddenBorderColor;
let hiddenBackgroundColor;
let hiddenGridTextColor;
let hiddenPencilColor;
let hiddenSlideBackgroundColor;
let colorDataValue;
let circleColor;
let colorIcon;

let globalMenuItem;
export default class extends ApplicationController {

  static targets = ['colorMode', 'colorModeMenu', 'gradientSelector'];
  handlePicker(event) {
    const controller = this;

    function $(selector) {
      return document.querySelector(selector);
    }

    const menuItem = event.target.closest('div');
    globalMenuItem = menuItem;
    const inputSource = menuItem.dataset.value;

    const parentElement = event.target.closest('div#colorParentElement');
    parentBasic = parentElement.querySelector('#placeColorSpectrum');

    if (typeof popupBasic === 'undefined') {
      popupBasic = new Picker(parentBasic);
    } else {
      popupBasic.destroy();
      popupBasic = new Picker(parentBasic);
    }

    colorDataValue = $('#colorDataValue');
    hiddenItemColor = $('#item_color');
    hiddenBorderColor = $('#item_border_color');
    hiddenBackgroundColor = $('#item_background_color');
    hiddenGridTextColor = $('#grid_text_color');
    hiddenPencilColor = $('#pencil_color');
    hiddenSlideBackgroundColor = $('#slide_color');

    colorIcon = event.target;

    popupBasic.setOptions({
      popup: false,
      editorFormat: 'hex',
      cancelButton: false,

    });

    popupBasic.openHandler();

    const arrowIcon = document.querySelectorAll('.picker_arrow');
    arrowIcon.forEach((arrowIcon) => {
      arrowIcon.remove();
    });
    const doneIcon = document.querySelectorAll('.picker_done');
    doneIcon.forEach((doneIcon) => {
      doneIcon.remove();
    });
    const sampleIcon = document.querySelectorAll('.picker_sample');
    sampleIcon.forEach((sampleIcon) => {
      sampleIcon.remove();
    });
    const opacityMeter = document.querySelectorAll('.picker_alpha');
    opacityMeter.forEach((opacityMeter) => {
      opacityMeter.remove();
    });

    this.hexLabelCheck();
    this.swapSlider();
    this.placeDropper();

    if(this.data.get('colorMode') === 'gradient') {
      document.querySelector('.gradientStop1 circle').dispatchEvent(new Event('click', { bubbles: true}));
    }

    if(this.data.get('colorMode') === 'color') {
      popupBasic.setColor(colorIcon.getAttribute('fill') || hiddenSlideBackgroundColor.value, false);
    }

    popupBasic.onChange = function (color) { // just client side
      colorIcon.setAttribute('fill', color.rgbaString);

      switch (inputSource) {
        case 'border':
          colorDataValue = $('[data-target-input-id="item_border_color"]');
          hiddenBorderColor.value = color.rgbaString;
          colorDataValue.dataset.value = color.rgbaString;

          break;
        case 'grid_text_color':
          colorDataValue = $('[data-target-input-id="grid_text_color"]');
          hiddenGridTextColor.value = color.rgbaString;
          colorDataValue.dataset.value = color.rgbaString;

          break;
        case 'text_color':
        case 'grid_border':
        case 'line_color':
        case 'shape':
        case 'vector_color':
          colorDataValue = $('[data-target-input-id="item_color"]');
          if(controller.data.get('colorMode') === 'gradient') {
            controller.data.set(`gradientStop${controller.data.get('selectedStop')}`, color.rgbaString);
          }
          hiddenItemColor.value = color.rgbaString;
          colorDataValue.dataset.value = color.rgbaString;

          break;
        case 'background_color':
        case 'grid_background_color':
          colorDataValue = $('[data-target-input-id="item_background_color"]');
          hiddenBackgroundColor.value = color.rgbaString;
          colorDataValue.dataset.value = color.rgbaString;

          break;
        case 'pencil_color':
          colorDataValue = $('[data-target-input-id="pencil_color"]');
          hiddenPencilColor.value = color.rgbaString;
          colorDataValue.dataset.value = color.rgbaString;
          break;
        case 'slide_color':
          colorDataValue = $('[data-target-input-id="slide_color"]');
          $('#slide_color').value = color.rgbaString;
          colorDataValue.dataset.value = color.rgbaString;
          break;
        default:
          break;
      }
    };

    event.preventDefault();
    const canvasController = document.querySelector('.canvas').canvas;
    canvasController.preventEmptySpaceClick = true;
  }

  handleDropper(event) {
    function $(selector) {
      return document.querySelector(selector);
    }
    if ('EyeDropper' in window) {
      const inputSource = globalMenuItem.dataset.value;

      colorDataValue = $('#colorDataValue');
      hiddenItemColor = $('#item_color');
      hiddenBorderColor = $('#item_border_color');
      hiddenBackgroundColor = $('#item_background_color');
      hiddenGridTextColor = $('#grid_text_color');
      circleColor = $('#circleColor');

      const eyeDropper = new EyeDropper();
      eyeDropper.open()
        .then((color) => {
          const changeEvent = new Event('change', { bubbles: true, cancelable: true });
          const mouseUpEvent = new Event('mouseup', { bubbles: true, cancelable: true });
          switch (inputSource) {
            case 'border':
              colorDataValue = $('[data-target-input-id="item_border_color"]');
              hiddenBorderColor.value = color.sRGBHex;
              colorDataValue.dataset.value = color.sRGBHex;
              circleColor.style.fill = color.sRGBHex;
              popupBasic.setOptions({
                color: color.sRGBHex,
              });

              hiddenBorderColor.dispatchEvent(changeEvent);
              break;
            case 'shape':
              const shapeId = hiddenItemColor.getAttribute('data-item-id');
              setShapeAttributes(shapeId, color.sRGBHex);
              circleColor.style.fill = color.sRGBHex;
              popupBasic.setOptions({
                color: color.sRGBHex,
              });

              colorDataValue.dispatchEvent(mouseUpEvent);
              break;
            case 'grid_text_color':
              colorDataValue = $('[data-target-input-id="grid_text_color"]');
              hiddenGridTextColor.value = color.sRGBHex;
              colorDataValue.dataset.value = color.sRGBHex;
              circleColor.style.fill = color.sRGBHex;
              popupBasic.setOptions({
                color: color.sRGBHex,
              });

              hiddenGridTextColor.dispatchEvent(changeEvent);
              break;
            case 'text_color':
            case 'grid_border':
            case 'line_color':
              colorDataValue = $('[data-target-input-id="item_color"]');
              hiddenItemColor.value = color.sRGBHex;
              colorDataValue.dataset.value = color.sRGBHex;
              circleColor.style.fill = color.sRGBHex;
              popupBasic.setOptions({
                color: color.sRGBHex,
              });

              hiddenItemColor.dispatchEvent(changeEvent);
              break;
            case 'background_color':
            case 'grid_background_color':
              colorDataValue = $('[data-target-input-id="item_background_color"]');
              hiddenBackgroundColor.value = color.sRGBHex;
              colorDataValue.dataset.value = color.sRGBHex;
              circleColor.style.fill = color.sRGBHex;
              popupBasic.setOptions({
                color: color.sRGBHex,
              });

              hiddenBackgroundColor.dispatchEvent(changeEvent);
              break;
            case 'pencil_color':
              colorDataValue = $('[data-target-input-id="pencil_color"]');
              hiddenPencilColor.value = color.sRGBHex;
              colorDataValue.dataset.value = color.sRGBHex;
              circleColor.style.fill = color.sRGBHex;
              popupBasic.setOptions({
                color: color.sRGBHex,
              });

              hiddenPencilColor.dispatchEvent(changeEvent);
              break;
            case 'slide_color':
              colorDataValue = $('[data-target-input-id="slide_color"]');
              hiddenSlideBackgroundColor.value = color.rgbaString;
              colorDataValue.dataset.value = color.rgbaString;
              circleColor.style.fill = color.sRGBHex;
              popupBasic.setOptions({
                color: color.sRGBHex,
              });
              hiddenSlideBackgroundColor.dispatchEvent(changeEvent);
              break;
            default:

              break;
          }
        })
        .catch((error) => {
          // handle the user choosing to exit eyedropper mode without a selection
        });
    } else {
      alert('The Eye Dropper Feature is not support in non-Chromium browsers');
    }
  }

  swapSlider() {
    const slider = document.getElementsByClassName('picker_hue')[0];
    const spectrum = document.getElementsByClassName('picker_sl')[0];
    spectrum.parentNode.insertBefore(spectrum, slider);
  }

  swapDropper() {
    const slider = document.getElementsByClassName('dropper-div')[0];
    const spectrum = document.getElementsByClassName('picker_sl')[0];
    spectrum.parentNode.insertBefore(spectrum, slider);
  }

  hexLabelCheck() {
    const hexLabelChecker = document.querySelector('.hexcode-text');
    if (hexLabelChecker == null) {
      const hexcode = document.getElementsByClassName('picker_editor')[0];

      const hexText = document.createElement('label');
      hexText.classList.add('hexcode-text');
      hexText.innerText = 'Hex';

      hexcode.getElementsByTagName('input')[0].classList.add('hexcode-input');
      hexcode.insertBefore(hexText, hexcode.firstChild);
    }
  }

  placeDropper() {
    const colorDropTemplate = document.querySelector('.colorDropperTemplate');
    const content = colorDropTemplate.content.firstElementChild.cloneNode(true);

    const slider = document.getElementsByClassName('picker_hue')[0];
    const spectrum = document.getElementsByClassName('picker_sl')[0];
    let dropperChecker = document.querySelector('.dropper-div');

    if (dropperChecker == null) {
      const dropDiv = document.createElement('div');
      dropDiv.classList.add('dropper-div');
      slider.parentNode.insertBefore(dropDiv, spectrum.nextSibling);
    }

    this.swapDropper();
    dropperChecker = document.querySelector('.dropper-div');
    dropperChecker.innerHTML = content.outerHTML;
  }

  setColorMode(event) {
    this.colorModeTarget.innerHTML = `${event.target.dataset.colorMode} <i class="fa fa-angle-down" aria-hidden="true"></i>`;
    this.colorModeMenuTarget.classList.add('hidden');
    this.data.set("colorMode", event.target.dataset.colorMode);

    const input = event.target.closest('#colorParentElement').querySelector('input');

    if(event.currentTarget.dataset.colorMode === 'gradient') {
      this.gradientSelectorTarget.classList.remove('hidden')
      document.querySelector('.gradientStop1 circle').dispatchEvent(new Event('click', { bubbles: true}));
      this.data.set('selectedStop', 1);
      const secondStopColor = document.querySelector('.gradientStop2 circle').style.fill;
      this.data.set('gradientStop2', secondStopColor);

      const gradientStop1 = this.data.get('gradientStop1');
      const gradientStop2 = this.data.get('gradientStop2');
      const gradientPercentage = document.querySelector('.sliderOffset').value;
      input.value = JSON.stringify({
        gradientStop1,
        gradientStop2,
        gradientPercentage,
      });

    } else {
      input.value = this.picker.color.rgbaString;
      this.gradientSelectorTarget.classList.add('hidden')
    }

    input.dispatchEvent(new Event('change', { bubbles: true}));
  }

  toggleColorModeMenu(event) {
    this.colorModeMenuTarget.classList.toggle('hidden');
  }

  selectGradientStop(event) {
    document.querySelector('.hoverMenu:not(.hidden)').querySelectorAll('.gradientValueCircle').forEach(circle => {
      console.log(circle);
      this.data.set(`gradientStop${circle.dataset.stopPoint}`, circle.style.fill);
      if(circle.style.stroke === 'deepskyblue') {
        circle.style.strokeWidth = '0';
      }
    })
    const circle = event.currentTarget.querySelector('circle');
    circle.style.stroke = 'deepskyblue';
    circle.style.strokeWidth = '2';
    this.data.set('selectedStop', circle.dataset.stopPoint);
    popupBasic.setColor(circle.style.fill);
  }

  setGradientPercentage(event) {
    const slideColor = document.querySelector('#slide_color');
    if (slideColor) {
      const gradient = JSON.parse(slideColor.value);
      gradient.gradientPercentage = event.target.value;
      this.stimulate('Slide#set_background_color', JSON.stringify(gradient));
      return;
    }
    const item = document.querySelector('.item-container .selected');
    const gradient = JSON.parse(item.dataset.color);
    gradient.gradientPercentage = event.target.value;
    item.dataset.color = JSON.stringify(gradient);
    save(item.dataset.itemId, this)
  }

  setGradientAngle(event) {
    console.log(event);
    const slideColor = document.querySelector('#slide_color');
    if (slideColor) {
      const gradient = JSON.parse(slideColor.value);
      gradient.gradientAngle = event.target.value;
      this.stimulate('Slide#set_background_color', JSON.stringify(gradient));
      return;
    }
    const item = document.querySelector('.item-container .selected');
    const gradient = JSON.parse(item.dataset.color);
    gradient.gradientAngle = event.target.value;
    item.dataset.color = JSON.stringify(gradient);
    save(item.dataset.itemId, this)
  }

  get picker() {
    return popupBasic;
  }
}
