const setShapeAttributes = (itemId, color) => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;
  const { shape } = target.dataset;

  switch (shape) {
    case 'circle':
    case 'rect':
      if (color === 'transparent') color = 'none';
      // target.querySelectorAll(`${shape}:not([transparent-border=true])`).forEach((element) => element.setAttribute('fill', color));
      target.setAttribute('data-color', color);
      break;
    case 'octagon':
    case 'pentagon':
    case 'star':
    case 'triangle':
      // target.querySelectorAll('polygon').forEach((element) => element.setAttribute('fill', color));
      target.setAttribute('data-color', color);
      break;
    default:
      break;
  }
};

export default setShapeAttributes;
