export const ITEM_ACTIONS = ['ADD_SLIDE_ITEM', 'UPDATE_SLIDE_ITEM', 'DELETE_SLIDE_ITEM'];
export const SLIDE_ACTIONS = ['ADD_SLIDE', 'DELETE_SLIDE', 'UPDATE_SLIDE', 'UPDATE_SLIDE_POSITIONS', 'UPDATE_SLIDE_BACKGROUND'];

export const getHistory = (params) => {
  const key = `Presentations:${params.presentationId}:Slide:${params.slideId}:User:${params.userId}:History:${params.key}`;
  return JSON.parse(localStorage.getItem(key) || '[]');
};

export const setButtonStates = (params) => {
  const pastHistoriesForItems = getHistory({ ...params, key: 'Past' });
  const pastHistoriesForSlides = getHistory({ ...params, key: 'Past', slideId: '' });

  if (pastHistoriesForItems.length > 0 || pastHistoriesForSlides.length > 0) {
    document.querySelector('#leftBarUndoButton').classList.remove('disabled');
  } else {
    document.querySelector('#leftBarUndoButton').classList.add('disabled');
  }

  const futureHistoriesForItems = getHistory({ ...params, key: 'Future' });
  const futureHistoriesForSlides = getHistory({ ...params, key: 'Future', slideId: '' });

  if (futureHistoriesForItems.length > 0 || futureHistoriesForSlides.length > 0) {
    document.querySelector('#leftBarRedoButton').classList.remove('disabled');
  } else {
    document.querySelector('#leftBarRedoButton').classList.add('disabled');
  }
};

export const addPast = (params) => {
  checkQuota();
  const pastHistory = getHistory({ ...params, key: 'Past' });
  const historyItem = {
    actionType: params.actionType,
    contentId: params.contentId,
    content: params.content,
    contentType: params.contentType,
    selectionId: params.selectionId,
    time: new Date().getTime(),
    context: params.context,
  };
  pastHistory.push(historyItem);
  localStorage.setItem(`Presentations:${params.presentationId}:Slide:${params.slideId}:User:${params.userId}:History:Past`, JSON.stringify(pastHistory));
  setButtonStates(params);
};

export const addFuture = (params) => {
  checkQuota();
  const futureHistory = getHistory({ ...params, key: 'Future' });
  const historyItem = {
    actionType: params.actionType,
    contentId: params.contentId,
    content: params.content,
    contentType: params.contentType,
    selectionId: params.selectionId,
    time: new Date().getTime(),
  };
  futureHistory.unshift(historyItem);
  localStorage.setItem(`Presentations:${params.presentationId}:Slide:${params.slideId}:User:${params.userId}:History:Future`, JSON.stringify(futureHistory));
  setButtonStates(params);
};

export const clearFuture = (params) => {
  const key = `Presentations:${params.presentationId}:Slide:${params.slideId}:User:${params.userId}:History:Future`;
  localStorage.setItem(key, []);
};

export const performHistoryAction = (params) => {
  addPast(params);
  clearFuture(params);
  setButtonStates(params);
};

export const storeItemSnapshotForUndo = (contentId, actionType, selectionId, context = {}) => {
  const target = document.querySelector(`#draggable-item-container-${contentId}`);

  performHistoryAction({
    presentationId: document.querySelector('.canvas').dataset.presentationId,
    slideId: document.querySelector('.canvasContainer .slide-container').dataset.slideId,
    userId: document.querySelector('.canvas').dataset.userId,
    actionType,
    content: target && target.parentElement.innerHTML,
    contentId,
    contentType: 'Item',
    selectionId,
    context
  });
};

export const storeSlideSnapshotForUndo = (contentId, actionType, selectionId, content) => {
  performHistoryAction({
    presentationId: document.querySelector('.canvas').dataset.presentationId,
    slideId: '',
    userId: document.querySelector('.canvas').dataset.userId,
    actionType,
    content,
    contentId,
    contentType: 'Slide',
    selectionId,
  });
};

export const checkQuota = () => {
  const currentSize = calculateQuota();
  if (currentSize > 5000) cleanLocalStorage(); // Over 5MB
};

const calculateQuota = () => {
  var _lsTotal = 0, _xLen, _x;
  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) {
        continue;
    }
    _xLen = ((localStorage[_x].length + _x.length) * 2);
    _lsTotal += _xLen;
  };
  return (_lsTotal / 1024).toFixed(2);
};

const cleanLocalStorage = () => {
  // TODO: Build up a more advanced cleanup
  window.localStorage.clear();
  console.log('Cleared local storage due to remaining space.');
};
