import { storeItemSnapshotForUndo } from '../history/manager';

const initializeCrop = (item) => {
  const imageContainer = item.querySelector('.moveableContainer');
  imageContainer.style.backgroundSize = '100% 100%';
  imageContainer.style.backgroundPosition = '';
  imageContainer.style.repeat = 'no-repeat';
  const cropBackground = item.cloneNode(true);

  cropBackground.id = 'croppable-item-container';

  cropBackground.style.opacity = '0.30';
  item.closest('.slide-container').appendChild(cropBackground);
  window.moveableForCrop.target = item;
  window.moveableForItems.target = null;
  window.moveableForGroups.target = null;
  window.moveableForText.target = null;
};

const continueCrop = (item) => {
  // Reset item to before crop values
  item.dataset.translateX = item.dataset.translateXBeforeCrop;
  item.dataset.translateY = item.dataset.translateYBeforeCrop;
  item.dataset.width = item.dataset.widthWas;
  item.dataset.height = item.dataset.heightWas;
  item.style.width = `${item.dataset.width}px`;
  item.style.height = `${item.dataset.height}px`;

  const imageContainer = item.querySelector('.moveableContainer');
  imageContainer.style.backgroundSize = '100% 100%';
  imageContainer.style.backgroundPosition = '';
  imageContainer.style.repeat = 'no-repeat';
  imageContainer.style.width = `${item.dataset.width}px`;
  imageContainer.style.height = `${item.dataset.height}px`;
  imageContainer.style.backgroundImage = `url("${imageContainer.dataset.uncroppedImage}")`;

  const frame = window.mapItems.get(item);

  frame.set('width', parseFloat(item.dataset.width));
  frame.set('height', parseFloat(item.dataset.height));
  frame.set('transform', 'translateX', `${parseFloat(item.dataset.translateX)}px`);
  frame.set('transform', 'translateY', `${parseFloat(item.dataset.translateY)}px`);
  frame.set('transform', 'rotate', `${parseInt(item.dataset.rotate, 0)}deg`);

  window.mapItems.set(item, frame);
  item.style.cssText += frame.toCSS();

  const cropBackground = item.cloneNode(true);
  cropBackground.id = 'croppable-item-container';

  cropBackground.style.opacity = '0.30';
  item.closest('.slide-container').appendChild(cropBackground);
  item.style.clip = item.dataset.clip;
  item.dataset.tempClip = item.dataset.clip; // tempClip will be used for cancelCrop action

  window.moveableForCrop.target = item;
  window.moveableForItems.target = null;
  window.moveableForGroups.target = null;
  window.moveableForText.target = null;
};

const fromAutoCropToCrop = (item) => {
  const assetRatio = parseFloat(item.dataset.assetWidth) / parseFloat(item.dataset.assetHeight);
  const itemRatio = parseFloat(item.dataset.width) / parseFloat(item.dataset.height);

  if (assetRatio > itemRatio) { // Horizontal auto crop
    const widthShouldBe = parseFloat(item.dataset.height) * assetRatio;
    const diff = widthShouldBe - parseFloat(item.dataset.width);
    item.dataset.translateXBeforeCrop = parseFloat(item.dataset.translateX) - (diff / 2);
    item.dataset.translateYBeforeCrop = parseFloat(item.dataset.translateY);
    item.dataset.widthWas = widthShouldBe;
    item.dataset.heightWas = parseFloat(item.dataset.height);
    item.dataset.clip = `rect(${0}px,${parseFloat(item.dataset.width) + (diff / 2)}px,${parseFloat(item.dataset.height)}px,${diff / 2}px)`;
  } else { // Vertical auto crop
    const heightShouldBe = parseFloat(item.dataset.width) / assetRatio;
    const diff = heightShouldBe - parseFloat(item.dataset.height);
    item.dataset.translateXBeforeCrop = parseFloat(item.dataset.translateX);
    item.dataset.translateYBeforeCrop = parseFloat(item.dataset.translateY) - (diff / 2)
    item.dataset.widthWas = parseFloat(item.dataset.width);
    item.dataset.heightWas = heightShouldBe;
    item.dataset.clip = `rect(${(diff / 2)}px,${parseFloat(item.dataset.width)}px,${parseFloat(item.dataset.height) + (diff / 2)}px,${0}px)`;
  }
};

const startCrop = (itemId) => {
  const item = document.querySelector(`#draggable-item-container-${itemId}`);
  if (item.dataset.groupId) return;

  window.cropEventItem = item; // Store item in crop mode
  storeItemSnapshotForUndo(itemId, 'UPDATE_SLIDE_ITEM');

  if (item.dataset.hasCrop === 'true' || item.dataset.hasAutoCrop === 'true') {
    if (item.dataset.hasAutoCrop === 'true') fromAutoCropToCrop(item);
    continueCrop(item);
  } else {
    initializeCrop(item);
  }
};

export default startCrop;
