import { Controller } from 'stimulus';
import debounce from 'lodash/debounce';
import { createRequest } from './helpers/createRequest';

export default class extends Controller {
  initialize() {
    this.updateCaptionForV2 = debounce(this.updateCaptionForV2, 500).bind(this);
  }

  slideIndex(target) {
    const value = target.closest('.data-ref').getAttribute('data-presentation-id');
    return parseInt(document.getElementById(`slideIndex${value}`).value);
  }

  keyPress(event) {
    if (event.code === 'ArrowRight' || event.code === 'ArrowLeft' || event.key === 'PageDown' || event.key === 'PageUp') {
      if (document.querySelector('.fullscreen')) {
        event.preventDefault();
        event.stopPropagation();

        this.completeSlide(event);
        const activeSlideIndex = parseInt(document.querySelector('.fullscreen input[type=hidden]').value);
        if (event.code === 'ArrowRight' || event.key === 'PageDown') {
          var newIndex = activeSlideIndex + 1;
          this.showSlide(newIndex, document.querySelector('.fullscreen .right-arrow'));
        }

        if (event.code === 'ArrowLeft' || event.key === 'PageUp') {
          var newIndex = activeSlideIndex - 1;
          this.showSlide(newIndex, document.querySelector('.fullscreen .left-arrow'));
        }
      }
    }
  }

  setSlideIndex(target, value) {
    document.getElementById(`slideIndex${parseInt(target.closest('.data-ref').getAttribute('data-presentation-id'))}`).value = value;
  }

  // Next/previous controls
  nextSlide(event) {
    event.preventDefault();
    event.stopPropagation();

    this.completeSlide(event);

    const newIndex = this.slideIndex(event.target) + 1;
    this.showSlide(newIndex, event.target);
  }

  completeSlide(event) {
    let slide;

    if (event.target.tagName == 'BODY') {
      // Safari full screen view
      slide = document.querySelector('.fullscreen');
    } else {
      slide = event.target.closest('.presentation-container');
    }

    if (slide) {
      const unloadedImages = slide.querySelectorAll('.unloaded-image');
      unloadedImages.forEach((image) => {
        const tempImage = new Image();
        tempImage.src = image.getAttribute('data-background-image');
        image.style.backgroundImage = `url("${image.getAttribute('data-background-image')}")`;
        image.classList.remove('unloaded-image');
      });
    }
  }

  goToFirstSlide(event) {
    event.preventDefault();
    event.stopPropagation();

    this.completeSlide(event);

    const newIndex = 1;
    this.showSlide(newIndex, event.target);
  }

  goToSlide(event) {
    event.preventDefault();
    event.stopPropagation();
    this.completeSlide(event);
    const realTarget = event.target.closest('.slide-thumb');
    const presentationId = realTarget.getAttribute('data-presentation-id');
    const index = realTarget.getAttribute('data-slide-index');
    this.showSlide(index, realTarget);

    if (document.getElementById(`presentation-canvas-${presentationId}`).classList.contains('opacity-10')) {
      document.getElementById(`presentation-canvas-${presentationId}`).classList.toggle('opacity-10');
    }

    if (document.getElementById(`presentation-canvas-${presentationId}`).classList.contains('opacity-0')) {
      document.getElementById(`presentation-canvas-${presentationId}`).classList.toggle('opacity-0');
    }

    document.getElementById(`slide-thumbs-${presentationId}`).classList.toggle('hidden');
  }

  linkTo(event) {
    const target = event.target.closest('.item-container');
    const link = target.dataset.linkTo;
    if (link.includes('http')) {
      window.open(link, '_blank').focus();
    } else {
      this.showSlideById(link, event.target, event);
      // console.log(link)
      // const canvasController = document.querySelector('.canvas').canvas;
      // canvasController.changeSlide(event, link)
    }
  }

  showSlideById(id, target, event) {
    this.pauseAllSoundSources();

    this.completeSlide(event);
    let i;
    const targetSlide = target.closest('.presentation-canvas').querySelector(`div[data-slide-id="${id}"]`);
    const n = parseInt(targetSlide.dataset.slideIndex);
    const slides = target.closest('.presentation-canvas').getElementsByClassName('slide');
    if (slides.length > 0) {
      document.getElementById(`previewIndexContainer`).dataset.currentIndex = n;

      for (i = 0; i < slides.length; i++) {
        slides[i].classList.add('hidden');
      }

      slides[n].classList.remove('hidden');
      if (target.closest('.presentation-canvas').querySelector('.slide-title')) {
        const captionElement = target.closest('.presentation-canvas').querySelector('.slide-title');
        const caption = slides[n].getAttribute('data-slide-title');
        if (captionElement.nodeName === 'INPUT') {
          captionElement.value = caption;
        } else {
          captionElement.innerText = caption;
        }
      }
    }
  }

  pauseAllSoundSources() {
    const videos = document.querySelectorAll('iframe, video');
    Array.prototype.forEach.call(videos, (video) => {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause();
      } else {
        const frame = video.cloneNode();
        frame.src = video.src;
        video.parentNode.replaceChild(frame, video);
      }
    });
  }

  previousSlide(event) {
    event.preventDefault();
    event.stopPropagation();

    this.completeSlide(event);

    const newIndex = this.slideIndex(event.target) - 1;
    this.showSlide(newIndex, event.target);
  }

  showSlide(n, target) {
    this.pauseAllSoundSources();
    let i;
    const slides = target.closest('.presentation-container').getElementsByClassName('slide');

    if (slides.length > 0) {
      if (n > slides.length) {
        this.setSlideIndex(target, 1);
      } else if (n < 1) {
        this.setSlideIndex(target, slides.length);
      } else {
        this.setSlideIndex(target, n);
      }

      for (i = 0; i < slides.length; i++) {
        slides[i].classList.add('hidden');
      }

      slides[this.slideIndex(target) - 1].classList.remove('hidden');
      if (target.closest('.presentation-container').querySelector('.slide-title')) {
        const captionElement = target.closest('.presentation-container').querySelector('.slide-title');
        const caption = slides[this.slideIndex(target) - 1].getAttribute('data-slide-title');
        if (captionElement.nodeName === 'INPUT') {
          captionElement.value = caption;
        } else {
          captionElement.innerText = caption;
        }
      }

      const presentationId = parseInt(target.closest('.data-ref').getAttribute('data-presentation-id'));
      Array.from(document.querySelectorAll(`#currentSlideIndex${presentationId}`)).forEach((item) => item.innerHTML = this.slideIndex(target));
    }
  }

  videoClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  updateCaptionForV2(event) {
    const currentSlide = event.target.closest('.presentation-container')
      .querySelector('.slide:not(.hidden)');
    currentSlide.dataset.slideTitle = event.target.value;

    const { slideId } = currentSlide.dataset;
    const formData = new FormData();
    formData.append('slide[title]', event.target.value);

    fetch(createRequest(`/slides/${slideId}`, 'PATCH', formData));
  }
}
