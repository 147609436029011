import itemType from '../itemType';
import updateRect from '../updateRect';
import setDataAttributes from '../setDataAttributes';
import setLineDataAttributes from '../line/setDataAttributes';
import calculateCropAttributes from './calculateCropAttributes';

const centerItem = (target, direction) => {
  const frame = window.mapItems.get(target);
  const { width, height } = target.getBoundingClientRect();
  const translateX = (window.slideWidth - (width / parseFloat(window.zoomLevel))) / 2;
  const translateY = (window.slideHeight - height / parseFloat(window.zoomLevel)) / 2;

  if (direction === 'both' || direction === 'vertical') {
    frame.set('transform', 'translateY', `${translateY}px`);
  }

  if (direction === 'both' || direction === 'horizontal') {
    frame.set('transform', 'translateX', `${translateX}px`);
  }

  target.style.cssText += frame.toCSS();

  calculateCropAttributes(target);
  setDataAttributes(target.dataset.itemId);
};

const centerLineItem = (target, direction = 'both') => {
  const actualLine = target.querySelector('.actual-line');
  let x1 = parseInt(actualLine.getAttribute('x1'), 0);
  let x2 = parseInt(actualLine.getAttribute('x2'), 0);
  let y1 = parseInt(actualLine.getAttribute('y1'), 0);
  let y2 = parseInt(actualLine.getAttribute('y2'), 0);

  const diffX = Math.abs(x1 - x2);
  const diffY = Math.abs(y1 - y2);

  if (direction === 'both' || direction === 'horizontal') {
    if (x1 < x2) {
      x1 = (window.slideWidth - diffX) / 2;
      x2 = x1 + diffX;
    } else {
      x2 = (window.slideWidth - diffX) / 2;
      x1 = x2 + diffX;
    }
  }

  if (direction === 'both' || direction === 'vertical') {
    if (y1 < y2) {
      y1 = (window.slideHeight - diffY) / 2;
      y2 = y1 + diffY;
    } else {
      y2 = (window.slideHeight - diffY) / 2;
      y1 = y2 + diffY;
    }
  }

  setLineDataAttributes(target.dataset.itemId, x1, x2, y1, y2);
};

const center = (itemId, direction = 'both') => {
  const target = document.querySelector(`#draggable-item-container-${itemId}`);
  if (!target) return;

  if (itemType(itemId) === 'line') {
    centerLineItem(target, direction);
  } else {
    centerItem(target, direction);
  }

  updateRect(itemId);
  window.hideContextMenus();
};

export default center;
