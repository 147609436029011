/*jshint esversion: 6 */
/* global SlimSelect */
import { Controller } from "stimulus";
import Rails from 'rails-ujs';

export default class extends Controller {
  // Init a timeout variable to be used below
  timeout = null;

  editorEmptySpaceClick(event) {
    if (event.target.classList.contains('item-container') || event.target.classList.contains('slide-editor')) {
      let slideId = event.target.getAttribute('data-slide-id');
      if (slideId) {
        window.unselectAllItems();
        window.hideGuides();
        window.hideContextMenus();
        this.showSlideEditForm(slideId);
        this.removeEditableItems();
      }
    }

    if (!event.target.classList.contains('editor-form-input')) {
      window.hideColorPicker();
    }
  }

  showSlideEditForm(slideId) {
    Rails.ajax({ url: `/slides/${slideId}/edit_form`, type: 'GET' });
  }

  removeEditableItems() {
    for (let editable_item of document.getElementsByClassName('edit-item-container')) {
      let itemId = editable_item.getAttribute('data-item-id');
      Rails.ajax({url: `/items/${itemId}`, type: 'GET'});
    }
  }

  keyPress(event) {
    let nav_keys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
    if (event.target.classList.contains('input-text-inline')) return;

    let selectedItems = document.querySelectorAll('.item-container .selected');
    if (selectedItems.length > 0) {
      if (event.code == 'Delete' || event.code == 'Backspace') {
        for (const selectedItem of selectedItems) {
          var itemId = selectedItem.getAttribute('data-item-id');
          if(itemId) {
            selectedItem.remove();
          }
        }
        let itemIds = [...selectedItems].map(a => a.getAttribute('data-item-id'));
        Rails.ajax({url: `/items/${itemIds}/batch_destroy`, type: 'DELETE'});
      } else if (event.code == 'Escape') {
        window.unselectAllItems();
        window.hideGuides();
        window.hideContextMenus();
        let slideId = selectedItems[0].getAttribute('data-slide-id');
        this.showSlideEditForm(slideId);
        this.removeEditableItems();
      } else if (nav_keys.includes(event.code)) {
        // Move items by pixel
        for (const selectedItem of selectedItems) {
          if (!selectedItem.classList.contains('edit-item-container') && !selectedItem.classList.contains('item-type-line')) {
            // Disable scroll
            event.preventDefault();
            event.stopPropagation();

            let distance = ((event.shiftKey) ? 50 : 2);
            this.moveItemsByPixel(selectedItem, event.code, distance);
          }
        }
      }
    }
  }

  moveItemsByPixel(target, event_code, distance) {
    let x_diff = 0;
    let y_diff = 0;

    switch(event_code) {
      case 'ArrowLeft':
        x_diff = -distance;
        break;
      case 'ArrowRight':
        x_diff = distance;
        break;
      case 'ArrowUp':
        y_diff = -distance;
        break;
      case 'ArrowDown':
        y_diff = distance;
        break;
    }

    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + x_diff;
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + y_diff;

    // Check canvas and move if there is space
    if (x < 0) x = 0;
    if (y < 0) y = 0;

    var right = x + parseInt(target.style.width);
    var bottom = y + parseInt(target.clientHeight);
    if (right > 1200) { x = 1200 - parseInt(target.style.width); }
    if (bottom > 675) { y = 675 - parseInt(target.clientHeight); }

    // translate the element
    target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

    // update the position attributes
    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);

    var final_top = parseInt(y);
    var final_bottom = final_top + parseInt(target.clientHeight);
    var final_left = parseInt(x);
    var final_right = final_left + parseInt(target.style.width);

    if (final_top % 25 == 0) {
      document.getElementById('horizontal-guide-top').classList.remove('hidden');
      document.getElementById('horizontal-guide-top').style.top = final_top + 'px';
    } else {
      document.getElementById('horizontal-guide-top').classList.add('hidden');
    }

    if (final_bottom % 25 == 0) {
      document.getElementById('horizontal-guide-bottom').classList.remove('hidden');
      document.getElementById('horizontal-guide-bottom').style.top = (final_bottom - 2) + 'px';
    } else {
      document.getElementById('horizontal-guide-bottom').classList.add('hidden');
    }

    if (final_left % 25 == 0) {
      document.getElementById('vertical-guide-left').classList.remove('hidden');
      document.getElementById('vertical-guide-left').style.left = final_left + 'px';
    } else {
      document.getElementById('vertical-guide-left').classList.add('hidden');
    }

    if (final_right % 25 == 0) {
      document.getElementById('vertical-guide-right').classList.remove('hidden');
      document.getElementById('vertical-guide-right').style.left = (final_right - 2) + 'px';
    } else {
      document.getElementById('vertical-guide-right').classList.add('hidden');
    }

    // TODO: Do not call on every key press
    let formData = new FormData();
    formData.append("item[left]", x);
    formData.append("item[top]", y);
    formData.append("item[update_ui]", false);
    var itemId = target.getAttribute('data-item-id');

    Rails.ajax({
      url: `/items/${itemId}`,
      type: "PATCH",
      data: formData
    });
  }

  disableEnter(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  }

  autoSubmitForm(event) {
    event.preventDefault();
    event.stopPropagation();

    var button = event.target.closest('.edit_item').querySelector('.auto-submit');
    button.click();
  }

  autoSubmitImageForm(event) {
    event.preventDefault();
    event.stopPropagation();

    // Check if changed
    let updatedValue = event.target.value;
    let originalValue = event.target.getAttribute('data-original-value');
    if (updatedValue === originalValue) return;

    let value = event.target.value;
    if (value === '' || isNaN(value)) return false;

    if (event.target.getAttribute('id') == 'item_width') {
      let width = parseFloat(event.target.value);
      let ratio = parseFloat(event.target.getAttribute('data-ratio'));
      event.target.closest('.edit_item').querySelector('#item_height').value = parseInt(width / ratio);
    }

    if (event.target.getAttribute('id') == 'item_height') {
      let height = parseFloat(event.target.value);
      let ratio = event.target.getAttribute('data-ratio');
      event.target.closest('.edit_item').querySelector('#item_width').value = parseInt(height * ratio);
    }

    if (event.target.getAttribute('id') == 'item_crop_w') {
      let crop_w = parseFloat(event.target.value);

      let ratio = parseFloat(event.target.getAttribute('data-ratio'));
      let width = parseFloat(event.target.closest('.edit_item').querySelector('#item_width').value);
      let height = parseFloat(event.target.closest('.edit_item').querySelector('#item_height').value);
      let cropTop = parseFloat(event.target.closest('.edit_item').querySelector('#item_crop_top').value);
      let cropLeft = parseFloat(event.target.closest('.edit_item').querySelector('#item_crop_left').value);

      let horizontalCropRatio = parseFloat(event.target.getAttribute('data-horizontal-crop-ratio'));
      let verticalCropRatio = parseFloat(event.target.getAttribute('data-vertical-crop-ratio'));

      let crop_h = crop_w / ratio;

      event.target.closest('.edit_item').querySelector('#item_width').value = (crop_w * horizontalCropRatio);
      event.target.closest('.edit_item').querySelector('#item_height').value = (crop_h * verticalCropRatio);

      let newWidth = parseFloat(event.target.closest('.edit_item').querySelector('#item_width').value);
      let newHeight = parseFloat(event.target.closest('.edit_item').querySelector('#item_height').value);

      event.target.closest('.edit_item').querySelector('#item_crop_top').value = (cropTop * newHeight / height);
      event.target.closest('.edit_item').querySelector('#item_crop_left').value = (cropLeft * newWidth / width);

      event.target.closest('.edit_item').querySelector('#item_crop_h').value = parseInt(crop_h);
    }

    if (event.target.getAttribute('id') == 'item_crop_h') {
      let crop_h = parseFloat(event.target.value);

      let ratio = parseFloat(event.target.getAttribute('data-ratio'));
      let width = parseFloat(event.target.closest('.edit_item').querySelector('#item_width').value);
      let height = parseFloat(event.target.closest('.edit_item').querySelector('#item_height').value);
      let cropTop = parseFloat(event.target.closest('.edit_item').querySelector('#item_crop_top').value);
      let cropLeft = parseFloat(event.target.closest('.edit_item').querySelector('#item_crop_left').value);

      let horizontalCropRatio = parseFloat(event.target.getAttribute('data-horizontal-crop-ratio'));
      let verticalCropRatio = parseFloat(event.target.getAttribute('data-vertical-crop-ratio'));

      let crop_w = crop_h * ratio;

      event.target.closest('.edit_item').querySelector('#item_width').value = (crop_w * horizontalCropRatio);
      event.target.closest('.edit_item').querySelector('#item_height').value = (crop_h * verticalCropRatio);

      let newWidth = parseFloat(event.target.closest('.edit_item').querySelector('#item_width').value);
      let newHeight = parseFloat(event.target.closest('.edit_item').querySelector('#item_height').value);

      event.target.closest('.edit_item').querySelector('#item_crop_top').value = (cropTop * newHeight / height);
      event.target.closest('.edit_item').querySelector('#item_crop_left').value = (cropLeft * newWidth / width);

      event.target.closest('.edit_item').querySelector('#item_crop_w').value = parseInt(crop_w);
    }

    clearTimeout(this.timeout);

    // Make a new timeout set to go off in 1000ms (1 second)
    this.timeout = setTimeout(function () {
      var button = event.target.closest('.edit_item').querySelector('.auto-submit');

      // Update original values
      let inputElements = event.target.closest('.edit_item').querySelectorAll('.input-text-inline');
      inputElements.forEach((element) => {
        element.setAttribute('data-original-value', element.value);
      });

      button.click();
    }, 500);
  }

  optionButtonChange(event) {
    console.log(event);

    event.target.classList.toggle('selected');
    const input = event.target.parentNode.querySelector('input[type=checkbox]');

    if (!input.checked) {
      input.checked = true;
    } else {
      input.checked = false;
    }

    this.autoSubmitForm(event);
  }

  updateColorButton(event) {
    if (event.target.value === 'transparent') {
      event.target.parentNode.querySelector('div').style.color = '#000000';
    } else {
      event.target.parentNode.querySelector('div').style.color = event.target.value;
    }

    this.autoSubmitForm(event);
  }

  updateColorPreview(event) {
    if (event.target.value === 'transparent') {
      event.target.closest('.color-input-container').querySelector('div.color-preview').style.backgroundColor = '#ffffff';
    } else {
      event.target.closest('.color-input-container').querySelector('div.color-preview').style.backgroundColor = event.target.value;
    }

    this.autoSubmitForm(event);
  }

  updateBackgroundColorButton(event) {
    event.target.closest('.color-input-container').querySelector('div svg').style.color = '#000000';

    if (event.target.value === 'transparent') {
      event.target.closest('.color-input-container').querySelector('div').style.backgroundColor = '#ffffff';
    } else if (event.target.value === '#000000') {
      event.target.closest('.color-input-container').querySelector('div').style.backgroundColor = event.target.value;
      event.target.closest('.color-input-container').querySelector('div svg').style.color = '#ffffff';
    } else {
      event.target.closest('.color-input-container').querySelector('div').style.backgroundColor = event.target.value;
    }

    this.autoSubmitForm(event);
  }
}
