import lodash from 'lodash';
import setDataAttributes from '../setDataAttributes';
import setLineAttributes from '../line/setDataAttributes';
import calculateCropAttributes from './calculateCropAttributes';

const items = (itemIds) => {
  return itemIds.map((itemId) => {
    return (document.querySelector(`#draggable-item-container-${itemId}`));
  });
};

const belongsToGroup = (item) => {
  return item.dataset.groupId !== undefined && item.dataset.groupId !== '';
};

const groupIds = (items) => {
  return lodash.uniq(items.map((item) => {
    if (belongsToGroup(item)) {
      return item.dataset.groupId;
    }
  })).filter((groupId) => {
    return groupId !== undefined;
  });
};

const lefts = (itemIds) => {
  return items(itemIds).map((item) => {
    if (item.dataset.itemType === 'line') {
      const line = item.querySelector('line');
      return line.getBBox().x;
    }
    return (parseFloat(item.dataset.translateX));
  });
};

const rights = (itemIds) => {
  return items(itemIds).map((item) => {
    if (item.dataset.itemType === 'line') {
      const line = item.querySelector('line');
      const dims = line.getBBox();
      return dims.x + dims.width;
    }
    return (parseFloat(item.dataset.translateX) + parseFloat(item.dataset.width));
  });
};

const tops = (itemIds) => {
  return items(itemIds).map((item) => {
    if (item.dataset.itemType === 'line') {
      const line = item.querySelector('line');
      const dims = line.getBBox();
      return dims.y;
    }
    return (parseFloat(item.dataset.translateY));
  });
};

const bottoms = (itemIds) => {
  return items(itemIds).map((item) => {
    if (item.dataset.itemType === 'line') {
      const line = item.querySelector('line');
      const dims = line.getBBox();
      return dims.y + dims.height;
    }
    return (parseFloat(item.dataset.translateY) + parseFloat(getComputedStyle(item).height));
  });
};

const setLeft = (target, left) => {
  if (target.dataset.itemType === 'line') {
    const line = target.querySelector('line');
    const x1 = line.x1.baseVal.value;
    const x2 = line.x2.baseVal.value;
    const y1 = line.y1.baseVal.value;
    const y2 = line.y2.baseVal.value;

    const reference = x1 > x2 ? x2 : x1;
    const diff = left - reference;

    const newX1 = x1 + diff;
    const newX2 = x2 + diff;

    setLineAttributes(target.dataset.itemId, newX1, newX2, y1, y2);
    return;
  }

  const frame = window.mapItems.get(target);

  frame.set('transform', 'translateX', `${parseInt(left, 0)}px`);

  target.style.cssText += frame.toCSS();
  calculateCropAttributes(target);
  setDataAttributes(target.dataset.itemId);
};

const horizontalCenters = (itemIds) => {
  return items(itemIds).map((item) => {
    return(parseFloat(item.dataset.translateX) + (parseFloat(item.dataset.width) / 2));
  });
};

const verticalCenters = (itemIds) => {
  return items(itemIds).map((item) => {
    return(parseFloat(item.dataset.translateY) + (parseFloat(getComputedStyle(item).height) / 2));
  });
};

const setRight = (target, right) => {
  if (target.dataset.itemType === 'line') {
    const line = target.querySelector('line');
    const x1 = line.x1.baseVal.value;
    const x2 = line.x2.baseVal.value;
    const y1 = line.y1.baseVal.value;
    const y2 = line.y2.baseVal.value;

    const reference = x1 > x2 ? x1 : x2;
    const diff = right - reference;

    const newX1 = x1 + diff;
    const newX2 = x2 + diff;

    setLineAttributes(target.dataset.itemId, newX1, newX2, y1, y2);
    return;
  }
  const frame = window.mapItems.get(target);

  frame.set('transform', 'translateX', `${parseFloat(right, 0) - parseFloat(target.dataset.width)}px`);

  target.style.cssText += frame.toCSS();
  calculateCropAttributes(target);
  setDataAttributes(target.dataset.itemId);
};

const setTop = (target, top) => {
  if (target.dataset.itemType === 'line') {
    const line = target.querySelector('line');
    const x1 = line.x1.baseVal.value;
    const x2 = line.x2.baseVal.value;
    const y1 = line.y1.baseVal.value;
    const y2 = line.y2.baseVal.value;

    const reference = y1 > y2 ? y2 : y1;
    const diff = top - reference;

    const newY1 = y1 + diff;
    const newY2 = y2 + diff;

    setLineAttributes(target.dataset.itemId, x1, x2, newY1, newY2);
    return;
  }

  const frame = window.mapItems.get(target);

  frame.set('transform', 'translateY', `${parseInt(top, 0)}px`);

  target.style.cssText += frame.toCSS();
  calculateCropAttributes(target);
  setDataAttributes(target.dataset.itemId);
};

const setBottom = (target, bottom) => {
  if (target.dataset.itemType === 'line') {
    const line = target.querySelector('line');
    const x1 = line.x1.baseVal.value;
    const x2 = line.x2.baseVal.value;
    const y1 = line.y1.baseVal.value;
    const y2 = line.y2.baseVal.value;

    const reference = y1 > y2 ? y1 : y2;
    const diff = bottom - reference;

    const newY1 = y1 + diff;
    const newY2 = y2 + diff;

    setLineAttributes(target.dataset.itemId, x1, x2, newY1, newY2);
    return;
  }

  const frame = window.mapItems.get(target);

  frame.set('transform', 'translateY', `${parseFloat(bottom, 0) - parseFloat(getComputedStyle(target).height)}px`);

  target.style.cssText += frame.toCSS();
  calculateCropAttributes(target);
  setDataAttributes(target.dataset.itemId);
};

const alignLeft = (itemIds) => {
  let allGroupMembers = true;
  const left = lodash.min(lefts(itemIds));

  items(itemIds).map((item) => {
    if (!belongsToGroup(item)) {
      setLeft(item, left);
      allGroupMembers = false;
    }
  });

  groupIds(items(itemIds)).map((groupId) => {
    const groupItems = document.querySelectorAll(`.draggable-item-container[data-group-id="${groupId}"]`);
    const groupLeft = lodash.min(lefts(Array.from(groupItems).map((item) => {
      return item.dataset.itemId;
    })));
    const diff = allGroupMembers ? groupLeft : groupLeft - left;

    groupItems.forEach((groupItem) => {
      setLeft(groupItem, parseFloat(groupItem.dataset.translateX) - diff);
    });
  });
};

const alignRight = (itemIds) => {
  let allGroupMembers = true;
  const right = lodash.max(rights(itemIds));

  items(itemIds).map((item) => {
    if (!belongsToGroup(item)) {
      setRight(item, right);
      allGroupMembers = false;
    }
  });

  groupIds(items(itemIds)).map((groupId) => {
    const groupItems = document.querySelectorAll(`.draggable-item-container[data-group-id="${groupId}"]`);
    const groupRight = lodash.max(rights(Array.from(groupItems).map((item) => {
      return item.dataset.itemId;
    })));
    const diff = allGroupMembers ? groupRight - window.slideWidth : groupRight - right;

    groupItems.forEach((groupItem) => {
      setRight(groupItem, parseFloat(groupItem.dataset.translateX) + parseFloat(groupItem.dataset.width) - diff);
    });
  });
};

const alignTop = (itemIds) => {
  const top = lodash.min(tops(itemIds));
  let allGroupMembers = true;

  items(itemIds).map((item) => {
    if (!belongsToGroup(item)) {
      setTop(item, top);
      allGroupMembers = false;
    }
  });

  groupIds(items(itemIds)).map((groupId) => {
    const groupItems = document.querySelectorAll(`.draggable-item-container[data-group-id="${groupId}"]`);
    const groupTop = lodash.min(tops(Array.from(groupItems).map((item) => {
      return item.dataset.itemId;
    })));
    const diff = allGroupMembers ? groupTop : groupTop - top;

    groupItems.forEach((groupItem) => {
      setTop(groupItem, parseFloat(groupItem.dataset.translateY) - diff);
    });
  });
};

const alignBottom = (itemIds) => {
  const bottom = lodash.max(bottoms(itemIds));
  let allGroupMembers = true;

  items(itemIds).map((item) => {
    if (!belongsToGroup(item)) {
      setBottom(item, bottom);
      allGroupMembers = false;
    }
  });

  groupIds(items(itemIds)).map((groupId) => {
    const groupItems = document.querySelectorAll(`.draggable-item-container[data-group-id="${groupId}"]`);
    const groupBottom = lodash.max(bottoms(Array.from(groupItems).map((item) => {
      return item.dataset.itemId;
    })));
    const diff = allGroupMembers ? groupBottom - window.slideHeight : groupBottom - bottom;

    groupItems.forEach((groupItem) => {
      setBottom(groupItem, parseFloat(groupItem.dataset.translateY) + parseFloat(groupItem.dataset.height) - diff);
    });
  });
};

const alignHorizontal = (itemIds) => {
  const center = lodash.sum(horizontalCenters(itemIds)) / itemIds.length;
  let allGroupMembers = true;

  items(itemIds).map((item) => {
    if (!belongsToGroup(item)) {
      setLeft(item, center - (parseFloat(item.dataset.width) / 2));
      allGroupMembers = false;
    }
  });

  groupIds(items(itemIds)).map((groupId) => {
    const groupItems = document.querySelectorAll(`.draggable-item-container[data-group-id="${groupId}"]`);
    const groupCenter = lodash.sum(horizontalCenters(Array.from(groupItems).map((item) => {
      return item.dataset.itemId;
    }))) / groupItems.length;
    const diff = allGroupMembers ? groupCenter - window.slideWidth / 2 : groupCenter - center;

    groupItems.forEach((groupItem) => {
      setLeft(groupItem, parseFloat(groupItem.dataset.translateX) - diff);
    });
  });
};

const alignVertical = (itemIds) => {
  const center = lodash.sum(verticalCenters(itemIds)) / itemIds.length;
  let allGroupMembers = true;

  items(itemIds).map((item) => {
    if (!belongsToGroup(item)) {
      setTop(item, center - (parseFloat(getComputedStyle(item).height) / 2));
      allGroupMembers = false;
    }
  });

  groupIds(items(itemIds)).map((groupId) => {
    const groupItems = document.querySelectorAll(`.draggable-item-container[data-group-id="${groupId}"]`);
    const groupCenter = lodash.sum(verticalCenters(Array.from(groupItems).map((item) => {
      return item.dataset.itemId;
    }))) / groupItems.length;
    const diff = allGroupMembers ? groupCenter - window.slideHeight / 2 : groupCenter - center;

    groupItems.forEach((groupItem) => {
      setTop(groupItem, parseFloat(groupItem.dataset.translateY) - diff);
    });
  });
};

const distributeVertical = (itemIds) => {
  itemIds = _.uniq(itemIds);
  if (groupIds(items(itemIds)).length > 0) return;

  items(itemIds).forEach((item) => {
    if(item.dataset.itemType === 'line') {
      const line = item.querySelector('line');
      const dimensions = line.getBBox();
      item.dataset.translateY = dimensions.y.toString();
      item.dataset.height = dimensions.height.toString();
    }
  });

  const sortedItems = _.sortBy(items(itemIds), (item) => {
    return(parseFloat(item.dataset.translateY) + (parseFloat(item.dataset.height) / 2));
  });
  const middleItems = sortedItems.slice(1, -1);
  const bottomOfFirstItem = parseFloat(sortedItems[0].dataset.translateY) + parseFloat(sortedItems[0].dataset.height);
  const topOfLastItem = parseFloat(sortedItems[sortedItems.length - 1].dataset.translateY);
  const diff = topOfLastItem - bottomOfFirstItem;
  const totalItemHeight = lodash.sum(middleItems.map((item) => {return(parseFloat(item.dataset.height))}));
  const interval = (diff - totalItemHeight) / (middleItems.length + 1)
  let topLine = parseFloat(sortedItems[0].dataset.translateY) + parseFloat(sortedItems[0].dataset.height) + interval;
  middleItems.map((item) => {
    setTop(item, topLine);
    topLine += parseFloat(item.dataset.height);
    topLine += interval;
  });
};

const distributeHorizontal = (itemIds) => {
  itemIds = _.uniq(itemIds);
  if (groupIds(items(itemIds)).length > 0) return;

  items(itemIds).forEach((item) => {
    if(item.dataset.itemType === 'line') {
      const line = item.querySelector('line');
      const dimensions = line.getBBox();
      item.dataset.translateX = dimensions.x.toString();
      item.dataset.width = dimensions.width.toString();
    }
  });

  const sortedItems = _.sortBy(items(itemIds), (item) => {
    return (parseFloat(item.dataset.translateX) + (parseFloat(item.dataset.width) / 2));
  });

  const middleItems = sortedItems.slice(1, -1);
  const rightOfFirstItem = parseFloat(sortedItems[0].dataset.translateX) + parseFloat(sortedItems[0].dataset.width);
  const leftOfLastItem = parseFloat(sortedItems[sortedItems.length - 1].dataset.translateX);
  const diff = leftOfLastItem - rightOfFirstItem;
  const totalItemWidth = lodash.sum(middleItems.map((item) => {return(parseFloat(item.dataset.width))}));
  const interval = (diff - totalItemWidth) / (middleItems.length + 1)
  let leftLine = parseFloat(sortedItems[0].dataset.translateX) + parseFloat(sortedItems[0].dataset.width) + interval
  middleItems.map((item) => {
    setLeft(item, leftLine);
    leftLine += parseFloat(item.dataset.width);
    leftLine += interval;
  });
};

const multiAlign = (itemIds, actionType) => {
  itemIds = Array.from(itemIds.split(','));

  switch (actionType) {
    case 'align-left':
      alignLeft(itemIds);
      break;
    case 'align-right':
      alignRight(itemIds);
      break;
    case 'align-top':
      alignTop(itemIds);
      break;
    case 'align-bottom':
      alignBottom(itemIds);
      break;
    case 'align-horizontal':
      alignHorizontal(itemIds);
      break;
    case 'align-vertical':
      alignVertical(itemIds);
      break;
    case 'distribute-vertical':
      distributeVertical(itemIds);
      break;
    case 'distribute-horizontal':
      distributeHorizontal(itemIds);
      break;
    default:
      break;
  }

  window.moveableForGroups.updateRect();
  window.hideContextMenus();
};

export {
  multiAlign, setLeft, setRight, setTop, setBottom, verticalCenters
};
