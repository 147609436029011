import ApplicationController from './application_controller.js'
import consumer from '../../channels/consumer';
import CableReady from 'cable_ready';
import io from 'socket.io-client';
import { debounce, forEach, throttle } from 'lodash';
import showNewCommentBox from '../helpers/editor/comments/showNewCommentBox';
import hideCommentBox from '../helpers/editor/comments/hideCommentBox';
import hideItemPanel from '../helpers/editor/panels/hideItemPanel';
import switchMode from '../helpers/editor/modes/switch';
import hideCommentPanel from "../helpers/editor/panels/hideCommentPanel";
import clearCloneCursor from '../helpers/editor/items/clone/clearCloneCursor';
import deleteItems from '../helpers/editor/items/actions/delete';
import { confirmDeleteSlide, deleteSlide } from '../helpers/editor/slides/deleteSlide';
import fixSlideIndexes from '../helpers/editor/slides/fixSlideIndexes';
import endCrop from '../helpers/editor/crop/endCrop';
import cancelCrop from '../helpers/editor/crop/cancelCrop';
import Quill from 'quill/core/quill';
import activateCloneCursor from '../helpers/editor/items/clone/activateCloneCursor';
import { storeItemSnapshotForUndo, storeSlideSnapshotForUndo } from '../helpers/editor/history/manager';
import uuidv4 from '../helpers/uuidv4';
import Rails from 'rails-ujs';
import { isTouchDevice } from '../helpers/utils.js';
import hideLinkPanel from "../helpers/editor/panels/hideLinkPanel";
import { createItem, setDiagramZoomLevel, setSlideDiagrams } from '../helpers/editor/items/diagram';

export default class extends ApplicationController {
  static socket;
  static presentationId;
  static slideId;
  static userId;
  static userName;

  initialize() {
    this.preventEmptySpaceClick = false;
    this.batchUpdateItems = debounce(this.batchUpdateItems, 500).bind(this);

    window.hideContextMenus();
    window.lastTap = 0
  }

  connect() {
    super.connect();

    this.presentationId = this.element.dataset.presentationId;
    this.slideId = document.querySelector('.canvasContainer .slide-container').dataset.slideId;
    this.userId = parseInt(this.element.dataset.userId);
    this.userName = this.element.dataset.userName;
    const socketServerUrl = this.element.dataset.socketServerUrl;
    this.socket = io(socketServerUrl);

    this.socket.emit('presentationLogin', {
      presentationId: this.presentationId,
      slideId: this.slideId,
      user: { id: this.userId, name: this.userName },
    });


    document.addEventListener('fixSlideIndexes', fixSlideIndexes);
    document.addEventListener('updateSelectedSlide', this.updateSelectedSlide);
    document.addEventListener('snapshotHistoryForPastedItems', this.snapshotHistoryForPastedItems);
    document.addEventListener('snapshotHistoryForMovedItems', this.snapshotHistoryForMovedItems);
    document.addEventListener('triggerSelectItems', this.triggerSelectItems.bind(this));
    const reinitializeDiagrams = (event) => {
      setTimeout(() => {
        window.Diagrams.initializeDiagrams(event.detail.touchedItems);
      }, 10);
    }
    document.addEventListener('reinitializeDiagrams', throttle(reinitializeDiagrams, 100))

    const broadcastMouse = (event) => {
      if (!event.target.closest('.slide-container')) return;

      let rect = document.querySelector('.canvasContainer').getBoundingClientRect();
      let x = (event.clientX - rect.left) / parseFloat(window.zoomLevel);
      let y = (event.clientY - rect.top) / parseFloat(window.zoomLevel);
      window.mouseX = x;
      window.mouseY = y;

      let currentUserContainer = document.querySelector('#currentUser');
      let userId = currentUserContainer.dataset.userId;
      let userName = currentUserContainer.dataset.userName;
      let userColor = currentUserContainer.dataset.userColor;
      let slideId = document.querySelector('.canvasContainer .slide-container').dataset.slideId;

      if (userColor) {
        this.socket.emit(`MouseTrack`, { userId, userName, userColor, slideId, x: window.mouseX, y: window.mouseY })
      }
    }

    document.querySelector('.canvasContainer').addEventListener('mousemove', throttle(broadcastMouse, 500));

    this.socket.on('PresentationUsers', (data) => {
      let container = document.querySelector(`#onlineUsers-${this.presentationId}`);
      if (container) {
        let content = '';
        data.users.forEach((user) => {
          // Online users for topbar
          let userContent = `
            <div
              class='onlineUser slide relative'
              style='background: ${user.color};'
              data-action='click->editor--canvas#changeSlide'
              data-slide-id='${user.slideId}'
            >
              <div class="toolbar-tooltip">${user.name}</div>
              <span
                class='onlineUserName'
                data-user-id='${user.id}'
              >
                ${user.name[0]}
              </span>
            </div>
          `;
          content += userContent;
          container.innerHTML = content;

          // Set current user's color
          if (user.id === this.userId) {
            document.querySelector('#currentUser').dataset.userColor = user.color;
          }

          // Online users of slide
          let userContainer = document.querySelector(
            `#activeUsersOfSlide-${user.slideId} #onlineUserOfSlide-${user.id}`,
          );
          if (!userContainer) {
            // Remove existing
            let userContainerInAnotherSlide = document.querySelector(
              `#onlineUserOfSlide-${user.id}`,
            );
            if (userContainerInAnotherSlide) {
              userContainerInAnotherSlide.remove();
            }

            let activeUsersOfSlideContainer = document.querySelector(
              `#activeUsersOfSlide-${user.slideId}`,
            );
            let contentContainer = document.createElement('div');
            let content = `
              <div class='onlineUser' id='onlineUserOfSlide-${user.id}' data-user-id=${user.id} style='background: ${user.color};'>
                <span class='onlineUserName' title='${user.name}'>${user.name[0]}</span>
              </div>
            `;
            contentContainer.innerHTML = content;
            if (activeUsersOfSlideContainer) {
              activeUsersOfSlideContainer.appendChild(contentContainer);
            }
          }
        });
      }
    });

    this.socket.on('RemoveCursor', function (msg) {
      let userCursor = document.querySelector(`#user-cursor-${msg.userId}`);
      if (userCursor) userCursor.remove();

      let userContainer = document.querySelector(`#onlineUserOfSlide-${msg.userId}`);
      if (userContainer) userContainer.remove();
    });

    this.socket.on('MouseMove', (msg) => {
      let slideId = parseInt(document.querySelector('.slide-container').dataset.slideId);
      let userId = parseInt(msg.userId);
      let cursorContainer = document.querySelector('#slide-cursors');
      let userCursor = document.querySelector(`#user-cursor-${userId}`);

      if (userId == this.userId) {
        if (userCursor) userCursor.remove();
        return;
      }

      if (parseInt(msg.slideId) !== slideId) {
        // User is on another slide, remove cursor.
        if (userCursor) {
          userCursor.remove();
        }
        return;
      }

      if (userCursor) {
        // Move cursor
        userCursor.style.transform = `translate(${msg.x}px, ${msg.y}px)`;
      } else {
        // Create cursor
        let userCursorContainer = document.createElement('div');
        userCursorContainer.setAttribute('id', `user-cursor-${userId}`);
        userCursorContainer.classList.add('absolute');
        userCursorContainer.style.zIndex = 9999;
        userCursorContainer.style.transform = `translate(${msg.x}px, ${msg.y}px)`;
        userCursorContainer.style.transitionDuration = '0.5s';
        let content = `
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="${msg.userColor || '#1d1d1d'}">
            <path d="M1 1L8 15L9.16891 9.17564L15 8.01346L1 1Z" stroke="${msg.userColor || '#1d1d1d'}" stroke-width="1.5" stroke-miterlimit="10" stroke-linejoin="round" />
          </svg>
          <div class='relative'>
            <span
              style='
                background-color: ${msg.userColor || '#1d1d1d'};
                color: white;
                position: absolute;
                top: -2px;
                left: 12px;
                font-size: 10px;
                padding: 2px 6px;
                border-radius: 4px;
              '
            >${msg.userName.split(' ')[0]}</span>
          </div>
        `;

        userCursorContainer.innerHTML = content;
        cursorContainer.appendChild(userCursorContainer);
      }
    });

    const trackFullScreenChange = function () {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement
      ) {
        // Skip
      } else {
        const elem = document.querySelector('.fullscreen');
        if (elem) elem.classList.remove('fullscreen');
        elem.querySelector('.presentation-canvas').style.zoom = 1;
        document.querySelector('.canvasContainer').classList.toggle('hidden');
        document.querySelector('#previewContainer').classList.toggle('hidden');

       // Remove embedded videos to stop them playing
        const iframes = document.querySelectorAll('iframe');
        iframes.forEach((iframe) => iframe.remove());
      }
    };

    document.addEventListener('fullscreenchange', () => {
      trackFullScreenChange();
    });

    /* Firefox */
    document.addEventListener('mozfullscreenchange', () => {
      trackFullScreenChange();
    });

    /* Chrome, Safari and Opera */
    document.addEventListener('webkitfullscreenchange', () => {
      trackFullScreenChange();
    });

    /* IE / Edge */
    document.addEventListener('msfullscreenchange', () => {
      trackFullScreenChange();
    });

    const afterMorphHandler = event => {
      // We are updating thumbnails
      if (event.detail.selector.toString() === "#pageThumbsContainer") {
        const slideId = document.querySelector(".canvasContainer .slide-container").dataset.slideId
        // Remove previously selected
        document.querySelectorAll("#pageThumbsContainer .slideContainer.selected").forEach((item) => {
          item.classList.remove('selected')
        })

        if (document.querySelector(`#page-thumb-container-with-index-${slideId}.slideContainer`)) {
          document
            .querySelector(`#page-thumb-container-with-index-${slideId}.slideContainer`)
            .classList.add('selected');
        } else {
          const firstSlideId = document.querySelectorAll("#pageThumbsContainer .slideContainer")[0].dataset.slideId;
          this.changeSlide(null, firstSlideId);
        }
      }
    }
    document.addEventListener('cable-ready:after-morph', afterMorphHandler)

    // Diagram Lines
    window.Diagrams = window.Diagrams || {};
    window.Diagrams.scrolling = {
      status: false,
      timeoutId: null,
    };
    window.Diagrams.currentDiagramItemSelection = [];
    window.Diagrams.selectedItemIds
    window.Diagrams.currentDiagrams = {};
    document.querySelectorAll('.slideContainer').forEach((slide) => {
      const { slideId } = slide.dataset;
      window.Diagrams.currentDiagrams[slideId] = {};
      window.Diagrams.currentDiagrams[slideId].diagrams = [];
    });
    const lines = document.querySelectorAll('.item-container-diagram_line .item-type-diagram-line');
    const loadedDiagrams = [];
    lines.forEach((line) => {
      const {
        sourceItemId,
        targetItemId,
        color,
        dash,
        path,
        thickness,
        startSocket,
        endSocket,
      } = line.dataset;
      const { leaderLine } = createItem({
        sourceItem: document.getElementById(`draggable-item-container-${sourceItemId}`),
        targetItem: document.getElementById(`draggable-item-container-${targetItemId}`),
        color,
        dash: dash === 'true',
        path,
        thickness,
        startSocket,
        endSocket,
        save: false,
      });
      loadedDiagrams.push(leaderLine);
    });
    setSlideDiagrams(loadedDiagrams);
    setTimeout(() => {
      window.Diagrams.initializeDiagrams();
      setDiagramZoomLevel();
    }, 200);
    window.Diagrams.clearCurrentDiagramSelection = () => {
      document.querySelectorAll('.leader-line').forEach((el) => el.classList.remove('active'));
      window.Diagrams.currentDiagramItemSelection = [];
      window.Diagrams.selectedItemIds = [];
    };
  }

  /* touchHandler(event) {
    return
    const contextMenuShowingTriggerInterval = 700;
    const eventBlockingTimeout = 1100;
    let touches = event.changedTouches,
      first = touches[0],
      types = [""];
    switch (event.type) {
      case "touchstart": types = ["mousedown"]; break;
      case "touchmove": types = ["mousemove"]; break;
      case "touchend": types = ["mouseup", "click"]; break;
      default: return;
    }
    const contextMenuVisible = document.querySelectorAll('.contextMenu:not(.hidden)').length > 0;
    const isPenActive = !document.querySelector('#pen').classList.contains('hidden')

    if (event.type == 'touchstart') {
      // For tracking swipe starting position
      window.touchStartPosition = [first.clientX, first.clientY]
      window.touchCurrentPosition = [first.clientX, first.clientY]
      // Set timeout to watch long press if it is not set already
      if (!window.timerId && !isPenActive) {
        if (!contextMenuVisible) {
          const tapPositionMoved = Math.abs(window.touchStartPosition[0] - window.touchCurrentPosition[0]) > 5 || Math.abs(window.touchStartPosition[1] - window.touchCurrentPosition[1]) > 5;
          window.timerId = setTimeout(function () {
            const tapPositionMoved = Math.abs(window.touchStartPosition[0] - window.touchCurrentPosition[0]) > 5 || Math.abs(window.touchStartPosition[1] - window.touchCurrentPosition[1]) > 5;
            // Don't show context menu if item is moving
            if (!tapPositionMoved) {
              console.log('start', window.touchStartPosition, 'current', window.touchCurrentPosition)
              var simulatedEvent = document.createEvent("MouseEvent");
              simulatedEvent.initMouseEvent('contextmenu', true, true, window, 1,
                first.screenX, first.screenY,
                first.clientX, first.clientY, false,
                false, false, false, 0/!*left*!/, null);
              first.target.dispatchEvent(simulatedEvent);
              window.preventContextMenuHiding = true
              setTimeout(function () {
                window.preventContextMenuHiding = false
              }, eventBlockingTimeout)
            }
          }, contextMenuShowingTriggerInterval);
          if (!tapPositionMoved) {
            event.preventDefault();
            return
          }
        }
      }
    } else if (event.type == 'touchmove') {
      window.touchCurrentPosition = [first.clientX, first.clientY]
    } else if (event.type == 'touchend') {
      clearTimeout(window.timerId);
      window.timerId = null;
      // Track if clicked the item while scrolling
      const tapPositionMoved = Math.abs(window.touchStartPosition[0] - window.touchCurrentPosition[0]) > 10 || Math.abs(window.touchStartPosition[1] - window.touchCurrentPosition[1]) > 10;
      if (tapPositionMoved && first.target.classList.contains('asset')) {
        event.preventDefault();
        return
      }
      // Track if swapped
      const end = [first.clientX, first.clientY];
      const xDelta = end[0] - window.touchStartPosition[0]
      if (this.isFullScreen()) {
        event.preventDefault();
        if (xDelta < -100) {
          this.previewNextSlide(event);
          return;
        }
        if (xDelta > 100) {
          this.previewPreviousSlide(event);
          return;
        }
      }
      // Double tap
      const currentTime = new Date().getTime();
      var tapLength = currentTime - window.lastTap;
      if (tapLength > 0 && tapLength < 500) {
        var simulatedEvent = document.createEvent("MouseEvent");
        simulatedEvent.initMouseEvent('dblclick', true, true, window, 1,
          first.screenX, first.screenY,
          first.clientX, first.clientY, false,
          false, false, false, 0/!*left*!/, null);
        first.target.dispatchEvent(simulatedEvent);
        event.preventDefault()
        return;
      }
      window.lastTap = currentTime;
    }
    // Prevent clicking or another contextmenu event by tracking window.preventContextMenuHiding
    if (contextMenuVisible && window.preventContextMenuHiding) {
      event.preventDefault();
      return;
    }


    // initMouseEvent(type, canBubble, cancelable, view, clickCount,
    //                screenX, screenY, clientX, clientY, ctrlKey,
    //                altKey, shiftKey, metaKey, button, relatedTarget);

    var simulatedEvent = document.createEvent("MouseEvent");
    forEach(types, function (type) {
      simulatedEvent.initMouseEvent(type, true, true, window, 1,
        first.screenX, first.screenY,
        first.clientX, first.clientY, false,
        false, false, false, 0/!*left*!/, null);
      first.target.dispatchEvent(simulatedEvent);
      if (first.target.tagName == 'INPUT') {
        first.target.focus();
      }
    });
    if (event.cancelable) event.preventDefault();
  } */

  skippedElements = ['INPUT', 'TEXTAREA'];
  emptySpaceClick(event) {
    endCrop(this, event);

    // Pencil mode
    if (event.target.id === 'vectors' || event.target.parentElement.id === 'vectors') {
      return;
    }

    if (event.target.closest('.commentContainer')) {
      return;
    }

    if (event.target.closest('.moveable-dropdown') || event.target.closest('.contextMenu')) {
      return;
    }

    // Clear multi selection on thumbs
    if (event.target.closest('.slide')) {
      return;
    }
    document.querySelectorAll('.slideContainer.multiSelected').forEach(slide => slide.classList.remove('multiSelected'));

    window.hideContextMenus();

    if (event.target.id == 'customZoomValue') {
      return;
    }

    window.hideContextMenus();

    if (this.preventEmptySpaceClick && !event.target.classList.contains('.infinite-viewer-scroll-area')) {
      return;
    }

    if (event.target.closest('.draggable-item-container.item-type-comment')) {
      return;
    }

    if (event.target.closest('#commentBox')) {
      return;
    }

    if (
      document.body.classList.contains('insertCommentMode') &&
      event.target.closest('.canvasContainer')
    ) {
      let mousePosition = document.querySelector('.canvasContainer').getBoundingClientRect();
      let x = event.clientX - mousePosition.left;
      let y = event.clientY - mousePosition.top;

      showNewCommentBox(x, y);
      return;
    }

    if (event.target.closest('.draggable-item-container')) {
      return;
    }

    if (window.draggingElement) {
      return;
    }

    if (this.skippedElements.includes(event.target.tagName)) {
      return;
    }

    if (event.target.closest('.panelContainer')) {
      return;
    }

    this.unSelectAllItems();
    hideCommentBox();
    hideItemPanel();
    hideLinkPanel();
    this.clearHighlightPanelTrigger();
    this.disableQuill();
    window.currentActiveGroupId = null;
    console.log('empty space clicked: removing diagram selection');
    window.Diagrams.clearCurrentDiagramSelection();
  }

  keyDown(event) {
    if (
      this.skippedElements.includes(event.target.tagName) ||
      event.target.classList.contains('ql-editor')
    ) {
      if (event.key === 'Enter' && (event.target instanceof HTMLInputElement)) {
        const { bubbles, cancelable, composed } = event
        const onEnterEvent = new CustomEvent('onEnter', {
          bubbles,
          cancelable,
          composed,
          detail: { originalEvent: event }
        })
        setTimeout(event.target.dispatchEvent(onEnterEvent))
        event.target.blur();
      }
      return;
    }

    if (event.metaKey && event.code === 'KeyA') {
      event.preventDefault();
      event.stopPropagation();

      this.selectAllItems();
    }

    if (event.code == 'Delete' || event.code == 'Backspace') {
      // Check if any item is selected. Delete slide if not
      if (this.selectedItems().length > 0) {
        this.deleteSelectedItems();
        return;
      } else {
        confirmDeleteSlide();
        return;
      }
    }

    if (document.querySelector('.item-container .selected td.selected')) {
      const container = document.querySelector('.item-container .selected .grid-v2-container');
      const gridController = container.grid;
      gridController.activateQuill(event);
      return;
    }

    if (event.code == 'Escape') {
      this.unSelectAllItems();
      window.hideContextMenus();
      hideItemPanel();
      hideCommentBox();
      switchMode(this, event, 'Pointer');
      this.clearHighlightPanelTrigger();
      cancelCrop();

      document.querySelector('#pen').classList.add('hidden');
      if (isTouchDevice) {
        document.querySelector('.canvas').moveable.activateSelecto();
      }
    }

    if (event.keyCode == 32) {
      // Space Key
      event.preventDefault();
      event.stopPropagation();

      if (!document.body.classList.contains('handMode')) {
        switchMode(this, event, 'Hand');
        this.clearHighlightPanelTrigger();
      }
    }

    const navKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
    const distanceToMove = event.shiftKey ? 50 : 2;
    if (navKeys.includes(event.code)) {
      event.preventDefault();
      event.stopPropagation();
      let direction = event.code.replace('Arrow', '');

      if (this.isFullScreen()) {
        if (direction === 'Right' || direction === 'Down') {
          this.previewNextSlide(event);
        }
        if (direction === 'Left' || direction === 'Up') {
          this.previewPreviousSlide(event);
        }
        return;
      }

      let selectedItems = document.querySelectorAll('.item-container .selected:not(.item-type-line), .item-container .selected.line-snapper');
      for (const selectedItem of selectedItems) {
        this.moveItemByArrowKeys(selectedItem, direction, distanceToMove);
      }

      if (selectedItems.length === 0) {
        // Change slide
        if (direction === 'Right' || direction === 'Down') {
          this.switchToNextSlide(event);
        }
        if (direction === 'Left' || direction === 'Up') {
          this.switchToPreviousSlide(event);
        }
        return;
      }

      let randomSelectId = this._generateRandomString();
      const batchItemData = [];

      selectedItems.forEach((target) => {
        const { translateX, translateY, rotate, width, height } = target.dataset;
        let x1; let x2; let y1; let y2 = null;
        let { itemId } = target.dataset;

        if (target.classList.contains('line-snapper')) {
          const actualLine = target.closest('.item-container').querySelector('.actual-line');
          itemId = actualLine.dataset.itemId;
          x1 = parseFloat(actualLine.getAttribute('x1'));
          y1 = parseFloat(actualLine.getAttribute('y1'));
          x2 = parseFloat(actualLine.getAttribute('x2'));
          y2 = parseFloat(actualLine.getAttribute('y2'));
        }

        batchItemData.push({ itemId, translateX, translateY, rotate, width, height, randomSelectId, x1, y1, x2, y2 })
      });
      this.batchUpdateItems(batchItemData);
    }

    if ((event.ctrlKey || event.metaKey) && event.key === 'z' && window.cropEventItem) {
      // Undo for crop action
      let croppedItem = window.cropEventItem;
      croppedItem.style.clip = croppedItem.dataset.tempClip;
      croppedItem.dataset.clip = croppedItem.dataset.tempClip;
      window.moveableForCrop.updateRect();
      event.preventDefault();
      event.stopPropagation();
    } else if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === 'z' || event.key === 'y')) {
      document.querySelector('#historyManager').history.redo();
      event.preventDefault();
      event.stopPropagation();
    } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      document.querySelector('#historyManager').history.undo();
      event.preventDefault();
      event.stopPropagation();
    }

    // Insert text
    if (event.key === 't' || event.key === 'T') {
      document.getElementById("btnInsertText").click()
      event.preventDefault();
      event.stopPropagation();
    }

    // Insert shape
    if (event.key === 's' || event.key === 'S') {
      document.getElementById("btnInsertShape").click()
      event.preventDefault();
      event.stopPropagation();
    }

    // Hide/Unhide Slide
    if (event.key === 'h' || event.ket === 'H') {
      document.getElementById("hideSlide").click()
      event.preventDefault();
      event.stopPropagation();
    }

    // Group / Ungroup
    if (event.key === 'g' || event.key === 'G') {
      this.toggleGroupForItems(event)
      event.preventDefault();
      event.stopPropagation();
    }

    // Insert line
    if (event.key === 'l' || event.key === 'L') {
      document.getElementById("btnInsertLine").click()
      event.preventDefault();
      event.stopPropagation();
    }

    // Insert Slide
    if (event.key === 'n' || event.key === 'N') {
      this.addSlide(event);
      event.preventDefault();
      event.stopPropagation();
    }

    // Ctrl+C or Cmd+C pressed?
    if ((event.ctrlKey || event.metaKey) && (event.key === 'c' || event.key === 'C')) {
      window.copiedText = null;
      const selectedItems = document.querySelectorAll('.item-container .selected');
      if (selectedItems.length > 0) {
        const ids = Array.from(this.selectedItems())
          .map((div) => div.dataset.itemId);
        const uniq_ids = [...new Set(ids)];
        this.stimulate('Item#copy', uniq_ids.join(), document.querySelector('.slide-container').dataset.slideId)

        // Clear clipboard for custom paste
        navigator.clipboard.writeText(JSON.stringify({context: 'copy', items: uniq_ids}));
      }
    }

    // Ctrl+X or Cmd+X pressed?
    if ((event.ctrlKey || event.metaKey) && event.key === 'x') {
      console.log('cut item')
      let randomSelectId = this._generateRandomString();

      const ids = Array.from(this.selectedItems())
        .map((div) => div.dataset.itemId)
        .join()

      this.stimulate('Item#cut', ids, document.querySelector('.slide-container').dataset.slideId, randomSelectId);

      window.moveableForItems.target = null;
      window.moveableForGroups.target = null;
      window.moveableForText.target = null;
    }

    // Cmd+B to toggle bold on text
    if ((event.ctrlKey || event.metaKey) && event.key === 'b') {
      console.log('make text bold')
      const dropdown = document.querySelector('select.ql-fontweight');
      if(dropdown) {
        dropdown.value === '700' ? dropdown.slim.set('400') : dropdown.slim.set('700');
      }
    }

    if (event.altKey) {
      activateCloneCursor();
    }
  }

  batchUpdateItems(batchItemData) {
    this.stimulate('Item#batch_update', batchItemData);
  }

  keyUp(event) {
    clearCloneCursor();

    if (
      this.skippedElements.includes(event.target.tagName) ||
      event.target.classList.contains('ql-editor')
    ) {
      return;
    }

    if (event.keyCode == 32) {
      // Space Key
      event.preventDefault();
      event.stopPropagation();

      switchMode(this, event, 'Pointer');
      this.clearHighlightPanelTrigger();
    }
  }

  mouse = {
    x: 0,
    y: 0,
    startX: 0,
    startY: 0,
  };

  multiSelectionContainer;
  multiSelectionTimer;
  multiSelect = false;

  mouseDown(event) {
    if (
      document.body.classList.contains('handMode') ||
      document.body.classList.contains('insertCommentMode')
    ) {
      return;
    }

    if (
      event.target.closest('.draggable-item-container') ||
      event.target.closest('.draggable-multi-item-selection-container')
    ) {
      return;
    }

    if (window.draggingElement) {
      return;
    }

    if (document.querySelector('.crop-container')) {
      return;
    }

    return;
    this.multiSelectionTimer = setTimeout(() => {
      this.multiSelect = true;
      this.mouse.startX = this.mouse.x;
      this.mouse.startY = this.mouse.y;
      const element = document.createElement('div');
      element.className = 'multi-select-container';
      element.style.zIndex = '100';
      element.style.border = '1px dashed black';
      element.style.position = 'absolute';
      element.style.left = this.mouse.x + 'px';
      element.style.top = this.mouse.y + 'px';
      this.multiSelectionContainer = element;
      document.querySelector('.canvasContainer').appendChild(element);
    }, 200);
  }

  mouseMove(event) {
    let rect = document.querySelector('.canvasContainer').getBoundingClientRect();
    this.mouse.x = event.clientX - rect.left;
    this.mouse.y = event.clientY - rect.top;

    const container = this.multiSelectionContainer;

    if (container) {
      container.style.width = Math.abs(this.mouse.x - this.mouse.startX) / zoomLevel + 'px';
      container.style.height = Math.abs(this.mouse.y - this.mouse.startY) / zoomLevel + 'px';
      container.style.left =
        this.mouse.x - this.mouse.startX < 0 ? this.mouse.x / zoomLevel + 'px' : this.mouse.startX / zoomLevel + 'px';
      container.style.top =
        this.mouse.y - this.mouse.startY < 0 ? this.mouse.y / zoomLevel + 'px' : this.mouse.startY / zoomLevel + 'px';

      const items = document
        .querySelector('.canvasContainer')
        .querySelectorAll('.draggable-item-container');

      for (const item of items) {
        if (this._isInBounds(container, item)) {
          item.classList.add('selected');
        } else {
          item.classList.remove('selected');
        }
      }
    }
  }

  _isInBounds(obj1, obj2) {
    if (obj2.dataset.itemType == 'line') {
      return this._isLineIntersect(obj1, obj2.querySelector('line'));
    } else {
      const a = obj1.getBoundingClientRect();
      const b = obj2.getBoundingClientRect();

      return (
        a.x < b.x + b.width && a.x + a.width > b.x && a.y < b.y + b.height && a.y + a.height > b.y
      );
    }
  }

  _isLineIntersect(container, line) {
    const x1 = line.x1.baseVal.value;
    const x2 = line.x2.baseVal.value;
    const y1 = line.y1.baseVal.value;
    const y2 = line.y2.baseVal.value;

    const minX = parseInt(container.style.left);
    const minY = parseInt(container.style.top);
    const maxX = minX + parseInt(container.style.width);
    const maxY = minY + parseInt(container.style.height);

    return this._aabbContainsSegment(x1, y1, x2, y2, minX, minY, maxX, maxY);
  }

  _aabbContainsSegment(x1, y1, x2, y2, minX, minY, maxX, maxY) {
    // Completely outside.
    if (
      (x1 <= minX && x2 <= minX) ||
      (y1 <= minY && y2 <= minY) ||
      (x1 >= maxX && x2 >= maxX) ||
      (y1 >= maxY && y2 >= maxY)
    )
      return false;

    var m = (y2 - y1) / (x2 - x1);

    var y = m * (minX - x1) + y1;
    if (y > minY && y < maxY) return true;

    y = m * (maxX - x1) + y1;
    if (y > minY && y < maxY) return true;

    var x = (minY - y1) / m + x1;
    if (x > minX && x < maxX) return true;

    x = (maxY - y1) / m + x1;
    if (x > minX && x < maxX) return true;

    return false;
  }

  mouseUp(event) {
    clearTimeout(this.multiSelectionTimer);
    if (this.multiSelect) {
      this.multiSelect = false;
      this.preventEmptySpaceClick = true;

      const container = document.querySelector('.multi-select-container');
      if (container) {
        container.remove();
        this.multiSelectionContainer = null;
        document.body.classList.remove('MultiSelect');

        const selectedItems = this.selectedItems();
        if (selectedItems.length > 1) {
          selectedItems.forEach((el) => el.classList.add('multi-select'));
          this.stimulate(
            'Item#select',
            Array.from(this.selectedItems())
              .map((div) => div.dataset.itemId)
              .join(),
          );
        }
      }
      setTimeout(() => this.preventEmptySpaceClick = false, 200);
    }
  }

  selectAllItems() {
    let selectableItems = document.querySelectorAll('.item-container .draggable-item-container[data-is-deleted="false"]:not(.line-container)');
    for (const item of selectableItems) {
      item.classList.add('selected');
    }

    let selectableLines = document.querySelectorAll('.item-container .line-snapper-container[data-is-deleted="false"] .line-snapper');
    for (const item of selectableLines) {
      item.classList.add('selected');
    }

    this.selectMultiItems();
  }

  selectMultiItems() {
    const selectedItems = Array.from(document.querySelectorAll('.item-container .selected:not(.item-type-line), .item-container .draggable-item-container.selected .line-snapper'));

    if (selectedItems.length === 1) {
      window.moveableForGroups.target = null;
      const target = selectedItems[0];

      if (target.dataset.itemType === 'text') {
        window.moveableForText.target = target;
      } else if (target.classList.contains('line-snapper')) {
        const selectedLine = target.closest('.item-container').querySelector('.item-type-line');
        selectedLine.classList.add('selected');
        selectedLine.closest('.item-container').querySelector('.handle-left').classList.remove('hidden');
        selectedLine.closest('.item-container').querySelector('.handle-right').classList.remove('hidden');
      } else {
        window.moveableForItems.target = target;
      }
    } else {
      window.moveableForGroups.target = selectedItems;
      window.moveableForItems.target = null;
      window.moveableForText.target = null;
      window.moveableForLineHandle.target = null;
      window.moveableForLineBody.target = null;
      window.moveableForGroups.elementGuidelines = [...document.querySelectorAll('.draggable-item-container:not(.line-container)'), document.querySelector('.canvasContainer')];

      if (document.querySelector('.moveable-area')) {
        const target = document.querySelector('.moveable-area');
        target.dataset.controller = 'editor--context-menu';
        target.dataset.action = 'contextmenu->editor--context-menu#displayContextMenu';
      }
    }
  }

  unSelectAllItems() {
    clearCloneCursor();

    let selectedItems = document.querySelectorAll('.item-container .selected');
    for (const selectedItem of selectedItems) {
      selectedItem.classList.remove('selected');
      if (selectedItem.closest('.item-container').querySelector('.handle')) {
        selectedItem.closest('.item-container').querySelector('.handle-left').classList.add('hidden');
        selectedItem.closest('.item-container').querySelector('.handle-right').classList.add('hidden');
      }
    }

    window.moveableForItems.target = null;
    window.moveableForGroups.target = null;
    window.moveableForText.target = null;
    window.moveableForLineHandle.target = null;
    window.moveableForLineBody.target = null;
  }

  deleteSelectedItems() {
    let selectedItems = document.querySelectorAll('.draggable-item-container.selected');
    // If this is a grid and there is an active cell selection, clear the cell instead of deleting the item.
    if (selectedItems.length === 1 && selectedItems[0].dataset.itemType === 'grid') {
      const currentItem = selectedItems[0];
      const selectedCells = currentItem.querySelectorAll('td.selected')
      if (selectedCells.length > 0) {
        selectedCells.forEach(cell => {
          cell.querySelector('.table-cell-content').innerHTML = ''
        })
        const controller = currentItem.querySelector('.grid-v2-container').grid;
        const table = currentItem.querySelector('table');
        controller.stimulate('Item#update_table', currentItem.dataset.itemId, controller._getTableHTML(table));
        return;
      }
    }
    let randomSelectId = this._generateRandomString();
    const deleteData = [];
    for (const selectedItem of selectedItems) {
      selectedItem.dataset.selectionId = randomSelectId;
      const { itemId } = selectedItem.dataset;
      storeItemSnapshotForUndo(itemId, 'DELETE_SLIDE_ITEM', randomSelectId);
      if (itemId) { deleteData.push({ itemId: itemId, selectionId: randomSelectId }); }
      deleteItems(itemId);
    }
    this.stimulate('Item#delete', deleteData);

    window.moveableForItems.target = null;
    window.moveableForGroups.target = null;
    window.moveableForText.target = null;
    this.stimulate('Item#update_multi_selection', '');
  }

  switchToPointerMode(event) {
    switchMode(this, event, 'Pointer');
    this.clearHighlightPanelTrigger();
  }

  switchToHandMode(event) {
    switchMode(this, event, 'Hand');
    this.clearHighlightPanelTrigger();
  }

  moveItemByArrowKeys(target, direction, distance) {
    let x_diff = 0;
    let y_diff = 0;

    switch (direction) {
      case 'Left':
        x_diff = -distance;
        break;
      case 'Right':
        x_diff = distance;
        break;
      case 'Up':
        y_diff = -distance;
        break;
      case 'Down':
        y_diff = distance;
        break;
    }

    if (target.classList.contains('line-snapper')) {
      const actualTarget = target.closest('.item-container').querySelector('.item-type-line');
      const lineSnapper = target.closest('.item-container').querySelector('.line-snapper');
      const lineHandler = target.closest('.item-container').querySelector('.line-handler');

      const lineStart = target.closest('.item-container').querySelector('.handle-left');
      const lineEnd = target.closest('.item-container').querySelector('.handle-right');
      const snapHandleLeft = target.closest('.item-container').querySelector('.snap-handle-left');
      const snapHandleRight = target.closest('.item-container').querySelector('.snap-handle-right');

      const frame = window.mapItems.get(lineSnapper);
      const handlerFrame = window.mapItems.get(lineHandler);
      const frameLineStart = window.mapItems.get(lineStart);
      const frameLineEnd = window.mapItems.get(lineEnd);
      const frameSnapHandleLeft = window.mapItems.get(snapHandleLeft);
      const frameSnapHandleRight = window.mapItems.get(snapHandleRight);

      frame.set('transform', 'translateX', `${parseFloat(frame.get('transform', 'translateX')) + x_diff}px`);
      frame.set('transform', 'translateY', `${parseFloat(frame.get('transform', 'translateY')) + y_diff}px`);
      handlerFrame.set('transform', 'translateX', `${parseFloat(handlerFrame.get('transform', 'translateX')) + x_diff}px`);
      handlerFrame.set('transform', 'translateY', `${parseFloat(handlerFrame.get('transform', 'translateY')) + y_diff}px`);
      frameLineStart.set('transform', 'translateX', `${parseFloat(frameLineStart.get('transform', 'translateX')) + x_diff}px`);
      frameLineStart.set('transform', 'translateY', `${parseFloat(frameLineStart.get('transform', 'translateY')) + y_diff}px`);
      frameLineEnd.set('transform', 'translateX', `${parseFloat(frameLineEnd.get('transform', 'translateX')) + x_diff}px`);
      frameLineEnd.set('transform', 'translateY', `${parseFloat(frameLineEnd.get('transform', 'translateY')) + y_diff}px`);
      frameSnapHandleLeft.set('transform', 'translateX', `${parseFloat(frameSnapHandleLeft.get('transform', 'translateX')) + x_diff}px`);
      frameSnapHandleLeft.set('transform', 'translateY', `${parseFloat(frameSnapHandleLeft.get('transform', 'translateY')) + y_diff}px`);
      frameSnapHandleRight.set('transform', 'translateX', `${parseFloat(frameSnapHandleRight.get('transform', 'translateX')) + x_diff}px`);
      frameSnapHandleRight.set('transform', 'translateY', `${parseFloat(frameSnapHandleRight.get('transform', 'translateY')) + y_diff}px`);

      lineStart.style.cssText += frameLineStart.toCSS();
      lineEnd.style.cssText += frameLineEnd.toCSS();
      snapHandleLeft.style.cssText += frameSnapHandleLeft.toCSS();
      snapHandleRight.style.cssText += frameSnapHandleRight.toCSS();
      lineSnapper.style.cssText += frame.toCSS();
      lineHandler.style.cssText += handlerFrame.toCSS();

      const actualLine = actualTarget.querySelector('.actual-line');
      actualLine.setAttribute('x1', parseFloat(actualLine.getAttribute('x1')) + x_diff);
      actualLine.setAttribute('y1', parseFloat(actualLine.getAttribute('y1')) + y_diff);
      actualLine.setAttribute('x2', parseFloat(actualLine.getAttribute('x2')) + x_diff);
      actualLine.setAttribute('y2', parseFloat(actualLine.getAttribute('y2')) + y_diff);
      lineStart.dataset.cx = actualLine.getAttribute('x1');
      lineStart.dataset.cy = actualLine.getAttribute('y1');
      lineEnd.dataset.cx = actualLine.getAttribute('x2');
      lineEnd.dataset.cy = actualLine.getAttribute('y2');
      snapHandleLeft.dataset.cx = actualLine.getAttribute('x1');
      snapHandleLeft.dataset.cy = actualLine.getAttribute('y1');
      snapHandleRight.dataset.cx = actualLine.getAttribute('x2');
      snapHandleRight.dataset.cy = actualLine.getAttribute('y2');
    } else {
      const frame = window.mapItems.get(target);

      const translateX = parseFloat(frame.get('transform', 'translateX')) + x_diff;
      const translateY = parseFloat(frame.get('transform', 'translateY')) + y_diff;

      frame.set('transform', 'translateX', `${translateX}px`);
      frame.set('transform', 'translateY', `${translateY}px`);

      target.style.cssText += frame.toCSS();

      target.setAttribute('data-translate-x', translateX);
      target.setAttribute('data-translate-y', translateY);

      // Update item panel
      if (document.querySelector(`#edit_item_${target.dataset.itemId}`)) {
        document.querySelector(`#edit_item_${target.dataset.itemId}`).querySelector('#item_translate_x').value = parseInt(translateX, 0);
        document.querySelector(`#edit_item_${target.dataset.itemId}`).querySelector('#item_translate_y').value = parseInt(translateY, 0);
      }
    }

    window.moveableForItems.updateRect();
    window.moveableForGroups.updateRect();
    window.moveableForText.updateRect();
  }

  selectedItems() {
    return document.querySelectorAll('.item-container .selected');
  }

  selectedItemIds() {
    return Array.from(this.selectedItems())
      .map((div) => div.dataset.itemId)
      .join();
  }

  changeSlide(event, slideId) {
    // Multiselect for delete and drag/drop
    if (event && (event.shiftKey || event.metaKey)) {
      return;
    }

    fixSlideIndexes();
    window.hideContextMenus();

    window.moveableForItems.target = null;
    window.moveableForGroups.target = null;
    window.moveableForText.target = null;
    window.moveableForLineHandle.target = null;
    window.moveableForLineBody.target = null;

    // Remove all diagram lines
    document.querySelectorAll('#connectors .leader-line').forEach((line) => {
      line.remove();
    });

    if (!slideId) {
      slideId = event.target.closest('.slide').dataset.slideId;
    }

    let currentSlideChannel = consumer.subscriptions.subscriptions.find((s) =>
      s.identifier.includes('slide_id'),
    );

    if (currentSlideChannel) {
      consumer.subscriptions.remove(currentSlideChannel);
      consumer.subscriptions.create(
        { channel: 'SlideChannel', slide_id: slideId },
        {
          received(data) {
            if (data.cableReady) {
              CableReady.perform(data.operations);
            }
          },
        },
      );
    } else {
      console.error('Could not find channel.');
    }

    this.stimulate('Slide#select', { resolveLate: true }, slideId).then(() => {
      switchMode(this, event, 'Pointer');
      this.clearHighlightPanelTrigger();

      document.querySelector('#pageThumbs').classList.remove('expandedView');
      document.querySelector('.openExpandedViewButton').style.display = 'flex';
      document.querySelector('.closeExpandedViewButton').style.display = 'none';

      if (window.leaderLine) {
        window.leaderLine.remove();
        window.leaderLine = null;
        window.leaderLine2.remove();
        window.leaderLine2 = null;
      }

      if (document.querySelector('#commentFilterMenu')) {
        const currentFilter = document.querySelector('#commentFilterMenuContainer div').innerText;
        if (currentFilter === 'Current Slide') {
          this.stimulate('Comment#filter_current_slide', event.target);
        } else if (currentFilter === 'All Slides') {
          this.stimulate('Comment#filter_all_slides', event.target);
        }
      }
      window.Diagrams.initializeDiagrams()
    });

    this.socket.emit('presentationLogin', {
      presentationId: this.presentationId,
      slideId: slideId,
      user: { id: this.userId, name: this.userName },
    });
  }

  updateSelectedSlide(event) {
    const selectedSlide = document.querySelector(`#page-thumb-container-with-index-${event.detail.slideId}.slideContainer`);
    document.querySelectorAll('.slideContainer.selected').forEach(slide => slide.classList.remove('selected'));
    document.querySelectorAll('.slideContainer.multiSelected').forEach(slide => slide.classList.remove('multiSelected'));
    selectedSlide.classList.add('selected');
  }

  beforeReflex(element, reflex, noop, reflexId) {
    window.hideContextMenus();
  }

  preview(event) {
    event.preventDefault();
    event.stopPropagation();

    // Display full screen loader
    document.querySelector('.canvasContainer').classList.add('hidden');
    document.querySelector('#previewContainer').classList.remove('hidden');
    document.querySelector("#previewIndexContainer .presentation-canvas").innerHTML = '';
    this.openFullScreen(event);

    Rails.ajax({
      url: `/presentations/${this.presentationId}/preview`,
      type: 'GET',
      success: (resp, status, xhr) => {
        this.completeSlideImages(event);
      }
    });
  }

  toggleFullScreen(event) {
    if (document.querySelector('.fullscreen')) {
      this.closeFullscreen(event);
    } else {
      this.openFullScreen(event);
    }
  }

  isFullScreen() {
    const elem = document.querySelector('#previewContainer');
    if (elem.classList.contains('fullscreen')) {
      return true;
    } else {
      return false;
    }
  }

  openFullScreen(event) {
    const presentationId = this.presentationId;
    const elem = document.querySelector('#previewContainer');

    let screenWidth = parseFloat(screen.width);
    let presentationWidth = parseFloat(document.querySelector('.presentation-canvas').offsetWidth);

    let innerWidth = parseFloat(window.innerWidth);
    let outerwidth = parseFloat(window.outerWidth);

    if (innerWidth != outerwidth) {
      presentationWidth *= outerwidth / innerWidth;
    }

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }

    elem.classList.add('fullscreen');
    elem.style.zoom = screenWidth / presentationWidth;
    if (isTouchDevice()) {
      elem.style.zoom = elem.style.zoom * 1.5;
    }
  }

  closeFullscreen(event) {
    const presentationId = this.presentationId;
    const elem = document.querySelector('#previewContainer');

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }

    elem.classList.remove('fullscreen');
    elem.querySelector('.presentation-canvas').style.zoom = 1;
  }

  previewPreviousSlide(event) {
    event.preventDefault();
    event.stopPropagation();
    let target = document.querySelector('#previewIndexContainer');

    let currentIndex = parseInt(target.dataset.currentIndex);
    let pageCount = parseInt(target.dataset.pageCount);

    if (currentIndex !== 0) {
      target.querySelectorAll('.slide').forEach((slide) => {
        slide.classList.add('hidden');
      });
      target
        .querySelector(`.slide[data-slide-index="${currentIndex - 1}"]`)
        .classList.remove('hidden');
      target.dataset.currentIndex = currentIndex - 1;
    }

    this.completeSlideImages(event);
  }

  previewNextSlide(event) {
    event.preventDefault();
    event.stopPropagation();

    let target = document.querySelector('#previewIndexContainer');

    let currentIndex = parseInt(target.dataset.currentIndex);
    let pageCount = parseInt(target.dataset.pageCount);

    if (currentIndex < pageCount - 1) {
      target.querySelectorAll('.slide').forEach((slide) => {
        slide.classList.add('hidden');
      });
      target
        .querySelector(`.slide[data-slide-index="${currentIndex + 1}"]`)
        .classList.remove('hidden');
      target.dataset.currentIndex = currentIndex + 1;
    }

    this.completeSlideImages(event);
  }

  completeSlideImages(event) {
    let unloadedImages = document.querySelectorAll('#previewContainer .unloaded-image');
    unloadedImages.forEach((image) => {
      let tempImage = new Image();
      tempImage.src = image.getAttribute('data-background-image');
      image.style.backgroundImage = `url("${image.getAttribute('data-background-image')}")`;
      image.classList.remove('unloaded-image');
    });
  }

  goBackToPost() {
    if (document.referrer.includes('nuvu')) {
      window.location.replace(document.referrer);
    } else {
      window.location = `/posts/${window.postId}`;
    }
  }

  goBackToUrl(event) {
    event.preventDefault();
    window.location = event.currentTarget.dataset.url;
  }

  clearHighlightPanelTrigger(event) {
    Array.from(document.querySelectorAll('.panel-trigger')).map(
      (item) => {
        item.classList.remove('text-pink');
        Array.from(item.querySelectorAll('svg:not(.shape) path')).map((path) => path.setAttribute('stroke', 'black'));
      }
    );
    if (document.getElementById('left-template-button')) {
      Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('fill', 'black'));
      Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('stroke', ''));
    }
    Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('stroke', 'none'));
    Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('fill', 'black'));
    Array.from(document.querySelectorAll('svg.only-fill path')).map((path) => path.setAttribute('fill', 'black'));

    document.querySelector('.button.pen svg path').setAttribute('fill', '');
  }

  highlightPanelTrigger(event) {
    event.stopPropagation();
    this.clearHighlightPanelTrigger();

    if (event.target && !event.target.closest('.button.pen')) {
      document.querySelector('#pen').pen.deactivate();
    }

    document.querySelector('#topbar').topbar.disablePanZoom();

    document.body.classList.remove('handMode', 'dragscroll', 'commentMode', 'insertCommentMode');
    if (event.target.closest('.comment-mode-button')) {
      document.body.classList.add('commentMode', 'insertCommentMode');
    } else {
      document.body.classList.add('pointerMode');
    }

    let target = event.target.closest(".panel-trigger");

    Array.from(target.querySelectorAll('svg:not(.shape) path')).map((path) => path.setAttribute('stroke', '#f35d91'));
    Array.from(target.querySelectorAll('svg.only-fill path')).map((path) => path.setAttribute('fill', '#f35d91'));
    Array.from(target.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('stroke', 'none'));
    Array.from(target.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('fill', '#f35d91'));

    if (target.id && target.id === 'left-template-button') {
      Array.from(target.querySelectorAll('svg path')).map((path) => path.setAttribute('fill', '#f35d91'));
      Array.from(target.querySelectorAll('svg path')).map((path) => path.setAttribute('stroke', ''));
    }

    if (target.id && target.id == 'diagram-button') {
      if (!window.Diagrams.isDiagramToolActive) {
        window.Diagrams.isDiagramToolActive = true;
      }
    } else {
      window.Diagrams.isDiagramToolActive = false;
    }

    //Hide all other types of panels
    hideCommentPanel();
    hideCommentBox();
    hideItemPanel();
    window.hideContextMenus();

    if (document.getElementById('left-template-button')) {
      Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('fill', 'black'));
      Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('stroke', ''));
    }
    Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('stroke', 'none'));
    Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('fill', 'black'));

    if (document.getElementsByClassName("share-button")[0].classList.length > 9) {
      document.getElementById("shareButton").click()
    }
    if (document.getElementsByClassName("comments-button")[0].classList.length > 9) {
      document.getElementById("commentButton").click()
    }
  }

  _generateRandomString() {
    return Math.round((Math.random() * 36 ** 12)).toString(36);
  }

  addSlide(event) {
    event.preventDefault();
    event.stopPropagation();
    this.stimulate('Slide#add', { resolveLate: true }, this.presentationId).then((payload) => {
      const nextSlide = document.querySelector('.slideContainer.selected + .slideContainer[data-is-deleted="false"]')
      if (nextSlide) {
        this.changeSlide(event, nextSlide.dataset.id);
        storeSlideSnapshotForUndo(nextSlide.dataset.id, 'ADD_SLIDE');
      } else {
        const firstSlide = document.querySelector('.slideContainer[data-is-deleted="false"]');
        this.changeSlide(event, firstSlide.dataset.id);
        storeSlideSnapshotForUndo(firstSlide.dataset.id, 'ADD_SLIDE');
      }
      fixSlideIndexes();
    });
  }

  disallowDeselect(ms = 10) {
    // To prevent clickking whitespace
    window.draggingElement = true;
    setTimeout(function () {
      window.draggingElement = false;
    }, 10);
  }

  disableQuill() {
    document.querySelectorAll('.text-content .ql-editor[contenteditable]').forEach((el) => {
      el.closest('.item-type-text').classList.remove('inline-edit');
      el.closest('.item-type-text').setAttribute('draggable', 'true');
      const quill = Quill.find(el.closest('.text-content'));
      if (quill) quill.disable();
    });

    // Quill instances on table
    document.querySelectorAll('.table-cell-content .ql-editor[contenteditable="true"]').forEach((el) => {
      const quill = Quill.find(el.parentNode);
      if (quill) {
        quill.disable();
      }
    });
  }

  toggleGroupForItems(event) {
    const generatedId = uuidv4();
    const selectedItems = this.selectedItems();
    const selectedItemsForMoveable = document.querySelectorAll('.item-container .selected:not(.item-type-line), .item-container.selected .line-snapper');
    selectedItems.forEach((item => {
      if (item.dataset.groupId === '') {
        item.dataset.groupId = generatedId;
      } else {
        item.dataset.groupId = '';
      }
    }))
    const groupId = selectedItems[0].dataset.groupId;
    this.stimulate('Item#set_group', { resolveLate: true }, this.selectedItemIds(), groupId).then(() => {
      if (groupId !== '') {
        window.moveableForGroups.target = selectedItemsForMoveable;
      }
    })
  }

  switchToPreviousSlide(event) {
    event.preventDefault();
    event.stopPropagation();
    const allSlidePositions = Array.from(document.querySelectorAll('.slideContainer[data-is-deleted="false"]')).map((slide) => slide.dataset.position);
    const selectedSlidePosition = document.querySelector('.slideContainer.selected').dataset.position;
    const previousSlidePosition = allSlidePositions[allSlidePositions.indexOf(selectedSlidePosition) - 1];
    if (previousSlidePosition) {
      const previousSlide = document.querySelector(`.slideContainer[data-position="${previousSlidePosition}"]`);
      this.changeSlide(event, previousSlide.dataset.id);
    }
  }

  switchToNextSlide(event) {
    event.preventDefault();
    event.stopPropagation();
    const allSlidePositions = Array.from(document.querySelectorAll('.slideContainer[data-is-deleted="false"]')).map((slide) => slide.dataset.position);
    const selectedSlidePosition = document.querySelector('.slideContainer.selected').dataset.position;
    const previousSlidePosition = allSlidePositions[allSlidePositions.indexOf(selectedSlidePosition) + 1];
    if (previousSlidePosition) {
      const previousSlide = document.querySelector(`.slideContainer[data-position="${previousSlidePosition}"]`);
      this.changeSlide(event, previousSlide.dataset.id);
    }
  }

  snapshotHistoryForPastedItems(event) {
    console.log(event.detail.newItemIds);
    window.moveableForItems.target = null;
    window.moveableForGroups.target = null;
    window.moveableForText.target = null;

    const items = event.detail.newItemIds.map(id => document.getElementById(`draggable-item-container-${id}`));

    clearCloneCursor();

    let selectedItems = document.querySelectorAll('.item-container .selected');
    for (const selectedItem of selectedItems) {
      selectedItem.classList.remove('selected');
      if (selectedItem.closest('.item-container').querySelector('.handle')) {
        selectedItem.closest('.item-container').querySelector('.handle-left').classList.add('hidden');
        selectedItem.closest('.item-container').querySelector('.handle-right').classList.add('hidden');
      }
    }

    window.moveableForItems.target = null;
    window.moveableForGroups.target = null;
    window.moveableForText.target = null;
    window.moveableForLineHandle.target = null;
    window.moveableForLineBody.target = null;

    if (items.length > 1) {
      window.moveableForGroups.target = items;
    } else {

      const selectedItem = items[0]
      if (selectedItem.dataset.itemType === 'text') {
        window.moveableForText.target = selectedItem;
      } else if (selectedItem.dataset.itemType === 'line') {
        const event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        });
        selectedItem.nextElementSibling.dispatchEvent(event);
      } else {
        window.moveableForItems.target = selectedItem;
      }
    }

    const randomSelectId = Math.round((Math.random() * 36 ** 12)).toString(36);
    event.detail.newItemIds.forEach((itemId) => {
      console.log(itemId);
      storeItemSnapshotForUndo(itemId, 'ADD_SLIDE_ITEM', randomSelectId);
    });
  }

  snapshotHistoryForMovedItems(event) {
    const {idsForSnapshot, fromSlideId, toSlideId} = event.detail
    const randomSelectId = Math.round((Math.random() * 36 ** 12)).toString(36);
    idsForSnapshot.forEach((ids) => {
      const [deletedItemId, addedItemId] = ids
      storeItemSnapshotForUndo(deletedItemId, 'DELETE_SLIDE_ITEM', randomSelectId, {addedItemId})
      storeItemSnapshotForUndo(addedItemId, 'ADD_SLIDE_ITEM', randomSelectId, {deletedItemId})
    });
  }

  triggerSelectItems(event) {
    const items = event.detail.itemIds.map(id => document.getElementById(`draggable-item-container-${id}`));
    items.forEach((item) => item.classList.add('selected'));

    window.moveableForItems.target = null;
    window.moveableForGroups.target = null;
    window.moveableForText.target = null;
    window.moveableForLineHandle.target = null;
    window.moveableForLineBody.target = null;

    if (items.length > 1) {
      window.moveableForGroups.target = items;
    } else {
      const selectedItem = items[0]
      if (selectedItem.dataset.itemType === 'text') {
        window.moveableForText.target = selectedItem;
      } else if(selectedItem.dataset.itemType === 'line') {

      } else if (selectedItem.dataset.itemType === 'diagram_line') {
        document.getElementById(`draggable-item-container-${selectedItem.dataset.itemId}`).classList.add('selected');
        this.stimulate('Item#select_line_tool', { itemIds: [selectedItem.dataset.itemId] }).then(() => {
          setTimeout(() => {
            document.querySelector("#connectors .leader-line:last-of-type").classList.add('active');
          }, 250)
        });
      } else {
        window.moveableForItems.target = selectedItem;
      }
    }
  }

  deleteSlide() {
    deleteSlide(event, this);
  }

  togglePanZoom() {
    document.querySelector('.pan-zoom').classList.toggle('active');
    document.querySelector('#eventDisabler').classList.toggle('hidden');
    if(document.querySelector('.pan-zoom').classList.contains('active')) {
      if(window.selectoForItems) {
        window.selectoForItems.destroy();
        window.selectoForItems = null;
      }
      document.querySelector('#pen').classList.add('hidden');
    } else {
      document.querySelector('.canvas').moveable.activateSelecto();
    }
  }
}
