import { Controller } from "stimulus";
export default class extends Controller {
    static targets = ['register', 'contactinput']
    registerOver(){
        if(this.registerTarget.classList.contains('bg-black')){
            this.registerTarget.classList.remove('bg-black');
            this.registerTarget.classList.add('bg-pink');
        }
    }

    registerOut(){
        if(this.registerTarget.classList.contains('bg-pink')){
            this.registerTarget.classList.remove('bg-pink');
            this.registerTarget.classList.add('bg-black');
        }
    }

    validateEmptyInputs(){
        let status = true;
        this.contactinputTargets.forEach(contactInput => {
            if(contactInput.value === ""){
                status = false;
            }
        });
        return status;
    }

    sendContactUsForm(){
        document.getElementById('submit-contact-us').click();
        return;
        const status = this.validateEmptyInputs();
        if(status === true){
            document.getElementById('submit-contact-us').click();
        }
    }
}
