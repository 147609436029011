import { Controller } from 'stimulus';
import { useIntersection } from 'stimulus-use';
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    useIntersection(this, {
      root: document.querySelector('.assetList'),
      rootMargin: '200px',
    });
  }

  appear() {
    this.stimulate('MediaHub#camera_assets');
  }
}
