const clearCloneCursor = () => {
  const items = document.querySelectorAll('.draggable-item-container');
  items.forEach((item) => {
    item.style.cursor = 'auto';
    if (item.dataset.itemType === 'line') {
      const handleElement = item.closest('.item-container').querySelector('.line-handler');
      handleElement.style.cursor = 'move';
    }

    if (item.dataset.itemType === 'text') {
      const qlEditorCursorContainer = item.querySelectorAll('.ql-editor > *');
      qlEditorCursorContainer.forEach((node) => {
        node.style.removeProperty('cursor');
      });
    }
  });

  const multipleItems = document.querySelector('.moveable-area');
  if (multipleItems) {
    multipleItems.style.cursor = 'auto';
  }
};

export default clearCloneCursor;
