import itemType from '../itemType';

const undeleteItem = (params, itemIds) => {
  // Set data attribute
  // target.dataset.isDeleted = false;

  // Unselect item
  // target.classList.remove('selected');

  // Insert item into the canvas
  params.controller.stimulate('Item#undelete', itemIds);
};

const undeleteLineItem = (params) => {
  // Set data attribute
  // target.closest('.item-container').querySelector('.draggable-item-container').dataset.isDeleted = false;
  // target.closest('.item-container').querySelector('.handle-left').dataset.isDeleted = false;
  // target.closest('.item-container').querySelector('.handle-right').dataset.isDeleted = false;
  // target.closest('.item-container').querySelector('.line-snapper').dataset.isDeleted = false;

  // Unselect item
  // target.closest('.item-container').querySelector('.draggable-item-container').classList.remove('selected');
  // target.closest('.item-container').querySelector('.handle-left').classList.add('hidden');
  // target.closest('.item-container').querySelector('.handle-right').classList.add('hidden');
};

const undeleteItems = (params, ...args) => {
  const itemIds = args.flat();
  undeleteItem(params, itemIds);
  /* return;

  if (itemIds.length === 1) {
    const itemId = itemIds[0];
    if (itemType(itemId) === 'line') {
      undeleteLineItem(params, itemId);
    } else {
      undeleteItem(params, itemId);
    }
  } else if (itemIds.length > 1) {
    undeleteItem(params, itemIds);
  } */

  window.hideContextMenus();
};

export default undeleteItems;
