import ApplicationController from './application_controller.js';
import hideCommentPanel from "../helpers/editor/panels/hideCommentPanel";
import hideCommentBox from '../helpers/editor/comments/hideCommentBox';
import hideItemPanel from '../helpers/editor/panels/hideItemPanel';


export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  togglePanel(event) {
    event.preventDefault();
    event.stopPropagation();

    this.deselectNotShare(event);
    const shareContainer = document.querySelector('.shareContainer');

    if (shareContainer.innerHTML) {
      shareContainer.innerHTML = '';
      event.target.closest('a').classList.remove('bg-pink', 'text-white');
      event.target.closest('a').classList.add('border-black');
    } else {
      this.stimulate('Share#render_panel', event.target);
      event.target.closest('a').classList.add('bg-pink', 'text-white');
      event.target.closest('a').classList.remove('border-black');
    }

  }

  toggleShareCollaboratorMenu(event) {
    event.preventDefault();
    event.stopPropagation();

    const menu = event.currentTarget.querySelector(".collaboratorActionsMenu")
    if (menu) {
      menu.classList.toggle("hidden");
    }
  }

  deselectNotShare(event) {
    event.preventDefault();
    event.stopPropagation();

    hideCommentPanel();
    hideCommentBox();
    hideItemPanel();

    if (document.getElementById('left-template-button')) {
      Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('fill', 'black'));
      Array.from(document.getElementById('left-template-button').querySelectorAll('svg path')).map((path) => path.setAttribute('stroke', ''));
    }
    Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('stroke', 'none'));
    Array.from(document.querySelectorAll('#leftBarFetchButton svg path')).map((path) => path.setAttribute('fill', 'black'));

    if (document.getElementsByClassName("comments-button")[0].classList.length > 9){
      document.getElementById("commentButton").click()
    }

  }
}
